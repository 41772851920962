import ConceptsEditor from 'components/ConceptsEditor/ConceptsEditor';
import ThemeDiscovery, { InitialThemeDiscoveryOptions } from 'components/ThemeDiscovery/ThemeDiscovery';
import ApplyButton from 'components/ThemeEditor/ApplyButton';
import ParametersEditor from 'components/ThemeEditor/ParametersEditor/ParametersEditor';
import RevertButton from 'components/ThemeEditor/RevertButton';
import SurveySelector from 'components/ThemeEditor/SurveySelector';
import analytics from 'lib/analytics';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import { UserStoreInterface } from 'stores/UserStore';
import { ImportThemes } from './ImportThemes/ImportThemes';
import RefreshMappedPhrases from './RefreshMappedPhrases/RefreshMappedPhrases';
import ReorganizeThemes from './ReorganizeThemes/ReorganizeThemes';
import ScoreThemeQuality from './ScoreThemeQuality/ScoreThemeQuality';
import ThemeAdminDropdown from './ThemeAdminDropdown';

interface ThemeHeaderStoreProps {
  surveyStore?: SurveyStoreInterface;
  themesStore?: ThemesStoreInterface;
  userStore?: UserStoreInterface;
}

interface ThemeHeaderProps extends ThemeHeaderStoreProps {
  orgId: string;
  surveyId: string;
  hideEditorOptions?: boolean;
  initialAction?: string;
  initialActionOptions?: object;
}

interface ThemeHeaderState {
  conceptsEditorOpen: boolean;
  importThemesOpen: boolean;
  parametersEditorOpen: boolean;
  scoreThemesOpen: boolean;
  refreshPhrasesOpen: boolean;
  showThemesDiscovery: boolean;
  reorganizeThemesOpen: boolean;
  initialDiscoveryOptions: InitialThemeDiscoveryOptions;
}

@inject('surveyStore', 'themesStore', 'userStore')
@observer
export default class ThemeHeader extends React.Component<
  ThemeHeaderProps,
  ThemeHeaderState
> {
  state = {
    conceptsEditorOpen: false,
    importThemesOpen: false,
    parametersEditorOpen: false,
    scoreThemesOpen: false,
    refreshPhrasesOpen: false,
    showThemesDiscovery: false,
    initialDiscoveryOptions: {
      selectedTheme: undefined,
      startImmediately: false
    } as InitialThemeDiscoveryOptions
  } as ThemeHeaderState;

  componentDidMount = () => {
    const { initialAction } = this.props;
    if (initialAction === 'discoverThemes') {
      // set the options and then open the modal
      this.setState(
        { initialDiscoveryOptions: this.props.initialActionOptions as InitialThemeDiscoveryOptions },
        this.openDiscoverThemes);
    }
  }

  openConceptsEditor = () => {
    this.setState({ conceptsEditorOpen: true });

    analytics.track('Concepts Editor: Opened');
  }
  closeConceptsEditor = () => {
    this.setState({ conceptsEditorOpen: false });
  }
  openImportThemes = () => {
    this.setState({ importThemesOpen: true });
  }
  closeImportThemes = () => {
    this.setState({ importThemesOpen: false });
  }
  openParametersEditor = () => {
    this.setState({ parametersEditorOpen: true });
  }
  closeParametersEditor = () => {
    this.setState({ parametersEditorOpen: false });
  }
  openScoreThemes = () => {
    this.setState({ scoreThemesOpen: true });
  }
  closeScoreThemes = () => {
    this.setState({ scoreThemesOpen: false });
  }
  openRefreshPhrases = () => {
    this.setState({ refreshPhrasesOpen: true });
  }
  closeRefreshPhrases = () => {
    this.setState({ refreshPhrasesOpen: false });
  }
  openDiscoverThemes = () => {
    this.setState({ showThemesDiscovery: true });

    analytics.track('Theme Discovery: Open discover themes', {
      category: 'Theme Discovery',
      survey: this.props.surveyId
    });
  }
  closeDiscoverThemes = () => {
    // if discover themes is closed, we reset the initial state for next time it is opened
    this.setState({
      showThemesDiscovery: false, initialDiscoveryOptions: {
        selectedTheme: undefined,
        startImmediately: false
      }
    });
  }
  openReorganizeThemes = () => {
    this.setState({ reorganizeThemesOpen: true });
  }
  closeReorganizeThemes = () => {
    this.setState({ reorganizeThemesOpen: false });
  }
  render() {
    const { orgId, surveyId, surveyStore, themesStore, hideEditorOptions } = this.props;
    const {
      conceptsEditorOpen,
      importThemesOpen,
      parametersEditorOpen,
      scoreThemesOpen,
      refreshPhrasesOpen,
      showThemesDiscovery,
      reorganizeThemesOpen,
      initialDiscoveryOptions
    } = this.state;
    const title = surveyStore!.getSurveyTitle(surveyId);
    const userCanAdminSurvey = surveyStore!.getSurveyCanAction(surveyId, 'manage:internalResource');
    const {
      applying,
      discovering,
      hasDraft,
      hasGroups,
      revertItems,
      loading,
      reverting,
      working,
      unsavedCount,
      hasNewThemes
    } = themesStore!;

    if (hideEditorOptions) {
      return (
        <div className="theme-groups__header">
          <SurveySelector orgId={orgId} surveyId={surveyId} />
        </div>
      );
    }

    return (
      <div className="theme-groups__header">
        <SurveySelector orgId={orgId} surveyId={surveyId} />
        {userCanAdminSurvey && (
          <ThemeAdminDropdown
            orgId={orgId}
            surveyId={surveyId}
            openConceptsEditor={this.openConceptsEditor}
            openImportThemes={this.openImportThemes}
            openParametersEditor={this.openParametersEditor}
            openScoreThemes={this.openScoreThemes}
            openRefreshPhrases={this.openRefreshPhrases}
            openReorganizeThemes={this.openReorganizeThemes}
          />
        )}
        <Button
          disabled={working || !hasGroups}
          onClick={this.openDiscoverThemes}
          size="small"
        >
          Discover themes
        </Button>
        {showThemesDiscovery && <ThemeDiscovery
          orgId={orgId}
          surveyId={surveyId}
          initialOptions={initialDiscoveryOptions}
          close={this.closeDiscoverThemes}
        />}
        <RevertButton
          disabled={working}
          hasDraft={!!hasDraft}
          reverting={reverting}
          surveyName={title}
          revertItems={revertItems}
        />
        <ApplyButton
          surveyName={title}
          applying={false}
          disabled={reverting || applying || loading || discovering}
          hasNewThemes={hasNewThemes}
          dirty={!!hasDraft || unsavedCount > 0}
        />
        {conceptsEditorOpen &&
          <ConceptsEditor
            cancel={this.closeConceptsEditor}
            surveyName={title}
            orgId={orgId}
            surveyId={surveyId}
          />
        }
        {importThemesOpen &&
          <ImportThemes
            onClose={this.closeImportThemes}
          />
        }
        <ParametersEditor
          cancel={this.closeParametersEditor}
          open={parametersEditorOpen}
          surveyName={title}
          orgId={orgId}
          surveyId={surveyId}
        />
        <ScoreThemeQuality
          cancel={this.closeScoreThemes}
          open={scoreThemesOpen}
          surveyId={surveyId}
        />
        <RefreshMappedPhrases
          cancel={this.closeRefreshPhrases}
          open={refreshPhrasesOpen}
          surveyId={surveyId}
        />
        {reorganizeThemesOpen && (
          <ReorganizeThemes
            cancel={this.closeReorganizeThemes}
            open={reorganizeThemesOpen}
            surveyId={surveyId}
          />
        )}
      </div>
    );
  }
}
