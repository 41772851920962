import { DataIntegrationStatus } from 'api/enums';
import {
  DataDestinationIntegration,
  DataIntegrationRun
} from 'api/interfaces';
import auth from 'Auth/Auth';
import { action, observable } from 'mobx';
import config from 'runtime-config';
import { OrganizationStoreInterface } from './OrganizationStore';

export interface DataDestinationType {
  name: string;
  requiresIntegration: boolean;
  availableIntegrations: boolean;
}
export interface DataDestinationOptions {
  type?: string;
  integrationId?: string;
  configuration?: object;
}


export interface DataDestinationStoreInterface {
  getAvailableDataDestinations: () => Promise<void>;

  setCurrentSurveyId: (surveyId: string) => void;
  resetCurrentSurveyId: () => void;

  getDataDestinations: (surveyId: string) => Promise<DataDestinationIntegration[]>;
  createDataDestination: (surveyId: string, dataDestinationOptions: DataDestinationOptions) =>
    Promise<DataDestinationIntegration | undefined>;
  updateDataDestination: (surveyId: string, dataDestinationId: string, dataDestinationOptions: DataDestinationOptions) =>
    Promise<DataDestinationIntegration | undefined>;
  deleteDataDestination: (surveyId: string, dataDestinationId: string) => void;


  getDataDestinationHistory: (surveyId: string, dataDestinationId: string) =>
    Promise<DataIntegrationRun[]>;
  pushSurveyDataDestination: (surveyId: string, dataDestinationId: string) => void;
  setDataDestinationStatus: (surveyId: string, dataDestinationId: string, status: DataIntegrationStatus) =>
    Promise<DataDestinationIntegration | undefined>;

  reset: () => void;

  pushingDataDestination: boolean;
  runPushDataDestinationError?: string;

  creatingDataDestination: boolean;
  createDataDestinationError?: string;

  updatingDataDestination: boolean;
  updateDataDestinationError?: string;

  deletingDataDestination: boolean;
  deleteDataDestinationError?: string;

  fetchingDataDestinationRuns: boolean;
  fetchDataDestinationRunsError?: string;

  listSurveysDataDestinationsError?: string;
  listSurveysDataDestinationsLoading: boolean;

  listAvailableDataDestinationsError?: string;
  listAvailableDataDestinationsLoading: boolean;

  availableDestinationsFetched: boolean;
  dataDestinationTypes: DataDestinationType[];

  dataDestinationsFetched: boolean;
  dataDestinations: DataDestinationIntegration[];

  currentSurveyId: string;
}

class DataDestinationStore implements DataDestinationStoreInterface {
  currentOrg: OrganizationStoreInterface;

  @observable
  pushingDataDestination = false;

  @observable
  runPushDataDestinationError = undefined;

  @observable
  updatingDataDestination = false;
  @observable
  updateDataDestinationError = undefined;

  @observable
  creatingDataDestination = false;
  @observable
  createDataDestinationError = undefined;

  @observable
  deletingDataDestination = false;
  @observable
  deleteDataDestinationError = undefined;

  @observable
  fetchingDataDestinationRuns = false;
  @observable
  fetchDataDestinationRunsError = undefined;

  @observable
  listAvailableDataDestinationsError = undefined as string | undefined;
  @observable
  listAvailableDataDestinationsLoading = false;

  @observable
  listSurveysDataDestinationsError = undefined as string | undefined;
  @observable
  listSurveysDataDestinationsLoading = false;

  @observable
  dataDestinationsFetched = false;

  @observable
  availableDestinationsFetched = false;
  @observable
  dataDestinations = [] as DataDestinationIntegration[];

  @observable
  dataDestinationTypes = [] as DataDestinationType[];

  @observable
  currentSurveyId = '';

  constructor(organizationStore: OrganizationStoreInterface) {
    this.currentOrg = organizationStore;
  }

  @action
  setCurrentSurveyId = (surveyId: string) => {
    this.currentSurveyId = surveyId;
  }

  @action
  resetCurrentSurveyId = () => {
    this.currentSurveyId = '';
  }

  private async apiCall<T>(
    endpoint: string,
    options: {
      method?: string;
      body?: string;
      errorMessage?: string;
      loadingState?: string;
      errorState?: string;
    } = {}
  ): Promise<T | undefined> {
    const {
      method = 'GET',
      body,
      errorMessage = 'An error occurred',
      loadingState,
      errorState,
    } = options;

    if (loadingState) {
      this[loadingState] = true;
    }
    if (errorState) {
      this[errorState] = undefined;
    }

    try {
      const { data, ok } = await auth.fetch<T>(endpoint, {
        method,
        body,
      });

      if (!data || !ok) {
        throw new Error(errorMessage);
      }
      return data;
    } catch (e) {
      if (errorState) {
        this[errorState] = e.message;
      }
      return undefined;
    } finally {
      if (loadingState) {
        this[loadingState] = false;
      }
    }
  }

  @action
  getAvailableDataDestinations = async () => {
    if (this.availableDestinationsFetched) {
      return;
    }

    const { apiLocation } = config;
    const params = this.currentOrg.orgIdQueryParam;
    const listUrl = [apiLocation, `/dataDestinations?${ params }`].join('/');

    const data = await this.apiCall<DataDestinationType[]>(listUrl, {
      loadingState: 'listAvailableDataDestinationsLoading',
      errorState: 'listAvailableDataDestinationsError',
      errorMessage: 'Failed to fetch data destinations',
    });

    if (data) {
      this.availableDestinationsFetched = true;
      this.dataDestinationTypes = data;
    }
  }

  @action
  getDataDestinations = async (surveyId: string) => {
    if (this.dataDestinationsFetched) {
      return this.dataDestinations;
    }

    const url = [config.apiLocation, `survey/${ surveyId }/dataDestinations`].join('/');

    const data = await this.apiCall<DataDestinationIntegration[]>(url, {
      loadingState: 'listSurveysDataDestinationsLoading',
      errorState: 'listSurveysDataDestinationsError',
      errorMessage: 'Failed to fetch data destinations',
    });

    if (data) {
      this.dataDestinations = data;
      this.dataDestinationsFetched = true;
    }
    return this.dataDestinations;
  }

  @action
  createDataDestination = async (
    surveyId: string,
    dataDestinationOptions: DataDestinationOptions
  ) => {
    const createUrl = [config.apiLocation, `survey/${ surveyId }/dataDestination`].join('/');

    const result = await this.apiCall<DataDestinationIntegration>(createUrl, {
      method: 'POST',
      body: JSON.stringify(dataDestinationOptions),
      loadingState: 'creatingDataDestination',
      errorState: 'createDataDestinationError',
      errorMessage: 'Error creating data destination',
    });

    if (result) {
      this.dataDestinations.unshift(result);
    }

    return result;
  }

  @action
  updateDataDestination = async (
    surveyId: string,
    dataDestinationId: string,
    dataDestinationOptions: DataDestinationOptions
  ) => {
    const updateUrl = [config.apiLocation, `survey/${ surveyId }/dataDestination/${ dataDestinationId }`].join('/');

    const result = await this.apiCall<DataDestinationIntegration>(updateUrl, {
      method: 'PUT',
      body: JSON.stringify(dataDestinationOptions),
      loadingState: 'updatingDataDestination',
      errorState: 'updateDataDestinationError',
      errorMessage: 'Error updating data destination',
    });

    if (result) {
      this.dataDestinations = this.dataDestinations.map(destination => destination.id === dataDestinationId ? result : destination);
    }

    return result;
  };

  @action
  deleteDataDestination = async (surveyId: string, dataDestinationId: string) => {
    const deleteUrl = [config.apiLocation, `survey/${ surveyId }/dataDestination/${ dataDestinationId }`].join('/');

    await this.apiCall<DataDestinationIntegration>(deleteUrl, {
      method: 'DELETE',
      loadingState: 'deletingDataDestination',
      errorState: 'deleteDataDestinationError',
      errorMessage: 'Error deleting data destination',
    });

    this.dataDestinations = this.dataDestinations.filter(destination => destination.id !== dataDestinationId);
  }

  @action
  setDataDestinationStatus = async (surveyId: string, dataDestinationId: string, status: DataIntegrationStatus) => {
    const statusUrl = status === DataIntegrationStatus.STOPPED
      ? `/survey/${ surveyId }/dataDestination/${ dataDestinationId }/pause`
      : `/survey/${ surveyId }/dataDestination/${ dataDestinationId }/start`;

    const result = await this.apiCall<DataDestinationIntegration>(statusUrl, {
      loadingState: 'updatingDataDestination',
      errorState: 'updateDataDestinationError',
      errorMessage: 'Error setting data destination status',
    });

    if (result) {
      this.dataDestinations = this.dataDestinations.map(destination => destination.id === dataDestinationId ? result : destination);
    }

    return result;
  }

  @action
  pushSurveyDataDestination = async (surveyId: string, dataDestinationId: string) => {
    const pushUrl = `/survey/${ surveyId }/dataDestination/${ dataDestinationId }/push`;

    return await this.apiCall<boolean>(pushUrl, {
      loadingState: 'pushingDataDestination',
      errorState: 'runPushDataDestinationError',
      errorMessage: 'Error pushing data destination',
    });
  }

  @action
  getDataDestinationHistory = async (surveyId: string, dataDestinationId: string) => {
    const historyUrl = `/survey/${ surveyId }/dataDestination/${ dataDestinationId }/runs`;

    const data = await this.apiCall<DataIntegrationRun[]>(historyUrl, {
      loadingState: 'fetchingDataDestinationRuns',
      errorState: 'fetchDataDestinationRunsError',
      errorMessage: 'Error retrieving data destination history',
    });

    return data || [];
  }

  @action
  reset = () => {
    this.dataDestinations = [];
    this.dataDestinationTypes = [];
    this.dataDestinationsFetched = false;
    this.availableDestinationsFetched = false;
  }
}

export default DataDestinationStore;
