import auth from 'Auth/Auth';
import { IntegrationType } from 'api/enums';
import { ConnectedIntegration } from 'api/interfaces';
import { Fetchable } from 'lib/fetch';
import { filter, find, forEach } from 'lodash';
import { action, observable, reaction } from 'mobx';
import { stringify } from 'query-string';
import { OrganizationStoreInterface } from './OrganizationStore';

export enum IntegrationConnectionType {
  OAUTH = 'oauth',
  FIELDS = 'fields',
  OAUTH_WITH_FIELDS = 'oauth-fields'
}

export interface IntegrationField {
  name: string;
  param: string;
  paramType?: string;
  needsHelp?: boolean;
  multipleValues?: boolean;
}

export interface Integration {
  authorizedAt?: string;
  authorizedBy?: string;
  connected: boolean;
  testable: boolean;
  integrationUrl?: string;
  name: string;
  id?: string;
  type: IntegrationType;
  connectionType: IntegrationConnectionType;
  requiredFields?: IntegrationField[];
}

export interface IntegrationsStoreInterface {
  checkIntegrations: () => void;
  createIntegration: (authInfo: object, type: IntegrationType) => Promise<ConnectedIntegration | undefined>;
  deleteIntegration: (integrationId: string) => void;

  getIntegrationTypeDetails: (type: IntegrationType) => Integration | undefined;
  getConnectedIntegrationsOfType: (type: IntegrationType) => ConnectedIntegration[];
  getIntegrationById: (integrationId: string) => ConnectedIntegration | undefined;
  enumerateIntegration: (integrationId: string, params?: object) => Promise<object | undefined>;
  testIntegration: (integrationId: string, params?: object) => Promise<boolean>;

  checking: boolean;
  creating: boolean;
  deleting: boolean;
  errored: boolean;
  enumerating: boolean;
  createIntegrationErroredMessage?: string;
  enumerateIntegrationErroredMessage?: string;

  integrations: Integration[];
  connectedIntegrations: ConnectedIntegration[];
}

class IntegrationsStore {
  currentOrg: OrganizationStoreInterface;

  @observable
  checking = false;

  @observable
  creating = false;

  @observable
  deleting = false;

  @observable
  enumerating = false;

  lastCheck?: Promise<Fetchable<{}>> = undefined;

  @observable
  integrations: Integration[] = [];

  @observable
  connectedIntegrations: ConnectedIntegration[] = [];

  @observable
  errored = false;

  @observable
  createIntegrationErroredMessage?: string = undefined;

  @observable
  enumerateIntegrationErroredMessage = '';

  constructor(currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;

    // React to orgId changes
    reaction(
      () => this.currentOrg.orgId,
      () => this.orgUpdated(),
      { fireImmediately: true }
    );
  }

  @action
  getIntegrationTypeDetails = (type: IntegrationType): Integration | undefined => {
    return find(this.integrations, { type });
  }

  @action
  getConnectedIntegrationsOfType = (type: IntegrationType): ConnectedIntegration[] => {
    const existing = filter(this.connectedIntegrations, { type });
    return existing;
  }

  @action
  getIntegrationById = (integrationId: string) => {
    return this.connectedIntegrations.find(integration => integration.id === integrationId);
  }

  @action
  checkIntegrations = async () => {
    const { orgIdQueryParam } = this.currentOrg;
    const url = `/integrations?${ orgIdQueryParam }`;

    const check = auth.fetch<ConnectedIntegration[]>(url);

    this.checking = true;
    this.errored = false; // reset error state
    this.lastCheck = check;
    this.connectedIntegrations = [];

    try {
      const { ok, data: results } = await check;
      if (check === this.lastCheck) {
        if (ok && results) {
          this.connectedIntegrations = results;
          const { integrations } = this;
          forEach(integrations, integration => {
            const type = integration.type as IntegrationType;
            const result = find(results, { type });
            if (result) {
              integration.id = `${ result.id }`;
              integration.connected = true;
              integration.authorizedAt = result.authorizedAt;
              integration.authorizedBy = result.authorizedBy;
            } else {
              integration.connected = false;
            }
          });
        }
      }
    } catch (e) {
      this.errored = true;
    } finally {
      if (check === this.lastCheck) {
        this.checking = false;
      }
    }
  };
  @action
  createIntegration = async (authInfo: object, type: IntegrationType) => {
    const { orgId } = this.currentOrg;

    this.creating = true;
    this.createIntegrationErroredMessage = undefined;
    try {
      const { data, ok, errorData } = await auth.fetch<ConnectedIntegration>(
        '/integration',
        {
          method: 'POST',
          body: JSON.stringify({ authInfo, type, organization: orgId })
        }
      );
      if (!data || !ok) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        this.createIntegrationErroredMessage = `Failed to create integration: ${ errorMessage }`;
      }

      await this.checkIntegrations();

      return data;
    } catch (e) {
      this.createIntegrationErroredMessage = `Failed to create integration: ${ e }`;
    } finally {
      this.creating = false;
    }

    return undefined;
  };

  @action
  deleteIntegration = async (integrationId: string) => {
    this.deleting = true;
    this.errored = false; // reset error state
    try {
      // find the integration by id
      const integration = find(this.connectedIntegrations, { id: integrationId });

      if (integration) {
        await auth.fetch(`/integration/${ integration.id }`, { method: 'DELETE' });
        await this.checkIntegrations();
      }

    } catch (e) {
      this.errored = true;
    } finally {
      this.deleting = false;
    }
  };

  @action
  enumerateIntegration = async (integrationId: string, params: object) => {
    this.enumerating = true;
    this.errored = false; // reset error state
    this.enumerateIntegrationErroredMessage = '';
    try {
      const query = stringify(params);
      const { data, ok, errorData } = await auth.fetch<object>(
        `/integration/${ integrationId }/enumerate?${ query }`
      );
      if (!data || !ok) {
        const errorMessage = errorData ? errorData.message : 'Please try again';
        this.enumerateIntegrationErroredMessage = `Failed to enumerate integration: ${ errorMessage }`;
        this.errored = true;
      }
      return data;
    } catch (e) {
      this.errored = true;
      this.enumerateIntegrationErroredMessage = `Failed to enumerate integration`;
    } finally {
      this.enumerating = false;
    }
    return undefined;
  };

  @action
  testIntegration = async (integrationId: string, params: object) => {
    try {
      const query = stringify(params);
      const { ok } = await auth.fetch<object>(
        `/integration/${ integrationId }/test?${ query }`
      );
      if (!ok) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  };

  reset() {
    this.checking = false;
    this.creating = false;
    this.enumerating = false;
    this.errored = false;
    this.deleting = false;

    this.createIntegrationErroredMessage = '';
    this.enumerateIntegrationErroredMessage = '';

    this.integrations = [];
    this.connectedIntegrations = [];
  }

  orgUpdated() {
    // Sets the currently active org and starts an enumeration of connected integrations
    this.reset();
    const { orgId } = this.currentOrg;

    if (orgId && this.currentOrg.getCanAction('manage:integration')) {
      this.integrations = [
        /* // Have removed salesforce as it is confusing people
        {
          testable: true,
          connected: false,
          integrationUrl: `https://login.salesforce.com/services/oauth2/authorize?${ stringify(
            {
              response_type: 'code',
              client_id:
                '3MVG9YDQS5WtC11pDRjGe_yS.UguudQTWfQcCe1dppLZFVU6P7XpJMX_ZWCZEti.VhPO69AQVII7gjPS4lqvq',
              redirect_uri:
                'https://client.getthematic.com/redirect.html?route=/redirects/salesforce',
            }
          ) }`,
          name: 'Salesforce',
          type: IntegrationType.SALESFORCE,
          connectionType: IntegrationConnectionType.OAUTH
        }, */
        {
          testable: true,
          connected: false,
          name: 'Aircall',
          type: IntegrationType.AIRCALL,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://developer.aircall.io/tutorials/basic-authentication/',
          requiredFields: [
            {
              name: 'API ID',
              param: 'api_id',
              needsHelp: true
            },
            {
              name: 'API Token',
              param: 'api_token'
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'Alchemer',
          type: IntegrationType.SURVEYGIZMO,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://apihelp.alchemer.com/help/authentication',
          requiredFields: [
            {
              name: 'Key',
              param: 'key',
              needsHelp: true
            },
            {
              name: 'Secret',
              param: 'secret'
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'AskNicely',
          type: IntegrationType.ASKNICELY,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://asknicely.asknice.ly/help/apidocs/auth',
          requiredFields: [
            {
              name: 'AskNicely Subdomain',
              param: 'subdomain'
            },
            {
              name: 'AskNicely API Key',
              param: 'api_key',
              needsHelp: true
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'Confirmit',
          type: IntegrationType.CONFIRMIT,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://www.confirmit.com/Resources/Market-Research/Confirmit-API/',
          requiredFields: [
            {
              name: 'API Key',
              param: 'api_key',
              needsHelp: true
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'Delighted',
          type: IntegrationType.DELIGHTED,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://delighted.com/account/api',
          requiredFields: [
            {
              name: 'API Key',
              param: 'api_key',
              needsHelp: true
            }
          ]
        },
        {
          testable: true,
          connected: false,
          integrationUrl: `https://discord.com/api/oauth2/authorize?${ stringify(
            {
              response_type: 'code',
              client_id: '1098789907268378634',
              permissions: 1024,
              scope: 'bot',
              redirect_uri: `https://client.getthematic.com/redirect.html?route=/redirects/discord`
            }
          ) }`,
          name: 'Discord',
          type: IntegrationType.DISCORD,
          connectionType: IntegrationConnectionType.OAUTH
        },
        {
          testable: true,
          connected: false,
          name: 'Feefo',
          type: IntegrationType.FEEFO,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://support.feefo.com/support/solutions/articles/8000096784-how-to-connect-using-an-oauth-2-0-token',
          requiredFields: [
            {
              name: 'Client ID',
              param: 'key',
              needsHelp: true
            },
            {
              name: 'Client Secret',
              param: 'secret'
            },
            {
              name: 'Merchant Identifier',
              param: 'merchantIdentifier'
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'Gong',
          type: IntegrationType.GONG,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://help.gong.io/hc/en-us/articles/360042449431-What-the-Gong-API-provides',
          requiredFields: [
            {
              name: 'Access Key',
              param: 'access_key'
            },
            {
              name: 'Access Key Secret',
              param: 'access_key_secret',
              needsHelp: true
            }
          ]
        },
        {
          testable: true,
          connected: false,
          integrationUrl: `https://accounts.google.com/o/oauth2/v2/auth?${ stringify(
            {
              response_type: 'code',
              client_id:
                '424069916302-ruh44fpq2r2k44g8cvmov35d56dvj5pu.apps.googleusercontent.com',
              redirect_uri:
                `https://client.getthematic.com/redirect.html?route=/redirects/googlebigquery`,
              access_type: 'offline',
              scope: 'https://www.googleapis.com/auth/bigquery.readonly'
            }
          ) }`,
          name: 'Google BigQuery',
          type: IntegrationType.GOOGLEBIGQUERY,
          connectionType: IntegrationConnectionType.OAUTH
        },
        {
          testable: false,
          connected: false,
          integrationUrl: `https://accounts.google.com/o/oauth2/v2/auth?${ stringify(
            {
              response_type: 'code',
              client_id:
                '1016175338913-de24qq1asp438nbf6vjnj02er7ed63ac.apps.googleusercontent.com',
              redirect_uri:
                `https://client.getthematic.com/redirect.html?route=/redirects/googlesheets`,
              access_type: 'offline',
              scope: 'https://www.googleapis.com/auth/spreadsheets.readonly'
            }
          ) }`,
          name: 'GoogleSheets',
          type: IntegrationType.GOOGLESHEETS,
          connectionType: IntegrationConnectionType.OAUTH
        },
        {
          testable: true,
          connected: false,
          name: 'Gorgias',
          type: IntegrationType.GORGIAS,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://developers.gorgias.com/docs/access-tokens-api-keys',
          requiredFields: [
            {
              name: 'Domain',
              param: 'domain'
            },
            {
              name: 'Username',
              param: 'username'
            },
            {
              name: 'API Token',
              param: 'api_token',
              needsHelp: true
            }
          ]
        },
        {
          testable: true,
          connected: false,
          integrationUrl: `https://app.intercom.com/oauth?${ stringify(
            {
              response_type: 'code',
              client_id:
                '2e78534e-219c-432f-b7b6-339c6146bbfc',
              redirect_uri:
                `https://client.getthematic.com/redirect.html?route=/redirects/intercom`,
              access_type: 'offline',
              scope: 'https://www.googleapis.com/auth/spreadsheets.readonly'
            }
          ) }`,
          name: 'Intercom',
          type: IntegrationType.INTERCOM,
          connectionType: IntegrationConnectionType.OAUTH
        },
        {
          testable: true,
          connected: false,
          name: 'JotForm',
          type: IntegrationType.JOTFORM,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://api.jotform.com/docs/',
          requiredFields: [
            {
              name: 'API Key',
              param: 'api_key',
              needsHelp: true
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'Okendo',
          type: IntegrationType.OKENDO,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://docs.okendo.io/merchant-rest-api/quick-start/',
          requiredFields: [
            {
              name: 'Merchant API Key (from Integration Settings)',
              param: 'merchant_api_key',
              needsHelp: true,
            },
            {
              name: 'User ID',
              param: 'user_id',
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'Qualtrics',
          type: IntegrationType.QUALTRICS,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://api.qualtrics.com/docs/api-key-authentication',
          requiredFields: [
            {
              name: 'Data Center ID',
              param: 'data_center_id'
            },
            {
              name: 'API Key',
              param: 'api_key',
              needsHelp: true
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'QuestionPro',
          type: IntegrationType.QUESTIONPRO,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://www.questionpro.com/help/generate-api-key.html',
          requiredFields: [
            {
              name: 'Environment',
              param: 'env'
            },
            {
              name: 'User ID',
              param: 'user_id'
            },
            {
              name: 'API Key',
              param: 'api_key',
              needsHelp: true
            },
            {
              name: 'CX Feedback ID',
              param: 'cx_feedback_id'
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'Reevoo',
          type: IntegrationType.REEVOO,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://developer.reevoo.com/',
          requiredFields: [
            {
              name: 'Key',
              param: 'key',
              needsHelp: true
            },
            {
              name: 'Secret',
              param: 'secret'
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'sFTP',
          type: IntegrationType.SFTP,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://help.getthematic.com/article/178-sftp-integration-instructions',
          requiredFields: [
            {
              name: 'Host/Server location',
              param: 'host'
            },
            {
              name: 'Port (default: 22)',
              param: 'port'
            },
            {
              name: 'Username',
              param: 'username',
            },
            {
              name: 'Keyfile content',
              param: 'password',
              paramType: 'textarea'
            }
          ]
        },
        {
          testable: true,
          connected: false,
          integrationUrl: `https://slack.com/oauth/v2/authorize?${ stringify(
            {
              response_type: 'code',
              client_id:
                '3166338794.3035408614035',
              redirect_uri:
                `https://client.getthematic.com/redirect.html?route=/redirects/slack`,
              scope: 'channels:read,channels:history,groups:read,groups:history,chat:write'
            }
          ) }`,
          name: 'Slack Conversations',
          type: IntegrationType.SLACK,
          connectionType: IntegrationConnectionType.OAUTH
        },
        {
          testable: true,
          connected: false,
          name: 'Snowflake (Reader)',
          type: IntegrationType.SNOWFLAKEREADER,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://help.getthematic.com/article/180-snowflake-reader-integration',
          requiredFields: [
            {
              name: 'Share URL',
              param: 'account',
              needsHelp: true
            },
            {
              name: 'Username',
              param: 'user',
            },
            {
              name: 'Password',
              param: 'password',
              paramType: 'password'
            }
          ]
        },
        {
          testable: true,
          connected: false,
          integrationUrl: `https://api.surveymonkey.com/oauth/authorize?${ stringify(
            {
              response_type: 'code',
              client_id: 'egA3_5OeScq1QKwan1reFQ',
              redirect_uri:
                'https://client.getthematic.com/redirect.html?route=/redirects/surveymonkey',
            }
          ) }`,
          name: 'SurveyMonkey',
          type: IntegrationType.SURVEYMONKEY,
          connectionType: IntegrationConnectionType.OAUTH
        },
        {
          testable: true,
          connected: false,
          name: 'Survicate',
          type: IntegrationType.SURVICATE,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://help.survicate.com/en/articles/4097085-where-can-i-find-my-workspace-key',
          requiredFields: [
            {
              name: 'Workspace API Key(s)',
              param: 'api_keys',
              needsHelp: true,
              multipleValues: true
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'Trustpilot',
          type: IntegrationType.TRUSTPILOT,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://developers.trustpilot.com/',
          requiredFields: [
            {
              name: 'Key',
              param: 'key',
              needsHelp: true
            },
            {
              name: 'Secret',
              param: 'secret'
            }
          ]
        },
        {
          testable: true,
          connected: false,
          integrationUrl: `https://api.typeform.com/oauth/authorize?${ stringify(
            {
              response_type: 'code',
              scope: 'offline forms:read responses:read',
              client_id: '9aDWPVk4LXsuJ2pVJZqcVwJUbJxyzwvCu81jJv3HULxD',
              redirect_uri:
                'https://client.getthematic.com/redirect.html?route=/redirects/typeform',
            }
          ) }`,
          name: 'Typeform',
          type: IntegrationType.TYPEFORM,
          connectionType: IntegrationConnectionType.OAUTH
        },
        {
          testable: true,
          connected: false,
          name: 'Yabble',
          type: IntegrationType.YABBLE,
          connectionType: IntegrationConnectionType.FIELDS,
          requiredFields: [
            {
              name: 'Username',
              param: 'username'
            },
            {
              name: 'Password',
              param: 'password',
              paramType: 'password'
            }
          ]
        },
        {
          testable: true,
          connected: false,
          name: 'Yotpo',
          type: IntegrationType.YOTPO,
          connectionType: IntegrationConnectionType.FIELDS,
          integrationUrl: 'https://support.yotpo.com/en/article/finding-your-yotpo-app-key-and-secret-key',
          requiredFields: [
            {
              name: 'App Key',
              param: 'key'
            },
            {
              name: 'Secret Key',
              param: 'secret',
              needsHelp: true
            }
          ]
        },
        {
          testable: true,
          connected: false,
          integrationUrl: `https://{subdomain}.zendesk.com/oauth/authorizations/new?${ stringify(
            {
              response_type: 'code',
              scope: 'read',
              client_id: 'zdg-thematic_integration_for_zendesk',
              redirect_uri:
                'https://client.getthematic.com/redirect.html?route=/redirects/zendesk',
            }
          ) }`,
          name: 'Zendesk Support',
          type: IntegrationType.ZENDESK,
          connectionType: IntegrationConnectionType.OAUTH_WITH_FIELDS,
          requiredFields: [
            {
              name: 'Subdomain',
              param: 'subdomain'
            }
          ]
        },
      ];
      this.checkIntegrations();
    }
  }
}

export default IntegrationsStore;
