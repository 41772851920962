import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataIntegrationStatus } from 'api/enums';
import { ConnectedIntegration, DataDestinationIntegration } from 'api/interfaces';
import { supportedDataDestinations } from 'components/ConfigureSurvey/constants';
import IntegrationLogo from 'components/Integrations/IntegrationLogo';
import { Button } from 'components/Shared/Button';
import { compact } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { Dropdown, DropdownItemProps, Form, FormInputProps, Label, Message, SemanticCOLORS } from 'semantic-ui-react';

import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { DataDestinationStoreInterface } from 'stores/DataDestinationStore';
import { IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import './configure-export.scss';

export const NEW_CONNECTION_ID = 'new-connection';

interface ConfigureExportDestinationStoreProps {
  dataDestinationStore?: DataDestinationStoreInterface;
  surveyStore?: SurveyStoreInterface;
  integrationsStore?: IntegrationsStoreInterface;
}

interface ConfigureExportDestinationProps extends
  ConfigureExportDestinationStoreProps {
  surveyId: string;
  orgId: string;
  destinationId: string;
  deleteDestination: (destinationId: string) => void;
  createDestination: (destination: DataDestinationIntegration) => Promise<void>;
}


interface ConfigureExportDestinationState {
  survey?: LiteSurvey;
  destination: DataDestinationIntegration;
  updatingDestination: boolean;
  showingChangeConnection: boolean;
  editableMode: boolean;
  integrations: ConnectedIntegration[],
  isInputValid: boolean;
}

@inject('dataDestinationStore', 'surveyStore', 'integrationsStore')
@observer
class ConfigureExportDestination extends React.Component<ConfigureExportDestinationProps, ConfigureExportDestinationState> {
  state: ConfigureExportDestinationState = {
    survey: undefined,
    integrations: [] as ConnectedIntegration[],
    showingChangeConnection: false,
    editableMode: false,
    isInputValid: false,
    destination: {
      id: NEW_CONNECTION_ID,
      configuration: {},
      status: DataIntegrationStatus.STOPPED,
      type: '',
    },
    updatingDestination: false,
  };

  componentDidMount() {
    this.loadDestinationDetails();
  }

  componentDidUpdate(prevProps: ConfigureExportDestinationProps) {
    const { surveyId } = this.props;
    if (prevProps.surveyId !== surveyId) {
      this.loadDestinationDetails();
    }
  }

  loadDestinationDetails = async () => {
    const { surveyId, destinationId, surveyStore, dataDestinationStore } = this.props;
    let { destination } = this.state;

    if (surveyId) {
      const { surveyId } = this.props;

      const [survey, dataDestinations] = await Promise.all([
        surveyStore!.getSurvey(surveyId),
        dataDestinationStore!.getDataDestinations(surveyId)
      ]);

      if (destinationId === NEW_CONNECTION_ID) {
        this.setState({ editableMode: true });
        const options = this.getDataDestinationOptions();
        const defaultValue = options[0].value;
        destination.type = defaultValue as string;
      }
      else {
        destination = (dataDestinations && dataDestinations.find(d => d.id === destinationId)) || destination;
      }
      this.setState({ survey, destination });
    }
  }

  updateDestination = async () => {
    const { surveyId, destinationId, dataDestinationStore } = this.props;
    const { destination } = this.state;

    await dataDestinationStore!.updateDataDestination(surveyId, destinationId, destination);
    this.setState({ editableMode: false });
  }
  cancelDestinationEdit = () => {
    const { destinationId, deleteDestination } = this.props;
    if (destinationId === NEW_CONNECTION_ID) {
      deleteDestination(destinationId);
    } else {
      this.setState({ editableMode: false });
    }
  }

  publishDestinationEdit = async () => {
    const { destinationId, createDestination } = this.props;
    const { destination } = this.state;
    this.setState({ updatingDestination: true });
    if (destinationId === NEW_CONNECTION_ID) {
      await createDestination(destination);
    } else {
      await this.updateDestination();
    }
    this.setState({ updatingDestination: false, editableMode: false });
  }

  pushNow = () => {
    const { surveyId, destinationId, dataDestinationStore } = this.props;

    dataDestinationStore?.pushSurveyDataDestination(surveyId, destinationId);
  }

  togglePushing = async () => {
    const { surveyId, destinationId, dataDestinationStore } = this.props;
    const { destination } = this.state;

    let status = DataIntegrationStatus.ENABLED;
    if (destination && destination.status === DataIntegrationStatus.ENABLED) {
      status = DataIntegrationStatus.STOPPED;
    }

    this.setState({ updatingDestination: true });
    const proposedDestination = await dataDestinationStore!.setDataDestinationStatus(surveyId, destinationId, status) || destination;
    this.setState({ destination: proposedDestination, updatingDestination: false });
  }


  changeDestinationType = (
    _e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    const { destination } = this.state;
    const destinationInfo = value.split(' ');
    destination.type = destinationInfo[0];
    destination.configuration = {};
    this.setState({ destination });
  };

  setConfig = (configuration: object, integrationId: string) => {
    const { destination } = this.state;
    if (destination) {
      this.setState({ destination: { ...destination, configuration, integrationId } });
    }
  }
  setValidity = (isValid: boolean) => {
    this.setState({ isInputValid: isValid });
  }

  getDataDestinationOptions = () => {
    const { dataDestinationStore } = this.props;
    const options: DropdownItemProps[] = dataDestinationStore!.dataDestinationTypes
      .filter(type => supportedDataDestinations[type.name])
      .map(type => ({
        text: supportedDataDestinations[type.name].name,
        value: type.name
      }))
      .sort((a, b) => a.text > b.text ? 1 : -1);
    return options;
  }

  renderDataDestinationForm = () => {
    const { surveyId, orgId } = this.props;
    const { destination } = this.state;
    if (!destination) {
      return null;
    }
    const { type: destinationType } = destination;
    // find the correct update form and return it
    if (supportedDataDestinations[destinationType]) {
      const component = supportedDataDestinations[destinationType].formComponent;
      return React.createElement(component, {
        existingConfiguration: destination.configuration,
        orgId,
        surveyId,
        onChange: this.setConfig,
        ...(this.setValidity && { setValidity: this.setValidity }),
      });
    }
    return null;
  }

  renderStatic() {
    const { dataDestinationStore, integrationsStore } = this.props;
    const {
      integrations,
      destination,
      updatingDestination
    } = this.state;


    let integration;
    let label = 'Unknown';
    let labelColor = 'grey';

    if (destination) {
      // get details where there is a connected integration
      if (destination.integrationId && integrations && integrationsStore) {
        integration = integrationsStore.getIntegrationById(destination.integrationId);
      }
      // get details for all data sources
      if (destination.status === DataIntegrationStatus.STOPPED) {
        label = 'Paused';
        labelColor = 'grey';
      } else if (destination.lastFailureDate &&
        (!destination.lastSuccessDate || destination.lastFailureDate > destination.lastSuccessDate)) {
        label = 'Errored';
        labelColor = 'red';
      } else if (destination.lastSuccessDate) {
        label = 'Active';
        labelColor = 'green';
      }
    }

    if (!destination || !destination.type) {
      return null;
    }

    return (
      <Form>
        <Form.Field>
          <div className="integration-manage">

            <div className="integration-details">
              <IntegrationLogo integrationType={destination.type} />
              <div className="integration-connection">
                <div className="integration-name">
                  {supportedDataDestinations[destination.type]!.name}
                </div>
                <div className="integration-connection-details">
                  {destination.lastSuccessDate ? (
                    <span>Refreshed: {moment(destination.lastSuccessDate).format('MMMM Do, YYYY')}</span>
                  ) : (
                    <span>No successful connections</span>
                  )}
                </div>
                <div className="integration-connection-controls">
                  <Button
                    size="small"
                    variant="secondary"
                    icon={<FontAwesomeIcon icon="sync-alt" />}
                    loading={dataDestinationStore?.pushingDataDestination}
                    onClick={this.pushNow}
                  >Refresh
                  </Button>
                  {destination.status === DataIntegrationStatus.ENABLED ? (
                    <Button
                      size="small"
                      variant="secondary"
                      icon={<FontAwesomeIcon icon="pause" />}
                      loading={updatingDestination}
                      onClick={this.togglePushing}
                    >Pause

                    </Button>) : (

                    <Button
                      size="small"
                      variant="secondary"
                      icon={<FontAwesomeIcon icon="play" />}
                      loading={updatingDestination}
                      onClick={this.togglePushing}
                    >Resume
                    </Button>
                  )}
                  <Button
                    size="small"
                    icon={<FontAwesomeIcon icon="pencil" />}
                    variant="secondary"
                    loading={dataDestinationStore?.pushingDataDestination}
                    onClick={() => this.setState({ editableMode: true })}
                  >Edit

                  </Button>
                </div>
              </div>
              <div>
                <Label color={labelColor as SemanticCOLORS} size="large">{label}</Label>
              </div>
            </div>
            {supportedDataDestinations[destination.type]!.requiresIntegration && (
              <div className="integration-change">
                {integration ? (
                  <div>
                    Integration set up by&nbsp;
                    {integration.authorizedBy}
                    &nbsp;on&nbsp;
                    {moment(integration.authorizedAt).format('MMMM Do, YYYY')}
                  </div>
                ) : (
                  <div>Integration missing!</div>
                )}

              </div>
            )}
          </div>
        </Form.Field>
      </Form>
    );
  }

  renderEditable() {
    const { dataDestinationStore, destinationId, deleteDestination } = this.props;
    const { updatingDestination, isInputValid } = this.state;

    const errorsToShow = compact([dataDestinationStore!.createDataDestinationError,
    dataDestinationStore!.updateDataDestinationError,
    ]);

    const options = this.getDataDestinationOptions();


    return (
      <Form>

        <Form.Field>
          <div className="integration-manage-editable">
            <div className="integration-manage-editable__delete">
              <Button
                icon={<FontAwesomeIcon icon="times" />}
                role="button"
                aria-label="remove-item"
                variant="link"
                size="small"
                onClick={() => deleteDestination(destinationId)}></Button>
            </div>

            <div className="integration-manage-editable__form">
              <Form.Field>
                <label>Choose destination</label>
                <Dropdown
                  selection={true}
                  search={true}
                  options={options}
                  defaultValue={options[0].value}
                  onChange={this.changeDestinationType}
                />
              </Form.Field>
              {this.renderDataDestinationForm()}
            </div>

            <div className="integration-manage-editable__actions">
              <Button
                role="button"
                aria-label="cancel-edit-item"
                variant="link"
                size="medium"
                onClick={() => this.cancelDestinationEdit()}>Cancel</Button>
              <Button
                role="button"
                aria-label="publish-item"
                variant="primary"
                size="medium"
                disabled={!isInputValid}
                loading={updatingDestination}
                onClick={() => this.publishDestinationEdit()}>Publish</Button>
            </div>
          </div>
        </Form.Field>
        {errorsToShow.map(error => (
          <Message
            key={error}
            className="error"
            negative={true}
            header={error}
          />
        ))}</Form>
    );
  }

  render() {
    const {
      editableMode
    } = this.state;

    if (editableMode) {
      return this.renderEditable();
    }
    return this.renderStatic();

  }
}

export default ConfigureExportDestination;