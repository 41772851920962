import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as workerPath from 'file-loader?name=[name].[hash].js!./CommentMatcher.worker';
import { ThemeGroup } from 'lib/theme-file-parser';
import { autorun, toJS } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import { PlainComment, Thread } from 'types/custom';
import './theme-info.scss';
import { UntaggedCommentsContent } from './UntaggedCommentsContent';

export interface UntaggedCommentsProps {
  orgId: string;
  surveyId: string;
  group: ThemeGroup;
}

interface UntaggedCommentsState {
  exampleComments: PlainComment[] | Thread[];
  processing: boolean;
  processError: boolean;
  coverage: number;
}

interface InjectedProps {
  themesStore: ThemesStoreInterface;
}

@inject('themesStore')
@observer
export default class UntaggedComments extends React.Component<
  UntaggedCommentsProps,
  UntaggedCommentsState
> {
  state = {
    exampleComments: [],
    coverage: 0,
    processing: false,
    processError: false,
  } as UntaggedCommentsState;
  commentWorker?: Worker;
  loadedExampleCommentsRef = {
    current: false,
  };

  get injected(): InjectedProps {
    return this.props as UntaggedCommentsProps & InjectedProps;
  }
  componentDidMount() {
    const commentWorker = new Worker(workerPath.default);
    this.commentWorker = commentWorker;

    commentWorker.onmessage = (ev: MessageEvent) => {
      const exampleComments = ev.data['comments'] as PlainComment[] | Thread[];
      const coverage = ev.data['coverage'] as number;

      this.setState({ exampleComments, processing: false, coverage });
      this.loadedExampleCommentsRef.current = true;
    };
    commentWorker.onerror = () => {
      this.setState({ processing: false });
    };
    disposeOnUnmount(
      this,
      autorun(() => {
        if (this.loadedExampleCommentsRef.current) {
          return;
        }

        this.refreshExampleComments();
      })
    );
  }
  componentWillUnmount() {
    if (this.commentWorker) {
      this.commentWorker.terminate();
    }
  }
  refreshExampleComments = () => {
    const { themesStore } = this.injected;
    const { exampleComments } = themesStore;

    if (!exampleComments.length) {
      return;
    }

    // if there is no active node then we are looking for ALL phrases
    const phrases = themesStore.allPhrases;
    // if we are showing all phrases we are showing comments NOT covered (so invert)
    const invertResults = true;
    const resultsSize = 50;
    this.setState({ exampleComments: [], processing: true });
    this.commentWorker?.postMessage({
      comments: toJS(exampleComments),
      phrases,
      invertResults,
      resultsSize
    });
  }
  render() {
    const { group, orgId, surveyId } = this.props;
    const { toggleThemeInfo, showThemeInfo } = this.injected.themesStore;
    const { exampleComments, processing, processError, coverage } = this.state;

    if (!showThemeInfo) {
      return null;
    }

    return (
      <Segment className="theme-editor__info theme-info">
        <div className="theme-info__header">
          <h2>Review untagged comments</h2>
          <div>
            <Button size="small" className="hide-theme-info-cta" onClick={() => toggleThemeInfo(false)}>
              <FontAwesomeIcon className="icon" size="sm" icon="times" />
              Close
            </Button>
          </div>
        </div>
        <UntaggedCommentsContent
          coverage={coverage}
          exampleComments={exampleComments}
          groupId={group.id}
          orgId={orgId}
          processing={processing}
          processError={processError}
          refreshComments={this.refreshExampleComments}
          surveyId={surveyId}
        />
      </Segment>
    );
  }
}
