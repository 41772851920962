import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { isNumber } from 'lodash';
import * as React from 'react';
import toFixed from 'vue/libs/to-fixed';
import { getScoreChange, getScoreChangeIndicator } from 'vue/libs/widget-utils';
import './original-score-comparison.scss';

interface ScoreData {
  name?: string;
  score: {
    score: number;
    componentScores?: {
      originalScore: {
        score: number;
        name: string;
      };
    }[];
  };
}

interface Props {
  config: {
    title?: string;
    precision?: number;
    score?: {
      options?: {
        precision?: number;
      };
    };
  };
  context: {
    compareFilter?: string;
    compareFilterName?: string;
  };
  data: {
    currentScore?: ScoreData;
    previousScore?: ScoreData;
    currentComparisonScore?: ScoreData;
    previousComparisonScore?: ScoreData;
    scoreConfig?: {
      options?: {
        precision?: number;
      };
    };
  };
  warning?: string;
  error?: string;
  loading?: boolean;
  source?: string;
  panelOrder: number;
  widgetOrder: number;
}

const OriginalScoreComparison = withCamelCaseProps((props: Props) => {
  const { config, context, data, warning, error, loading, panelOrder, widgetOrder } = props;

  const getOriginalScore = (score?: ScoreData) => {
    if (!score) {
      return undefined;
    }
    return score.score.componentScores?.[0]?.originalScore;
  };

  const getPrecision = (scoreChange: number) => {
    const precision = data?.scoreConfig?.options?.precision ?? config?.precision;
    if (isNumber(precision)) {
      return precision;
    }

    const absChange = Math.abs(scoreChange);
    return absChange < 10 ? 1 : 0;
  };

  const getScoreChangeLabel = (scoreChange?: number) => {
    if (scoreChange) {
      const precision = getPrecision(scoreChange);
      const label = toFixed(scoreChange, precision);
      return Math.abs(Number(label));
    }
    return undefined;
  };

  const currentScore = data?.currentScore?.score?.score;
  const previousScore = data?.previousScore?.score?.score;
  const currentComparisonScore = data?.currentComparisonScore?.score?.score;
  const previousComparisonScore = data?.previousComparisonScore?.score?.score;
  const currentComparisonOriginalScore = getOriginalScore(data?.currentComparisonScore);
  const previousComparisonOriginalScore = getOriginalScore(data?.previousComparisonScore);

  const scoreName = data?.currentScore?.name ?? '';
  const comparisonScoreName = data?.currentComparisonScore?.name ?? '';
  const originalComparisonScoreName = currentComparisonOriginalScore?.name ?? '';

  const scoreLabel = currentScore !== undefined ? toFixed(currentScore, 1) : undefined;
  const comparisonScoreLabel = currentComparisonScore !== undefined ? toFixed(currentComparisonScore, 1) : undefined;
  const originalComparisonScoreLabel = currentComparisonOriginalScore?.score !== undefined
    ? toFixed(currentComparisonOriginalScore.score, 1)
    : undefined;

  const scoreChange = getScoreChange(currentScore, previousScore);
  const scoreChangeLabel = getScoreChangeLabel(scoreChange);
  const scoreChangeIndicator = isNumber(scoreChange) ? getScoreChangeIndicator(scoreChange) : undefined;

  const comparisonScoreChange = getScoreChange(currentComparisonScore, previousComparisonScore);
  const comparisonScoreChangeLabel = getScoreChangeLabel(comparisonScoreChange);
  const comparisonScoreChangeIndicator = isNumber(comparisonScoreChange) ? getScoreChangeIndicator(comparisonScoreChange) : undefined;


  const originalComparisonScoreChange = getScoreChange(
    currentComparisonOriginalScore?.score,
    previousComparisonOriginalScore?.score
  );
  const originalComparisonScoreChangeLabel = getScoreChangeLabel(originalComparisonScoreChange);
  const originalComparisonScoreChangeIndicator = isNumber(originalComparisonScoreChange)
    ? getScoreChangeIndicator(originalComparisonScoreChange)
    : undefined;

  const widgetSubtitle = comparisonScoreName
    ? `${scoreName} vs ${comparisonScoreName}`
    : scoreName;

  const subtitleElements = () => (
    <div className="widget-subtitle">
      <div className="widget-subtitle-text">{widgetSubtitle}</div>
    </div>
  );

  return (
    <WidgetWrapper
      aria-label={widgetSubtitle}
      title={config.title}
      loading={loading}
      error={error}
      warning={warning}
      panelOrder={panelOrder}
      widgetOrder={widgetOrder}
      subtitle={subtitleElements()}
    >
      {scoreLabel ? (
        <div
          className={`widget-body original-score-comparison-widget scores${context.compareFilter ? ' has-comparison' : ''}`}
          data-testid="original-score-comparison-widget"
        >
          <div className="overall score">
            <div className="score-header">
              <h2>{scoreLabel}</h2>
              <div className="score-change">
                {scoreChangeIndicator && (
                  <FontAwesomeIcon
                    icon={scoreChangeIndicator}
                    data-testid={scoreChangeIndicator}
                  />
                )}
                {scoreChangeLabel}
              </div>
            </div>
            <legend>{scoreName}</legend>
          </div>

          {context.compareFilter && (
            <div className="comparison score">
              <div className="current">
                <div className="score-header">
                  <h2>{comparisonScoreLabel}</h2>
                  <div className="score-change">
                    {comparisonScoreChangeIndicator && (
                      <FontAwesomeIcon
                        icon={comparisonScoreChangeIndicator}
                        data-testid={comparisonScoreChangeIndicator}
                      />
                    )}
                    {comparisonScoreChangeLabel}
                  </div>
                </div>
                <legend>{comparisonScoreName}</legend>
                <legend className="goodwill">Goodwill</legend>
              </div>

              {originalComparisonScoreLabel &&
                <div className="original">
                  <div className="score-header">
                    <h2>{originalComparisonScoreLabel}</h2>
                    <div className="score-change">
                      {originalComparisonScoreChangeIndicator && (
                        <FontAwesomeIcon
                          icon={originalComparisonScoreChangeIndicator}
                          data-testid={originalComparisonScoreChangeIndicator}
                        />
                      )}
                      {originalComparisonScoreChangeLabel}
                    </div>
                  </div>
                  <legend>{originalComparisonScoreName}</legend>
                </div>
              }
            </div>
          )}
        </div>
      ) : (
        <div className="empty widget-body">
          No data found
        </div>
      )}
    </WidgetWrapper>
  );
});

export { OriginalScoreComparison };
