import * as React from 'react';

interface Props {
  config?: object;
}

const Unknown = (props: Props) => {
  return (
    <div>
      This version doesn't understand the widget requested
    </div>
  );
};

export { Unknown };