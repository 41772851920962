import * as React from 'react';
import { compact, isNumber } from 'lodash';
import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import format from 'vue/libs/to-locale-string';

interface Props {
  config: {
    title?: string;
  };
  context: {
    compareFilterName?: string;
  };
  data: {
    count?: number;
    compareCount?: number;
    currentPeriodName?: string;
  };
  filterNames?: string;
  filterString?: string;
  warning?: string;
  error?: string;
  loading?: boolean;
  source?: string;
  panelOrder: number;
  widgetOrder: number;
}

const Metadata = withCamelCaseProps((props: Props) => {
  const {
    config,
    context,
    data,
    filterNames,
    filterString,
    warning,
    error,
    loading,
    panelOrder,
    widgetOrder
  } = props;

  const overallCount = format(data?.count ?? 0);
  const comparisonCount = format(data?.compareCount ?? 0);
  const currentPeriodName = data?.currentPeriodName;
  const hasComparison = isNumber(data?.compareCount);

  const getComparisonName = () => {
    const filters: string[] = [];
    if (filterString && filterNames) {
      filters.push(filterNames);
    }
    if (context?.compareFilterName) {
      filters.unshift(context.compareFilterName);
    }
    return compact(filters).join(', ');
  };

  const getMainLabel = () => {
    const comparisonName = getComparisonName();
    if (hasComparison || !comparisonName) {
      return 'Overall';
    }
    return comparisonName;
  };

  const getSubtitle = () => {
    let title = 'Total number of responses';
    if (currentPeriodName) {
      title += ` in ${currentPeriodName}`;
    }
    return title;
  };

  const subtitle = getSubtitle();
  const comparisonName = getComparisonName();
  const mainLabel = getMainLabel();

  const subtitleElements = () => (
    <div className="widget-subtitle">
      <div className="widget-subtitle-text">{subtitle}</div>
    </div>
  );

  return (
    <WidgetWrapper
      aria-label={subtitle}
      title={config.title}
      loading={loading}
      error={error}
      warning={warning}
      panelOrder={panelOrder}
      widgetOrder={widgetOrder}
      subtitle={subtitleElements()}
      className="dashboard-widget"
    >
      <div className="widget-body">
        <div className="counts">
          <div className="count overall">
            <h2>{overallCount}</h2>
            <legend>
              {mainLabel}
            </legend>
          </div>
          {hasComparison && (
            <div className="count comparison">
              <h2>{comparisonCount}</h2>
              <legend>{comparisonName}</legend>
            </div>
          )}
        </div>
      </div>
    </WidgetWrapper>
  );
});

export { Metadata };