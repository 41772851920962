<template>
  <div class="dashboard-panel">
    <el-row
      v-if="title"
      class="dashboard-panel__title"
    >
      <h2 class="h2">
        {{ title }}
      </h2>
    </el-row>
    <draggable
      v-if="isEditing"
      v-model="widgetList"
      :handle="`.${activeDashboardUIStore.dragHandleClass}`"
      class="dashboard-panel__body"
      ghost-class="destination-item"
      draggable=".dashboard-panel__widget"
      @input="updateWidgetList"
    >
      <widget-chooser
        v-for="(widget, index) in widgetList"
        :key="index + widget.config.type"
        class="dashboard-panel__widget"
        :config="widget"
        :context="context"
        :dashboard-id="dashboardId"
        :filter-names="filterNames"
        :filter-string="filterString"
        :compare-filter="compareFilter"
        :compare-filter-name="compareFilterName"
        :date-filter="dateFilter"
        :base-filter="baseFilter"
        :panel-order="config.order"
      />
      <el-button
        class="add-widget"
        @click="showAddWidgetDialog"
      >
        <font-awesome-icon icon="plus" /> Add widget
      </el-button>
    </draggable>
    <div
      v-else
      class="dashboard-panel__body"
    >
      <widget-chooser
        v-for="(widget, index) in widgetList"
        :key="index + widget.config.type"
        class-names="dashboard-panel__widget"
        :config="widget"
        :context="context"
        :dashboard-id="dashboardId"
        :filter-names="filterNames"
        :filter-string="filterString"
        :compare-filter="compareFilter"
        :compare-filter-name="compareFilterName"
        :date-filter="dateFilter"
        :base-filter="baseFilter"
        :panel-order="config.order"
      />
    </div>
    <configure-widget
      v-if="isAddWidgetDialogVisible"
      :is-new="true"
      :panel-order="config.order"
      @onCancel="hideAddWidgetDialog"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import { WidgetChooser } from 'components/Dashboard/WidgetChooser.tsx';
import { ReactInVue } from 'vuera';
import { getActiveDashboardUIStore } from 'stores/RootStore';
import ConfigureWidget from '../DashboardEditable/ConfigureWidget/ConfigureWidget.vue';
import draggable from 'vuedraggable';
export default {
  name: 'Panel',
  components: {
    WidgetChooser: ReactInVue(WidgetChooser),
    ConfigureWidget,
    draggable,
  },
  props: {
    baseFilter: { type: String },
    compareFilter: { type: String },
    compareFilterName: { type: String },
    config: { type: Object },
    context: { type: Object },
    dashboardId: { type: String },
    dateFilter: { type: Object },
    filterString: { type: String },
    filterNames: { type: String },
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),

      isAddWidgetDialogVisible: false,
      widgetList: [],
    };
  },
  computed: {
    title() {
      return get(this, 'config.title', '');
    },
    widgets() {
      return get(this, 'config.widgets', []);
    },
    isEditing() {
      return this.activeDashboardUIStore.isEditing;
    },
  },
  watch: {
    widgets: {
      handler(widgets) {
        this.widgetList = widgets;
      },
      immediate: true,
    },
  },
  methods: {
    showAddWidgetDialog() {
      this.isAddWidgetDialogVisible = true;
    },
    hideAddWidgetDialog() {
      this.isAddWidgetDialogVisible = false;
    },
    updateWidgetList(updatedWidgetList) {
      const panelPath = this.activeDashboardUIStore.getPanelWidgetPath(this.config.order);
      this.activeDashboardUIStore.setValue(panelPath, { ...this.config, widgets: updatedWidgetList });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
.dashboard-panel {
  display: block;
  @media print {
    page-break-after: always;
  }
}
.dashboard-panel__title {
  align-items: center;
  margin: 0;
  padding: 0 30px;
  @media (max-width: $--md) {
    padding: 0 10px;
  }

  h2 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 600;
    margin: 0 0 20px;
  }
  @media print {
    display: none;
    align-items: center;
    margin: 0;
    padding: 10px 0px;
    .h2 {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      margin: 0;
    }
  }
}
.dashboard-panel__body {
  display: flex;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  gap: 1.4rem;
  @media (max-width: $--md) {
    padding: 0 10px 20px;
  }
  @media print {
    padding: 0;
    grid-gap: 5px;
    page-break-after: always;
  }
  .add-widget {
    border: 3px dotted $--neutral-700;
    background-color: transparent;
    border-radius: $--border-radius-small;
    font-size: 16px;
  }
}
@media (max-width: $--sm) {
  .dashboard-panel__body {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}
.destination-item {
  opacity: 0.5;
}
</style>
