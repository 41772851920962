import { Survey } from 'api/interfaces';
import analytics from 'lib/analytics';
import { compact, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dimmer, Form, Loader, Message, Segment } from 'semantic-ui-react';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { ConfigureDataUIStoreInterface } from 'stores/ui/ConfigureDataUIStore';
import ConfigureDate from './ConfigureDate';
import ConfigureFilters from './ConfigureFilters';
import ConfigureScores from './ConfigureScores';
import ConfigureUniqueId from './ConfigureUniqueId';
import './configure-analysis-new.scss';

interface ConfigureAnalysisStoreProps {
  configureDataUIStore?: ConfigureDataUIStoreInterface;
  surveyStore?: SurveyStoreInterface;
  setupStore?: SetupStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

interface ConfigureAnalysisProps extends
  ConfigureAnalysisStoreProps {
  surveyId: string;
  orgId: string;
}

interface ConfigureAnalysisState {
  updatingSurvey: boolean;
}

@inject('configureDataUIStore', 'setupStore', 'surveyStore', 'organizationStore')
@observer
class ConfigureAnalysis extends React.Component<ConfigureAnalysisProps, ConfigureAnalysisState> {
  state = {
    updatingSurvey: false
  } as ConfigureAnalysisState;

  componentDidMount() {
    this.getSurveyDetails();
    analytics.startRecording(true);
  }

  componentDidUpdate(prevProps: ConfigureAnalysisProps) {
    const { surveyId } = this.props;
    if (prevProps.surveyId !== surveyId) {
      this.getSurveyDetails();
    }
  }

  getSurveyDetails() {
    const { surveyId } = this.props;
    this.props.configureDataUIStore!.reset();
    if (surveyId) {
      this.getSurveyConfig();
      this.getSurveySynopsis();
    }
  }

  getSurveyConfig = async () => {
    const { surveyId } = this.props;
    const { configureDataUIStore, surveyStore } = this.props;
    const surveyConfig = await surveyStore!.getSurveyConfig(surveyId);
    const surveyStatus = await surveyStore!.getSurveyStatus(surveyId);
    if (surveyConfig) {
      configureDataUIStore!.initializeSurvey(surveyId, surveyConfig, surveyStatus);
    }
  }

  getSurveySynopsis = async () => {
    const { surveyId } = this.props;
    const { configureDataUIStore, setupStore } = this.props;
    const surveySynopsis = await setupStore!.getSurveySynopsis(surveyId);

    if (surveySynopsis) {
      configureDataUIStore!.setColumns(surveySynopsis);
    }
  };

  updateSurvey = async () => {
    const { surveyId } = this.props;
    const { configureDataUIStore } = this.props;

    this.setState({ updatingSurvey: true });
    analytics.track('Manage Data: Configure Analysis', { category: 'Manage Data' });

    const { updateSurvey } = this.props.surveyStore!;

    const configuration = configureDataUIStore!.prepareAPIData();
    await updateSurvey(surveyId, { configuration }) as Survey;

    this.setState({ updatingSurvey: false });

  }

  onUniqueIdChange = (uniqueId: number[]) => {
    this.props.configureDataUIStore!.updateUniqueId(uniqueId);
  }

  render() {
    const { updatingSurvey } = this.state;
    const { fetchingSurveySynopsis, fetchSurveySynopsisError } = this.props.setupStore!;
    const { updateSurveyError } = this.props.surveyStore!;
    const { columns, hasChanged, isValid, surveyConfig } = this.props.configureDataUIStore!;
    const { fetchingSurveys } = this.props.surveyStore!;
    const disabled = !(hasChanged && isValid);
    const loading = !updatingSurvey && (!!fetchingSurveys || fetchingSurveySynopsis);
    const errors = compact([fetchSurveySynopsisError, updateSurveyError]);

    return (
      <Segment className="white configure-analysis">
        {errors.map(error =>
          <Message
            className="error"
            negative={true}
            header={error}
            key={error}
          />
        )}
        {loading ?
          <Dimmer active={true} inverted={true}>
            <Loader size="medium">Loading survey configuration&hellip;</Loader>
          </Dimmer>
          :
          // Show the form when there is an error with update but not fetch
          !isEmpty(columns) ?
            <>
              <Form>
                <ConfigureUniqueId
                  columns={columns}
                  uniqueId={surveyConfig.sort_column}
                  onUniqueIdChange={this.onUniqueIdChange}
                />
                <ConfigureDate />
                <ConfigureScores />
                <ConfigureFilters />
              </Form>
              <div className="actions">
                <Button
                  className="save"
                  color="blue"
                  type="submit"
                  onClick={this.updateSurvey}
                  disabled={disabled || updatingSurvey}
                  loading={loading}
                >
                  Save
                </Button>
              </div>
            </>
            :
            null
        }
      </Segment>
    );
  }
}

export default ConfigureAnalysis;