import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntegrationType } from 'api/enums';
import { ConnectedIntegration } from 'api/interfaces';
import { getIntegrationUrlWithState } from 'components/Integrations/integration-helper';
import IntegrationFieldsForm from 'components/Integrations/IntegrationFieldsForm';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dimmer, Dropdown, Loader } from 'semantic-ui-react';
import { IntegrationConnectionType, IntegrationsStoreInterface } from 'stores/IntegrationsStore';

interface RenderFormComponent {
  renderForm(selectSurvey: object | undefined, config: string | undefined): JSX.Element | null;
}

interface DataDestinationWithIntegrationFormStoreProps {
  integrationsStore?: IntegrationsStoreInterface;
}

export interface DataDestinationWithIntegrationFormProps<T> extends DataDestinationWithIntegrationFormStoreProps {
  integrationType: IntegrationType;
  config?: object;
  configKey?: string;
  renderFormComponent: RenderFormComponent;
  datasetName?: string;
  orgId: string;
  surveyId: string;
  onChange(configuration: object, integrationId: string): void;
}

interface DataDestinationWithIntegrationFormState {
  integrationId: string;
  integrations: ConnectedIntegration[];
  loading: boolean;
  availableSurveys?: any;
}

@inject('integrationsStore')
@observer
class DataDestinationWithIntegrationForm<T> extends React.Component<
  DataDestinationWithIntegrationFormProps<T>,
  DataDestinationWithIntegrationFormState
> {
  acceptIntegrationsForm = null as unknown as () => Promise<boolean> | null;

  // config starts with one entry because it makes sense
  state = {
    integrations: [] as ConnectedIntegration[],
    loading: false
  } as DataDestinationWithIntegrationFormState;

  componentDidMount() {
    this.checkConnectedIntegration();
  }

  componentDidUpdate(prevProps: DataDestinationWithIntegrationFormProps<T>) {
    // check if the configuration for this form has changed, and if so broadcast
    if (this.props.config !== prevProps.config) {
      this.updateExternal();
    }
  }

  updateExternal() {
    // tells the listener of the onchange function that things.. changed
    const { onChange, config } = this.props;
    const { integrationId } = this.state;

    if (config) {
      onChange(config, integrationId);
    } else {
      onChange({}, integrationId);
    }
  }

  checkConnectedIntegration() {
    // checks if there is already a connected integration for the currently selected data soruce type
    const { integrationsStore, integrationType } = this.props;
    // check the store to find a connected integration
    if (integrationsStore) {
      const integrations = integrationsStore.getConnectedIntegrationsOfType(integrationType);
      if (integrations.length > 0) {
        this.setState({ integrationId: integrations[0].id }, () => {
          this.checkConnection();
          this.updateExternal();
        });
      }
      this.setState({ integrations });
    }
  }

  async checkConnection() {
    // Enumerates surveys available to check basic connectivity
    const { integrationsStore } = this.props;
    const { integrationId } = this.state;

    this.setState({ loading: true });
    if (integrationsStore) {
      await integrationsStore.enumerateIntegration(integrationId, {});

      this.setState({ loading: false });
    }
  }

  onConnectIntegration = async () => {
    // Connect a field integration. Accepts the form
    if (this.acceptIntegrationsForm) {
      this.setState({ loading: true });
      await this.acceptIntegrationsForm();
      await this.checkConnection();
      this.setState({ loading: false });
      this.checkConnectedIntegration();
    }
  };

  renderConnectIntegration(): JSX.Element {
    // renders the form for connecting an integration in situ
    const { integrationsStore, integrationType, datasetName, orgId, surveyId } = this.props;
    const { loading } = this.state;

    if (integrationsStore) {
      const knownIntegration = integrationsStore.getIntegrationTypeDetails(integrationType);
      if (knownIntegration) {
        if (knownIntegration.connectionType === IntegrationConnectionType.FIELDS ||
          knownIntegration.connectionType === IntegrationConnectionType.OAUTH_WITH_FIELDS
        ) {
          return (
            <div>
              This requires a connection
              <IntegrationFieldsForm

                {...knownIntegration}
                orgId={orgId}
                surveyId={surveyId}
                setAcceptForm={acceptForm => this.acceptIntegrationsForm = acceptForm}
              />
              <Button
                color="blue"
                onClick={this.onConnectIntegration}
                disabled={loading}
                loading={loading} >
                Connect
              </Button>
            </div>
          );
        } else {
          // Is an Oauth integration so we are going to need to go through an off-site flow
          const integrationUrl = getIntegrationUrlWithState({
            orgId,
            integrationUrl: knownIntegration.integrationUrl,
            surveyId: surveyId,
            datasetName
          });

          return (
            <div>
              <p>
                This requires a connection
              </p>
              <Button
                color="blue"
                onClick={() => {
                  if (integrationUrl) {
                    window.location.href = integrationUrl;
                  }
                }} >
                Connect
              </Button>
            </div>
          );
        }
      }
    }

    return (<div>
      No connection possible for this data source.
    </div>);
  }

  render(): JSX.Element | null {
    const { renderFormComponent, configKey } = this.props;
    const { loading, availableSurveys, integrations, integrationId } = this.state;

    return (
      <div>
        {integrations.length > 0 && (
          <div>
            {integrations.length > 1 && (
              <div>
                <div>Use integration connected by</div>
                <Dropdown
                  fluid={true}
                  selection={true}
                  placeholder="Select Integration"
                  options={integrations.map(integration => {
                    return {
                      value: integration.id,
                      key: integration.id,
                      text: `${ integration.authorizedBy } on ${ integration.authorizedAt }`
                    };
                  })}
                  value={integrationId}
                  onChange={(_e, { value }) => { this.setState({ integrationId: value as string }); }}
                />
              </div>
            )}
            <p>
              <FontAwesomeIcon
                icon="check"
                className="icon"
              />
              Successfully connected
            </p>
            {loading && (
              <Dimmer active={true} inverted={true}>
                <Loader size="small">Loading details...</Loader>
              </Dimmer>)}
            {renderFormComponent.renderForm ?
              renderFormComponent.renderForm(availableSurveys, configKey) :
              <div>
                Failed to find details
              </div>
            }

          </div>
        )}
        {integrations.length === 0 && (
          <div>
            {this.renderConnectIntegration()}
          </div>
        )}
      </div>
    );
  }
}

export default DataDestinationWithIntegrationForm;
