import * as React from 'react';
import * as workerPath from 'file-loader?name=[name].[hash].js!./CommentMatcher.worker';
import { InlineNotification } from 'components/Shared/InlineNotification';
import { useAddThemeToSelection } from 'components/SimilarSentences/useAddThemeToSelection';
import { observer } from 'mobx-react-lite';
import { Loader } from 'semantic-ui-react';
import { getThemesStore } from 'stores/RootStore';
import { PlainComment, Thread } from 'types/custom';
import { getCommentText, isThread } from 'lib/comment-helpers';
import segmentsToBlocks, { Block } from 'lib/segments-to-blocks';
import './theme-editor-comments.scss';
import classNames from 'classnames';
import { Button } from 'components/Shared/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddThemeToSelection } from 'components/AddTheme/AddThemeToSelection';
import { AddThemeToComment } from 'components/SimilarSentences/AddThemeToComment';
import { toJS } from 'mobx';
import './untagged-example-comments.scss';
import { DeletePhrasesTransform, TransformType } from './theme-file-transforms';
import { SearchInput } from 'components/Shared/SearchInput';
import * as Highlighter from 'react-highlight-words';

type MappedPhrases = string[];

interface NewThemes {
  [themeId: string]: MappedPhrases;
}

export interface EditedComments {
  [commentId: string]: NewThemes;
}

interface ActiveComment {
  commentId: string;
  blocks: Block[];
}

interface AddThemePosition {
  left: number;
  top: number;
}

interface UntaggedExampleCommentsProps {
  comments: PlainComment[] | Thread[];
  editedComments: EditedComments;
  groupId: string;
  orgId: string;
  processing: boolean;
  processError: boolean;
  setEditedComments: React.Dispatch<React.SetStateAction<EditedComments>>;
  surveyId: string;
}

const UntaggedExampleComments = observer(({
  comments,
  editedComments,
  groupId,
  orgId,
  processing,
  processError,
  setEditedComments,
  surveyId,
}: UntaggedExampleCommentsProps) => {
  const [activeComment, setActiveComment] = React.useState<ActiveComment | null>(null);
  const [openAddThemeToComment, setOpenAddThemeToComment] = React.useState<boolean>(false);
  const [addThemePosition, setAddThemePosition] = React.useState<AddThemePosition | null>(null);
  const [search, setSearch] = React.useState<string>('');
  const commentsRef = React.useRef<HTMLUListElement>(null);
  const addEditWorkerRef = React.useRef<Worker | null>(null);
  const undoEditWorkerRef = React.useRef<Worker | null>(null);
  const {
    applying,
    applyTransform,
    exampleCommentsError,
    exampleCommentsLoading,
    titles,
    transforms,
    undone
  } = getThemesStore();

  React.useEffect(() => {
    undoEditWorkerRef.current = new Worker(workerPath.default);

    undoEditWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { comments } = event.data;
      const [latestUndone] = undone;

      if (latestUndone && 'phrases' in latestUndone && 'toThemeId' in latestUndone) {
        const { phrases, toThemeId } = latestUndone;
        for (const comment of comments) {
          const { id } = comment;

          setEditedComments((prev) => {
            const updatedPhrases = prev[id][toThemeId].filter((phrase) => !phrases.includes(phrase));

            if (updatedPhrases.length === 0) {
              const { [id]: _, ...rest } = prev;
              return rest;
            }

            return {
              ...prev,
              [id]: {
                ...prev[id],
                [toThemeId]: updatedPhrases,
              },
            };
          });
        }
      }
    };

    const [latestUndone] = undone;

    if (!latestUndone || !('phrases' in latestUndone)) {
      return;
    }

    const { phrases } = latestUndone;

    undoEditWorkerRef.current.postMessage({
      comments,
      invertResults: false,
      phrases: toJS(phrases),
      resultsSize: 500,
    });

    return () => {
      undoEditWorkerRef.current?.terminate();
    };
  }, [comments, setEditedComments, undone, undone.length]);

  React.useEffect(() => {
    const [latestTransform] = transforms.slice().reverse();

    if (latestTransform?.type !== TransformType.AddPhrases) {
      return;
    }

    addEditWorkerRef.current = new Worker(workerPath.default);

    addEditWorkerRef.current.onmessage = (event: MessageEvent) => {
      const { comments } = event.data;

      const { phrases, toThemeId } = latestTransform;
      for (const comment of comments) {
        const { id } = comment;

        setEditedComments((prev) => {
          if (!(id in prev)) {
            return {
              ...prev,
              [id]: {
                [toThemeId]: phrases,
              }
            }
          } else {
            return {
              ...prev,
              [id]: {
                ...prev[id],
                [toThemeId]: [...(prev[id][toThemeId] || []), ...phrases],
              }
            }
          }
        });
      }
    };

    const { phrases } = latestTransform;

    addEditWorkerRef.current.postMessage({
      comments,
      invertResults: false,
      phrases: toJS(phrases),
      resultsSize: 500,
    });

    return () => {
      addEditWorkerRef.current?.terminate();
    };
  }, [comments, setEditedComments, transforms]);

  const {
    clearSelection,
    onEnterBlock,
    onSelectionChange,
    selectedPhrase,
    selectedPhrasePosition,
    selectedStatus,
    onTextSelectionEnd
  } = useAddThemeToSelection();

  React.useEffect(() => {
    const commentsElement = commentsRef.current;

    commentsElement?.addEventListener('selectionchange', () => {
      onSelectionChange();
    });

    return () => {
      commentsElement?.removeEventListener('selectionchange', onSelectionChange);
    };
  }, [onSelectionChange]);

  if (exampleCommentsError || processError) {
    return (
      <InlineNotification
        isHighlighted={true}
        title={exampleCommentsError ? 'Unable to fetch example comments' : 'Unable to process comments'}
        type="error"
      />
    );
  }

  if (exampleCommentsLoading || processing) {
    return (
      <Loader inline="centered" size="small" active={true}>
        {processing ? 'Processing comments' : 'Loading comments'}
      </Loader>
    );
  }

  const getBlocks = (comment: PlainComment | Thread) => {
    if (isThread(comment)) {
      return null;
    }

    return segmentsToBlocks(comment.comment, comment.segments, -1, []);
  };

  const getAddThemePosition = (commentRect: DOMRect, buttonRect: DOMRect) => {
    const left = (buttonRect.left - commentRect.left);
    const top = (buttonRect.top - commentRect.top) + (buttonRect.height / 2);

    return {
      left,
      top,
    };
  };

  const handleAddTheme = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, activeComment: ActiveComment) => {
    if (!commentsRef.current) {
      return;
    }

    const addThemePosition = getAddThemePosition(
      commentsRef.current?.getBoundingClientRect(),
      event.currentTarget.getBoundingClientRect(),
    );

    setAddThemePosition(addThemePosition);
    setActiveComment(activeComment);
    setOpenAddThemeToComment(true);
  };

  const handleAddThemeToCommentClose = () => {
    setOpenAddThemeToComment(false);
  };

  const commentIsEdited = (commentId: string) => {
    return !!editedComments[commentId];
  }

  const showAddThemeToComment = openAddThemeToComment && !!activeComment && !!addThemePosition;
  const showAddThemeToSelection = !!selectedPhrase && !!activeComment;

  const getCommentThemes = (commentId: string) => {
    const commentThemes = editedComments[commentId];

    if (!commentThemes) {
      return;
    }

    const commentThemeIds = Object.keys(commentThemes);

    return [...new Set(commentThemeIds)].map((themeId) => {
        const themeTitle = titles[themeId];

        return {
          themeId,
          themeTitle,
        }
      })
      .sort((a, b) => a.themeTitle.localeCompare(b.themeTitle));
  };

  const getAllPhrasesByThemeId = (themeId: string) => {
    return [... new Set(
        Object.values(editedComments).reduce((acc, commentThemes) => {
          const phrases = commentThemes[themeId];
          if (!phrases) {
            return acc;
          }
          return [...acc, ...phrases];
      }, [])
    )];
  };

  const handleRemoveTheme = (themeId: string) => {
    const phrasesToRemove = getAllPhrasesByThemeId(themeId);
    const formattedPhrasesForMessage = phrasesToRemove.map(phrase => `"${ phrase }"`);

    const transform: DeletePhrasesTransform = {
      groupId,
      message: { prefix: `Deleted phrase${ formattedPhrasesForMessage.length > 1 ? 's' : '' } ${ formattedPhrasesForMessage }` },
      phrases: phrasesToRemove,
      themeId: themeId,
      type: TransformType.DeletePhrases
    };

    applyTransform(transform);

    setEditedComments((prev) => {
      return Object.entries(prev).reduce((acc, [commentId, value]) => {
        const phrases = value[themeId];
        const updatedPhrases = phrases.filter((phrase) => !phrasesToRemove.includes(phrase));

        if (updatedPhrases.length === 0) {
          return acc;
        }

        return {
          ...acc,
          [commentId]: updatedPhrases,
        };
      }, {});
    });
  };

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  const sortedComments = comments.sort((currentComment, previousComment) => {
    const currentCommentText = getCommentText(currentComment);
    const previousCommentText = getCommentText(previousComment);

    return previousCommentText.length - currentCommentText.length;
  });

  const filteredComments = sortedComments.filter((sortedComment) => {
    if (isThread(sortedComment)) {
      const threadText = sortedComment.comment.join('\n');

      return threadText.toLowerCase().includes(search.toLowerCase());
    }

    return sortedComment.comment.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
      <div className="theme-editor-comments">
        <SearchInput onChange={handleSearch} resultCount={filteredComments?.length} />
        <ul className="theme-editor-comments__list" onMouseUp={onTextSelectionEnd} ref={commentsRef}>
          {filteredComments.map((sortedComment) => {
            const blocks = getBlocks(sortedComment);

            if (!blocks) {
              return null;
            }

            return (
              <li
                className={classNames('theme-editor-comments__item', {
                  'theme-editor-comments__item--edited': commentIsEdited(sortedComment.id),
                })}
                key={sortedComment.id}
                onClick={() => setActiveComment({ commentId: sortedComment.id, blocks })}
              >
                {blocks.map((block) => (
                  <span key={block.key} onMouseEnter={() => onEnterBlock(block)}>
                    <Highlighter textToHighlight={block.content} searchWords={[search]} />
                  </span>
                ))}
                <div className="theme-editor-comments__controls">
                  <Button
                    onClick={(event) => handleAddTheme(event, { commentId: sortedComment.id, blocks })}
                    icon={<FontAwesomeIcon icon="plus" />}
                    size="small"
                    subvariant="neutral-text"
                    variant="tertiary"
                  >
                    Add theme
                  </Button>
                  {getCommentThemes(sortedComment.id)?.map((commentTheme) => (
                    <span className="untagged-example-comments-pill" key={commentTheme.themeId}>
                      {commentTheme.themeTitle}
                      <button className="untagged-example-comments-pill__button" onClick={() => handleRemoveTheme(commentTheme.themeId)}>
                        <FontAwesomeIcon icon="times" />
                      </button>
                    </span>
                  ))}
                </div>
              </li>
            )
          })}
        </ul>
        {showAddThemeToComment && (
          <div
            className="theme-editor-comments__add-theme-to-comment"
            style={{
              left: addThemePosition.left,
              top: addThemePosition.top,
            }}
          >
            <AddThemeToComment
              blocks={activeComment.blocks}
              clearSelection={clearSelection}
              commentId={activeComment.commentId}
              isApplyingThemes={applying}
              afterClose={handleAddThemeToCommentClose}
              orgId={orgId}
              preSelectedPhrase={''}
              surveyId={surveyId}
            />
          </div>
        )}
        {showAddThemeToSelection && (
          <AddThemeToSelection
            blocks={activeComment.blocks}
            clearSelection={clearSelection}
            commentId={activeComment.commentId}
            isApplyingThemes={applying}
            orgId={orgId}
            preSelectedPhrase={selectedPhrase}
            selectedPhrasePosition={selectedPhrasePosition}
            selectionStatus={selectedStatus}
            surveyId={surveyId}
          />
        )}
      </div>
    </>
  );
});

export { UntaggedExampleComments };
