import * as React from 'react';
import { Dropdown, DropdownMenu, Input, ModalActions, Radio } from 'semantic-ui-react';
import { Modal, ModalContent, ModalHeader } from 'components/Shared/Modal';
import { AddThemeBaseItem, SelectedTheme } from './types';
import { Button } from 'components/Shared/Button';
import './new-theme-modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeTreeView } from './ThemeTreeView';

type ThemeType = 'baseTheme' | 'subTheme';

interface Props {
  onAddNewTheme: (newTheme: any) => void;
  onClose: () => void;
  themeName: string;
  themes: AddThemeBaseItem[];
}

const NewThemeModal = ({ onAddNewTheme, onClose, themeName }: Props) => {
  const [themeType, setThemeType] = React.useState<ThemeType>('baseTheme');
  const [newBaseTitle, setNewBaseTitle] = React.useState<string>(themeName);
  const [selectedBaseTheme, setSelectedBaseTheme] = React.useState<SelectedTheme | undefined>(undefined);

  const getNewTheme = () => {
    if (themeType === 'baseTheme') {
      return {
        baseTitle: newBaseTitle,
        label: newBaseTitle,
      };
    }

    if (themeType === 'subTheme' && selectedBaseTheme) {
      return {
        base: selectedBaseTheme?.base,
        baseTitle: selectedBaseTheme?.baseTitle,
        label: newBaseTitle,
        subTitle: newBaseTitle,
      };
    }

    return null;
  };

  const handleAddNewTheme = () => {
    if (!newBaseTitle) {
      return;
    }

    const newTheme = getNewTheme();

    if (!newTheme) {
      return;
    }

    onAddNewTheme(newTheme);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setNewBaseTitle(value);
  };

  const handleSelectBaseTheme = (selectedTheme: SelectedTheme) => {
    setSelectedBaseTheme(selectedTheme);
  };

  const dropdownButtonText = selectedBaseTheme ? selectedBaseTheme.baseTitle : 'Select base theme';

  return (
    <Modal onClose={onClose} open={true}>
      <ModalHeader as="h2">Add new theme</ModalHeader>
      <ModalContent showOverflow={true}>
        <div className="new-theme-modal__theme-input">
          <Input fluid={true} onChange={handleChange} placeholder="Theme name" value={newBaseTitle} />
        </div>
        <div className="new-theme-modal__base-theme">
          <Radio
            id="base-theme"
            checked={themeType === 'baseTheme'}
            label={<label htmlFor="base-theme">Base theme</label>}
            name="themeType"
            onChange={() => setThemeType('baseTheme')}
            value="baseTheme"
          />
        </div>
        <div className="new-theme-modal__sub-theme">
          <div className="new-theme-modal__sub-theme-radio">
            <Radio
              id="sub-theme"
              checked={themeType === 'subTheme'}
              label={<label htmlFor="sub-theme">Sub theme of</label>}
              name="themeType"
              onChange={() => setThemeType('subTheme')}
              value="subTheme"
            />
          </div>
          <Dropdown
            className="new-theme-modal__dropdown"
            icon={null}
            trigger={
              <Button
                icon={<FontAwesomeIcon icon="chevron-down" />}
                size="small"
                subvariant="neutral-text"
                variant="toggle"
              >
                {dropdownButtonText}
              </Button>
            }
            value="candy"
          >
            <DropdownMenu>
              <div className="new-theme-modal__dropdown-content">
                <ThemeTreeView hideSubThemes={true} onThemeSelect={handleSelectBaseTheme} />
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleAddNewTheme}>Next</Button>
      </ModalActions>
    </Modal>
  );
};

export { NewThemeModal };
