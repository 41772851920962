import { IntegrationType } from 'api/enums';
import * as React from 'react';
import { Form, FormInputProps, Input } from 'semantic-ui-react';
import './data-destination-sftp.scss';
import DataDestinationWithIntegrationForm from './DataDestinationWithIntegrationForm';

export interface DataDestinationSFTPCreateFormProps {
  orgId: string;
  surveyId: string;
  existingConfiguration?: SFTPConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

export enum TreatmentAfterProcessing {
  DELETE = 'DELETE',
  MOVE = 'MOVE'
}

interface SFTPConfig {
  pathPattern: string;
}

interface DataDestinationSFTPCreateFormState {
  config: SFTPConfig;
}

class DataDestinationSFTPCreateForm extends React.Component<
  DataDestinationSFTPCreateFormProps,
  DataDestinationSFTPCreateFormState
> {

  state = {
    config: {
      pathPattern: ''
    }
  };

  componentDidMount() {
    const { existingConfiguration } = this.props;
    const { config } = this.state;

    if (existingConfiguration) {
      const combinedConfig = { ...config, ...existingConfiguration };
      this.setState({
        config: combinedConfig
      });
      this.checkValidity(combinedConfig);
    }
  }

  checkValidity = (config: SFTPConfig) => {
    if (this.props.setValidity) {
      let validity = !!config.pathPattern && config.pathPattern.length > 0;
      this.props.setValidity(validity);
    }
  }

  changeSelectedPathPattern = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    // update the config
    const { config } = this.state;
    config.pathPattern = value;
    this.setState({ config });
    this.checkValidity(config);
  };

  renderForm(): JSX.Element | null {
    const { config } = this.state;
    return (<>
      <Form.Field className="sftp-data-source-row">
        <label>sFTP Path Pattern</label>
        <Input
          data-testid="sftp-path-pattern"
          placeholder="Path Pattern"
          value={config.pathPattern}
          onChange={this.changeSelectedPathPattern}
        />
        <div className="helper-text">
          <div>This should look like <span className="code-text">destination/data_{"{"}date{"}"}.csv</span></div>
          <ul>
            <li><span className="code-text">{"{"}date{"}"}</span> will be replaced with the date of the export</li>
            <li><span className="code-text">{"{"}datetime{"}"}</span> will be replaced with the date and time of the export</li>
          </ul>
        </div>
      </Form.Field>

    </>
    );
  }

  render(): JSX.Element | null {
    const { surveyId, orgId, onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataDestinationWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          configKey={JSON.stringify(config)}
          integrationType={IntegrationType.SFTP}
          orgId={orgId}
          surveyId={surveyId}
        />
      </div>
    );
  }
}

export default DataDestinationSFTPCreateForm;