<template>
  <impact-with-subthemes-component
    :org-id="orgId"
    :error="error"
    :loading="loading"
    :max-value="maxValue"
    :range="range"
    :score-name="scoreName"
    :sort="sort"
    :filters="filters"
    :title="config.title"
    :themes="themes"
    :source="source"
    :show-comments="config.showComments"
    :show-summary="config.showSummary"
    :theme-sources="themeSources"
    :is-weighted="isWeighted"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
    :compare-and-filters="compareAndFilters"
  />
</template>

<script>
import { filter, get, map, max, slice, orderBy } from 'lodash';
import { ReactInVue } from 'vuera';
import { ImpactWithSubthemesComponent } from 'components/Dashboard/Widgets/Components/ImpactWithSubthemesComponent.tsx';
import customHistory from 'routing/history';
import { parse  } from 'query-string';

export default {
  name: 'IMPACT_WITH_SUBTHEMES',
  components: {
    ImpactWithSubthemesComponent: ReactInVue(ImpactWithSubthemesComponent),
  },
  props: {
    orgId: { type: String },
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    error: { default: undefined, type: String },
    data: { default: undefined, type: Object },
    limit: { default: 4, type: Number },
    subthemesLimit: { default: 3, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    isWeighted: { default: false, type: Boolean },
    panelOrder: { type: Number },
    widgetOrder: { type: Number }
  },
  // called as a static function from WidgetChooser#widgetType to determine what type to use
  isComparison(config) {
    return config.compareToOverall;
  },
  computed: {
    maxValue() {
      const impacts = map(this.themes, theme => Math.abs(theme.impact));
      return max(impacts) || 0;
    },
    themeSources() {
      return get(this, 'data.metadata.sources', undefined);
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    scoreName() {
      return get(this.config, 'score.name', get(this, 'scoreConfig.name'));
    },
    sort() {
      return get(this, 'config.sort', 'desc');
    },
    themes() {
      const asc = this.sort === 'asc';
      let themes = filter(get(this, 'data.themes', []), theme => {
        const impact = this.comparisonThemes
          ? theme.impactBaseline
          : theme.impact;
        if (asc) {
          return impact < 0;
        } else {
          return impact > 0;
        }
      });
      themes = themes.map(theme => {
        let subthemes = filter((theme.subthemes || []), subtheme => {
          if (asc) {
            return subtheme.impact < 0;
          } else {
            return subtheme.impact > 0;
          }
        });
        subthemes = orderBy(subthemes, 'impact', this.sort);
        return {
          ...theme,
          subthemes: slice(subthemes, 0, this.subthemesLimit)
        };
      });
      return slice(themes, 0, this.limit);
    },
    range() {
      return get(this, 'scoreConfig.options.range');
    },
    compareAndFilters() {
      const queryParams = parse(customHistory.location.search);
      const { filters, compare } = queryParams;
      return { compare, filters };
    }
  }
};
</script>
