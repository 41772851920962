import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './tree-view.scss';

interface SubTreeRefMap {
  [itemId: string]: HTMLUListElement | null;
}

export interface TreeViewItem {
  id: string;
  label: string;
  children?: TreeViewItem[];
}

interface Props {
  filter?: string;
  items: TreeViewItem[];
  hideSubThemes?: boolean;
  onEmptyStateChange?: (isEmpty: boolean) => void;
  onItemClick: (itemId: string) => void;
}

const TreeView = ({ filter, hideSubThemes, items, onEmptyStateChange, onItemClick }: Props) => {
  const [expandedSubTrees, setExpandedSubTrees] = React.useState<string[]>([]);
  const prevFilter = React.useRef<string | undefined>(filter);
  const subTreeRefs = React.useRef<SubTreeRefMap>({});

  const isChildTreeExpanded = (itemId: string) => {
    return expandedSubTrees.includes(itemId) || !!filter;
  };

  const toggleSubTree = (itemId: string) => {
    const subTreeElement = subTreeRefs.current[itemId];
    if (expandedSubTrees.includes(itemId)) {
      setExpandedSubTrees(expandedSubTrees.filter((id) => id !== itemId));

      if (subTreeElement) {
        subTreeElement.style.height = `${subTreeElement.scrollHeight}px`;
        setTimeout(() => (subTreeElement.style.height = '0'), 10);
      }
    } else {
      setExpandedSubTrees([...expandedSubTrees, itemId]);

      if (subTreeElement) {
        subTreeElement.style.height = '0';
        subTreeElement.style.display = 'block';
        const height = subTreeElement.scrollHeight;
        setTimeout(() => (subTreeElement.style.height = `${height}px`), 10);
      }
    }
  };

  const filteredItems = React.useMemo(() => {
    if (!filter) {
      return items;
    }

    const filterTreeItem = (theme) => {
      return theme.label.toLowerCase().includes(filter.toLowerCase());
    };

    return items
      .map((item) => ({
        ...item,
        children: item.children ? item.children.filter(filterTreeItem) : [],
      }))
      .filter((item) => {
        return filterTreeItem(item) || (item.children && item.children.length > 0);
      });
  }, [filter, items]);

  React.useEffect(() => {
    const closeAllSubTrees = () => {
      if (!subTreeRefs.current) {
        return;
      }

      Object.values(subTreeRefs.current).forEach((subTreeElement) => {
        if (subTreeElement) {
          subTreeElement.style.display = 'block';
          subTreeElement.style.height = '0';
        }
      });
    };

    const expandAllSubTrees = () => {
      if (!subTreeRefs.current) {
        return;
      }

      Object.entries(subTreeRefs.current).forEach(([itemId, subTreeElement]) => {
        setExpandedSubTrees([...expandedSubTrees, itemId]);

        if (subTreeElement) {
          subTreeElement.style.display = 'block';
          subTreeElement.style.height = 'auto';
        }
      });
    };

    const handleFilterChange = () => {
      if (filter) {
        expandAllSubTrees();
      } else {
        closeAllSubTrees();
      }
    };

    if ((prevFilter.current && !filter) || (!prevFilter.current && filter)) {
      handleFilterChange();
    }

    prevFilter.current = filter;
  }, [expandedSubTrees, filter]);

  React.useEffect(() => {
    onEmptyStateChange?.(filteredItems?.length === 0);
  }, [filteredItems, onEmptyStateChange]);

  return (
    <ul className="tree-view" role="tree">
      {filteredItems.map((item) => (
        <li key={item.id} id={item.id} role="treeitem">
          <div className="tree-view__item">
            {!!item.children &&
              <div className="tree-view__toggle">
                {item.children && item.children.length > 0 && (
                  <button
                    className={classNames('tree-view__toggle-button', {
                      'tree-view__toggle-button--is-expanded': isChildTreeExpanded(item.id),
                    })}
                    onClick={() => toggleSubTree(item.id)}
                  >
                    <span
                      className={classNames('tree-view__toggle-icon', {
                        'tree-view__toggle-icon--is-expanded': isChildTreeExpanded(item.id),
                      })}
                    >
                      <FontAwesomeIcon icon="chevron-right" />
                    </span>
                  </button>
                )}
              </div>
            }
            <button className={classNames('tree-view__button', {
            'tree-view__button--hide-sub-themes': hideSubThemes,
          })}onClick={() => onItemClick(item.id)}>
              {item.label}
            </button>
          </div>
          {!!item.children && (
            <ul
              className={classNames('tree-view__child-tree', {
                'tree-view__child-tree--is-expanded': expandedSubTrees.includes(item.id),
              })}
              ref={(el) => (subTreeRefs.current[item.id] = el)}
              role="group"
            >
              {item.children.map((subItem) => {
                return (
                  <li className="tree-view__child-item" id={subItem.id} key={subItem.id} role="treeitem">
                    <button onClick={() => onItemClick(subItem.id)} className="tree-view__button">
                      {subItem.label}
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export { TreeView };
