import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import ArrowDownComparison from 'images/icons/arrow-down-comparison.svg';
import ArrowDownOverall from 'images/icons/arrow-down-overall.svg';
import ArrowUpComparison from 'images/icons/arrow-up-comparison.svg';
import ArrowUpOverall from 'images/icons/arrow-up-overall.svg';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { isNaN, isNumber } from 'lodash';
import * as React from 'react';
import toFixed from 'vue/libs/to-fixed';
import './score-comparison.scss';

interface ScoreData {
  score: {
    score: number;
  };
  count: number;
}

interface Props {
  config: {
    title?: string;
    precision?: number;
    score?: {
      name?: string;
      options?: {
        precision?: number;
      };
    };
  };
  context: {
    compareFilterName?: string;
  };
  data: {
    currentScore?: ScoreData;
    currentComparisonScore?: ScoreData;
    previousScore?: ScoreData;
    previousComparisonScore?: ScoreData;
    previousPeriod?: string;
    scoreConfig?: {
      name?: string;
      options?: {
        precision?: number;
      };
    };
  };
  warning?: string;
  error?: string;
  loading?: boolean;
  source?: string;
  panelOrder: number;
  widgetOrder: number;
}

const calculatePrecision = (precision: number | undefined, difference: number) => {
  const absDifference = Math.abs(difference);
  if (isNumber(precision)) {
    return precision;
  }
  return absDifference < 10 ? 1 : 0;
};

const differenceLabel = (difference: number, precision: number) => {
  if (isNaN(difference)) {
    return null;
  }
  const absDifference = Math.abs(difference);
  const label = toFixed(difference, precision);

  if (absDifference < 0.1) {
    return 'No change';
  }
  return difference > 0 ? `+${label}` : label;
};

const calculateArrow = (previousScore: number | undefined, scoreDifference: number, overall: boolean) => {
  if (previousScore) {
    const absDifference = Math.abs(scoreDifference);
    if (absDifference < 0.1) {
      return null;
    }
    if (scoreDifference < 0) {
      return overall ? <ArrowDownOverall className="arrowIndicator" /> : <ArrowDownComparison className="arrowIndicator" />;
    }
    return overall ? <ArrowUpOverall className="arrowIndicator" /> : <ArrowUpComparison className="arrowIndicator" />;
  }
  return null;
};

const calculateDifference = (score?: number, previous?: number) => {
  if (isNumber(score) && isNumber(previous)) {
    return score - previous;
  }
  return Number.NaN;
};

const ScoreComparison = withCamelCaseProps((props: Props) => {
  const { config, context, data, warning, error, loading, panelOrder, widgetOrder } = props;

  const currentScore = data?.currentScore?.score?.score;
  const currentComparisonScore = data?.currentComparisonScore?.score?.score;
  const previousScore = data?.previousScore?.score?.score;
  const previousComparisonScore = data?.previousComparisonScore?.score?.score;
  const previousPeriod = data?.previousPeriod;
  const responseCount = data?.currentScore?.count ?? 0;
  const comparisonResponseCount = data?.currentComparisonScore?.count ?? 0;
  const previousResponseCount = data?.previousScore?.count ?? 0;
  const previousComparisonResponseCount = data?.previousComparisonScore?.count ?? 0;
  const scoreConfig = data?.scoreConfig ?? config?.score;

  const scoreDifference = calculateDifference(currentScore, previousScore);
  const comparisonScoreDifference = calculateDifference(currentComparisonScore, previousComparisonScore);

  const precision = calculatePrecision(
    scoreConfig?.options?.precision ?? config?.precision,
    scoreDifference
  );
  const comparisonPrecision = calculatePrecision(
    scoreConfig?.options?.precision ?? config?.precision,
    comparisonScoreDifference
  );

  const showComparison = !!data?.currentComparisonScore;
  const showScore = responseCount > 0;
  const scoreName = config?.score?.name ?? scoreConfig?.name;

  const subtitleElements = () => (
    <div className="widget-subtitle">
      <div className="widget-subtitle-text">{scoreName}</div>
    </div>
  );

  return (
    <WidgetWrapper
      aria-label={scoreName}
      title={config.title}
      loading={loading}
      error={error}
      warning={warning}
      panelOrder={panelOrder}
      widgetOrder={widgetOrder}
      subtitle={subtitleElements()}
    >
      {showScore ? (
        <div
          data-testid="score-comparison-widget"
          className="widget-body"
        >
          <div className="scores">
            <div className="score overall">
              <h2>
                {toFixed(currentScore, 1)}
                {previousResponseCount > 0 && (
                  <div
                    className="old-score"
                    title={previousPeriod ? `${differenceLabel(scoreDifference, precision)} compared to ${previousPeriod}` : undefined}
                  >
                    {calculateArrow(previousScore, scoreDifference, true)}
                    {differenceLabel(scoreDifference, precision)}
                  </div>
                )}
                <div className="response-count-subtext">
                  {responseCount} responses
                </div>
              </h2>
              <legend>Overall</legend>
            </div>

            {showComparison && (
              <div className="score comparison">
                {currentComparisonScore ? (
                  <h2 className="score-title">
                    {toFixed(currentComparisonScore, 1)}
                    {previousComparisonResponseCount > 0 && (
                      <div
                        className="old-score"
                        title={previousPeriod ? `${differenceLabel(comparisonScoreDifference, comparisonPrecision)} compared to ${previousPeriod}` : undefined}
                      >
                        {calculateArrow(previousComparisonScore, comparisonScoreDifference, false)}
                        {differenceLabel(comparisonScoreDifference, comparisonPrecision)}
                      </div>
                    )}
                    <div className="response-count-subtext">
                      {comparisonResponseCount} responses
                    </div>
                  </h2>
                ) : (
                  <div className="score-title small-text">
                    Not enough response to calculate
                  </div>
                )}
                <legend>{context.compareFilterName}</legend>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="empty widget-body">
          Not enough responses to calculate
        </div>
      )}
    </WidgetWrapper>
  );
});

export { ScoreComparison };
