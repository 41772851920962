import Auth from 'Auth/Auth';
import analytics from 'lib/analytics';

import { Transform, TransformType } from 'components/ThemeEditor/theme-file-transforms';
import {
  getAnalysisToolsUIStore, getNotificationStore,
  getThemesStore
} from 'stores/RootStore';

const doubleJSON = (obj: object) => JSON.stringify(JSON.stringify(obj));

export async function applyThemeChanges() {
  const notificationStore = getNotificationStore();
  const analysisToolsUIStore = getAnalysisToolsUIStore();
  const themesStore = getThemesStore();
  analytics.track('Analysis: Apply Themes');

  const uploadNotificationId = `themes-uploading-${ analysisToolsUIStore.currentSurveyId }`;
  const errorNotificationId = `themes-uploading-error-${ analysisToolsUIStore.currentSurveyId }`;
  // clear upload error if we are trying again
  notificationStore.dismiss(errorNotificationId);
  try {
    notificationStore.push({
      id: uploadNotificationId,
      preventClose: true,
      title: 'Uploading changes',
      message: 'One moment please',
      icon: 'THEME_EDIT_BLUE',
    });

    await themesStore.handleInlineThemeApplication();
    notificationStore.dismiss(uploadNotificationId);

    await themesStore.getStatus(analysisToolsUIStore.currentSurveyId!);

    logThemeChanges(
      themesStore.surveyId!,
      themesStore.orgId!,
      themesStore.transforms
    );

    themesStore.transforms = [];

  } catch (e) {
    notificationStore.push({
      id: errorNotificationId,
      title: 'Something went wrong',
      message: 'Please try again',
      icon: 'THEME_EDIT_ERROR',
      timeout: 5,
    });
  }
}

export async function logThemeChanges(surveyId: string, orgId: string, transforms: Transform[]): Promise<void> {

  const url = `/survey/${ surveyId }/flag_comment`;

  await transforms.map(async tx => {
    switch (tx.type) {
      case TransformType.AddTheme: {

        const description = doubleJSON({
          mappedPhrases: tx.newMappedPhrases,
          themeId: tx.themeId,
          title: tx.title,
          type: tx.type,
          orgId
        });

        await Auth.fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            comment: '',
            commentId: '',
            description,
            flagType: 'themes'
          })
        });

        return;
      }
      case TransformType.AddPhrases: {

        const description = doubleJSON({
          themeId: tx.toThemeId,
          mappedPhrases: tx.phrases,
          type: tx.type,
          orgId
        });

        await Auth.fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            comment: '',
            commentId: '',
            description,
            flagType: 'themes'
          })
        });

        return;
      }
      case TransformType.DeletePhrases: {

        const description = doubleJSON({
          mappedPhrases: tx.phrases,
          themeId: tx.themeId,
          type: tx.type,
          orgId
        });

        await Auth.fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            comment: '',
            commentId: '',
            description,
            flagType: 'themes'
          })
        });
        return;
      }
      default:
        return;
    }

  }).reduce(async (acc, p) => {
    await acc;
    return p;
  }, Promise.resolve());

}
