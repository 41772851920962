import * as classnames from 'classnames';
import Openbox from 'images/icons/open-box.svg';
import WritingComment from 'images/icons/writing-comment.svg';
import * as React from 'react';
import './empty.scss';

interface Props {
  orientation?: 'horizontal' | 'vertical';
  children?: React.ReactNode;
  description?: string;
  title: string;
  icon?: 'writing-comment' | 'open-box'
}

const getIcon = (icon: Props['icon']) => {
  switch (icon) {
    case 'open-box': {
      return <Openbox />
    }
    default: {
      return <WritingComment />
    }
  }
};

const Empty = ({ children, description, title, orientation = 'vertical', icon = 'writing-comment' }: Props) => {

  return (
    <div className="empty-state">
      <div className={classnames("empty-state__content", {
        'isHorizontal': orientation === 'horizontal'
      })}>
        <div className="empty-state__icon">
          {getIcon(icon)}
        </div>
        <div>
          <h2 className="empty-state__title">{title}</h2>
          <p className="empty-state__description">{description}</p>
        </div>
        {children}
      </div>
    </div>
  );
}

export { Empty };
