import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Shared/Button';
import analytics from 'lib/analytics';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import * as React from 'react';
import { getActiveDashboardUIStore } from 'stores/RootStore';
import CopyToClipboardVue from 'vue/components/CopyToClipboard/CopyToClipboard.vue';
import ReviewControlsVue from 'vue/components/Summary/ReviewControls.vue';
import queryBuilder from 'vue/libs/queryBuilder';
import thematicData from 'vue/libs/thematicData';
import { VueInReact } from 'vuera';
import './summary-component.scss';

const ReviewControls = VueInReact(ReviewControlsVue);
const CopyToClipboard = VueInReact(CopyToClipboardVue);

interface Theme {
  theme: {
    code: string;
    title: string;
  };
  subtheme?: {
    code: string;
    title: string;
  } | null;
}

interface Props {
  theme?: Theme;
  source?: string;
  filters?: {
    baseline?: string;
    comparison?: string;
  };
  canNavigate?: boolean;
  comparisonThemes?: unknown;
  doNavigate: () => void;
}

const SummaryComponent = withCamelCaseProps((props: Props) => {
  const { theme, source, filters, canNavigate, comparisonThemes, doNavigate } = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [baselineSummary, setBaselineSummary] = React.useState('');
  const [comparisonSummary, setComparisonSummary] = React.useState('');

  const activeDashboardUIStore = getActiveDashboardUIStore();
  const hasComparison = comparisonThemes !== undefined;

  const getLocationForFeedback = () => {
    const dashboardId = activeDashboardUIStore.currentDashboardId;
    return `dashboard:${dashboardId}`;
  };

  const fetchSummary = React.useCallback(async () => {
    if (!theme) return;

    const selectedThemeName = () => {
      if (!theme) return '';
      return theme.subtheme ? theme.subtheme.title : theme.theme.title;
    };

    setError(false);
    setLoading(true);
    setBaselineSummary('');

    const base = theme.theme.code || '';
    const sub = theme.subtheme?.code ?? undefined;
    const sentiment = 'all';
    const summaryContext = selectedThemeName();
    const segmentSelection = theme
      ? queryBuilder.appendThemesToSegmentFilter('', base, sub)
      : '';

    const options = {
      dataSource: source,
      segmentSelection,
      summaryContext,
      type: 'default',
      summarize: true,
      maxTokens: 100,
    };

    try {
      const filter = filters?.baseline ?? '';
      const data = await thematicData.getSummarization(
        filter,
        base,
        sub,
        sentiment,
        options,
        false
      );
      setBaselineSummary(data.data.summary);

      if (hasComparison) {
        const comparisonFilter = filters?.comparison ?? '';
        const comparisonData = await thematicData.getSummarization(
          comparisonFilter,
          base,
          sub,
          sentiment,
          options,
          false
        );
        setComparisonSummary(comparisonData.data.summary);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [theme, source, filters, hasComparison]);

  React.useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const handleMouseUp = () => {
    const selectedText = window.getSelection()?.toString() ?? '';
    const includesInBaselineSummary = baselineSummary.includes(selectedText);
    const includesInComparisonSummary = comparisonSummary.includes(selectedText);
    if (selectedText.length > 0 && (includesInBaselineSummary || includesInComparisonSummary)) {
      analytics.track('Dashboard: Select Summary');
    }
  };

  const handleTextCopied = () => {
    analytics.track('Dashboard: Copy Summary');
  };

  const retrySummaryRequests = () => {
    thematicData.clearSummarizationCache();
    fetchSummary();
  };

  if (!theme) return null;

  return (
    <div className="summary-body">
      <div className="summary-title-area">
        <span className="summary-title">
          Summary for {theme.subtheme ? theme.subtheme.title : theme.theme.title}
        </span>
        {canNavigate && (
          <Button
            inline={true}
            variant="tertiary"
            size="small"
            onClick={doNavigate}
          >
            See more
          </Button>
        )}
        {!hasComparison && baselineSummary && (
          <div className="widget-summary-controls">
            <ReviewControls
              summary={baselineSummary}
              featureId="summarization"
              location={getLocationForFeedback()}
            />
          </div>
        )}
      </div>

      {loading ? (
        <div className="loading">
          <div>
            <FontAwesomeIcon icon="spinner" spin />
          </div>
          Loading
        </div>
      ) : error ? (
        <div className="summary-error">
          <FontAwesomeIcon icon="exclamation-triangle" />
          <span className="text">Something went wrong while loading the sentences.</span>
          <Button
            inline={true}
            variant="tertiary"
            size="small"
            onClick={retrySummaryRequests}
          >
            Click here to try again
          </Button>
        </div>
      ) : (
        <div className="summary">
          <div className="summary-content">
            {hasComparison && (
              <h4 className="label-baseline">
                <span>Baseline</span>
                {baselineSummary && (
                  <div className="widget-summary-controls">
                    <ReviewControls
                      className="widget-summary-controls"
                      summary={baselineSummary}
                      featureId="summarization"
                      location={getLocationForFeedback()}
                    />
                  </div>
                )}
              </h4>
            )}
            {baselineSummary ? (
              <span onMouseUp={handleMouseUp}>
                {baselineSummary}
              </span>
            ) : (
              <span className="empty-state">
                <FontAwesomeIcon icon="exclamation-triangle" />
                <span className="text">No data</span>
              </span>
            )}
            {baselineSummary && (
              <div>
                <CopyToClipboard
                  text={baselineSummary}
                  popupText="Copy summary"
                  on={{
                    'textCopied': () => handleTextCopied()
                  }}
                />
              </div>
            )}
          </div>

          {hasComparison && (
            <div className="summary-content">
              <h4 className="label-comparison">
                <span>Comparison</span>
                {comparisonSummary && (
                  <div className="widget-summary-controls">
                    <ReviewControls
                      className="widget-summary-controls"
                      summary={comparisonSummary}
                      featureId="summarization"
                      location={getLocationForFeedback()}
                    />
                  </div>
                )}
              </h4>
              {comparisonSummary ? (
                <span onMouseUp={handleMouseUp}>
                  {comparisonSummary}
                </span>
              ) : (
                <span className="empty-state">
                  <FontAwesomeIcon icon="exclamation-triangle" />
                  <span className="text">No data</span>
                </span>
              )}
              {comparisonSummary && (
                <div>
                  <CopyToClipboard
                    text={comparisonSummary}
                    popupText="Copy summary"
                    on={{
                      'textCopied': () => handleTextCopied()
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export { SummaryComponent };
