import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UploadType } from 'api/enums';
import { SurveyUpload } from 'api/interfaces';
import { formatDateWithTime } from 'lib/date';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Accordion, Popup, Table } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import './configure-import-upload-history.scss';

interface ViewUploadHistoryModalStoreProps {
  setupStore?: SetupStoreInterface;
}

export interface ViewUploadHistoryModalProps extends ViewUploadHistoryModalStoreProps {
  survey: LiteSurvey;
}

interface ViewUploadHistoryModalState {
  isShowingDetail: boolean;
  retrievedUploads: boolean;
  uploads: SurveyUpload[];
}

@inject('setupStore', 'integrationsStore')
@observer
class ViewUploadHistoryModal extends React.Component<ViewUploadHistoryModalProps, ViewUploadHistoryModalState> {
  state = {
    isShowingDetail: false,
    retrievedUploads: false,
    uploads: []
  } as ViewUploadHistoryModalState;

  handleAccordionClick = () => {
    const { isShowingDetail, retrievedUploads } = this.state;
    this.setState({ isShowingDetail: !isShowingDetail });
    if (!retrievedUploads) {
      this.retrieveUploads();
      this.setState({ retrievedUploads: true });
    }
  }

  retrieveUploads() {
    const { setupStore, survey } = this.props;
    setupStore!.getSurveyHistory(survey.surveyId).then(uploads => {

      // filter to only the job types we want
      const uploadsToShow = uploads.filter(upload => {
        return upload.job_type === UploadType.INITIALDATA ||
          upload.job_type === UploadType.NEWDATA
          ;
      });

      this.setState({ uploads: uploadsToShow });
    });
  }

  renderStatusCell(status?: string, message?: string) {
    status = status?.substring(20);
    return (
      <Popup
        position="right center"
        hoverable={true}
        mouseEnterDelay={200}
        mouseLeaveDelay={100}
        trigger={
          <div className={`processing-status ${ status }`} >
            {status ? status : 'No data'}
          </div>
        }
      >
        <Popup.Content>
          {message ? message : 'No information available'}
        </Popup.Content>
      </Popup>
    );
  }

  renderJobTypeCell(jobType: UploadType) {
    if (jobType === UploadType.INITIALDATA) {
      return 'Initial data';
    } else if (jobType === UploadType.NEWDATA) {
      return 'Incremental data';
    }
    return '';
  }

  renderHistory() {
    const { uploads } = this.state;

    return (
      <div>
        <div className="history-table">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Upload Type
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {uploads.map(run => {
                return (
                  <Table.Row key={run.id}>
                    <Table.Cell>{formatDateWithTime(run.created)}</Table.Cell>
                    <Table.Cell>{this.renderJobTypeCell(run.job_type)}</Table.Cell>
                    <Table.Cell>{this.renderStatusCell(run.status, '')}</Table.Cell>
                  </Table.Row>);
              }
              )}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }

  render() {
    const { setupStore } = this.props;
    const { uploads, isShowingDetail } = this.state;

    return (


      <Accordion className='configure-import-upload-history'>
        <Accordion.Title
          className='import-section-title'
          active={isShowingDetail}
          onClick={this.handleAccordionClick}
        >
          <FontAwesomeIcon
            className="icon"
            icon={isShowingDetail ? 'chevron-down' : 'chevron-right'}
            fixedWidth={true}
          />
          Most recent history
        </Accordion.Title>
        <Accordion.Content active={isShowingDetail}>
          {setupStore!.fetchingSurveyUploads ? (
            <div>
              Retrieving history...
            </div>
          ) : !uploads || uploads.length === 0 ? (
            <div>
              No history recorded!
            </div>
          ) : (
            this.renderHistory()
          )}
        </Accordion.Content>
      </Accordion>



    );
  }
}

export default ViewUploadHistoryModal;
