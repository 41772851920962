import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeSelect from 'components/ThemeDiscovery/ThemeSelect';
import MappedPhrase from 'components/ThemeEditor/MappedPhrase';
import { ThemeGroup } from 'lib/theme-file-parser';
import * as React from 'react';
import { Button, List } from 'semantic-ui-react';
import { ThemeTreeItem } from 'stores/ThemesStore';
import toLocaleString from 'vue/libs/to-locale-string';
import './mapped-phrases.scss';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';

interface MappedPhrasesProps {
  activeNode: ThemeTreeItem;
  group: ThemeGroup;
  onDeletePhrases: (phrases: string[]) => void;
  onSelectTheme: (selectedTheme: SelectedTheme, selectedPhrases: string[]) => void;
  phrases: string[];
}

const LIMIT = 10;

const MappedPhrases = (props: MappedPhrasesProps) => {
  const {
    activeNode,
    group,
    onDeletePhrases,
    onSelectTheme,
    phrases,
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  const [selectedPhrases, setSelectedPhrases] = React.useState<string[]>([]);
  const [showAll, setShowAll] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }

    setSelectedPhrases([]);
    setShowAll(false);
  }, [activeNode.id]);

  function togglePhrase (phrase: string) {
    const nextSelectedPhrases = selectedPhrases.includes(phrase)
      ? selectedPhrases.filter(p => p !== phrase)
      : [...selectedPhrases, phrase];

    setSelectedPhrases(nextSelectedPhrases);
  }

  const count = phrases.length;
  const overflow = count - LIMIT;
  const hasOverflow = !showAll && overflow > 0;
  const limitedPhrases = [...phrases]
    .sort()
    .slice(0, showAll ? Infinity : LIMIT);

  if (phrases.length === 0) {
    return <div className="mapped-phrases__none">No Mapped Phrases</div>
  }

  return (
    <>
      <div className="mapped-phrases__action-buttons">
        <ThemeSelect
          groupId={group.id}
          disabled={selectedPhrases.length === 0}
          icon="arrow-alt-to-right"
          hasOther={false}
          onChange={selectedTheme => {
            onSelectTheme(selectedTheme, selectedPhrases);
            setSelectedPhrases([]);
          }}
          placeholder="Move"
          resetAfterChange={true}
        />
        <Button
          size="small"
          disabled={!selectedPhrases.length}
          onClick={() => {
            props.onDeletePhrases(selectedPhrases);
          }}
        >
          <FontAwesomeIcon icon="trash" className="icon" />
          Delete
        </Button>
      </div>
      <div
        className="mapped-phrases-container"
        aria-label="mapped phrases"
        ref={ref}
      >
        <List className="mapped-phrases">
          {limitedPhrases.map((phrase, index: number) => (
            <MappedPhrase
              isSelected={selectedPhrases.includes(phrase)}
              key={`${index}-${phrase}`}
              onClick={() => togglePhrase(phrase)}
              onDelete={() => onDeletePhrases([phrase])}
              phrase={phrase}
            />
          ))}
          {hasOverflow && (
            <div className="item list-link" onClick={() => setShowAll(true)}>
              Show {toLocaleString(overflow, 'more phrase', 'more phrases')}
            </div>
          )}
        </List>
      </div>
    </>
  );
};

export { MappedPhrases };
