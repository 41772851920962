import { AnswersLoader } from 'components/Answers/AnswersLoader';
import AuditAnswers from 'components/Answers/AuditAnswers';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import BillingUsage from 'components/BillingUsage/BillingUsage';
import ConfigureAnalysis from 'components/ConfigureSurvey/ConfigureAnalysis';
import ConfigureDataset from 'components/ConfigureSurvey/ConfigureDataset';
import ConfigureSurvey from 'components/ConfigureSurvey/ConfigureSurvey';
import CreateDashboard from 'components/Dashboard/CreateDashboard';
import { DashboardRoute } from 'components/Dashboard/DashboardRoute';
import Integrations from 'components/Integrations/Integrations';
import ManageRole from 'components/ManageRoles/ManageRole';
import Roles from 'components/ManageRoles/Roles';
import ManageUser from 'components/ManageUsers/ManageUser';
import Users from 'components/ManageUsers/Users';
import NavBar from 'components/NavBar/NavBar';
import { Error } from 'components/Shared/Error';
import Subscriptions from 'components/Subscription/Subscriptions';
import ManageThematicAdmins from 'components/ThematicAdmin/ManageThematicAdmins';
import ThemeGroups from 'components/ThemeEditor/ThemeGroups';
import Upload from 'components/Upload/Upload';
import UserProfile from 'components/User/UserProfile';
import Vis from 'components/Vis/Vis';
import InitialScreen from 'components/Welcome/InitialScreen';
import { ManageWorkflow } from 'components/Workflows/ManageWorkflow';
import WorkflowHistory from 'components/Workflows/WorkflowHistory';
import WorkflowResults from 'components/Workflows/WorkflowResults';
import Workflows from 'components/Workflows/Workflows';
import UnauthorizedIcon from 'images/icons/unauthorized.svg';
import { compose } from 'lib/composeHOCs';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link, matchPath, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { Button, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';

interface OrgStoreProps {
  organizationStore: OrganizationStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
}

interface OrgParams {
  orgId: string;
}

interface OrgState {
  prevOrgId: string | null;
}

interface OrgProps extends OrgStoreProps, RouteComponentProps<OrgParams> { }

const withHocs = compose(
  withRouter,
  inject('organizationStore', 'themeEditorSessionStore'),
  observer,
);

export default withHocs(class Org extends React.Component<OrgProps, OrgState> {
  state = {
    prevOrgId: null
  };

  componentDidMount(): void {
    const { organizationStore } = this.props;
    const currentOrgId = this.props.match.params.orgId;
    organizationStore?.setOrg(currentOrgId);
  }

  componentDidUpdate(prevProps: Readonly<OrgProps>, prevState: Readonly<OrgState>): void {
    const prevOrgId = prevProps.match.params.orgId;
    const currentOrgId = this.props.match.params.orgId;
    const { organizationStore } = this.props;

    if (prevOrgId !== currentOrgId) {
      organizationStore?.setOrg(currentOrgId);
    }

    if (currentOrgId !== prevState.prevOrgId) {
      this.setState({
        prevOrgId: currentOrgId
      });
    }

    const previousPath = prevProps.location.pathname;
    const currentPath = this.props.location.pathname;

    const prevMatch = matchPath<{ orgId: string; surveyId: string }>(previousPath, {
      path: "/c/:orgId/s/:surveyId/themes",
      exact: true,
    });
    const currentMatch = matchPath<{ orgId: string; surveyId: string }>(currentPath, {
      path: "/c/:orgId/s/:surveyId/themes",
      exact: true,
    });

    const themeEditorSessionStore = this.props.themeEditorSessionStore;

    if (!prevMatch && currentMatch) {
      themeEditorSessionStore.addEvent({
        type: 'Navigation',
        subType: 'Enter',
        timestamp: Date.now(),
      });
    }
    if (prevMatch && !currentMatch) {
      themeEditorSessionStore.addEvent({
        type: 'Navigation',
        subType: 'Exit',
        timestamp: Date.now(),
      });
    }

  }

  render(): JSX.Element | null {
    const { organizationStore } = this.props;
    const prevOrgId = this.state.prevOrgId;
    const { orgId } = this.props.match.params;

    const hasOrgChanged = prevOrgId !== null && prevOrgId !== orgId;
    const isFetchingOrganization = organizationStore?.fetchingOrganization instanceof Promise;
    const hasOrganization = organizationStore?.orgExists;
    const canManageUsers = organizationStore!.getCanAction('manage:user');
    const canManageRoles = organizationStore!.getCanAction('manage:role');
    const canManageAnswers = organizationStore!.getCanAction('manage:answer');
    const canThematicAdmin = organizationStore!.getCanAction('manage:internalResource');
    const organizationError = organizationStore?.fetchOrganizationError;
    const canShowRoutes = hasOrganization && !organizationError && !isFetchingOrganization && !hasOrgChanged;

    let welcomeScreenComponent = InitialScreen;

    return (
      <>
        <AuthenticatedRoute
          path="/c/:orgId"
          component={NavBar}
          fetching={isFetchingOrganization}
        />
        {isFetchingOrganization && <Dimmer active inverted={true}>
          <Loader size="large" content="Loading organization..." />
        </Dimmer>}
        {canShowRoutes &&
          <Switch>
            <AuthenticatedRoute
              component={welcomeScreenComponent}
              exact={true}
              path="/c/:orgId"
            />
            <AuthenticatedRoute
              component={AnswersLoader}
              exact={true}
              path="/c/:orgId/answers/:savedAnswerId?"
            />
            <AuthenticatedRoute path="/c/:orgId/billing-usage" component={BillingUsage} />
            <AuthenticatedRoute
              path="/c/:orgId/s/:surveyId/themes"
              component={ThemeGroups}
            />
            <AuthenticatedRoute
              path="/c/:orgId/s/:surveyId/configure-survey"
              component={ConfigureSurvey}
            />
            <AuthenticatedRoute
              path="/c/:orgId/s/:surveyId/configure/:tool?"
              component={ConfigureDataset}
            />
            <AuthenticatedRoute
              path="/c/:orgId/s/:surveyId/configure-analysis"
              component={ConfigureAnalysis}
            />
            <AuthenticatedRoute
              path="/c/:orgId/s/:surveyId/v/:visId/:tool?"
              component={Vis}
            />
            <AuthenticatedRoute
              path="/c/:orgId/s/:surveyId/view/:viewId/v/:visId/:tool?"
              component={Vis}
            />
            <AuthenticatedRoute
              path="/c/:orgId/r/create"
              component={CreateDashboard}
            />
            <AuthenticatedRoute
              path="/c/:orgId/r/:dashboardId"
              component={DashboardRoute}
            />
            <AuthenticatedRoute
              path="/c/:orgId/workflows"
              component={Workflows}
            />
            <AuthenticatedRoute
              path="/c/:orgId/workflow/:workflowId/history/:runId"
              component={WorkflowHistory}
            />
            <AuthenticatedRoute
              path="/c/:orgId/workflow/:workflowId/history"
              component={WorkflowHistory}
            />
            <AuthenticatedRoute
              path="/c/:orgId/workflow/:workflowId"
              component={ManageWorkflow}
            />
            <AuthenticatedRoute
              path="/c/:orgId/workflow-results/:workflowId/:runId"
              component={WorkflowResults}
            />
            <AuthenticatedRoute
              path="/c/:orgId/integrations"
              component={Integrations}
            />
            <AuthenticatedRoute
              path="/c/:orgId/profile"
              component={UserProfile}
            />
            <AuthenticatedRoute
              path="/c/:orgId/upload"
              component={Upload}
            />
            {canManageRoles && <AuthenticatedRoute
              path="/c/:orgId/roles/create"
              component={ManageRole}
            />}
            {canManageRoles && <AuthenticatedRoute
              path="/c/:orgId/roles/:roleId"
              component={ManageRole}
            />}
            {canManageRoles && <AuthenticatedRoute
              path="/c/:orgId/roles"
              component={Roles}
            />}
            {canManageUsers && <AuthenticatedRoute
              path="/c/:orgId/users/create"
              component={ManageUser}
            />}
            {canManageUsers && <AuthenticatedRoute
              path="/c/:orgId/users/:userId"
              component={ManageUser}
            />}
            {canManageUsers && <AuthenticatedRoute
              path="/c/:orgId/users"
              component={Users}
            />}
            {canThematicAdmin && <AuthenticatedRoute
              path="/c/:orgId/thematic-admin"
              component={ManageThematicAdmins}
            />}
            {canManageAnswers && <AuthenticatedRoute
              path="/c/:orgId/audit-answers"
              component={AuditAnswers}
            />}
            <AuthenticatedRoute
              path="/c/:orgId/subscriptions"
              component={Subscriptions}
            />
            <Route>
              <Segment className="simple-segment fullscreen white hero">
                <h1>404</h1>
                <p>There's nothing here</p>
                <Button as={Link} to={`/c/${ orgId }`} className="subtle">
                  Go back
                </Button>
              </Segment>
            </Route>
          </Switch>
        }
        {!canShowRoutes && !isFetchingOrganization &&
          <Error
            title="Can't access this content?"
            icon={<UnauthorizedIcon />}
          >
            <p>It looks like you don't have access to this specific content. Contact your administrator to add you to Thematic.</p>
          </Error>
        }
      </>
    );
  }
});
