import { isSourceValid } from 'components/Dashboard/Utils/navigation-helpers';
import { Tooltip } from 'components/Shared/Tooltip';
import { toRoute } from 'lib/route-helper';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { isEmpty } from 'lodash';
import { stringify } from 'query-string';
import * as React from 'react';
import { getOrganizationStore } from 'stores/RootStore';
import toFixed from 'vue/libs/to-fixed';
import './theme-name-with-sources.scss';

export interface ThemeSource {
  name: string;
  survey: string;
  vis: string;
  view: string;
}

export interface ThemeComponent {
  count: number;
  impact: number;
  score: number;
  source: number;
}

interface Props {
  source?: string;
  subtool?: string;
  subtheme?: string;
  tool: string;
  theme: string;
  themeComponents?: ThemeComponent[];
  themeName?: string;
  themeSources: ThemeSource[];
  truncateThemeName?: boolean;
  compareAndFilters: { compare: string, filters: string };
}

const isValidSource = (sourceIndex: number, sources: ThemeSource[]) => {
  if (sourceIndex < sources.length) {
    const matchingSource = sources[sourceIndex];
    return !!matchingSource && !isEmpty(matchingSource.name);
  }
  return false;
};

const ThemeNameWithSources = withCamelCaseProps((props: Props) => {
  const {
    subtool,
    subtheme,
    tool,
    theme,
    themeComponents = [],
    themeName,
    themeSources,
    truncateThemeName = false,
    compareAndFilters = { compare: undefined, filters: undefined }
  } = props;

  const components = React.useMemo(() => {
    const validComponents = themeComponents
      .filter((component) => isValidSource(component.source, themeSources))
      .map((component) => {
        const { count, impact, score, source } = component;
        const themeSource = themeSources[source];
        const name = themeSource.name;
        return {
          count,
          name,
          impact: toFixed(impact, 1),
          score: toFixed(score, 1),
          source: themeSource,
        };
      });
    return validComponents.length ? validComponents : undefined;
  }, [themeComponents, themeSources]);

  const clickableTheme = !!components;
  const themeNameTextClass = truncateThemeName ? 'theme-name--truncated' : '';

  const navigateToSource = (source: ThemeSource) => {
    const { survey, vis, view } = source;
    const { orgId } = getOrganizationStore();
    const path = `${toRoute(orgId, survey, view, vis)}/${tool}`;

    if (!isSourceValid(survey, view)) {
      return;
    }

    const query = {
      compare: compareAndFilters.compare,
      expand: !!theme,
      filters: compareAndFilters.filters,
      subtheme,
      subtool,
      theme,
    };
    window.location.href = `/#${path}?${stringify(query)}`;
  };

  const renderTooltipContent = () => (
    <table className="source-breakdown-table">
      <thead>
        <tr>
          <td>Source</td>
          <td>Score</td>
          <td>Count</td>
          <td>Impact</td>
        </tr>
      </thead>
      <tbody>
        {components?.map((component, i) => (
          <tr key={`${component.name}-${i}`} className="component">
            <td
              className="source-name"
              onClick={() => navigateToSource(component.source)}
            >
              {component.name}
            </td>
            <td>{component.score}</td>
            <td>{component.count}</td>
            <td>{component.impact}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className={`theme-name-with-sources theme-name ${clickableTheme ? 'clickable-theme' : ''}`}>
      {components ? (
        <Tooltip
          content={renderTooltipContent()}
          position="right center"
          trigger={
            <div className={themeNameTextClass}>{themeName}</div>
          }
          className="source-breakdown-tooltip"
          on={'click'}
        />
      ) : (
        <div className={themeNameTextClass}>{themeName}</div>
      )}
    </div>
  );
});

export { ThemeNameWithSources };
