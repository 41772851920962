import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataIntegrationRunStatus, UploadStatus } from 'api/enums';
import { DataIntegrationRun } from 'api/interfaces';
import { formatDateWithTime } from 'lib/date';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Accordion, Popup, Table } from 'semantic-ui-react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import './configure-import-data-source-history.scss';

interface ViewDataSourceHistoryModalStoreProps {
  setupStore?: SetupStoreInterface;
}

export interface ViewDataSourceHistoryModalProps extends ViewDataSourceHistoryModalStoreProps {
  survey: LiteSurvey;
}

interface ViewDataSourceHistoryModalState {
  isShowingDetail: boolean;
  retrievedRuns: boolean;
  dataSourceRuns: DataIntegrationRun[];
}

@inject('setupStore', 'integrationsStore')
@observer
class ViewDataSourceHistoryModal extends React.Component<
  ViewDataSourceHistoryModalProps,
  ViewDataSourceHistoryModalState
> {
  state = {
    isShowingDetail: false,
    retrievedRuns: false,
    dataSourceRuns: []
  } as ViewDataSourceHistoryModalState;

  handleAccordionClick = () => {
    const { isShowingDetail, retrievedRuns } = this.state;
    this.setState({ isShowingDetail: !isShowingDetail });
    if (!retrievedRuns) {
      this.retrieveDataSourceRuns();
      this.setState({ retrievedRuns: true });
    }
  }

  retrieveDataSourceRuns() {
    const { setupStore, survey } = this.props;
    setupStore!.getDataSourceHistory(survey.surveyId).then(dataSourceRuns => {
      this.setState({ dataSourceRuns });
    });
  }

  renderStatusCell(status?: DataIntegrationRunStatus | UploadStatus, message?: string) {
    return (
      <Popup
        position="right center"
        hoverable={true}
        mouseEnterDelay={200}
        mouseLeaveDelay={100}
        trigger={
          <div className={`processing-status ${ status }`} >
            {status ? status : ''}
          </div>
        }
      >
        <Popup.Content>
          {message ? message : 'No information available'}
        </Popup.Content>
      </Popup>
    );
  }

  renderHistory() {
    const { dataSourceRuns } = this.state;
    return (
      <div className="history-table">
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell width={4}>Pulling data</Table.HeaderCell>
              <Table.HeaderCell width={4}>Processing data</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dataSourceRuns.map(run => {
              let uploadStatus = run.uploadStatuses.reduce((result, value) => {
                if (!result) {
                  result = value;
                } else if (result === UploadStatus.COMPLETED) {
                  result = value;
                }
                return result;
              }, undefined as UploadStatus | undefined);

              return (
                <Table.Row key={run.id}>
                  <Table.Cell>{formatDateWithTime(run.submitted)}</Table.Cell>
                  <Table.Cell>{this.renderStatusCell(run.status, run.message)}</Table.Cell>
                  <Table.Cell>{this.renderStatusCell(uploadStatus, '')}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }

  render() {
    const { setupStore } = this.props;
    const { dataSourceRuns, isShowingDetail } = this.state;

    return (
      <Accordion className='configure-import-data-source-history'>
        <Accordion.Title
          className='import-section-title'
          active={isShowingDetail}
          onClick={this.handleAccordionClick}
        >
          <FontAwesomeIcon
            className="icon"
            icon={isShowingDetail ? 'chevron-down' : 'chevron-right'}
            fixedWidth={true}
          />
          Most recent history
        </Accordion.Title>
        <Accordion.Content active={isShowingDetail}>
          {setupStore!.fetchingDataSourceRuns ? (
            <div>
              Retrieving history...
            </div>
          ) : !dataSourceRuns || dataSourceRuns.length === 0 ? (
            <div>
              No history recorded!
            </div>
          ) : (
            this.renderHistory()
          )}
        </Accordion.Content>
      </Accordion>
    );
  }
}

export default ViewDataSourceHistoryModal;