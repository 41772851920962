import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

function subtractDate(m, diff = 1, scale = 'days') {
  return m.clone().subtract(diff, scale);
}

export function daysInDateRange(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, "days")
}

export function describeDateRange(startDate, endDate) {
  if (startDate && endDate) {
    const start = moment(startDate);
    const end = moment(endDate);

    if (isWholeMonth(start, end)) {
      return start.format('MMMM YYYY');
    } else if (isPartialMonth(start, end)) {
      return start.format('MMMM YYYY') + ' (incomplete)';
    } else if (sameYear(start, end)) {
      return `${start.format('D MMMM')} – ${end.format('D MMMM YYYY')}`;
    } else {
      return `${start.format('D MMMM YYYY')} – ${end.format('D MMMM YYYY')}`;
    }
  }
  return '';
}

function resolutionToMomentResolution(dateResolution) {
  if (dateResolution === 'annualy') {
    return ['year', 'years'];
  } else if (dateResolution === 'quarterly') {
    return ['quarter', 'quarters'];
  } else if (dateResolution === 'monthly') {
    return ['month', 'months'];
  } else if (dateResolution === 'weekly') {
    return ['week', 'weeks'];
  }
  // default
  return ['month', 'months'];
}

export function expandEndToCompleteResolution(range, dateResolution) {
  const momentRes = resolutionToMomentResolution(dateResolution);
  return {
    end: moment(range.end)
      // @ts-ignore Expects StartOf, not string
      .endOf(momentRes[0])
      .startOf('day')
      .toDate(),
    start: moment(range.end)
      // @ts-ignore Expects StartOf, not string
      .startOf(momentRes[0])
      .toDate()
  }
}

export function applyOffsetToDate(range, dateResolution, offset) {
  const momentRes = resolutionToMomentResolution(dateResolution);
  return {
    end: moment(range.end)
    .add(offset, momentRes[0])
    .toDate(),
    start: moment(range.start)
    .add(offset, momentRes[0])
    .toDate()
  };
}

function isWholeMonth(startDate, endDate) {
  return (
    moment(startDate).isSame(endDate, 'month') &&
    isWholeMonths(startDate, endDate)
  );
}

function isWholeMonths(startDate, endDate) {
  const range = moment.range(startDate, endDate);
  const monthRange = range.snapTo('month');
  return (
    range.start.isSame(monthRange.start, 'day') &&
    range.end.isSame(monthRange.end, 'day')
  );
}

function isWholeQuarter(startDate, endDate) {
  const range = moment.range(startDate, endDate);
  const monthRange = range.snapTo('quarter');
  return (
    moment(startDate).isSame(endDate, 'quarter') &&
    range.start.isSame(monthRange.start, 'day') &&
    range.end.isSame(monthRange.end, 'day')
  );
}

export function expandUtcRange(range, size, validRange) {
  const RANGE_DATE_FORMAT = 'DD-MM-YYYY';
  const rangeLength = (range.end - range.start) / (1000 * 60 * 60 * 24);
  const expansion = rangeLength * (size - 1) / 2;

  const outRange = {

    end: moment(range.end)
    .add(expansion, 'days')
    .toDate(),
    start: moment(range.start)
    .subtract(expansion, 'days')
    .toDate()

  };

  if (validRange) {
    const validStart = moment(validRange.start, RANGE_DATE_FORMAT);
    const validEnd = moment(validRange.end, RANGE_DATE_FORMAT);

    const outRangeMoment = moment.range(outRange.start, outRange.end);
    const validRangeMoment = moment.range(validStart, validEnd);
    const intersectingRange = outRangeMoment.intersect(validRangeMoment);

    if (intersectingRange) {
      return intersectingRange;
    }
  }

  return outRange;
}

export function defaultDateFromResolution(startDate, endDate, dateResolution) {
  let returnStartDate = startDate;
  const returnEndDate = endDate;
  const momentEnd = moment(endDate);
  if (dateResolution == 'annually') {
    returnStartDate = momentEnd.subtract(1, 'years').toDate();
  } else if (dateResolution == 'biannual') {
    returnStartDate = momentEnd.subtract(2, 'quarters').toDate();
  } else if (dateResolution == 'quarterly') {
    returnStartDate = momentEnd.subtract(1, 'quarters').toDate();
  } else if (dateResolution == 'monthly') {
    returnStartDate = momentEnd.subtract(1, 'months').toDate();
  } else if (dateResolution == 'weekly') {
    returnStartDate = momentEnd.subtract(1, 'weeks').toDate();
  } else if (dateResolution == 'daily') {
    returnStartDate = momentEnd.subtract(1, 'days').toDate();
  }
  return [returnStartDate, returnEndDate];
}

function isPartialMonth(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);

  const sameMonth = end.isSame(start, 'month');
  const wholeMonth = isWholeMonth(startDate, endDate);

  return sameMonth && !wholeMonth;
}

function sameYear(startDate, endDate) {
  return moment(startDate).isSame(moment(endDate), 'year');
}

export function utcToDateString(utc) {
  const utcTime = new Date(utc);
  let dd: string | number = utcTime.getDate();
  let mm: string | number = utcTime.getMonth() + 1;
  const yyyy = utcTime.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return yyyy + '-' + mm + '-' + dd;
}

export function dateStringToUtc(dateStr): Date | null {
  if (!dateStr) {
    return null;
  }
  let parts = dateStr.split('-');
  let date;
  if (parts[0].length == 4) {
    date = new Date(parts[0], parts[1] - 1, parts[2]);
  } else {
    date = new Date(parts[2], parts[1] - 1, parts[0]);
  }
  return date;
}

export function getRangeLabel(startDate, endDate) {
  if (startDate === endDate) {
    return `${dateToString(startDate)}`
  }

  if (isWholeMonth(startDate, endDate)) {
    return dateToMonthYear(startDate);
  }

  const isSameYear = sameYear(startDate, endDate);
  const isManyWholeMonths = isWholeMonths(startDate, endDate);

  if (isSameYear) {
    const start = moment(startDate);
    const end = moment(endDate);

    const startMonth = start.month();
    const endMonth = end.month();

    if (isManyWholeMonths) {
      return `${dateToMonth(startDate)} - ${dateToMonthYear(endDate)}`;
    } else if (startMonth === endMonth) {
      return `${start.date()} - ${dateToString(endDate)}`
    } else {
      return `${dateToDateMonth(startDate)} - ${dateToString(endDate)}`
    }
  } else {
    if (isManyWholeMonths) {
      return `${dateToMonthYear(startDate)} - ${dateToMonthYear(endDate)}`;
    }
    return `${dateToString(startDate)} – ${dateToString(endDate)}`;
  }
}

function dateToString(date) {
  return moment(date).format('D MMM YYYY');
}

function dateToMonthYear(date) {
  return moment(date).format('MMM YYYY');
}

export function dateToDateMonth(date) {
  return moment(date).format('D MMM');
}

function dateToMonth(date) {
  return moment(date).format('MMM');
}

export function dateToTime(date) {
  return moment(date).format('h:mm A');
}

export function formatDateWithTime(date) {
  return moment.utc(date).local().format('MMM D, YYYY, h:mm a');
}

export function previousDateRangeFromCurrentDateRange(startDate, endDate) {
  /*
  Given a date range, return a 'smart' previous date range.
  i.e. if a whole month of march is selected the previous period
  should be february (not february plus 3 days of january)
  */
  const isMonth = isWholeMonth(startDate, endDate);
  const isQuarter = isWholeQuarter(startDate, endDate);
  const range = moment.range(startDate, endDate);
  const days = range.diff('days');

  let startDatePrevious = subtractDate(range.start, days, 'days').subtract(1, 'day').toDate();
  let endDatePrevious = subtractDate(range.end, days, 'days').subtract(1, 'day').toDate();

  if (isMonth) {
    const start = subtractDate(range.start, 1, 'month');
    const end = subtractDate(range.end, 1, 'month');
    const previousMonth = moment.range(start, end).snapTo('month');
    startDatePrevious = previousMonth.start.toDate();
    endDatePrevious = previousMonth.end.toDate();
  } else if (isQuarter) {
    const start = subtractDate(range.start, 1, 'quarter');
    const end = subtractDate(range.end, 1, 'quarter');
    const previousQuarter = moment.range(start, end).snapTo('quarter');
    startDatePrevious = previousQuarter.start.toDate();
    endDatePrevious = previousQuarter.end.toDate();
  }

  return {
    start: startDatePrevious,
    end: endDatePrevious,
  }
}

