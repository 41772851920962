import { DashboardWidgetType } from 'api/enums';
import { assign, get, isEqual, omit } from 'lodash';
import { getReportUIStore } from 'stores/RootStore';

export default {
  props: {
    config: { type: Object },
    context: { type: Object },
    dashboardId: { type: String },
  },
  data() {
    return {
      error: undefined,
      warning: undefined,
      ignoreCompare: false,
      lastPromise: undefined,
      loading: false,
      widgetData: undefined,
      reportUIStore: getReportUIStore(),
    };
  },
  computed: {
    filteredContext() {
      if (this.ignoreCompare) {
        return omit(this.context, 'compareFilter', 'compareFilterName', 'currentCompareFilters');
      } else {
        return this.context;
      }
    },
  },
  methods: {
    fetchData: async function () {
      this.loading = true;
      // @FIXME these invalid types will need to be removed when migrated
      let { config } = this.config;
      const { filteredContext: context, source, dashboardId } = this;
      if (config && context) {
        if (!source) {
          this.error = 'The source for this widget was not found.';
          this.loading = false;
          return;
        }

        // default compareToOverall to true if Metadata widget
        if (config.type === DashboardWidgetType.METADATA) {
          config = assign({}, config, { compareToOverall: true });
        }
        this.widgetData = null;
        const promise = this.reportUIStore.getWidget({ context, config, source, dashboardId });
        this.lastPromise = promise;
        try {
          // clear any previous errors
          this.error = undefined;
          this.warning = undefined;
          const widgetData = await promise;
          if (promise !== this.lastPromise) {
            return;
          }
          this.widgetData = widgetData;
          this.warning = widgetData.error;
        } catch (e) {
          this.error = get(e, 'response.data.message', e.message) || 'Could not fetch data.';
        } finally {
          if (promise === this.lastPromise) {
            this.loading = false;
          }
        }
      }
    },
  },
  watch: {
    config: {
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.fetchData();
        }
      },
      // this watch always triggers on mount
      immediate: true,
    },
    // be resistant to changes in filtered properties
    filteredContext(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.fetchData();
      }
    },
  },
};
