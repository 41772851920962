import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfigureAnalysis from 'components/ConfigureSurvey/ConfigureAnalysisNew';
import ConfigureExport from 'components/ConfigureSurvey/ConfigureExport';
import ConfigureImport from 'components/ConfigureSurvey/ConfigureImport';
import { EditableHeader } from 'components/Shared/EditableHeader';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Accordion, Grid, Header, Segment, Table } from 'semantic-ui-react';
import { SurveyStoreInterface } from 'stores/SurveyStore';

import './configure-dataset.scss';

enum ConfigureDatasetTool {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
  ANALYSIS = 'ANALYSIS',
}

export interface ConfigureDatasetParams {
  orgId: string;
  surveyId: string;
  tool?: string;
}

interface ConfigureDatasetStoreProps {
  surveyStore?: SurveyStoreInterface;
}


interface ConfigureDatasetProps extends
  ConfigureDatasetStoreProps, RouteComponentProps<ConfigureDatasetParams> {
}

interface ConfigureDatasetState {
  currentTool: string;
}

const datasetConfigOptions = [
  {
    key: ConfigureDatasetTool.IMPORT,
    title: 'Dataset import',
    slug: 'import',
    component: ConfigureImport,
  },
  {
    key: ConfigureDatasetTool.EXPORT,
    title: 'Dataset export',
    slug: 'export',
    component: ConfigureExport,
  },
  {
    key: ConfigureDatasetTool.ANALYSIS,
    title: 'Configure analysis',
    slug: 'analysis',
    component: ConfigureAnalysis,
  },
]

@inject('surveyStore')
@observer
class ConfigureDataset extends React.Component<ConfigureDatasetProps, ConfigureDatasetState> {
  state = {
    currentTool: datasetConfigOptions[0].slug,
  } as ConfigureDatasetState;

  get orgId(): string {
    const { orgId } = this.props.match.params;
    return orgId;
  }

  get surveyId(): string {
    const { surveyId } = this.props.match.params;
    return surveyId;
  }

  get survey() {
    const { surveyStore, match } = this.props;
    const { surveyId } = match.params;

    return surveyStore!.surveys.find(s => s.surveyId === surveyId);
  }

  componentDidMount(): void {
    const { params } = this.props.match;

    if (params.tool) {
      this.setState({ currentTool: params.tool });
    }
  }

  componentDidUpdate(prevProps: ConfigureDatasetProps) {
    if (this.props.match.params.tool && prevProps.match.params.tool !== this.props.match.params.tool) {
      this.setState({ currentTool: this.props.match.params.tool });
    }
  }


  onTitleChange = async (title: string) => {
    const { surveyStore } = this.props;

    const surveyOptions = { name: title };
    await surveyStore!.updateSurvey(this.surveyId, surveyOptions);
  }

  renderSelectedTool() {
    const { currentTool } = this.state;

    const definition = datasetConfigOptions.find(option => option.slug === currentTool);

    if (!definition) {
      return null;
    }

    const Component = definition.component as React.ComponentType<any>;

    return <Component surveyId={this.surveyId} orgId={this.orgId} />;
  }

  navigateToManageData = () => {
    const pathname = `/c/${ this.orgId }/upload`;
    this.props.history.push(pathname);
  }

  render() {
    const { currentTool } = this.state;
    const surveyTitle = this.survey ? `${ this.survey?.title }` : '';

    return (
      <Segment className="configure-dataset">
        <Header className="configure-dataset-header">
          <div data-testid="manage-data-back" className="back" onClick={() => this.navigateToManageData()}>
            <FontAwesomeIcon
              size="lg"
              className="icon"
              icon="arrow-alt-circle-left"
            />
          </div>
          <div className="configure-dataset-manage-label">
            Manage Dataset:
          </div>

          <EditableHeader
            title={surveyTitle}
            isReadOnly={false}
            onChange={title => this.onTitleChange(title)}
          />
        </Header>

        <Grid className="configure-dataset-grid">
          <Grid.Row>
            <Grid.Column width={4} className="configure-dataset-sidebar">
              <Segment className="configure-dataset-segment">
                <Accordion>
                  <Accordion.Title
                    active={true}
                  >
                    <h3>Manage dataset</h3>
                  </Accordion.Title>
                  <Accordion.Content active={true}>
                    <hr />
                    <Table selectable={true} className="inline-org-list-table">
                      <Table.Body>

                        {datasetConfigOptions.map((option) => {
                          return (
                            <Table.Row key={option.key} verticalAlign="middle">
                              <Table.Cell className="check-cell">
                                {option.slug === currentTool ? (
                                  <FontAwesomeIcon className="icon" icon="check" fixedWidth={true} />
                                ) : (
                                  ''
                                )}
                              </Table.Cell>
                              <Table.Cell className="link-cell">
                                <Link to={`/c/${ this.orgId }/s/${ this.surveyId }/configure/${ option.slug }`} className={currentTool === option.slug ? 'selected' : ''}>
                                  {option.title}
                                </Link>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </Accordion.Content>
                </Accordion>
              </Segment>

            </Grid.Column>
            <Grid.Column width={12}>
              {this.renderSelectedTool()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default ConfigureDataset;