<template>
  <div
    ref="comments"
    :class="{ narrow: isNarrow }"
    class="vertical-comments"
  >
    <div class="vertical-comments__title">
      <div class="vertical-comments__theme-name">
        <div class="vertical-comments__theme-name__title">
          {{ isSummary ? 'Summaries:' : 'Comments:' }}
        </div>
        <div
          v-if="themeName || subTitle"
          class="vertical-comments__theme-name__label"
        >
          {{ themeName ? themeName : subTitle }}
          <div
            v-if="themeName != null && subTitle"
            class="vertical-comments__theme-name__filter"
          >
            in {{ subTitle }}
          </div>
        </div>
      </div>
      <el-button
        v-if="downloading"
        disabled="disabled"
        size="small"
        type="plain"
      >
        <font-awesome-icon
          spin
          icon="spinner"
        />
      </el-button>
      <el-dropdown
        v-else-if="analysisConfigStore.canDownload"
        trigger="click"
        @command="downloadResults"
      >
        <el-button
          size="small"
          type="plain"
        >
          <font-awesome-icon icon="cloud-download-alt" />
          <font-awesome-icon icon="chevron-down" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            command="baseline"
            class="download-item"
          >
            <span class="tab-icon baseline" />
            <span class="download-text">Download {{ baselineTabLabel }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-if="hasComparison"
            command="comparison"
            class="download-item"
          >
            <span class="tab-icon comparison" />
            <span class="download-text">Download {{ comparisonTabLabel }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-tabs
      ref="tabs"
      stretch
      class="el-tabs__widget-tab"
    >
      <el-tab-pane>
        <div
          slot="label"
          class="tab-label"
        >
          <span class="tab-icon baseline" />
          <el-tooltip placement="top">
            <div slot="content">
              {{ baseline.title }}<br>
              {{ baselineTabLabel }}
            </div>
            <div class="count-title">
              {{ baselineTabLabel }}
            </div>
          </el-tooltip>
        </div>
        <div
          v-if="hasSentiment"
          class="vertical-comments__count"
        >
          {{ baselineCountLabel }}
        </div>
        <vertical-comments-area
          :is-narrow="isNarrow"
          :sentiment-filter="sentimentFilter"
          type="baseline"
          :query="baselineFilterString"
          :selected-theme="selectedTheme"
          :should-fetch-comments="shouldFetchComments"
          :is-summary="isSummary"
          @onCommentThemeSelected="onCommentThemeSelected"
        />
      </el-tab-pane>

      <el-tab-pane
        v-if="hasComparison"
        name="comparison-tab"
      >
        <div
          slot="label"
          class="tab-label"
        >
          <span class="tab-icon comparison" />
          <el-tooltip placement="top">
            <div slot="content">
              {{ comparison.title }}<br>
              {{ comparisonTabLabel }}
            </div>
            <div class="count-title">
              {{ comparisonTabLabel }}
            </div>
          </el-tooltip>
        </div>
        <div
          v-if="hasSentiment"
          class="vertical-comments__count"
        >
          {{ comparisonCountLabel }}
        </div>
        <vertical-comments-area
          :is-narrow="isNarrow"
          :sentiment-filter="sentimentFilter"
          type="comparison"
          :query="comparisonFilterString"
          :selected-theme="selectedTheme"
          :should-fetch-comments="shouldFetchComments"
          @onCommentThemeSelected="onCommentThemeSelected"
        />
      </el-tab-pane>

      <el-tab-pane
        disabled
      >
        <span
          slot="label"
          key="label"
        >
          <el-radio-group
            v-if="hasSentiment && showSentimentFilters"
            v-model="sentimentFilter"
            size="mini"
            class="pull-right sentiment-filters"
            :disabled="disableSentimentFilters"
          >
            <el-radio-button label="all">All</el-radio-button>
            <el-radio-button
              label="negative"
              class="svg-icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <g fill="none">
                  <path
                    :fill="colors.neutral800"
                    d="M8.719 0a8.717 8.717 0 0 1 8.718 8.719 8.717 8.717 0 0 1-8.718 8.718A8.717 8.717 0 0 1 0 8.72 8.717 8.717 0 0 1 8.719 0z"
                  />
                  <path
                    :fill="colors.colorWhite"
                    d="M11.531 5.906a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm-5.625 0a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm5.984 7.671c.474.573 1.34-.147.861-.717a5.25 5.25 0 0 0-8.068-.004c-.475.574.39 1.294.865.721a4.125 4.125 0 0 1 3.17-1.483c1.228 0 2.384.541 3.172 1.483z"
                  />
                </g>
              </svg>
            </el-radio-button>
            <el-radio-button
              label="positive"
              class="svg-icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <g fill="none">
                  <path
                    :fill="colors.neutral800"
                    d="M8.719 0a8.717 8.717 0 0 1 8.718 8.719 8.717 8.717 0 0 1-8.718 8.718A8.717 8.717 0 0 1 0 8.72 8.717 8.717 0 0 1 8.719 0z"
                  />
                  <path
                    d="M11.531 5.906a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm-5.625 0a1.124 1.124 0 1 0 0 2.25 1.124 1.124 0 1 0 0-2.25zm6.849 5.984c.474-.573-.394-1.29-.865-.72a4.12 4.12 0 0 1-3.171 1.483 4.108 4.108 0 0 1-3.171-1.484c-.478-.57-1.343.148-.865.72a5.238 5.238 0 0 0 4.036 1.892 5.238 5.238 0 0 0 4.036-1.891z"
                    :fill="colors.colorWhite"
                  />
                </g>
              </svg>
            </el-radio-button>
          </el-radio-group>
        </span>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { assign, get, isEmpty, isFinite } from 'lodash';

import analytics from 'lib/analytics';
import downloadResultsHelper from 'vue/libs/download-results';
import queryBuilder from 'vue/libs/queryBuilder';
import toLocaleString from 'vue/libs/to-locale-string';

import {
  getAnalysisConfigStore,
  getAnalysisToolsUIStore,
  getInitConfigStore,
  getExploreToolUIStore,
  getFilterStore,
} from 'stores/RootStore';
import VerticalCommentsArea from 'vue/components/Comments/VerticalCommentsArea.vue';
import colors from 'vue/styles/element-variables.scss';
import fuzzNum from '../../libs/fuzz-num';
import { VolumeType } from 'stores/ui/ExploreToolUIStore';

export default {
  name: 'VerticalComments',
  components: { VerticalCommentsArea },
  props: {
    isNarrow: { default: false, type: Boolean },
    selections: { default: undefined, type: Object },
    selectedTheme: { default: () => ({}), type: Object },
    showSentimentFilters: { default: true, type: Boolean },
    allowCommentsWithoutThemes: { default: false, type: Boolean },
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      initConfigStore: getInitConfigStore(),
      exploreToolUIStore: getExploreToolUIStore(),
      filterStore: getFilterStore(),

      downloading: false,
      sentimentFilter: 'all',
      colors: colors,

      pageSize: 25,
    };
  },
  computed: {
    disableSentimentFilters() {
      const baseLineQuery = get(this, 'selections.selections.baseline.query', []);

      return baseLineQuery.includes('sentiment');
    },
    volumeType() {
      return this.exploreToolUIStore.volumeType;
    },
    axisType() {
      return this.exploreToolUIStore.axisType;
    },
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    commentHighlightingType() {
      return this.initConfigStore.commentHighlightingType;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    hasSentiment() {
      return this.analysisConfigStore.hasSentiment;
    },
    metadataColumns() {
      return this.analysisConfigStore.metadataColumns;
    },
    commentRequestOptions() {
      const { metadataColumns, requestOptions } = this;
      return assign({}, requestOptions, { metadataColumns });
    },
    subTitle() {
      return get(this, 'selections.subTitle');
    },
    baseline() {
      return get(this, 'selections.selections.baseline', {});
    },
    comparison() {
      return get(this, 'selections.selections.comparison', {});
    },
    hasComparison() {
      const comp = get(this, 'comparison.query');
      const base = get(this, 'baseline.query');
      return comp !== base;
    },
    themeName() {
      const { theme, subtheme } = this.selectedTheme;
      return (subtheme && subtheme.name) || (theme && theme.name);
    },
    baselineCountLabel() {
      const pos = get(this, 'baseline.sentimentCounts.pos');
      const neut = get(this, 'baseline.sentimentCounts.neut');
      const neg = get(this, 'baseline.sentimentCounts.neg');
      return this.countLabel(pos, neut, neg);
    },
    baselineTabLabel() {
      const count = get(this, 'baseline.count', 0);
      return this.tabLabel(count);
    },
    comparisonCountLabel() {
      const pos = get(this, 'comparison.sentimentCounts.pos');
      const neut = get(this, 'comparison.sentimentCounts.neut');
      const neg = get(this, 'comparison.sentimentCounts.neg');
      return this.countLabel(pos, neut, neg);
    },
    comparisonTabLabel() {
      const count = get(this, 'comparison.count', 0);
      return this.tabLabel(count);
    },
    baselineFilterString() {
      return this.getFilterString('baseline');
    },
    comparisonFilterString() {
      return this.getFilterString('comparison');
    },
    shouldFetchComments() {
      const isExploreTool = this.analysisToolsUIStore.visType === 'EXPLORE';
      const hasSelections = !isEmpty(this.selections);
      if (isExploreTool) {
        const hasQuery = hasSelections && (!isEmpty(this.selections.selections.baseline.title) || !isEmpty(this.selections.selections.comparison.title));
        const isExploreToolLoaded = !this.analysisToolsUIStore.isExploreToolLoading;
        // themes will always be available in explore tool, therefore check if query string is not empty in explore tool
        return hasQuery && isExploreToolLoaded;
      }
      return hasSelections;
    },
    isSummary() {
      return !!this.analysisConfigStore?.config?.threadConfig?.summarize;
    },
  },
  watch: {
    hasComparison(hasComparison) {
      // if we're leaving comparison, this is some nonsense magic to reset "currentName"
      if (!hasComparison && this.$refs.tabs) {
        this.$refs.tabs.currentName = '0';
      }
    },
    overallCounts(overallCounts) {
      const count = get(overallCounts, 'total');
      this.$emit('onCommentCount', { count });
    },
    sentimentFilter(sentimentFilter) {
      // Only keep this in sync when sentiment filter on explore tool is shown
      if (this.analysisToolsUIStore.showSentimentFilterOnExploreTool) {
        this.exploreToolUIStore.setSentimentFilter(sentimentFilter);
      }
    },
    volumeType: {
      handler(volumeType) {
        this.sentimentFilter = volumeType;
      },
      immediate: true,
    },
    selections: {
      handler(selections) {
        const baseLineQuery = get(selections, 'selections.baseline.query', []);
        const sentimentFilterIsSet = baseLineQuery.includes('sentiment');

        if (sentimentFilterIsSet && this.analysisToolsUIStore.showSentimentFilterOnExploreTool) {
          this.exploreToolUIStore.setSentimentFilter(VolumeType.All);
        }
      }
    },
    axisType() {
      // Sync sentiment filter when sentiment filter is shown on explore tool
      if (this.analysisToolsUIStore.showSentimentFilterOnExploreTool) {
        this.exploreToolUIStore.setSentimentFilter(this.sentimentFilter);
      }
    },
  },
  methods: {
    /**
     * return `x positive/y negative` unless not passed numbers
     */
    countLabel(pos, neut, neg) {
      // either of the params aren't numbers, bail
      if (!isFinite(pos) || !isFinite(neut) || !isFinite(neg)) {
        return undefined;
      }
      const tokens = [toLocaleString(neg), 'negative', '/', toLocaleString(neut), 'neutral', '/', toLocaleString(pos), 'positive'];

      return tokens.join(' ');
    },
    tabLabel(count) {
      const singularLabel = this.isSummary ? 'summary' : 'comment';
      const pluralLabel = this.isSummary ? 'summaries' : 'comments';
      if (count === 1) {
        return `${count} ${singularLabel}`;
      } else {
        return `${fuzzNum(count)} ${pluralLabel}`;
      }
    },
    getFilterString(filterKey) {
      let query = get(this, `${filterKey}.query`);
      const basetheme = get(this.selectedTheme, 'theme.code');
      const subtheme = get(this.selectedTheme, 'subtheme.code');
      let themeSegmentFilter = '';
      if (basetheme) {
        themeSegmentFilter = queryBuilder.appendThemesToSegmentFilter('', basetheme, subtheme);
      }
      // if there is no theme rql, and we aren't allowed comments without themes, then add this special query
      if (!themeSegmentFilter && !this.allowCommentsWithoutThemes) {
        query = queryBuilder.appendToFilter(query, 'theme!=empty_string;theme!=*!nothing!*;theme!=*!other!*');
      }
      // incorporate the sentiment filter into this
      if (this.sentimentFilter !== 'all') {
        const sentimentSegmentFilter = `sentiment:${this.sentimentFilter}`;
        themeSegmentFilter = queryBuilder.appendToSegmentFilter(themeSegmentFilter, sentimentSegmentFilter);
      }
      // now create the final segment query and append to the query
      if (themeSegmentFilter) {
        let segmentQuery = this.filterStore.segmentQueryStrings[filterKey] || '';
        segmentQuery = queryBuilder.appendToSegmentFilter(segmentQuery, themeSegmentFilter);
        query = queryBuilder.appendSegmentFilterToFilter(query, segmentQuery);
        if (segmentQuery !== themeSegmentFilter) {
          query = queryBuilder.appendThemesToFilter(query, basetheme, subtheme);
        }
      }
      return query;
    },
    downloadResults: async function (filterKey) {
      const filterString = this.getFilterString(filterKey);
      const title = get(this, `${filterKey}.title`);
      const filename = downloadResultsHelper.createFilename(title);

      analytics.track('Comments: Download', {
        category: 'Comments',
        label: filterKey,
      });

      this.downloading = true;

      const table = await downloadResultsHelper.getResultTable(filterString);
      await downloadResultsHelper.downloadArray(table, filename);

      this.downloading = false;
    },
    onCommentThemeSelected(theme) {
      this.$emit('onCommentThemeSelected', theme);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables';
.sentiment-filters {
  label {
    margin-bottom: 0px;
    ::v-deep .el-radio-button__inner {
      display: inline-flex;
      line-height: 14px;
      padding: 6px 10px;
      svg {
        height: 14px;
      }
    }
  }
}
.el-tabs__widget-tab {
  ::v-deep {
    .el-tabs__nav > .el-tabs__item {
      flex: 0 1 auto;
      text-align: unset;
      padding: 0 10px;
      &:last-child {
        align-self: center;
        flex: 1 1 auto;
        justify-content: flex-end;
      }
    }
    .el-tabs__nav-wrap {
      padding: 0 20px;
    }
    .el-tabs__active-bar {
      &:after,
      &:before {
        background-color: $--color-primary;
        content: '';
        height: 2px;
        position: absolute;
      }
      &:before {
        left: -15px;
        width: 15px;
      }
      &:after {
        right: -15px;
        width: 15px;
      }
    }
  }
}
.vertical-comments {
  position: fixed;
  top: $--header-height;
  right: 0;
  bottom: 0;
  width: 30%;
  background: $--color-white;
  flex: 1 1 auto;
  box-shadow: 0 7px 6px -3px rgba(84, 99, 119, 0.3);

  &.narrow {
    background-color: $--neutral-100;
    box-shadow: none;
    padding-top: 5px;
    transition: padding ease-in-out 0.1s;
  }

  &__title {
    background: $--background-color-light;

    color: $--neutral-600;
    font-weight: 400;

    align-items: center;
    display: flex;
    .narrow & {
      padding: 6px 20px 2px;
      background: inherit;
    }

    .el-dropdown {
      .el-button {
        height: 28px;
      }
    }
  }

  &__theme-name {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    &__title {
      color: $--neutral-700;
      font-size: 14px;
      margin-right: 5px;
    }
    &__label {
      background: $--color-white;
      border: solid 1px $--neutral-200;
      border-radius: 13px;
      color: $--neutral-900;
      font-size: 14px;
      line-height: 18px;
      margin-right: 10px;
      padding: 1px 10px;
      min-height: 22px;
    }
    &__filter {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__all-themes-filter {
    font-size: 12px;
  }

  &__count {
    background: $--background-color-light;
    color: $--neutral-500;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.4px;
    line-height: 16px;
    margin: 0;
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
  }
  ::v-deep .el-tabs__header {
    margin: 0;
    .el-tabs__item {
      align-items: center;
      display: inline-flex;
    }
  }

  .tab-label {
    align-items: center;
    display: grid;
    grid: 40px / 14px auto;
    grid-gap: 0 5px;
    grid-template-areas: 'spot filter';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    padding: 0 5px;
  }
  .count-title {
    grid-area: filter;
    font-size: 12px;
    line-height: 30px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-tabs__item.is-active .count-title {
    font-weight: 600;
  }
}

.tab-icon {
  border-radius: 50%;
  display: block;
  grid-area: spot;
  height: 14px;
  justify-self: center;
  width: 14px;
  &.baseline {
    background: $--color-primary;
  }
  &.comparison {
    background: $--color-comparison;
  }
}
.download-item {
  display: grid;
  grid: 30px / 14px auto;
  align-items: center;
  grid-gap: 0 5px;
  grid-template-areas: 'spot text';
  .download-text {
    grid-area: text;
    text-align: left;
  }
}
</style>
