import * as React from 'react';
import { AddThemePopUp } from 'components/SimilarSentences/AddThemePopUp';
import { AddThemeToComment } from 'components/SimilarSentences/AddThemeToComment';
import { Block } from 'lib/segments-to-blocks';
import { SelectedPhrasePosition, SELECTION_STATUS, SelectionStatus } from './types';
import './add-theme-to-selection.scss';

interface Props {
  blocks?: Block[];
  clearSelection: () => void;
  commentId: string;
  isApplyingThemes: boolean;
  orgId: string;
  preSelectedPhrase: string;
  selectedPhrasePosition: SelectedPhrasePosition;
  selectionStatus: SelectionStatus;
  surveyId: string;
}

const AddThemeToSelection = ({
  blocks,
  clearSelection,
  commentId,
  isApplyingThemes,
  orgId,
  preSelectedPhrase,
  selectedPhrasePosition,
  selectionStatus,
  surveyId,
}: Props) => {
  const [showAddThemeToComment, setShowAddThemeToComment] = React.useState(false);
  const showPopup = selectionStatus !== SELECTION_STATUS.None && !showAddThemeToComment;

  const positionTop = selectedPhrasePosition.y + 10;

  const handleAddTheme = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowAddThemeToComment(true);
  };

  return (
    <div
      className="add-theme-to-selection"
      style={{
        left: `${selectedPhrasePosition.x}px`,
        top: `${positionTop}px`,
      }}
    >
      <AddThemePopUp
        clearSelection={clearSelection}
        isApplyingThemes={isApplyingThemes}
        isOpen={showPopup}
        onAddTheme={handleAddTheme}
        selectionStatus={selectionStatus}
      />
      {showAddThemeToComment && (
        <AddThemeToComment
          blocks={blocks}
          clearSelection={clearSelection}
          commentId={commentId}
          isApplyingThemes={isApplyingThemes}
          orgId={orgId}
          preSelectedPhrase={preSelectedPhrase}
          surveyId={surveyId}
        />
      )}
    </div>
  )
};

export { AddThemeToSelection };
