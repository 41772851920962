import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntegrationType } from 'api/enums';
import TokenizedInput from 'components/Shared/TokenizedInput';
import { map } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
  Form,
  Input,
  InputOnChangeData,
  Message,
  TextArea
} from 'semantic-ui-react';
import { IntegrationConnectionType, IntegrationField, IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { getIntegrationUrlWithState } from './integration-helper';

interface IntegrationFieldsStoreProps {
  integrationsStore?: IntegrationsStoreInterface;
}

export interface IntegrationFieldsProps extends IntegrationFieldsStoreProps {
  // integration information
  type: IntegrationType;
  connectionType: IntegrationConnectionType;
  name: string;
  integrationId?: string;
  integrationUrl?: string;
  requiredFields?: IntegrationField[];

  // state information
  orgId: string;
  surveyId?: string;

  setAcceptForm: (acceptForm: () => Promise<boolean>) => void;
}

interface IntegrationFieldsState {
  working: boolean;
  errorMessage?: string;
  fieldValues: { [id: string]: string };
  inputText: string;
  tokenArray: string[];
}

@inject('integrationsStore')
@observer
export default class IntegrationFieldsForm extends React.Component<
  IntegrationFieldsProps,
  IntegrationFieldsState
> {
  state = {
    working: false,
    errorMessage: '',
    fieldValues: {},
    inputText: '',
    tokenArray: []
  };

  constructor(props: IntegrationFieldsProps) {
    super(props);
    this.onAccept = this.onAccept.bind(this);
  }

  componentDidMount() {
    this.props.setAcceptForm(this.onAccept);
  }

  onInputChange = (keyName: string, data: InputOnChangeData) => {
    // sets the state for a provided key
    let { fieldValues } = this.state;
    fieldValues[keyName] = data.value;
    this.setState({ fieldValues });
  };

  updateTokenArray = (keyName: string, tokenArray: string[]) => {
    let { fieldValues } = this.state;
    fieldValues[keyName] = tokenArray;
    this.setState({ fieldValues });
    this.setState({ tokenArray });
  };

  onAccept = async (): Promise<boolean> => {
    // send through the api key to the parent for saving
    const { integrationsStore: store, type, connectionType, integrationUrl: url, orgId, surveyId } = this.props;
    const { fieldValues } = this.state;
    if (connectionType === IntegrationConnectionType.FIELDS) {
      this.setState({ working: true, errorMessage: '' });
      if (store) {
        const authInfo = { ...fieldValues };
        await store.createIntegration(authInfo, type);
        if (!store.createIntegrationErroredMessage) {
          return true;
        }
        // if not errored it will be undefined
        this.setState({ errorMessage: store.createIntegrationErroredMessage });
      }
    } else if (connectionType === IntegrationConnectionType.OAUTH_WITH_FIELDS) {
      let integrationUrl = url!;
      // replace any instances of {key} with value
      map(fieldValues, (value, key) => {
        integrationUrl = integrationUrl.replace(`{${ key }}`, encodeURIComponent(value));
      });
      integrationUrl = getIntegrationUrlWithState({ orgId, integrationUrl, surveyId, fieldValues })!;
      window.location.href = integrationUrl;
    }
    return false;
  };

  determineInputType = (field: IntegrationField, tokenArray: string[], inputText: string, fieldValues: { [id: string]: string }) => {
    if (field.multipleValues) {
      return <TokenizedInput
        inputText={inputText}
        tokens={tokenArray}
        autoFocus={true}
        placeholder="Enter values"
        onInputTextChange={(newValue) => this.setState({ inputText: newValue })}
        onTokensChange={(newTokenArray) => this.updateTokenArray(field.param, newTokenArray)
        }
      />;
    } else if (field.paramType === 'textarea') {
      return <TextArea
        rows={5}
        value={fieldValues[field.param] ? fieldValues[field.param] : ''}
        onChange={(_e, data) => this.onInputChange(field.param, data as InputOnChangeData)}
      />;
    }
    return <Input
      name={field.param}
      type={field.paramType ? field.paramType : 'text'}
      value={fieldValues[field.param] ? fieldValues[field.param] : ''}
      onChange={(_e, data) => this.onInputChange(field.param, data)}
    />
  }

  render() {
    const { props } = this;
    const { errorMessage, tokenArray, inputText, fieldValues } = this.state;
    const { integrationUrl, requiredFields } = props;

    return (
      <div className="modal-text apikey-modal-body">
        <div className="apikey-modal-body__error">
          {errorMessage && (
            <Message negative={true}>
              <Message.Content>
                <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
                {errorMessage}
              </Message.Content>
            </Message>
          )}
        </div>
        <div className="apikey-modal-body__form">

          {requiredFields && (
            <div>
              {requiredFields.map(field => {
                const element = this.determineInputType(field, tokenArray, inputText, fieldValues);
                return (
                  <Form.Field key={field.name}>
                    <label>
                      {field.name} {field.needsHelp && integrationUrl && (
                        <a
                          target="blank"
                          rel="noopener noreferrer"
                          href={integrationUrl}>
                          (Help me find this)
                        </a>
                      )}
                    </label>
                    {element}
                  </Form.Field>
                );
              })}
            </div>
          )}

        </div>

      </div>
    );
  }
}
