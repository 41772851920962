import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { sortGroup } from 'components/ThemeEditor/group-sorter';
import ThemeInfo from 'components/ThemeEditor/ThemeInfo';
import UntaggedComments from 'components/ThemeEditor/UntaggedComments';
import ThemeTree, { ThemeCardInterface } from 'components/ThemeTree/ThemeTree';
import analytics from 'lib/analytics';
import { compose } from 'lib/composeHOCs';
import { ThemeGroup } from 'lib/theme-file-parser';
import { concat, find, isEmpty, last, map, sortBy, trim } from 'lodash';
import { reaction } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import * as React from 'react';
import {
  Button,
  Dropdown,
  DropdownProps,
  Form,
  Input,
  Modal,
  Popup
} from 'semantic-ui-react';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';
import { SortType, ThemesStoreInterface } from 'stores/ThemesStore';
import { ThemeEditorEventManager } from './ThemeEditorEventManager';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { ThemesQualityPopup } from './ThemesQualityPopup';
import { ThemeMultiSelectInfo } from './ThemeMultiSelectInfo';
import { ContextMenuPosition, ContextMenuDirection, CONTEXT_MENU_WIDTH } from 'components/ThemeTree/ThemeTreeContextMenu';
import NotificationStore from 'stores/NotificationStore';


interface ThemeEditorStoreProps {
  notificationStore: NotificationStore;
  themesStore: ThemesStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
}

interface ThemeEditorProps extends ThemeEditorStoreProps {
  orgId: string;
  group: ThemeGroup;
  surveyId: string;
}

interface ThemeEditorState {
  proposeNewBasetheme: boolean;
  proposedBasetheme: string;
  currentSave?: Promise<void>;
  saveTimeout?: ReturnType<typeof setTimeout>;
  multipleSelectItems: ThemeCardInterface[];
  contextMenuPosition: ContextMenuPosition | null;
  contextMenuDirection: ContextMenuDirection;
  expanded: boolean;
}

const withHocs = compose(
  inject('notificationStore', 'themesStore', 'themeEditorSessionStore'),
  observer,
);

export default withHocs(class ThemeEditor extends React.Component<
  ThemeEditorProps,
  ThemeEditorState
> {
  canSeeMovableThemes: boolean;

  state = {
    proposeNewBasetheme: false,
    proposedBasetheme: '',

    saveTimeout: undefined,
    currentSave: undefined,
    multipleSelectItems: [] as ThemeCardInterface[],
    contextMenuPosition: null,
    contextMenuDirection: 'left' as ContextMenuDirection,
    expanded: true,
  };
  componentDidMount() {
    const { themesStore: store } = this.props;
    // Sort by frequency by default
    this.sortByFrequency();
    disposeOnUnmount(this, [
      reaction(() => concat(store!.undone, store!.transforms), this.queueSave)
    ]);
    this.canSeeMovableThemes = !!FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_MOVABLE_THEMES_IN_THEME_EDITOR);

    if (this.canSeeMovableThemes) {
      window.addEventListener('keydown', this.handleKeyDown);
    }
  }
  componentWillUnmount(): void {
    if (this.canSeeMovableThemes) {
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }
  handleKeyDown = (event: KeyboardEvent) => {
    const { key } = event;

    if (event.metaKey && key === 'z') {
      const { themesStore } = this.props;
      themesStore.undoTransform();

      analytics.track('Themes Editor: Keyboard shortcut', {
        'Type': 'Undo'
      });
    }

    if (event.metaKey && event.shiftKey && key === 'x') {
      this.handleMultiSelectClear();

      analytics.track('Themes Editor: Keyboard shortcut', {
        'Type': 'Clear multiple selection'
      });
    }
  };
  handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    const canSeeMovableThemes = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_MOVABLE_THEMES_IN_THEME_EDITOR);
    const multipleSelectCount = this.state.multipleSelectItems.length;

    if (!canSeeMovableThemes || multipleSelectCount < 1) {
      return;
    }

    event.preventDefault();

    const { clientX, clientY } = event;

    if (clientX < CONTEXT_MENU_WIDTH) {
      this.setState({
        contextMenuDirection: 'right',
        contextMenuPosition: {
          top: clientY,
          left: clientX + 140
        }
      });
    } else {
      this.setState({
        contextMenuDirection: 'left',
        contextMenuPosition: {
          top: clientY,
          left: clientX
        }
      });
    }
  };
  getUpdatedMultiSelectItems = (selectedItem: ThemeCardInterface): ThemeCardInterface[] => {
    const { multipleSelectItems } = this.state;
    const index = multipleSelectItems.map(({ id }) => id).indexOf(selectedItem.id);

    if (index !== -1) {
      return multipleSelectItems.filter(({ id }) => id !== selectedItem.id);
    } else {
      return [...multipleSelectItems, selectedItem];
    }
  };
  toggleMultipleSelectItem = (selectedItem: ThemeCardInterface) => {
    const { themesStore } = this.props;
    const updatedMultiSelectItems = this.getUpdatedMultiSelectItems(selectedItem);

    themesStore.toggleThemeInfo(updatedMultiSelectItems.length > 0);

    this.setState((prevState) => ({
      ...prevState,
      multipleSelectItems: [...updatedMultiSelectItems]
    }))
  };
  queueSave = async () => {
    const { currentSave, saveTimeout } = this.state;
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    if (currentSave) {
      await currentSave;
    }
    this.setState({
      saveTimeout: setTimeout(this.saveDraft, 3000)
    });
  };
  toggleTrack = e => {
    e.currentTarget.blur();
    const { themesStore: store } = this.props;
    store!.trackCommentCounts = !store!.trackCommentCounts;
    store!.countComments();
  };
  saveDraft = async () => {
    const { currentSave } = this.state;
    const { themesStore: store } = this.props;
    const { orgId, surveyId } = this.props;
    const { unsaved, unreverted } = store!;
    if (unsaved.length || unreverted.length) {
      if (currentSave) {
        await currentSave;
      }
      this.setState({
        currentSave: store!.saveDraft(orgId, surveyId)
      });
    }
  };
  confirmBasetheme = () => {
    const {
      themesStore: store,
      themeEditorSessionStore: sessionStore
    } = this.props;
    const { group } = this.props;
    const { proposedBasetheme: title } = this.state;
    store.addTheme(group, title);
    if (sessionStore.currentSessionId) {
      sessionStore.addEvent({
        type: 'Addition',
        subType: 'AddBaseTheme',
        timestamp: Date.now()
      });
    }
    this.setState({ proposeNewBasetheme: false });
  };
  addBasetheme = () => {
    this.setState({ proposeNewBasetheme: true, proposedBasetheme: '' });
  };
  cancelBasetheme = () => {
    this.setState({ proposeNewBasetheme: false });
  };
  collapseAll = e => {
    e.currentTarget.blur();
    const { themesStore: store } = this.props;
    const { group } = this.props;
    store!.collapseGroup(group);
    this.setState({ expanded: false });
  };
  expandAll = e => {
    e.currentTarget.blur();
    const { themesStore: store } = this.props;
    const { group } = this.props;
    store!.expandGroup(group);
    this.setState({ expanded: true });
  };
  updateProposedBasetheme = (e: React.FormEvent<HTMLInputElement>) => {
    const { value: proposedBasetheme } = e.currentTarget;
    this.setState({ proposedBasetheme });
  };
  sortByName = () => {
    const { group } = this.props;
    const { sorts, commentCounts } = this.props.themesStore!;
    sorts[group.id] = SortType.Name;
    sortGroup(group, SortType.Name, commentCounts);
  };
  sortByFrequency = () => {
    const { group } = this.props;
    const { sorts, commentCounts } = this.props.themesStore!;
    sorts[group.id] = SortType.Frequency;
    sortGroup(group, SortType.Frequency, commentCounts);
  };
  sortByNew = () => {
    const { group } = this.props;
    const { sorts, commentCounts } = this.props.themesStore!;
    sorts[group.id] = SortType.New;
    sortGroup(group, SortType.New, commentCounts);
  };
  sortByReview = () => {
    const { group } = this.props;
    const { sorts, commentCounts } = this.props.themesStore!;
    sorts[group.id] = SortType.Review;
    sortGroup(group, SortType.Review, commentCounts);
  };
  setUnsorted = () => {
    const { group } = this.props;
    const { sorts } = this.props.themesStore!;
    sorts[group.id] = null;
  };
  changeGroup = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    const { themesStore: store } = this.props;
    const group = find(store!.groups, grp => grp.id === data.value);
    store!.editingGroup = group;
  };
  redo = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
    const { themesStore: store } = this.props;
    store!.redoTransform();
  };
  undo = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
    const { notificationStore, themesStore } = this.props;
    notificationStore.clear();
    themesStore!.undoTransform();
  };
  renderSave = () => {
    const { themesStore: store } = this.props;
    const { unsavedCount, saving, saveError, lastSaved } = store!;
    if (saveError) {
      return (
        <div className="undo-redo__save-message error">
          <FontAwesomeIcon icon="exclamation-triangle" className="icon" />
          Save failed.
          <a onClick={this.saveNow}>Try again</a>
        </div>
      );
    } else if (saving) {
      return <div className="undo-redo__save-message">Saving...</div>;
    } else if (unsavedCount) {
      return <div className="undo-redo__save-message">Save pending...</div>;
    } else if (lastSaved) {
      return <div className="undo-redo__save-message">{lastSaved}</div>;
    } else {
      return null;
    }
  };
  saveNow = () => {
    const { themesStore: store } = this.props;
    const { orgId, surveyId } = this.props;
    const { saveTimeout } = this.state;
    // if we *were* going to trigger a save, cancel it
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    store!.saveDraft(orgId, surveyId);
  };
  reviewOtherComments = () => {
    analytics.track('Themes Editor: Review Untagged', {});
    const { group } = this.props;
    group.activeNodeId = undefined;
  };
  handleMultiSelectClear = () => {
    const { themesStore } = this.props;

    themesStore.toggleThemeInfo(false);

    this.setState({ multipleSelectItems: [] });
  };
  handleContextMenuClose = () => {
    this.setState({ contextMenuPosition: null });
  };
  renderSideBar = () => {
    const { group, orgId, surveyId } = this.props;
    const { multipleSelectItems } = this.state;

    if (group.activeNodeId) {
      return <ThemeInfo group={group} orgId={orgId} surveyId={surveyId} />
    }

    if (multipleSelectItems.length > 0) {
      return <ThemeMultiSelectInfo multiSelectCount={multipleSelectItems.length} onClear={this.handleMultiSelectClear} />
    }

    return <UntaggedComments group={group} orgId={orgId} surveyId={surveyId} />;
  }
  render() {
    const { themesStore: store } = this.props;

    const { group } = this.props;
    const { contextMenuDirection, contextMenuPosition, expanded, proposeNewBasetheme, proposedBasetheme } = this.state;

    const { trackCommentCounts, transforms, undone, working, showThemeInfo, toggleThemeInfo } = store!;

    const canUndo = transforms.length > 0;
    const canRedo = undone.length > 0;

    const latest = last(transforms);
    let hintMessage = '';
    let hasError = false;

    if (store!.validationErrors.length) {
      hasError = true;
      hintMessage =
        'Invalid theme file generated. We recommend pressing "Undo" then contacting support.';
    }
    let sort;
    if (store!.sorts[group.id] !== undefined) {
      sort = store!.sorts[group.id];
    } else {
      sort = SortType.Name;
    }

    const canSeeThemesQuality = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_THEMES_QUALITY);

    return (
      <div className={classnames('theme-editor ob-themes-editor', {
        'simplified': !showThemeInfo,
        'advanced': showThemeInfo
      })} onContextMenu={this.handleContextMenu}>
        <ThemeEditorEventManager
          surveyId={this.props.surveyId}
          sessionStore={this.props.themeEditorSessionStore}
        />
        <div
          className={classnames('theme-editor__toolbar', {
            'has-error': hasError,
            'no-group-dropdown': store!.groups.length <= 1,
            'themes-quality-dropdown': canSeeThemesQuality
          })}
        >
          {canSeeThemesQuality && (
            <ThemesQualityPopup
              overallScore="Great"
              overallScoreDescription="Themes are in great shape"
              scores={[
                {
                  name: 'Coverage',
                  level: 'Excellent',
                  description: 'Coverage shows an appropriate proportion of the feedback tagged with one or more themes.'
                },
                {
                  name: 'Specificity',
                  level: 'Good',
                  description: 'Coverage shows an appropriate proportion of the feedback tagged with one or more themes.'
                }
              ]}
            />
          )}
          {store!.groups.length > 1 && (
            <Dropdown
              icon={
                <FontAwesomeIcon
                  icon="angle-down"
                  fixedWidth={true}
                  className="icon"
                />
              }
              onChange={this.changeGroup}
              options={sortBy(
                map(store!.groups, grp => ({
                  key: grp.id,
                  value: grp.id,
                  text: grp.title
                })),
                grp => grp.text
              )}
              selectOnBlur={false}
              selectOnNavigation={false}
              selection={true}
              text={group.title}
            />
          )}

          <Button.Group size="small" className="theme-editor__expansion-tools">
            <Popup
              inverted={true}
              position="top center"
              trigger={
                <Button onClick={this.collapseAll} className="nw-themes-editor-collapse">
                  <FontAwesomeIcon icon="compress-alt" />
                </Button>
              }
              content="Collapse all nodes"
            />
            <Popup
              inverted={true}
              position="top center"
              trigger={
                <Button onClick={this.expandAll} className="nw-themes-editor-expand">
                  <FontAwesomeIcon icon="expand-alt" />
                </Button>
              }
              content="Expand all nodes"
            />
            <Popup
              inverted={true}
              position="top center"
              trigger={
                <Button onClick={this.toggleTrack} size="small">
                  {trackCommentCounts ? (
                    <FontAwesomeIcon icon="chart-bar" />
                  ) : (
                    <span className="fa-stack">
                      <FontAwesomeIcon
                        className="fa-stack-1x faded-icon"
                        icon="chart-bar"
                      />
                      <FontAwesomeIcon
                        flip="horizontal"
                        className="fa fa-stack-1x red-icon"
                        icon={['fal', 'ban']}
                      />
                    </span>
                  )}
                </Button>
              }
              content={
                trackCommentCounts
                  ? 'Stop counting comments'
                  : 'Count comments. WARNING: this can seriously affect performance.'
              }
            />
            <Dropdown
              icon={null}
              direction="left"
              trigger={
                <Button size="small">
                  <FontAwesomeIcon icon="sort-amount-down" />{' '}
                  <FontAwesomeIcon icon="angle-down" />
                </Button>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  active={sort === SortType.Name}
                  onClick={this.sortByName}
                >
                  Name
                </Dropdown.Item>
                <Dropdown.Item
                  active={sort === SortType.Frequency}
                  onClick={this.sortByFrequency}
                >
                  Frequency
                </Dropdown.Item>
                <Dropdown.Item
                  active={sort === SortType.New}
                  onClick={this.sortByNew}
                >
                  New
                </Dropdown.Item>
                <Dropdown.Item
                  active={sort === SortType.Review}
                  onClick={this.sortByReview}
                >
                  Review
                </Dropdown.Item>
                <Dropdown.Item
                  active={sort === null}
                  onClick={this.setUnsorted}
                  disabled={sort !== null}
                >
                  By hand
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Button.Group>

          <Button
            size="small"
            icon={
              <FontAwesomeIcon className="icon" icon="plus" fixedWidth={true} />
            }
            onClick={this.addBasetheme}
          />

          <Button.Group size="small">
            <Button disabled={!canUndo} onClick={this.undo}>
              <FontAwesomeIcon icon="undo" />
            </Button>
            <Button disabled={!canRedo} onClick={this.redo}>
              <FontAwesomeIcon icon="redo" />
            </Button>
          </Button.Group>
          <div
            className={classnames('undo-redo__message', {
              disabled: working
            })}
          >
            {latest && latest.message && !hasError ? (
              <>
                Last action: {latest.message.prefix}{' '}
                {latest.message.theme && <label>{latest.message.theme}</label>}
              </>
            ) : (
              hintMessage
            )}
          </div>
          {this.renderSave()}
        </div>
          <ThemeTree
            contextMenuDirection={contextMenuDirection}
            contextMenuPosition={contextMenuPosition}
            clearMultipleSelect={this.handleMultiSelectClear}
            expanded={expanded}
            toggleMultipleSelectItem={this.toggleMultipleSelectItem}
            group={group}
            multipleSelectItems={this.state.multipleSelectItems}
            onContextMenuClose={this.handleContextMenuClose}
            onReviewUntagged={() => {
              this.reviewOtherComments();
              toggleThemeInfo(true);
            }}
            themeInfoIsDisplayed={showThemeInfo}
          />
        {showThemeInfo && this.renderSideBar()}
        <Modal
          dimmer="blurring"
          open={proposeNewBasetheme}
          onClose={this.cancelBasetheme}
          size="mini"
          style={{ minWidth: '400px' }}
        >
          <Modal.Header>Add new theme</Modal.Header>
          <Modal.Content>
            <Form noValidate="novalidate" onSubmit={this.confirmBasetheme}>
              <Form.Field>
                <Input
                  autoFocus={true}
                  fluid={true}
                  placeholder="Theme name"
                  onChange={this.updateProposedBasetheme}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.cancelBasetheme}>Cancel</Button>
            <Button
              color="blue"
              disabled={isEmpty(trim(proposedBasetheme))}
              onClick={this.confirmBasetheme}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
});
