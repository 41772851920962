import * as React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToastSuccessIcon from '../../images/icons/toast-success.svg';
import { NotificationStoreInterface } from 'stores/NotificationStore';
import { Button } from './Button';
import usePrevious from 'hooks/usePrevious';
import './toast.scss';
import TOAST_ICONS from 'components/Shared/toast-icons';

type Animation = 'OUT' | 'ENTERING' | 'IN' | 'LEAVING';

interface Props {
  notificationStore?: NotificationStoreInterface;
}

const ToastComponent = observer(({ notificationStore }: Props) => {
  const [animation, setAnimation] = React.useState<Animation>('ENTERING');
  const prevAnimation = usePrevious(animation);
  const currentNotification = notificationStore?.current;

  React.useEffect(() => {
    if (!currentNotification) {
      setAnimation('OUT');
    }

    if (prevAnimation === 'OUT') {
      setAnimation('ENTERING');
    }
  }, [prevAnimation, currentNotification]);

  if (!currentNotification) {
    return null;
  }

  const handleTransitionEnd = () => {
    if (animation === 'LEAVING') {
      setAnimation('OUT');
      notificationStore.dismiss(currentNotification.id);
    }
  }

  const toastClassNames = classNames(`toast`, {
    'toast--entering': animation === 'ENTERING',
    'toast--in': animation === 'IN',
    'toast--leaving': animation === 'LEAVING',
    'toast--clickable': currentNotification.action,
  });

  const { action, actionText, message, title, link, icon } = currentNotification
  const Icon = TOAST_ICONS[icon ?? ''];

  return (
    <div
      className={toastClassNames}
      onTransitionEnd={handleTransitionEnd}
      role="alert"
      aria-live="assertive"
    >
      <div className="toast__icon">
        {Icon ?
          <Icon />
        :
          <ToastSuccessIcon className="toast__icon-svg" />
        }
      </div>
      <div className="toast__content">
        {title && <h3 className="toast__title">{title}</h3>}
        <p className="toast__message">{message}</p>
      </div>
      {action && actionText && (
        <div className="toast__action">
          {link ?
            <Button
              onClick={action}
              size="small"
              variant="primary"
            >
              {actionText}
            </Button>
          :
            <Button
              onClick={action}
              size="small"
              subvariant="neutral-text"
              variant="tertiary"
            >
              {actionText}
            </Button>
          }
        </div>
      )}
      {!currentNotification.preventClose &&
        <div className="toast__close">
          <Button
            onClick={() => setAnimation('LEAVING')}
            icon={<FontAwesomeIcon icon="times" />}
            size="small"
            subvariant="neutral-text"
            variant="tertiary"
          />
        </div>
      }
    </div>
  );
});

const Toast = inject('notificationStore')(ToastComponent);

export { Toast };
