import React, { useState, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'semantic-ui-react';
import { Button } from 'components/Shared/Button';
import classNames from 'classnames';
import './suggested-phrases.scss';

interface SuggestedPhrasesProps {
  onPhrasesUpdate: (phrases: string[]) => void;
  suggestedPhrases?: string[];
}

const SuggestedPhrases: React.FC<SuggestedPhrasesProps> = ({ suggestedPhrases = [], onPhrasesUpdate }) => {
  const [phrases, setPhrases] = useState<string[]>(suggestedPhrases);
  const [newPhraseValue, setNewPhraseValue] = useState<string>('');
  const [inputError, setInputError] = useState<string>('');

  const deletePhrase = (phraseToDelete: string): void => {
    const newPhrases = phrases.filter((phrase) => phrase !== phraseToDelete);
    setPhrases(newPhrases);
    onPhrasesUpdate(newPhrases);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.currentTarget.value as string;

    setNewPhraseValue(value);
  };

  const handleAddPhrase = () => {
    if (newPhraseValue.trim() === '') {
      return;
    }

    const newPhrase = newPhraseValue.trim();
    const phraseAlreadyExists = phrases.some((phrase) => phrase.toLowerCase() === newPhrase.toLowerCase());

    if (phraseAlreadyExists) {
      setInputError('Phrase already exists');
      return;
    }

    const updatedPhrases = [...phrases, newPhraseValue];
    setPhrases(updatedPhrases);
    onPhrasesUpdate(updatedPhrases);
    setNewPhraseValue('');
  };

  return (
    <form className="suggested-phrases" onSubmit={handleAddPhrase}>
      <div className="suggested-phrases__phrases">
        {phrases.map((phrase, index) => (
          <div key={index + phrase} className="suggested-phrases__pill">
            <span className="suggested-phrases__phrase">
              {phrase}
            </span>
            <button
              type="button"
              className="suggested-phrases__delete"
              onClick={() => deletePhrase(phrase)}
              aria-label={`Delete phrase ${phrase}`}
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          </div>
        ))}
      </div>
      <div className="suggested-phrases__add-phrase">
        <Input
          className={classNames(`suggested-phrases__input`, {
            'suggested-phrases__input--error': inputError,
          })}
          onChange={handleInputChange}
          size="small"
          value={newPhraseValue}
        />
        <Button
          onClick={handleAddPhrase}
          icon={<FontAwesomeIcon icon="plus" />}
          size="small"
          type="submit"
          variant="secondary"
        >
          Add phrase
        </Button>
      </div>
      {inputError && <div className="suggested-phrases__error">{inputError}</div>}
    </form>
  );
};

export { SuggestedPhrases };
