import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import * as React from 'react';
import {
  Input,
  Dropdown as SemanticDropdown,
  DropdownItem as SemanticDropdownItem,
  DropdownMenu as SemanticDropdownMenu,
  DropdownProps as SemanticDropdownProps
} from 'semantic-ui-react';
import { Button } from './Button';
import './search-in-menu-dropdown.scss';

type DropdownOption = {
  key: string | number;
  text: string;
  value: string | number;
}

export interface SearchInMenuDropdownProps extends Omit<SemanticDropdownProps, 'options'> {
  options: DropdownOption[];
  selectedOption: DropdownOption;
  onChange: (option: DropdownOption) => void;
  searchable?: boolean;
}

export const SearchInMenuDropdown = withCamelCaseProps(({
  options,
  selectedOption,
  searchable = true,
  onChange,
}: SearchInMenuDropdownProps): JSX.Element => {
  const [searchValue, setSearchValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const searchInputRef = React.useRef<Input>(null);
  const [focusedOptionIndex, setFocusedOptionIndex] = React.useState(-1);
  const optionRefs = React.useRef<(HTMLSpanElement | null)[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  }

  const clearSearch = () => {
    setSearchValue('');
    searchInputRef.current?.focus();
  }

  const handleBlur = (e: React.FocusEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.relatedTarget as Node)) {
      setOpen(false);
    }
  };

  const filteredOptions = React.useMemo(() => {
    if (!searchValue) {
      return options;
    }

    const filtered = options.filter(option =>
      option.text.toLowerCase().includes(searchValue.toLowerCase())
    );

    return filtered.sort((a, b) => b.text.localeCompare(a.text));
  }, [options, searchValue]);

  React.useEffect(() => {
    if (open) {
      const dropdown = dropdownRef?.current;

      if (dropdown) {
        searchInputRef.current?.focus();
        const dropdownMenu = dropdown.querySelector('.menu.scrolling');
        if (dropdownMenu) {
          dropdownMenu.scrollTop = 0;
        }
      }
    }
  }, [open, searchInputRef]);

  React.useEffect(() => {
    if (!open) {
      setFocusedOptionIndex(-1);
      setSearchValue('');
    }
  }, [open]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { key } = event;

    if (key === 'Escape') {
      setOpen(false);
      return;
    }

    if (!open) return;

    event.stopPropagation();

    const keyHandlers: Record<string, () => void> = {
      ArrowDown: () => handleArrowDown(event),
      ArrowUp: () => handleArrowUp(event),
      Enter: () => handleSelection(event),
      ' ': () => {
        if (focusedOptionIndex !== -1) {
          handleSelection(event);
        }
      },
    };

    keyHandlers[key]?.();
  };

  const handleArrowDown = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (focusedOptionIndex === -1) {
      focusFirstOption();
      return;
    }

    const nextIndex = focusedOptionIndex + 1;
    if (nextIndex < filteredOptions.length) {
      focusOption(nextIndex);
    }
  };

  const handleArrowUp = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (focusedOptionIndex === 0) {
      focusSearchInput();
      return;
    }

    if (focusedOptionIndex > 0) {
      focusOption(focusedOptionIndex - 1);
    }
  };

  const handleSelection = (event: React.KeyboardEvent) => {
    event.preventDefault();
    const selectedOption = filteredOptions[focusedOptionIndex];

    if (selectedOption) {
      onChange(selectedOption);
      setOpen(false);
    }
  };

  const focusFirstOption = () => {
    setFocusedOptionIndex(0);
    optionRefs.current[0]?.focus();
  };

  const focusOption = (index: number) => {
    setFocusedOptionIndex(index);
    optionRefs.current[index]?.focus();
    optionRefs.current[index]?.scrollIntoView({ block: 'nearest' });
  };

  const focusSearchInput = () => {
    setFocusedOptionIndex(-1);
    searchInputRef.current?.focus();
  };

  return (
    <div
      ref={dropdownRef}
      className="search-in-menu-dropdown-wrapper"
      onKeyDown={handleKeyDown}
    >
      <SemanticDropdown
        className="search-in-menu-dropdown"
        trigger={
          <Button
            variant="secondary"
            size="small"
            subvariant="white"
            onClick={() => setOpen(!open)}
          >
            {selectedOption.text}
            <FontAwesomeIcon
              icon='chevron-down'
              className="icon"
            />
          </Button>
        }
        icon={null}
        open={open}
        onBlur={handleBlur}
      >
        <SemanticDropdownMenu role="listbox">
          {searchable && (
            <Input
              ref={searchInputRef}
              className='search-in-menu-dropdown__search'
              placeholder="Search"
              value={searchValue}
              onChange={handleInputChange}
              icon={
                searchValue.length > 0 ?
                  <Button
                    role="button"
                    aria-label="clear search"
                    tabIndex={0}
                    onClick={() => clearSearch()}
                    onKeyDown={
                      (e: React.KeyboardEvent) => e.key === 'Enter' && clearSearch()
                    }
                    size="small"
                    variant="tertiary"
                  >
                    <FontAwesomeIcon
                      icon="times"
                      fixedWidth={true}
                    />
                  </Button>
                :
                  null
              }
              onKeyDown={handleKeyDown}
            />
          )}
          <SemanticDropdownMenu scrolling={true}>
            {(filteredOptions || []).map((option, index) => {
              return (
                <SemanticDropdownItem
                  key={option.key}
                >
                  <span
                    className="search-in-menu-dropdown__item"
                    onKeyDown={handleKeyDown}
                    onClick={() => {
                      onChange(option)
                      setOpen(false);
                    }}
                    ref={el => {
                      if (el) {
                        optionRefs.current[index] = el;
                      }
                    }}
                    tabIndex={0}
                  >
                    {option.text}
                  </span>
                </SemanticDropdownItem>
              );
            })}
          </SemanticDropdownMenu>
        </SemanticDropdownMenu>
      </SemanticDropdown>
    </div>
  );
});