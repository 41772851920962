import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { getThemesStore } from 'stores/RootStore';
import { useThemes } from './useThemes';
import { TreeView, TreeViewItem } from 'components/Shared/TreeView';
import { AddThemeBaseItem, AddThemeSubItem, SelectedTheme } from './types';

interface Props {
  filterText?: string;
  hideSubThemes?: boolean;
  onEmptyStateChange?: (isEmpty: boolean) => void;
  onThemeSelect: (selectedTheme: SelectedTheme) => void;
}

const ThemeTreeView = observer(({ filterText, hideSubThemes, onEmptyStateChange, onThemeSelect }: Props) => {
  const { themesHierarchy, transforms } = getThemesStore();
  const themes = useThemes(themesHierarchy, transforms);

  const handleEmptyStateChange = (filteredListIsEmpty: boolean) => {
    if (onEmptyStateChange) {
      onEmptyStateChange(filteredListIsEmpty);
    }
  };

  const findThemeById = (items: AddThemeBaseItem[], themeId: string): AddThemeBaseItem | AddThemeSubItem | undefined => {
    for (const item of items) {
      if (item.id === themeId) {
        return item;
      }

      if (item.children) {
        const foundInChildren = item.children.find(child => child.id === themeId);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return undefined;
  }

  const handleItemClick = (themeId: string) => {
    const theme = findThemeById(themes, themeId);

    if (theme) {
      onThemeSelect(theme);
    }
  };

  const treeItems: TreeViewItem[] = themes.map((theme) => {
    const treeItem = {
      id: theme.id,
      label: theme.label
    }

    const treeItemChildren = theme?.children?.map((childTheme) => ({
      id: childTheme.id,
      label: childTheme.label,
    }));

    if (hideSubThemes) {
      return treeItem;
    }

    return {
      ...treeItem,
      children: treeItemChildren
    }
  });

  if (!treeItems) {
    return null;
  }

  return (
    <TreeView
      filter={filterText}
      hideSubThemes={hideSubThemes}
      onEmptyStateChange={handleEmptyStateChange}
      onItemClick={handleItemClick}
      items={treeItems}
    />
  );
});

export { ThemeTreeView };
