import { Context, WidgetWithOrder } from 'api/interfaces';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { find, isFunction } from 'lodash';
import * as React from 'react';
import { getActiveDashboardUIStore, getOrganizationStore } from 'stores/RootStore';
import 'vue/dashboards/Dashboard/widgets.scss';
import queryBuilder from 'vue/libs/queryBuilder';
import { VueInReact } from 'vuera';
import { useWidgetData } from './Hooks/useWidgetData';

import { KeyTakeaways } from './Widgets/KeyTakeaways';
import { Metadata } from './Widgets/Metadata';
import { OriginalScore } from './Widgets/OriginalScore';
import { OriginalScoreComparison } from './Widgets/OriginalScoreComparison';
import { Score, hasOriginalScore as hasOriginalScoreScore, isComparison as isComparisonScore } from './Widgets/Score';
import { ScoreComparison } from './Widgets/ScoreComparison';
import { Summary } from './Widgets/Summary';
import { Unknown } from './Widgets/Unknown';

import ImpactChangesVue from 'vue/dashboards/Dashboard/Widgets/ImpactChanges.vue';
import ImpactComparisonVue from 'vue/dashboards/Dashboard/Widgets/ImpactComparison.vue';
import ImpactSimpleVue from 'vue/dashboards/Dashboard/Widgets/ImpactSimple.vue';
import ImpactWithSubthemesVue from 'vue/dashboards/Dashboard/Widgets/ImpactWithSubthemes.vue';
import ScoreChangesVue from 'vue/dashboards/Dashboard/Widgets/ScoreChanges.vue';
import ScoreDetailVue from 'vue/dashboards/Dashboard/Widgets/ScoreDetail.vue';
import ScoreOvertimeVue from 'vue/dashboards/Dashboard/Widgets/ScoreOvertime.vue';
import ScoreOvertimeComparisonVue from 'vue/dashboards/Dashboard/Widgets/ScoreOvertimeComparison.vue';
import SignificantChangesVue from 'vue/dashboards/Dashboard/Widgets/SignificantChanges.vue';
import SignificantScoreChangesVue from 'vue/dashboards/Dashboard/Widgets/SignificantScoreChanges.vue';
import Themes1Vue from 'vue/dashboards/Dashboard/Widgets/Themes1.vue';
import ThemesChangesVue from 'vue/dashboards/Dashboard/Widgets/ThemesChanges.vue';
import ThemesCompareTwoVue from 'vue/dashboards/Dashboard/Widgets/ThemesCompareTwo.vue';
import ThemesNewVue from 'vue/dashboards/Dashboard/Widgets/ThemesNew.vue';
import ThemesSimpleVue from 'vue/dashboards/Dashboard/Widgets/ThemesSimple.vue';

const Themes1 = VueInReact(Themes1Vue);
const ThemesSimple = VueInReact(ThemesSimpleVue);
const ImpactChanges = VueInReact(ImpactChangesVue);
const ImpactComparison = VueInReact(ImpactComparisonVue);
const ImpactSimple = VueInReact(ImpactSimpleVue);
const ImpactWithSubthemes = VueInReact(ImpactWithSubthemesVue);
const ScoreChanges = VueInReact(ScoreChangesVue);
const ScoreDetail = VueInReact(ScoreDetailVue);
const ScoreOvertime = VueInReact(ScoreOvertimeVue);
const ScoreOvertimeComparison = VueInReact(ScoreOvertimeComparisonVue);
const ThemesChanges = VueInReact(ThemesChangesVue);
const ThemesNew = VueInReact(ThemesNewVue);
const ThemesCompareTwo = VueInReact(ThemesCompareTwoVue);
const SignificantChanges = VueInReact(SignificantChangesVue);
const SignificantScoreChanges = VueInReact(SignificantScoreChangesVue);

const widgetTypes = [
  { name: 'THEMES1', component: Themes1 },
  { name: 'THEMES_SIMPLE', component: ThemesSimple },
  { name: 'IMPACT_CHANGES', component: ImpactChanges },
  { name: 'IMPACT_SIMPLE', component: ImpactSimple },
  { name: 'IMPACT_WITH_SUBTHEMES', component: ImpactWithSubthemes },
  { name: 'KEY_TAKEAWAYS', component: KeyTakeaways },
  { name: 'METADATA', component: Metadata },
  { name: 'ORIGINAL_SCORE', component: OriginalScore },
  { name: 'ORIGINAL_SCORE_COMPARISON', component: OriginalScoreComparison },
  { name: 'SCORE', component: Score },
  { name: 'SCORE_CHANGES', component: ScoreChanges },
  { name: 'SCORE_DETAIL', component: ScoreDetail },
  { name: 'SCORE_OVERTIME', component: ScoreOvertime },
  { name: 'SIGNIFICANT_CHANGES', component: SignificantChanges },
  { name: 'SIGNIFICANT_SCORE_CHANGES', component: SignificantScoreChanges },
  { name: 'SUMMARY', component: Summary },
  { name: 'THEMES_CHANGES', component: ThemesChanges },
  { name: 'THEMES_NEW', component: ThemesNew },
  { name: 'THEMES_COMPARE_TWO', component: ThemesCompareTwo }
];

const COMPARISON_TYPES = {
  IMPACT_SIMPLE: ImpactComparison,
  SCORE: ScoreComparison,
  SCORE_OVERTIME: ScoreOvertimeComparison
};

const ORIGINAL_SCORE_TYPES = {
  SCORE: OriginalScore
};

const ORIGINAL_SCORE_COMPARISON_TYPES = {
  SCORE: OriginalScoreComparison
};

interface Props {
  baseFilter?: string;
  compareFilter?: string;
  compareFilterName?: string;
  config: WidgetWithOrder;
  context?: Context;
  dashboardId: string;
  dateFilter?: {
    selected?: {
      rql?: string;
    };
  };
  filterString?: string;
  filterNames?: string;
  panelOrder?: number;
  classNames?: string;
}

const WidgetChooser = withCamelCaseProps((props: Props) => {
  const {
    compareFilter,
    compareFilterName,
    filterNames,
    dateFilter,
    baseFilter,
    panelOrder,
    config,
    context,
    dashboardId,
    classNames
  } = props;

  const activeDashboardUIStore = getActiveDashboardUIStore();
  const organizationStore = getOrganizationStore();

  const sourceKey = activeDashboardUIStore.getWidgetSourceKey(config);
  const source = activeDashboardUIStore.getSourceUrl(sourceKey ?? '');

  const { error, warning, loading, widgetData } = useWidgetData({
    config: config.config,
    context,
    source,
    dashboardId
  });

  const isWeighted = widgetData?.metadata?.weighted ?? false;

  const filters = {
    baseline: queryBuilder.appendToFilter(dateFilter?.selected?.rql ?? '', baseFilter ?? ''),
    comparison: queryBuilder.appendToFilter(dateFilter?.selected?.rql ?? '', compareFilter ?? '')
  };

  const getWidgetType = () => {
    const { config: widgetConfig } = config;
    if (!widgetConfig) return Unknown;

    const expectedType = widgetConfig.type;
    const themeLevel = 'themeLevel' in widgetConfig ? widgetConfig.themeLevel : undefined;
    const widgetType = find(widgetTypes, { name: expectedType });

    if (widgetType) {
      const { component } = widgetType;

      // TODO: FIX 'as any' type https://thematicanalysis.atlassian.net/browse/PORTAL-6187
      const showOriginalScore = isFunction(hasOriginalScoreScore) && hasOriginalScoreScore(widgetData as any);
      const showComparison = isFunction(isComparisonScore) && isComparisonScore(widgetConfig);

      if (showOriginalScore) {
        if (showComparison) {
          const comparisonType = ORIGINAL_SCORE_COMPARISON_TYPES[expectedType];
          if (comparisonType) {
            return comparisonType;
          }
        }

        const originalScoreType = ORIGINAL_SCORE_TYPES[expectedType];
        if (originalScoreType) {
          return originalScoreType;
        }
      }

      if (showComparison) {
        const comparisonType = COMPARISON_TYPES[expectedType];
        if (comparisonType) {
          return comparisonType;
        }
      }

      if (widgetType.name === 'IMPACT_SIMPLE' && themeLevel !== 'subtheme') {
        return ImpactWithSubthemes;
      }

      return component;
    }

    return Unknown;
  };

  const WidgetComponent = getWidgetType();

  return (
    <div
      className={`dashboard-widget ${classNames}`}
    >
      <WidgetComponent
        orgId={organizationStore.orgId}
        config={config.config}
        panelOrder={panelOrder}
        widgetOrder={config.order}
        context={context}
        data={widgetData}
        isWeighted={isWeighted}
        error={error}
        warning={warning}
        loading={loading}
        filters={filters}
        dateFilter={dateFilter}
        filterNames={filterNames}
        compareFilter={compareFilter}
        compareFilterName={compareFilterName}
        baseFilter={baseFilter}
        source={source}
        sourceKey={sourceKey}
        dashboardId={dashboardId}
      />
    </div>
  );
});

export { WidgetChooser };
