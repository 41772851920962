import * as React from 'react';
import { EditedComments, UntaggedExampleComments } from './UntaggedExampleComments';
import { Accordion } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExampleComments from './ExampleComments';
import FlagKeys from 'Auth/flag-keys';
import { FeatureFlagManager } from 'lib/feature-flag';
import { PlainComment, Thread } from 'types/custom';
import { getAllCommentsText } from 'lib/comment-helpers';
import { InlineNotification } from 'components/Shared/InlineNotification';

const EXAMPLE_COMMENTS_SECTION = 'example_comments';
const APPROXIMATE_COVERAGE_SECTION = 'approx_coverage';

interface Props {
  coverage: number;
  exampleComments: PlainComment[] | Thread[];
  groupId: string;
  orgId: string;
  processing: boolean;
  processError: boolean;
  surveyId: string;
  refreshComments: () => void;
}

const UntaggedCommentsContent = ({
  coverage,
  exampleComments,
  groupId,
  orgId,
  processing,
  processError,
  refreshComments,
  surveyId,
}: Props) => {
  const [activeSections, setActiveSections] = React.useState<string[]>([EXAMPLE_COMMENTS_SECTION, APPROXIMATE_COVERAGE_SECTION]);
  const [editedComments, setEditedComments] = React.useState<EditedComments>({});
  const canSeeAddThemeFromUntaggedComments = FeatureFlagManager.checkFlag(
    FlagKeys.CAN_SEE_ADD_THEME_FROM_UNTAGGED_COMMENTS,
  );

  const allCommentsText = getAllCommentsText(exampleComments);

  const handleAccordionClick = (key: string) => {
    const updatedActiveSections = activeSections.includes(key)
      ? activeSections.filter((activeSection) => activeSection !== key)
      : [...activeSections, key];

    setActiveSections(updatedActiveSections);
  };

  const handleRefresh = () => {
    refreshComments();
    setEditedComments({});
  }

  const hasEditedComments = Object.keys(editedComments).length > 0;

  const sections = [
    {
      key: APPROXIMATE_COVERAGE_SECTION,
      title: 'Approximate coverage',
      content: <>Approximately {Math.round(100 * (1.0 - coverage))}% of comments have a theme</>,
    },
    {
      key: EXAMPLE_COMMENTS_SECTION,
      title: 'Example comments',
      content: canSeeAddThemeFromUntaggedComments ? (
        <UntaggedExampleComments
          comments={exampleComments}
          editedComments={editedComments}
          groupId={groupId}
          orgId={orgId}
          processing={processing}
          processError={processError}
          setEditedComments={setEditedComments}
          surveyId={surveyId}
        />
      ) : (
        <ExampleComments
          comments={allCommentsText}
          istruncated={false}
          isquickedit={false}
          phrases={undefined}
          processing={processing}
          processerror={processError}
        />
      ),
    },
  ];

  return (
    <>
      {hasEditedComments && (
        <div className="theme-info__sub-section">
          <InlineNotification
            actionText="Refresh"
            isHighlighted={true}
            onActionClick={handleRefresh}
            title="Click refresh to update coverage and comments."
            type="info"
          />
        </div>
      )}
      <Accordion>
        {sections.map((section) => {
          const isActive = activeSections.includes(section.key);
          return (
            <div key={section.key} className="theme-info__sub-section">
              <Accordion.Title active={isActive} index={section.key} onClick={() => handleAccordionClick(section.key)}>
                <FontAwesomeIcon icon={isActive ? 'chevron-down' : 'chevron-right'} />
                {section.title}
              </Accordion.Title>
              <Accordion.Content active={isActive}>{section.content}</Accordion.Content>
            </div>
          );
        })}
      </Accordion>
    </>
  );
};

export { UntaggedCommentsContent };
