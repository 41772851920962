import KeyTakeawaysIconSvg from 'images/icons/key-takeaways.svg';
import ScoreChangeDownIcon from 'images/icons/score-change-down.svg';
import ScoreChangeNoChangeIcon from 'images/icons/score-change-no-change.svg';
import ScoreChangeUpIcon from 'images/icons/score-change-up.svg';
import ThemesDownNeutralIcon from 'images/icons/themes-down-neutral.svg';
import ThemesEmergingIcon from 'images/icons/themes-emerging.svg';
import ThemesUpNeutralIcon from 'images/icons/themes-up-neutral.svg';
import ThemesIcon from 'images/icons/themes.svg';
import * as React from 'react';
import './key-takeaways-icon.scss';

interface Props {
  icon: string;
}

const IconMap: Record<string, React.FC<React.SVGProps<SVGElement>>> = {
  'key-takeaways': KeyTakeawaysIconSvg,
  'themes-emerging': ThemesEmergingIcon,
  'themes-up-neutral': ThemesUpNeutralIcon,
  'themes-down-neutral': ThemesDownNeutralIcon,
  'score-change-up': ScoreChangeUpIcon,
  'score-change-down': ScoreChangeDownIcon,
  'score-change-no-change': ScoreChangeNoChangeIcon,
  'themes': ThemesIcon
};

const KeyTakeawaysIcon = (props: Props) => {
  const { icon } = props;
  const Icon = IconMap[icon];

  if (!Icon) {
    return null;
  }

  return <Icon className="key-takeaways-icon" />;
};

export { KeyTakeawaysIcon };
