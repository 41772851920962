import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { canClickTheme } from 'components/Dashboard/Utils/dashboard-helper';
import { canNavigate, getDestination, goToAnalysis } from 'components/Dashboard/Utils/navigation-helpers';
import { Bar } from 'components/Dashboard/Widgets/Bar';
import { CommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import { ThemeComponent, ThemeNameWithSources, ThemeSource } from 'components/Dashboard/Widgets/Components/ThemeNameWithSources';
import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import { Tooltip } from 'components/Shared/Tooltip';
import analytics from 'lib/analytics';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import * as React from 'react';
import './impact-with-subthemes-component.scss';
import { SummaryComponent } from './SummaryComponent';

interface Subtheme {
  code: string;
  name: string;
  impact: number;
  components: ThemeComponent[];
}

interface Theme {
  code: string;
  components: ThemeComponent[];
  count: number;
  impact: number;
  impactNormalized: number;
  impactPercent: number;
  name: string;
  subthemes: Subtheme[];
}

interface Props {
  orgId: string;
  isWeighted?: boolean;
  filters?: {
    baseline?: string;
    comparison?: string;
  };
  error?: string;
  warning?: string;
  loading?: boolean;
  maxValue?: number;
  range?: number[];
  scoreName?: string;
  showComments?: boolean;
  showSummary?: boolean;
  sort?: string;
  source: string;
  title?: string;
  themes?: Theme[];
  themeSources?: ThemeSource[];
  panelOrder: number;
  widgetOrder: number;
  compareAndFilters?: {
    compare?: string;
    filters?: string;
  };
}

const ImpactWithSubthemesComponent = withCamelCaseProps((props: Props) => {
  const {
    orgId,
    isWeighted = false,
    filters = {},
    error,
    warning,
    loading,
    maxValue,
    range,
    scoreName,
    showComments = false,
    showSummary = false,
    sort,
    source,
    title,
    themes = [],
    themeSources,
    panelOrder,
    widgetOrder,
    compareAndFilters
  } = props;

  const [selectedTheme, setSelectedTheme] = React.useState<{
    theme: { code: string; title: string };
    subtheme?: { code: string; title: string };
    options?: { volumeBy: string };
  } | null>(null);

  const canClickThemeItem = canClickTheme({
    source,
    isWeighted,
    isCommentsEnabled: showComments,
    isSummaryEnabled: showSummary,
  });

  const canNavigateToAnalysis = canNavigate({ source, isWeighted });
  const empty = !themes || themes.length === 0;

  const subtitle = `${sort === 'asc' ? 'Key negative drivers of' : 'Key positive drivers of'} ${
    scoreName || 'score'
  }`;

  const emptyMessage = sort === 'asc' ? 'No negative themes found' : 'No positive themes found';

  const handleNavigate = (
    theme?: { code: string; title: string },
    subtheme?: { code: string; title: string },
    options?: { volumeBy?: string },
    isFinalNavigate?: boolean
  ) => {
    if ((showComments || showSummary) && !isFinalNavigate && theme) {
      setSelectedTheme({
        theme,
        subtheme,
        options: options?.volumeBy ? { volumeBy: options.volumeBy } : undefined
      });
      analytics.track('Report: open comments', { category: 'Report' });
      return;
    }

    if (!canNavigateToAnalysis) {
      return;
    }

    const destination = getDestination({
      options,
      orgId,
      source,
      subtheme,
      theme,
      subtool: 'impact',
      tool: 'explore'
    });
    goToAnalysis(destination);
  };

  const subtitleElements = () => (
    <div
      className={`widget-subtitle${canNavigateToAnalysis ? ' widget-subtitle-clickable' : ''}`}
      onClick={() => handleNavigate()}
    >
      <div className="widget-subtitle-text">
        {subtitle}
        {canNavigateToAnalysis && <FontAwesomeIcon className="link-icon" icon="chart-bar" />}
      </div>
    </div>
  );

  return (
    <WidgetWrapper
      aria-label={subtitle}
      title={title}
      loading={loading}
      error={error}
      warning={warning}
      panelOrder={panelOrder}
      widgetOrder={widgetOrder}
      subtitle={subtitleElements()}
      className="impact-with-subthemes ob-impact-with-subthemes"
    >
      <div className={`themes-body widget-body${empty ? ' empty' : ''}`}>
        {!empty ? (
          <>
            <div className="section-header">
              <h5>Base Theme</h5>
              <h5>Impact</h5>
              <h5>Subthemes</h5>
              <h5>Impact</h5>
            </div>
            {themes.map((theme, index) => (
              <div key={theme.code + index} className="theme-row" aria-label={theme.name}>
                <div className="base-theme">
                  {!isWeighted ? (
                    <div
                      className={`theme-name${canNavigateToAnalysis ? ' clickable-theme' : ''}`}
                      aria-label="theme name"
                      onClick={() => handleNavigate({ code: theme.code, title: theme.name })}
                    >
                      <Tooltip
                        content={theme.name}
                        trigger={
                          <span>
                            {theme.name}
                          </span>
                        }
                        position="bottom center"
                        mouseEnterDelay={300}
                        on={['hover']}
                        inverted={true}
                      />
                    </div>
                  ) : (
                    <div>
                      <ThemeNameWithSources
                        source={source}
                        tool="explore"
                        subtool="impact"
                        theme={theme.name}
                        themeComponents={theme.components}
                        themeName={theme.name}
                        themeSources={themeSources}
                        compareAndFilters={compareAndFilters}
                      />
                    </div>
                  )}
                  <Bar
                    value={theme.impact}
                    maxValue={maxValue}
                    range={range}
                    lastItem={index + 1 === themes.length}
                  />
                </div>
                <div className="subthemes">
                  {theme.subthemes.map((subtheme, i) => (
                    <div key={theme.code + i} className="subtheme">
                      {!isWeighted ? (
                        <div
                          className={`theme-name${canClickThemeItem ? ' clickable-theme' : ''}`}
                          onClick={() =>
                            handleNavigate(
                              { code: theme.code, title: theme.name },
                              { code: subtheme.code, title: subtheme.name }
                            )
                          }
                        >
                          <Tooltip
                            content={subtheme.name}
                            trigger={
                              <span className="truncate">
                                {subtheme.name}
                              </span>
                            }
                            position="bottom center"
                            mouseEnterDelay={300}
                            on={['hover']}
                            inverted={true}
                          />
                        </div>
                      ) : (
                        <div>
                          <ThemeNameWithSources
                            source={source}
                            tool="explore"
                            subtool="impact"
                            theme={subtheme.name}
                            themeComponents={subtheme.components}
                            themeName={subtheme.name}
                            themeSources={themeSources}
                            truncateThemeName={true}
                            compareAndFilters={compareAndFilters}
                          />
                        </div>
                      )}
                      <Bar
                        value={subtheme.impact}
                        narrow={true}
                        maxValue={maxValue}
                        range={range}
                        lastItem={index + 1 === theme.subthemes.length}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="empty-theme-list">{emptyMessage}</div>
        )}
      </div>
      {showSummary && selectedTheme && (
        <SummaryComponent
          filters={filters}
          theme={selectedTheme}
          source={source}
          canNavigate={canNavigateToAnalysis}
          doNavigate={() =>
            handleNavigate(
              selectedTheme.theme,
              selectedTheme.subtheme,
              selectedTheme.options,
              true
            )
          }
        />
      )}
      {showComments && selectedTheme && (
        <CommentComponent
          filterString={filters.baseline}
          theme={selectedTheme}
          sentiment={sort === 'asc' ? 'negative' : 'positive'}
          source={source}
          canNavigate={canNavigateToAnalysis}
          doNavigate={() =>
            handleNavigate(
              selectedTheme.theme,
              selectedTheme.subtheme,
              selectedTheme.options,
              true
            )
          }
        />
      )}
    </WidgetWrapper>
  );
});

export { ImpactWithSubthemesComponent };
