import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import CopyToClipboardVue from 'vue/components/CopyToClipboard/CopyToClipboard.vue';
import ReviewControlsVue from 'vue/components/Summary/ReviewControls.vue';
import { VueInReact } from 'vuera';
import { WidgetSummaryType } from 'api/enums';
import { getActiveDashboardUIStore, getAnalysisToolsStore } from 'stores/RootStore';
import analytics from 'lib/analytics';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import './summary.scss';
import { isAnalysisViewSource } from 'api/interfaces';

const ReviewControls = VueInReact(ReviewControlsVue);
const CopyToClipboard = VueInReact(CopyToClipboardVue);

interface Props {
  config: {
    title?: string;
    summaryType?: WidgetSummaryType;
  };
  data: {
    summary?: string;
  };
  warning?: string;
  error?: string;
  loading?: boolean;
  source?: string;
  panelOrder: number;
  widgetOrder: number;
  sourceKey: string;
}

const Summary = withCamelCaseProps((props: Props) => {
  const { config, data, warning, error, loading, panelOrder, widgetOrder, sourceKey } = props;

  const activeDashboardUIStore = getActiveDashboardUIStore();
  const analysisToolsStore = getAnalysisToolsStore();

  const summary = data?.summary ?? '';

  const getSourceTitle = () => {
    const source = activeDashboardUIStore.sources?.[sourceKey];

    if (!source) {
      return null;
    }

    if (isAnalysisViewSource(source)) {
      const { survey, view, visualization } = source;
      return analysisToolsStore.getAnalysisTitle(survey, visualization, view);
    }
    return undefined;
  };

  const getWidgetSubtitle = () => {
    switch (config.summaryType) {
      case WidgetSummaryType.ISSUE:
        return 'Summary of issues';
      case WidgetSummaryType.REQUEST:
        return 'Summary of requests';
      case WidgetSummaryType.QUESTION:
        return 'Summary of questions';
      case WidgetSummaryType.NEGATIVE:
        return 'Summary of negative feedback';
      case WidgetSummaryType.POSITIVE:
        return 'Summary of positive feedback';
      default: {
        const title = getSourceTitle() || 'feedback';
        return `Summary of ${title}`;
      }
    }
  };

  const handleMouseUp = () => {
    const selectedText = window.getSelection()?.toString() ?? '';
    if (summary.includes(selectedText) && selectedText.length > 0) {
      analytics.track('Dashboard: Select Summary');
    }
  };

  const handleTextCopied = () => {
    analytics.track('Dashboard: Copy Summary');
  };

  const getLocationForFeedback = () => {
    const dashboardId = activeDashboardUIStore.currentDashboardId;
    return `dashboard:${dashboardId}`;
  };

  const subtitleElements = () => (
    <div className="widget-subtitle">
      <div className="widget-subtitle-text">{getWidgetSubtitle()}</div>
      {!config.title && (
        <div>
          <ReviewControls
            className="widget-header__controls"
            summary={summary}
            featureId="summarization"
            location={getLocationForFeedback()}
          />
        </div>
      )}
    </div>
  );

  const headerElements = () =>
    config.title && !activeDashboardUIStore.isEditing ? (
      <div>
        <ReviewControls
          className="widget-header__controls"
          summary={summary}
          featureId="summarization"
          location={getLocationForFeedback()}
        />
      </div>
    ) : null;

  return (
    <WidgetWrapper
      aria-label={getWidgetSubtitle()}
      title={config.title}
      loading={loading}
      error={error}
      warning={warning}
      panelOrder={panelOrder}
      widgetOrder={widgetOrder}
      subtitle={subtitleElements()}
      header={headerElements()}
    >
      {summary ? (
        <section className="widget-body summary">
          <span className="summary__content" onMouseUp={handleMouseUp}>
            {summary}
          </span>
          <div>
            <CopyToClipboard text={summary} popupText="Copy summary" onTextCopied={handleTextCopied} />
          </div>
        </section>
      ) : !loading ? (
        <section className="widget-body summary summary--empty">
          <FontAwesomeIcon icon="exclamation-triangle" />
          <span className="text">No data</span>
        </section>
      ) : (
        <section className="widget-body" />
      )}
    </WidgetWrapper>
  );
});

export { Summary };