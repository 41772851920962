<template>
  <widget-wrapper
    :aria-label="subtitle"
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ subtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
      <div class="widget-display-categories">
        <div class="category">
          Impact
        </div>
        <div class="category">
          Volume
        </div>
      </div>
    </div>
    <div
      :class="{ empty: themes.length === 0 }"
      class="themes-body widget-body impact-comparison"
    >
      <div
        v-if="comparisonThemes"
        class="legend"
      >
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.primary500"
          />
        </svg>
        <span class="key-text">{{ baselineName }}</span>
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.orange500"
          />
        </svg>
        <span class="key-text">{{ comparisonName }}</span>
      </div>
      <div
        v-for="(theme, index) in themes"
        :key="theme + index"
        class="theme-row"
        :aria-label="`row: ${theme.name}`"
      >
        <div
          v-if="!isWeighted"
          :class="{ 'clickable-theme': canClickTheme }"
          class="theme-name"
          aria-label="theme name"
          @click="doNavigate({code: theme.code, title: theme.name})"
        >
          <span>{{ theme.name }}</span>
        </div>
        <theme-name-with-sources
          v-else
          :source="source"
          tool="explore"
          subtool="impact"
          :theme="theme.name"
          :theme-components="theme.components"
          :theme-name="theme.name"
          :theme-sources="themeSources"
          :compare-and-filters="compareAndFilters"
        />
        <bar-comparison
          v-if="comparisonThemes"
          :value="theme.impactBaseline"
          :comparison-value="theme.impactComparison"
          :max-positive="maxPositive"
          :max-negative="maxNegative"
          :range="range"
          :display-percentage="theme.volumeBaseline"
          :comparison-display-percentage="theme.volumeComparison"
        />
        <bar
          v-else
          :value="theme.impact"
          :max-value="maxValue"
          :range="range"
          :display-percentage="theme.volume"
          :last-item="index + 1 == themes.length"
        />
      </div>
      <div
        v-if="themes.length === 0"
        class="empty-theme-list"
      >
        {{ emptyMessage }}
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      :comparison-themes="comparisonThemes"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :sentiment="sort === 'asc' ? 'negative' : 'positive'"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import { filter, flatten, get, slice, map, max, min } from 'lodash';

import { Bar } from 'components/Dashboard/Widgets/Bar';
import { BarComparison } from 'components/Dashboard/Widgets/BarComparison';
import { ThemeNameWithSources } from 'components/Dashboard/Widgets/Components/ThemeNameWithSources';
import { CommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import { SummaryComponent } from 'components/Dashboard/Widgets/Components/SummaryComponent.tsx';

import { canClickTheme } from 'components/Dashboard/Utils/dashboard-helper';
import {
  canNavigate,
  goToAnalysis,
  getDestination
} from 'components/Dashboard/Utils/navigation-helpers';

import WidgetWrapper from './WidgetWrapper';
import colors from 'vue/styles/element-variables.scss';
import customHistory from 'routing/history';
import { parse  } from 'query-string';

export default {
  name: 'IMPACT_COMPARISON',
  components: {
    Bar: ReactInVue(Bar),
    BarComparison: ReactInVue(BarComparison),
    ThemeNameWithSources: ReactInVue(ThemeNameWithSources),
    WidgetWrapper,
    SummaryComponent: ReactInVue(SummaryComponent),
    CommentComponent: ReactInVue(CommentComponent),
  },
  props: {
    orgId: { type: String },
    isWeighted: { type: Boolean, default: false },
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    data: { default: undefined, type: Object },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      selectedTheme: null,
      isCommentsEnabled: false,
      isSummaryEnabled: false,
      colors: colors,
    };
  },
  computed: {
    canClickTheme() {
      return canClickTheme({
        source: this.source,
        isWeighted: this.isWeighted,
        isCommentsEnabled: this.isCommentsEnabled,
        isSummaryEnabled: this.isSummaryEnabled,
      });
    },
    canNavigate() {
      return canNavigate({ source: this.source, isWeighted: this.isWeighted });
    },
    themeSources() {
      return get(this, 'data.metadata.sources', undefined);
    },
    baselineName() {
      return get(this, 'data.baseline.name');
    },
    comparisonName() {
      return get(this, 'data.comparison.name');
    },
    sort() {
      return get(this, 'config.sort', 'desc');
    },
    subtitle() {
      const scoreName = get(this, 'scoreConfig.name');
      const tokens = [];

      if (this.sort === 'asc') {
        tokens.push(`Themes decreasing`);
      } else {
        tokens.push('Themes increasing');
      }

      if (scoreName) {
        tokens.push(scoreName);
      } else {
        tokens.push('score');
      }

      return tokens.join(' ');
    },
    emptyMessage() {
      if (this.sort === 'asc') {
        return 'No negative themes found';
      } else {
        return 'No positive themes found';
      }
    },
    comparisonThemes() {
      return get(this, 'data.comparison');
    },
    themes() {
      const asc = this.sort === 'asc';
      const themes = filter(get(this, 'data.themes', []), (theme) => {
        const impact = this.comparisonThemes ? theme.impactBaseline : theme.impact;
        if (asc) {
          return impact <= 0;
        } else {
          return impact >= 0;
        }
      });
      return slice(themes, 0, this.limit);
    },
    count() {
      return get(this, 'data.count', 0);
    },
    maxNegative() {
      const impacts = this.getImpacts();
      return Math.min(0, min(impacts));
    },
    maxPositive() {
      const impacts = this.getImpacts();
      return Math.max(0, max(impacts));
    },
    maxValue() {
      const impacts = this.getImpacts();
      return max(map(impacts, (impact) => Math.abs(impact))) || 0;
    },
    range() {
      return get(this, 'scoreConfig.options.range');
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    scoreType() {
      return get(this, 'scoreConfig.type', 'nps');
    },
    compareAndFilters() {
      const queryParams = parse(customHistory.location.search);
      const { filters, compare } = queryParams;
      return { compare, filters };
    }
  },
  watch: {
    config: {
      handler (config) {
        this.isCommentsEnabled = config.showComments;
        this.isSummaryEnabled = config.showSummary;
      },
      immediate: true
    },
  },
  methods: {
    doNavigate(theme, subtheme, options, isFinalNavigate) {
      const {
        source,
        isCommentsEnabled,
        isSummaryEnabled,
        isWeighted,
        orgId,
      } = this;

      // if the navigation is to a specific theme, and there is a comments component, don't navigate yet
      if ((isCommentsEnabled || isSummaryEnabled) && !isFinalNavigate && theme) {
        this.selectedTheme = { theme, subtheme, options };
        analytics.track('Report: open comments', { category: 'Report' });
        return;
      }
      if (!canNavigate({ source, isWeighted })) {
        return;
      }

      const destination = getDestination({
        options,
        orgId,
        source,
        subtheme,
        theme,
        subtool: 'impact',
        tool: 'explore'
      });
      goToAnalysis(destination);
    },
    getImpacts() {
      if (this.comparisonThemes) {
        const impacts = map(this.themes, (theme) => {
          return [theme.impactBaseline, theme.impactComparison];
        });
        return flatten(impacts);
      } else {
        return map(this.themes, 'impact');
      }
    },
  },
};
</script>

<style lang="scss">
.impact-comparison .theme-name {
  width: 150px;
}
</style>
