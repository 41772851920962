

















































































import thematicData from 'vue/libs/thematicData';
import CopyToClipboard from "vue/components/CopyToClipboard/CopyToClipboard.vue";
import analytics from 'lib/analytics';
import ReviewControls from 'vue/components/Summary/ReviewControls.vue';
import { reaction, IReactionDisposer } from 'mobx';
import {
  getFilterStore,
} from 'stores/RootStore';
import { filterPopUpId } from "components/Filters/types";

export default {
  name: 'ExploreToolSummary',
  components: {
    CopyToClipboard,
    ReviewControls
  },
  props: {
    requestOptions: { type: Object },
    segmentQueryStrings: { type: Object },
    hasComparison: { type: Boolean },
    surveyTitle: { type: String },
    surveyId: { type: String },
    orgId: { type: String },
  },
  data() {
    return {
      disposer: undefined as IReactionDisposer | undefined,
      summary: '',
      error: false,
      loading: false,
      lastRequest: undefined,
      filterStore: getFilterStore(),
    };
  },
  mounted() {
    const analysisFilterIds = [
      filterPopUpId.AnalysisBaseLine,
      filterPopUpId.AnalysisComparison
    ];

    this.disposer = reaction(
      () => this.filterStore.selections,
      () => {

        // Do not respond to filter selection changes while popups are open
        const isAnyAnalyisFilterOpen = analysisFilterIds.includes(this.filterStore.openFilterPopUpId);
        if (isAnyAnalyisFilterOpen) {
          return;
        }

        this.error = false;
        this.loading = false;

        // Cancel all in-flight summarization requests which were made by this component
        thematicData.cancelSummarizationRequestsById('exploreToolDatasetSummary');

        this.makeSummaryRequest();

      });
    this.makeSummaryRequest();
  },
  beforeDestroy() {
    if (this.disposer) {
      this.disposer();
    }
  },
  methods: {
    makeSummaryRequest() {
      const filterSelections = this.filterStore.selections;
      const isFilterPresent = filterSelections.baseline.title !== '';

      if (isFilterPresent) {
        this.fetchSummary();
      }

    },
    getLocationForFeedback() {
      return `analysis:${this.surveyId}:themes:dataSource`;
    },
    fetchSummary: async function () {

      this.loading = true;
      this.error = false;

      const { requestOptions } = this;
      const filterSelections = this.filterStore.selections;
      const hasComparison = filterSelections.baseline.query !== filterSelections.comparison.query;
      const baselineSegmentQueryString = this.filterStore.segmentQueryStrings.baseline;
      const comparisonFilter = hasComparison ? filterSelections.comparison.query : '';

      const options = {
        dataSource: requestOptions.dataSource,
        exampleColumns: requestOptions.exampleColumns,
        scoreOptions: {
          scoreCommentsOnly: true
        },
        segmentSelection: baselineSegmentQueryString,
        comparisonFilter,
        type: 'default',
        baselineLabel: filterSelections.baseline.title,
        comparisonLabel: filterSelections.comparison?.title,
        summarize: true,
        cluster: false,
      };

      const filter = filterSelections.baseline.query;

      let request;
      try {
        request = thematicData.getSummarization(
          filter,
          undefined,
          undefined,
          'all',
          options,
          false, // cachedOnly,
          'exploreToolDatasetSummary' // location of this summary
        );
        this.lastRequest = request;
        const data = await request;
        if (request !== this.lastRequest) {
          return;
        }

        if (typeof data?.data?.summary !== 'string') {
          thematicData.clearSummarizationCache();
          throw Error('No summary data');
        }

        this.summary = data.data.summary?.trim();
      } catch(e) {
        if (request === this.lastRequest) {
          this.error = true;
        }
      } finally {
        if (request === this.lastRequest) {
          this.loading = false;
        }
      }
    },
    onMouseUp() {
      const selectedText = (window.getSelection() || '').toString();
      const includesInSummary = this.summary.includes(selectedText);
      if (selectedText.length > 0 && includesInSummary) {
        analytics.track('Analysis: Select Summary',
          {
            'Location': 'Data Source',
          }
        );
      }
    },
    onTextCopied() {
      analytics.track('Analysis: Copy Summary',
        {
          'Location': 'Data Source',
        }
      );
    }
  }
};
