<template>
  <div>
    <el-alert
      v-if="tagStore.fetchTagsError"
      :title="tagStore.fetchTagsError"
      type="error"
    />
    <div class="details-row">
      <div class="details-row__counts">
        <explore-tool-selection-detail
          class="count-label"
          :counts="baselineCounts"
          :error-message="baselineErrorMessage"
          :are-comments-loading="loading"
          :is-conversation="isConversation"
        />
      </div>
      <div
        v-if="hasHiddenParts"
        class="details-row__toggle"
      >
        Show full threads
        <el-switch
          :value="showSupportMessages"
          @change="toggleSupportMessageVisibility"
        />
      </div>
      <div class="details-row__columns">
        <el-dropdown
          :hide-on-click="false"
          trigger="click"
          placement="bottom-end"
          class="configure-table"
        >
          <el-button
            size="small"
            type="plain"
          >
            Columns
            <font-awesome-icon icon="chevron-down" />
          </el-button>
          <el-dropdown-menu
            slot="dropdown"
            class="feedback-columns-dropdown"
          >
            <div class="chart-option-label">
              Show:
            </div>
            <el-checkbox-group
              v-model="selectedColumnCheckboxes"
              @change="saveColumnSelection()"
            >
              <ul class="chart-option-list">
                <li class="chart-option-item">
                  <el-checkbox label="comment">
                    Feedback
                  </el-checkbox>
                </li>
                <li class="chart-option-item">
                  <el-checkbox label="theme">
                    Themes
                  </el-checkbox>
                </li>
                <li
                  v-if="!isSentimentDisabled"
                  class="chart-option-item"
                >
                  <el-checkbox label="sentiment">
                    Sentiment
                  </el-checkbox>
                </li>
                <li class="chart-option-item">
                  <el-checkbox label="categories">
                    Categories
                  </el-checkbox>
                </li>
                <li class="chart-option-item">
                  <el-checkbox label="tags">
                    Labels
                  </el-checkbox>
                </li>
                <li
                  v-for="d in dimensionsAvailable"
                  :key="`${d.key}-select`"
                  class="chart-option-item"
                >
                  <el-checkbox :label="d.key">
                    {{ d.name }}
                  </el-checkbox>
                </li>
              </ul>
            </el-checkbox-group>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="details-row__download">
        <el-button
          v-if="downloading"
          disabled="disabled"
          size="small"
          type="plain"
        >
          <font-awesome-icon
            spin
            icon="spinner"
          />
        </el-button>
        <el-dropdown
          v-else-if="analysisConfigStore.canDownload"
        >
          <el-button
            size="small"
            type="plain"
          >
            <font-awesome-icon icon="cloud-download-alt" />
            <font-awesome-icon icon="chevron-down" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="baseline"
              class="download-item"
            >
              <button
                class="dropdown-button"
                @click="downloadResults"
              >
                <span class="tab-icon baseline" />
                <span class="download-text">Download {{ baselineTitle }}</span>
              </button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <feedback-table
      v-if="initialized"
      :total="baselineCounts.total"
      :query="baselineFilterString"
      :dimensions-available="dimensionsAvailable"
      :selected-column-checkboxes="selectedColumnCheckboxes"
      @onCommentThemeSelected="onCommentThemeSelected"
    />
  </div>
</template>

<script>
import { find, get, isEmpty, reduce } from 'lodash';

import {
  getAnalysisConfigStore,
  getAnalysisToolsUIStore,
  getExploreToolUIStore,
  getFilterStore,
  getInitConfigStore,
  getTagStore,
  getThemesStore,
  getUserStore,
} from 'stores/RootStore';
import ExploreToolSelectionDetail from 'vue/explore/ExploreToolSelectionDetail.vue';
import downloadResultsHelper from 'vue/libs/download-results';
import dataMixin from 'vue/tools/Utils/dataMixin';
import FeedbackTable from './components/FeedbackTable.vue';
import { createThemeFilterOptions, createThemeQueryFilter, getThemeMatchType } from 'lib/filters/theme-filter-helper';
import { selectAllChildren } from 'lib/filters/hierarchical-filter-helper';

export default {
  name: 'Feedback',
  components: {
    FeedbackTable,
    ExploreToolSelectionDetail,
  },
  mixins: [dataMixin],
  props: {},
  data() {
    return {
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      analysisConfigStore: getAnalysisConfigStore(),
      tagStore: getTagStore(),
      filterStore: getFilterStore(),
      userStore: getUserStore(),
      initConfigStore: getInitConfigStore(),
      themesStore: getThemesStore(),
      exploreToolUIStore: getExploreToolUIStore(),

      sortBy: 'date',
      sortDir: 'desc',
      downloading: false,
      selectedColumnCheckboxes: [],
      dimensionsAvailable: [],
      initialized: false,
      updatedThemeFilterOptions: [],
      showReportAssistantModal: false,
    };
  },
  computed: {
    requestOptions() {
      return this.exploreToolUIStore.requestOptions;
    },
    config() {
      return this.initConfigStore.config;
    },
    dimensions() {
      return this.initConfigStore.dimensions;
    },
    selections() {
      return this.filterStore.selections;
    },
    selectedThemeFilterQuery() {
      return this.filterStore.filters['baseline'].themes;
    },
    metadataColumns() {
      return this.analysisConfigStore.metadataColumns;
    },
    isSentimentDisabled() {
      return this.config && this.config.sentiment === 'disabled';
    },
    baselineFilterString() {
      return get(this.selections, 'baseline.query');
    },
    showSupportMessages() {
      return this.analysisToolsUIStore.areSupportMessagesVisible;
    },
    hasHiddenParts() {
      return (this.analysisConfigStore.threadDisplayConfig.hiddenThreadParts || []).length > 0;
    },
    themesHierarchy() {
      return this.themesStore.themesHierarchy;
    },
    themeOptions() {
      if (isEmpty(this.themesHierarchy)) {
        return [];
      }
      return createThemeFilterOptions(this.themesHierarchy, this.selectedThemeFilterQuery);
    },
    isConversation() {
      return !!this.analysisConfigStore?.config?.threadConfig;
    }
  },
  watch: {
    selections: {
      handler() {
        this.onFilterChange();
      },
      immediate: true,
    },
    requestOptions() {
      this.calculateFilter();
      this.onFilterChange();
    },
  },
  mounted() {
    this.dimensionsAvailable = this.calculateDimensionsAvailable();
    this.initializeSelectedColumns();
    this.initialized = true;
  },
  methods: {
    onCommentThemeSelected(theme) {
      // a sub theme title will always have a colon in it
      const isASubTheme = theme.title.includes(':');
      const themeId = isASubTheme ? theme.sub : theme.base;
      const themeTitle = isASubTheme ? theme.subTitle : theme.baseTitle;
      const themeAlreadySelected = find(this.selectedThemeFilterQuery.selected || [], (element) => {
        return element.id === themeId && element.name === themeTitle;
      });
      if (!themeAlreadySelected) {
        // this is to prevent the deselection of the theme which is already selected
        if (isASubTheme) {
          this.updatedThemeFilterOptions = this.onSubThemeSelection(theme);
        } else {
          this.updatedThemeFilterOptions = this.onBaseThemeSelection(theme);
        }
        // we do not want to calculate filter if theme is already selected
        this.calculateFilter();
      }
    },
    onBaseThemeSelection(theme) {
      return this.themeOptions.map((p) => {
        if (p.id === theme.base) {
          return {
            ...p,
            isSelected: !p.isSelected,
            children: (p.children || []).map((c) => selectAllChildren(c, !p.isSelected)),
          };
        } else {
          return p;
        }
      });
    },
    onSubThemeSelection(theme) {
      return this.themeOptions.map((p) => {
        if (p.id === theme.base) {
          const areAllChildrenSelected = (p.children || []).every((val) => val.isSelected);
          // if all children are selected then we deselect the parent and all it's children but the child which has been clicked
          const updatedOption = {
            ...p,
            isSelected: areAllChildrenSelected ? !areAllChildrenSelected : p.isSelected,
            children: (p.children || []).map((c) => {
              if (c.id === theme.sub) {
                return {
                  ...c,
                  isSelected: true,
                };
              } else {
                return {
                  ...c,
                  isSelected: areAllChildrenSelected ? !areAllChildrenSelected : c.isSelected,
                };
              }
            }),
          };
          const areAllChildOptionsSelected = (updatedOption.children || []).every((val) => val.isSelected);
          // the same checked here is to determine if the last child option has been selected
          // if so, then we select the parent option
          if (areAllChildOptionsSelected) {
            return {
              ...updatedOption,
              isSelected: areAllChildOptionsSelected,
            };
          } else {
            return updatedOption;
          }
        } else {
          return p;
        }
      });
    },
    calculateFilter() {
      const filterDetails = {
        id: 'themes',
        name: 'Themes',
      };
      const matchType = getThemeMatchType(this.selectedThemeFilterQuery);
      const newQueryFilter = createThemeQueryFilter(
        filterDetails,
        'baseline',
        this.updatedThemeFilterOptions,
        matchType,
      );
      this.filterStore.setFilterAndUpdateSelections(newQueryFilter);
    },
    downloadResults: async function () {
      let filterString = this.baselineFilterString;
      let title = this.baselineTitle;
      const filename = downloadResultsHelper.createFilename(title);

      this.downloading = true;

      const columns = [];

      if (this.selectedColumnCheckboxes.includes('comment')) {
        columns.push({ name: `Feedback`, column: `comment`});
      }
      if (this.selectedColumnCheckboxes.includes('sentiment') && !this.isSentimentDisabled) {
        columns.push({ name: `Overall Sentiment`, column: `sentiment`});
      }
      if (this.selectedColumnCheckboxes.includes('theme')) {
        columns.push({ name: `Themes`, column: `theme`});
      }
      if (this.selectedColumnCheckboxes.includes('categories')) {
        columns.push({ name: `Categories`, column: `categories`});
      }
      if (this.selectedColumnCheckboxes.includes('tags')) {
        columns.push({ name: `Labels`, column: `tags`});
      }
      this.dimensionsAvailable.forEach(dimension => {
        if (this.selectedColumnCheckboxes.includes(dimension.key)) {
          columns.push({ name: dimension.name, column: dimension.key });
        }
      });

      const table = await downloadResultsHelper.getResultTable(filterString, columns);
      await downloadResultsHelper.downloadArray(table, filename);

      this.downloading = false;
    },
    calculateDimensionsAvailable() {
      const result = reduce(
        this.dimensions,
        (result, val, key) => {
          // TODO: the key != responsetext is because of a bug in the backend that is saying responsetext is of type data when it isn't
          if (val.type === 'data' && key != 'responsetext') {
            result.push({ name: val.name, key });
          }
          return result;
        },
        [],
      );
      return result;
    },
    initializeSelectedColumns() {
      // if this is in the normal comment metadata, include it now
      const { feedbackColumnPresets } = this.userStore.preferences;
      const { currentSurveyId } = this.analysisToolsUIStore;
      // Use users preferred selected columns if they exist
      if (!isEmpty(feedbackColumnPresets) && !isEmpty(feedbackColumnPresets[currentSurveyId])) {
        const selectedColumns = feedbackColumnPresets[currentSurveyId];
        // select the columns from preferences if they are valid dimensions
        selectedColumns.forEach((column) => {
          if (this.dimensions[column]) {
            this.selectedColumnCheckboxes.push(column);
          }
        });
      } else {
        this.selectedColumnCheckboxes = ['comment', 'theme', 'tags', 'categories'];
        // Get tags for the current org
        this.tagStore.getTags();
        this.dimensionsAvailable.forEach((dimension) => {
          const isInMetadata = find(this.metadataColumns, { name: dimension.name });
          if (isInMetadata || dimension.name === 'Date') {
            this.selectedColumnCheckboxes.push(dimension.key);
          }
        });
      }
    },
    async saveColumnSelection() {
      const { currentSurveyId } = this.analysisToolsUIStore;
      const existingFeedbackColumnPresets = this.userStore.preferences.feedbackColumnPresets || {};
      const feedbackColumnPresets = { ...existingFeedbackColumnPresets };
      feedbackColumnPresets[currentSurveyId] = this.selectedColumnCheckboxes;
      // Intentional to not have error handling here
      await this.userStore.setPreferences({ feedbackColumnPresets });
    },
    toggleSupportMessageVisibility() {
      this.analysisToolsUIStore.toggleSupportMessageVisibility();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables';

.tab-icon {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  border-radius: 50%;
  display: block;
  grid-area: spot;
  height: 10px;
  justify-self: center;
  width: 10px;
  &.baseline {
    background: $--color-primary;
  }
}

.button-group {
  display: flex;
}

.button-group-item {
  padding-right: 5px;
}

.chart-option-label {
  padding-left: 0;
}

.el-dropdown-menu {
  padding: 10px 20px;
}

.download-item {
  position: relative
}

.details-row {
  padding: 5px 0px;
  display: grid;
  grid-template-areas: 'toggle counts columns download';
  grid-template-columns: auto 160px 100px 55px;
  grid-gap: 5px;

  &__toggle {
    grid-area: toggle;
    line-height: 25px;
    text-align: right;
  }
  &__counts {
    grid-area: counts;
    line-height: 25px;
    text-align: right;
  }

  &__columns {
    grid-area: columns;
    text-align: center;
  }
  &__download {
    grid-area: download;
    text-align: center;
  }
}

.dropdown-button {
  background: transparent;
  border: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
}

</style>

<style lang="scss">
.feedback-columns-dropdown .el-checkbox-group {
  --feedback-columns-dropdown-height: calc(100vh - 350px);
  max-height: var(--feedback-columns-dropdown-height);
  overflow-y: auto;
  padding-right: 1rem;
}
</style>
