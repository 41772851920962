import ThemeEditBlue from 'images/icons/theme-edit-blue.svg';
import ThemeEditError from 'images/icons/theme-edit-error.svg';
import ThemeEditGreen from 'images/icons/theme-edit-green.svg';
import ThemeEditYellow from 'images/icons/theme-edit-yellow.svg';

const TOAST_ICONS = {
  THEME_EDIT_ERROR: ThemeEditError,
  THEME_EDIT_YELLOW: ThemeEditYellow,
  THEME_EDIT_GREEN: ThemeEditGreen,
  THEME_EDIT_BLUE: ThemeEditBlue
};

export default TOAST_ICONS;