import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import { KeyTakeawaysIcon } from 'components/ExploreDetail/KeyTakeawaysIcon';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import * as React from 'react';
import toFixed from 'vue/libs/to-fixed';
import './key-takeaways.scss';

enum DashboardTakeawayType {
  SCORE_CHANGE = 'SCORE_CHANGE',
  THEMES_NEGATIVE_TO_SCORE = 'THEMES_NEGATIVE_TO_SCORE',
  THEMES_POSITIVE_TO_SCORE = 'THEMES_POSITIVE_TO_SCORE',
  EMERGING_THEMES = 'EMERGING_THEMES',
  RISING_THEMES = 'RISING_THEMES',
  FALLING_THEMES = 'FALLING_THEMES'
}

interface ScoreChangeValue {
  movement: number;
  movementIsSlight: boolean;
  pointsNoun: string;
  scoreName: string;
  score: number;
}

interface ThemeChangeValue {
  volumeChange: 'INCREASED' | 'DECREASED' | 'NOCHANGE';
  scoreChange: 'INCREASED' | 'DECREASED' | 'NOCHANGE';
  themes: string[];
}

interface Takeaway {
  type: DashboardTakeawayType;
  value: string[] | ScoreChangeValue | ThemeChangeValue;
  string?: string;
}

interface Props {
  config: {
    title?: string;
  };
  context?: Record<string, unknown>;
  data?: {
    currentPeriodName?: string;
    takeaways?: Takeaway[];
  };
  warning?: string;
  error?: string;
  limit?: number;
  loading?: boolean;
  source?: string;
  panelOrder: number;
  widgetOrder: number;
}

const KeyTakeaways = withCamelCaseProps((props: Props) => {
  const { config, data, warning, error, loading, panelOrder, widgetOrder } = props;

  const formatScoreChange = (takeaway: Takeaway) => {
    const value = takeaway.value as ScoreChangeValue;
    const { movement, movementIsSlight, pointsNoun, scoreName } = value;
    const absMovement = toFixed(Math.abs(movement), 1);
    const score = toFixed(value.score, 1);

    if (movement < 0) {
      return {
        icon: 'score-change-down',
        text: `<b>${scoreName}</b> ${
          movementIsSlight ? 'decreased slightly by' : 'decreased'
        } <b>${absMovement}</b> ${pointsNoun} to <b>${score}</b>`
      };
    }

    if (movement > 0) {
      return {
        icon: 'score-change-up',
        text: `<b>${scoreName}</b> ${
          movementIsSlight ? 'increased slightly by' : 'increased'
        } <b>${absMovement}</b> ${pointsNoun} to <b>${score}</b>`
      };
    }

    return {
      icon: 'score-change-no-change',
      text: `<b>${scoreName}</b> did not change, and is still <b>${score}</b>`
    };
  };

  const formatThemeChangeToScore = (takeaway: Takeaway) => {
    const value = takeaway.value as ThemeChangeValue;
    const { volumeChange, scoreChange, themes } = value;

    const icon = takeaway.type === DashboardTakeawayType.THEMES_NEGATIVE_TO_SCORE
      ? 'themes-down-neutral'
      : 'themes-up-neutral';

    let peopleCount = '';
    let sentiment = '';
    let impact = '';

    if (volumeChange === 'INCREASED' || volumeChange === 'NOCHANGE') {
      peopleCount = 'more ';
      switch (scoreChange) {
        case 'INCREASED':
          sentiment = ' more positively';
          break;
        case 'DECREASED':
          sentiment = ' more negatively';
          break;
        default:
          impact = takeaway.type === DashboardTakeawayType.THEMES_NEGATIVE_TO_SCORE
            ? 'which hurt the score'
            : 'which improved the score';
      }
    }

    if (volumeChange === 'DECREASED') {
      peopleCount = 'less ';
      switch (scoreChange) {
        case 'INCREASED':
          impact = ', but they were overall more positive';
          break;
        case 'DECREASED':
          impact = ', but they were overall more negative';
          break;
        default:
          impact = takeaway.type === DashboardTakeawayType.THEMES_NEGATIVE_TO_SCORE
            ? 'which negatively impacted the score'
            : 'which positively impacted the score';
      }
    }

    const text = `${peopleCount}people talked${sentiment} about ${themes.map(theme => `<b>${theme}</b>`).join(' and ')} ${impact}`;

    return { icon, text };
  };

  const getTakeaways = () => {
    return (data?.takeaways || []).map(takeaway => {
      if (Array.isArray(takeaway.value)) {
        const themes = `${takeaway.value.map(value => `<b>${value}</b>`).join(' and ')}`;

        switch (takeaway.type) {
          case DashboardTakeawayType.EMERGING_THEMES:
            return {
              icon: 'themes-emerging',
              text: `New emerging themes this period are <b>${themes}</b>`
            };

          case DashboardTakeawayType.RISING_THEMES:
            return {
              icon: 'themes-up-neutral',
              text: `There are now more people who talk about <b>${themes}</b>`
            };

          case DashboardTakeawayType.FALLING_THEMES:
            return {
              icon: 'themes-down-neutral',
              text: `Less people now talk about <b>${themes}</b>`
            };
        }
      }

      switch (takeaway.type) {
        case DashboardTakeawayType.SCORE_CHANGE:
          return formatScoreChange(takeaway);

        case DashboardTakeawayType.THEMES_NEGATIVE_TO_SCORE:
        case DashboardTakeawayType.THEMES_POSITIVE_TO_SCORE:
          return formatThemeChangeToScore(takeaway);

        default:
          return {
            icon: 'themes',
            text: takeaway.string
          };
      }
    });
  };

  const empty = !data?.takeaways || data.takeaways.length === 0;
  const subtitle = `Key Takeaways${data?.currentPeriodName ? ` for ${data.currentPeriodName}` : ''}`;

  const subtitleElements = () => (
    <div className="widget-subtitle">
      <div className="widget-subtitle-text">
        <span className="takeaways__key-text">
          <KeyTakeawaysIcon icon="key-takeaways" />
          {subtitle}
        </span>
      </div>
    </div>
  );

  return (
    <WidgetWrapper
      aria-label={subtitle}
      title={config.title}
      loading={loading}
      error={error}
      warning={warning}
      panelOrder={panelOrder}
      widgetOrder={widgetOrder}
      subtitle={subtitleElements()}
      className="widget-width-2"
    >
      <div className={`themes-body widget-body${empty ? ' empty' : ''}`}>
        {!empty ? (
          <ul className="takeaways-list">
            {getTakeaways().map((takeaway, index) => (
              <li key={index}>
                <KeyTakeawaysIcon icon={takeaway.icon} />
                <p dangerouslySetInnerHTML={{ __html: takeaway.text ?? '' }} />
              </li>
            ))}
          </ul>
        ) : (
          <div className="empty-change-list">
            No changes found
          </div>
        )}
      </div>
    </WidgetWrapper>
  );
});

export { KeyTakeaways };
