import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { isNumber } from 'lodash';
import * as React from 'react';
import toFixed from 'vue/libs/to-fixed';
import { getScoreChange, getScoreChangeIndicator } from 'vue/libs/widget-utils';
import './original-score.scss';

interface ScoreData {
  name?: string;
  score: {
    score: number;
    componentScores?: {
      originalScore: {
        score: number;
        name: string;
      };
    }[];
  };
  count?: number;
}

export interface OriginalScoreData {
  currentScore?: ScoreData;
  previousScore?: ScoreData;
  scoreConfig?: {
    options?: {
      precision?: number;
    };
  };
}

interface Props {
  config: {
    title?: string;
    precision?: number;
    score?: {
      options?: {
        precision?: number;
      };
    };
  };
  context: {
    compareFilter?: unknown;
    compareFilterName?: string;
  };
  data: OriginalScoreData;
  warning?: string;
  error?: string;
  loading?: boolean;
  source?: string;
}

const OriginalScore = withCamelCaseProps((props: Props) => {
  const { config, context, data, warning, error, loading } = props;

  const getOriginalScore = (score?: ScoreData) => {
    if (!score) {
      return undefined;
    }
    return score.score.componentScores?.[0]?.originalScore;
  };

  const getPrecision = (score: number) => {
    const configPrecision = data?.scoreConfig?.options?.precision ?? config?.precision;
    if (isNumber(configPrecision)) {
      return configPrecision;
    }

    const absChange = Math.abs(score);
    return absChange < 10 ? 1 : 0;
  };

  const getScoreChangeLabel = (scoreChange?: number) => {
    if (!scoreChange) {
      return undefined;
    }
    const precision = getPrecision(scoreChange);
    const label = toFixed(scoreChange, precision);
    return Math.abs(Number(label));
  };

  const currentScore = data?.currentScore?.score?.score;
  const previousScore = data?.previousScore?.score?.score;
  const currentOriginalScore = getOriginalScore(data?.currentScore);
  const previousOriginalScore = getOriginalScore(data?.previousScore);
  const compareFilter = context?.compareFilter;

  const scoreName = compareFilter
    ? context?.compareFilterName
    : data?.currentScore?.name ?? '';
  const originalScoreName = currentOriginalScore?.name ?? '';
  const scoreLabel = currentScore !== undefined ? toFixed(currentScore, 1) : undefined;
  const originalScoreLabel = currentOriginalScore?.score !== undefined
    ? toFixed(currentOriginalScore.score, 1)
    : undefined;

  const scoreChange = getScoreChange(currentScore, previousScore);
  const scoreChangeLabel = getScoreChangeLabel(scoreChange);
  const scoreChangeIndicator = isNumber(scoreChange) ? getScoreChangeIndicator(scoreChange) : undefined;

  const originalScoreChange = getScoreChange(
    currentOriginalScore?.score,
    previousOriginalScore?.score
  );
  const originalScoreChangeLabel = getScoreChangeLabel(originalScoreChange);
  const originalScoreChangeIndicator = isNumber(originalScoreChange) ? getScoreChangeIndicator(originalScoreChange) : undefined;

  const subtitleElements = () => (
    <div className="widget-subtitle">
      <div className="widget-subtitle-text">{scoreName}</div>
    </div>
  );

  return (
    <WidgetWrapper
      aria-label={scoreName}
      title={config.title}
      loading={loading}
      error={error}
      warning={warning}
      subtitle={subtitleElements()}
    >
      {scoreLabel ? (
        <div
          className="widget-body original-score-widget scores"
          data-testid="original-score-widget"
        >
          <div className="score-container">
            <div className="overall score">
              <h2>
                {scoreLabel}
                <div className="score-change">
                  {scoreChangeIndicator && (
                    <FontAwesomeIcon icon={scoreChangeIndicator} data-testid={scoreChangeIndicator} />
                  )}
                  {scoreChangeLabel}
                </div>
              </h2>
              <legend>{scoreName}</legend>
              {compareFilter && (
                <legend className="goodwill">
                  Goodwill
                </legend>
              )}
            </div>
            {compareFilter && (
              <div className="original score">
                <h2>
                  {originalScoreLabel}
                  <div className="score-change">
                    {originalScoreChangeIndicator && (
                      <FontAwesomeIcon icon={originalScoreChangeIndicator} data-testid={scoreChangeIndicator} />
                    )}
                    {originalScoreChangeLabel}
                  </div>
                </h2>
                <legend>{originalScoreName}</legend>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="empty widget-body">
          No data found
        </div>
      )}
    </WidgetWrapper>
  );
});

export { OriginalScore };
