import * as React from 'react';
import { TranslatedFilter } from "api/interfaces";
import './filter-list.scss';

interface Props {
  filters: TranslatedFilter[];
}

export const FilterList = ({ filters }: Props) => {
  return (
    <ul className="filter-list__filters">
      {filters.map(({ filterName, filterValues }, index: number) => {
        return (
          <li className="filter-list__filter-item" key={`${filterName}-${index}`}>
            <span className="filter-list__filter-title">{filterName}</span> <span className="filter-list__filter-value">{filterValues}</span>
          </li>
        );
      })}
    </ul>
  );
}
