import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import ArrowDown from 'images/icons/arrow-down.svg';
import ArrowUp from 'images/icons/arrow-up.svg';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { get, isNumber } from 'lodash';
import * as React from 'react';
import toFixed from 'vue/libs/to-fixed';
import './score.scss';

interface ScoreConfig {
  options?: {
    precision?: number;
  };
  name?: string;
}

export interface ScoreData {
  currentScore?: {
    name: string;
    score: {
      score: number;
    };
    count: number;
  };
  previousScore?: {
    name: string;
    score: {
      score: number;
    };
  };
  currentPeriodName?: string;
  previousPeriodName?: string;
  scoreConfig?: ScoreConfig;
}

interface Props {
  config: {
    title?: string;
    precision?: number;
    score?: ScoreConfig;
    compareToOverall?: boolean;
  };
  context: {
    compareFilterName?: string;
  };
  data: ScoreData;
  warning?: string;
  error?: string;
  limit?: number;
  loading?: boolean;
  source?: string;
  panelOrder: number;
  widgetOrder: number;
}

// Static methods (used in WidgetChooser)
export const isComparison = (config: Props['config']) => config.compareToOverall;
export const hasOriginalScore = (data: Props['data']) =>
  !!get(data, 'currentScore.score.componentScores.0.originalScore.score');

const Score = withCamelCaseProps((props: Props) => {
  const { config, context, data, warning, error, loading, panelOrder, widgetOrder } = props;

  const currentScore = get(data, 'currentScore.score.score');
  const previousScore = get(data, 'previousScore.score.score');
  const currentPeriodName = get(data, 'currentPeriodName');
  const previousPeriodName = get(data, 'previousPeriodName');
  const responseCount = get(data, 'currentScore.count', 0);
  const scoreConfig = get(data, 'scoreConfig', get(config, 'score'));
  const scoreDifference = currentScore - previousScore;

  const getPrecision = () => {
    const configPrecision = get(scoreConfig, 'options.precision', config.precision);
    const absDifference = Math.abs(scoreDifference);

    if (isNumber(configPrecision)) {
      return configPrecision;
    }
    return absDifference < 10 ? 1 : 0;
  };

  const getScoreDifferenceLabel = () => {
    if (currentScore === null || previousScore === null) {
      return null;
    }

    const precision = getPrecision();
    const absDifference = Math.abs(scoreDifference);
    const label = toFixed(scoreDifference, precision);

    if (absDifference < 0.1) {
      return 'No change';
    }
    return scoreDifference > 0 ? `+${label}` : label;
  };

  const getScoreChangeIndicator = () => {
    if (previousScore) {
      const absDifference = Math.abs(scoreDifference);
      if (absDifference < 0.1) {
        return null;
      }
      return scoreDifference < 0
        ? <ArrowDown className="arrowIndicator" />
        : <ArrowUp className="arrowIndicator" />;
    }
    return null;
  };

  const getScoreName = () => {
    const compareFilterName = get(context, 'compareFilterName', '');
    const scoreConfigName = get(scoreConfig, 'name', '');
    return compareFilterName || scoreConfigName;
  };

  const getSubtitle = () => {
    let title = getScoreName();
    if (currentPeriodName) {
      title += ` in ${currentPeriodName}`;
    }
    return title;
  };

  const subtitleElements = () => (
    <div className="widget-subtitle">
      <div className="widget-subtitle-text">{getSubtitle()}</div>
    </div>
  );

  return (
    <WidgetWrapper
      aria-label={getSubtitle()}
      title={config.title}
      loading={loading}
      error={error}
      warning={warning}
      panelOrder={panelOrder}
      widgetOrder={widgetOrder}
      subtitle={subtitleElements()}
    >
      {responseCount > 0 ? (
        <div
          data-testid="score-widget"
          className="widget-body scores"
        >
          <div className="overall score">
            <h2>
              {toFixed(currentScore, 1)}
              <div className="response-count-subtext">{toFixed(responseCount, 1, ' responses')}</div>
            </h2>
            {previousScore ? (
              <legend>
                {getScoreChangeIndicator()}
                {getScoreDifferenceLabel()} compared to {previousPeriodName}
              </legend>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="empty widget-body">Not enough responses to calculate</div>
      )}
    </WidgetWrapper>
  );
});

export { Score };
