import auth from 'Auth/Auth';
import { CommentColumn, RefreshMappedPhrasesSuggestions, ThemeJson, ThemeQualityDetails } from 'api/interfaces';
import {
  AddPhrasesTransform, AddThemeTransform,
  ChangeThemeTitleTransform,
  DeletePhrasesTransform, DeleteThemesTransform,
  MarkAllAsReviewedTransform, MergeThemeTransform,
  MoveThemeTransform, Transform, TransformType,
  UnmergeThemeTransform,
  apply,
  collectIds,
  undo
} from 'components/ThemeEditor/theme-file-transforms';
import { ThemeLookup, repair, validate } from 'components/ThemeEditor/theme-file-validation';
import * as workerPath from 'file-loader?name=[name].[hash].js!components/ThemeComments/BatchCommentExtractor.worker';
import analytics from 'lib/analytics';
import { ErrorData, Fetchable } from 'lib/fetch';
import { ThemesObject } from 'lib/filters/theme-filter-helper';
import { ThemeGroup, ThemesJson, parse } from 'lib/theme-file-parser';
import {
  cloneDeep,
  debounce,
  filter,
  find,
  flatten,
  forEach,
  get,
  has,
  indexOf,
  isArray,
  isEmpty,
  last,
  map,
  max,
  pick,
  reduce,
  reduceRight,
  snakeCase,
  sortBy,
  startsWith,
  uniqBy,
  without,
  zipObject
} from 'lodash';
import { IReactionDisposer, action, autorun, computed, observable } from 'mobx';
import * as moment from 'moment';
import { stringify } from 'query-string';
import thematicData from 'vue/libs/thematicData';
import { NotificationStoreInterface } from './NotificationStore';
import { SurveyStoreInterface } from './SurveyStore';
import { ThemeEditorSessionStoreInterface } from './ThemeEditorSessionStore';
import { PlainComment } from 'types/custom';
import { toDataSourceUrl } from './surveys/config-parser';
import { getAllCommentsText } from 'lib/comment-helpers';

export interface RevertItem {
  id: number;
  label: string;
  updatedBy: string;
}

export enum InputName {
  END_DATE = 'endDate',
  START_DATE = 'startDate'
}

export enum SortType {
  Name = 'Name',
  Frequency = 'Frequency',
  New = 'New',
  Review = 'Review'
}

export interface LiteTreeItem {
  freq: number;
  id: string;
  isNew: boolean;
  title: string;
}
export interface ThemeTreeItem {
  children?: ThemeTreeItem[];
  comments: number;
  expanded?: boolean;
  id: string;
  freq: number;
  hasMergedNew?: boolean;
  isNew: boolean;
  merged: LiteTreeItem[];
  phrases: string[];
  title: string;
  toReview: boolean;
}

export interface ThemeStatusJson {
  status: string;
  responsible?: string;
}

export interface ThemeParameters {
  'APPLY_THEMES_PARAMS.APPLY_CONFIDENCE': number;
  'ROW_FILTERING_DEFINITION': string;
}

function parseError(e?: ErrorData) {
  const fallback = 'Uh oh! Something went wrong. Please try again later.';
  if (!e) {
    return fallback;
  }
  try {
    return e.message || fallback;
  } catch (e) {
    return fallback;
  }
}

interface LiteTheme {
  children?: LiteTheme[];
  id: string;
  title: string;
}

interface EditorState {
  activeNodeIds: (string | undefined)[];
  activeNodeTitles: string[];
  expansions: { [id: string]: boolean };
  sorts: { [id: string]: string[] };
}

interface MappedPhraseMatch {
  id: string;
  phrase: string;
  start: number;
  end: number;
}

function getUrl(
  orgId: string,
  surveyId: string,
  suffix: string,
  params?: { [key: string]: string | number | boolean }
) {
  if (!params) {
    params = {};
  }
  params.organization = orgId;
  const paramsString = stringify(params);
  return `/survey/${ surveyId }/${ suffix }?${ paramsString }`;
}

function getFormData(json: object): FormData {
  const blob = new Blob([JSON.stringify(json)]);
  const formData = new FormData();
  formData.append('file', blob);
  return formData;
}

function getDraftBody(json: object): string {
  const contents = JSON.stringify(json, null, 2);
  return JSON.stringify({ contents });
}

async function updateThemes(
  token: string,
  surveyId: string,
  orgId: string,
  transforms: Transform[]
): Promise<Fetchable<{ data: ThemeJson }>> {
  const currentThemesUrl = `/survey/${ surveyId }/themes/current/contents?organization=${ orgId }`;
  const uploadCurrentThemesUrl = `/survey/${ surveyId }/upload_themes`;

  try {
    const themeResult = await auth.fetch<ThemeJson>(currentThemesUrl);

    if (!themeResult.ok) {
      throw themeResult.error;
    }

    const currentThemes: ThemesJson = JSON.parse(themeResult.data?.contents ?? '');

    const transformedThemes = transforms.reduce((acc, tx) => apply(acc, tx), currentThemes);

    const uploadResult = await auth.fetch<{ data: ThemeJson }>(uploadCurrentThemesUrl, {
      body: getFormData(transformedThemes),
      headers: { 'Authorization': `Bearer ${ token }` },
      method: 'POST'
    });

    if (!uploadResult.ok) {
      throw uploadResult.error;
    }

    return uploadResult;

  } catch (e) {
    return e;
  }

}

async function updateDraft(surveyId: string, orgId: string, transforms: Transform[]): Promise<void> {
  const draftThemesUrl = `/survey/${ surveyId }/themes/draft/contents?organization=${ orgId }`;
  const saveDraftThemeUrl = `/survey/${ surveyId }/themes/draft?organization=${ orgId }`;

  try {
    const draftThemesResult = await auth.fetch<ThemeJson>(draftThemesUrl);

    if (!draftThemesResult.ok && draftThemesResult.status === 400) {
      // No draft exists, nothing more to do.
      return Promise.resolve();
    }
    if (!draftThemesResult.ok) {
      throw draftThemesResult.error;
    }

    const draftThemes: ThemesJson = JSON.parse(draftThemesResult.data?.contents ?? '');

    const transformedDraft = transforms.reduce((acc, tx) => apply(acc, tx), draftThemes);

    const saveResult = await auth.fetch<{ data: ThemeJson }>(saveDraftThemeUrl, {
      body: getDraftBody(transformedDraft),
      method: 'PUT'
    });

    if (!saveResult.ok) {
      throw saveResult.error;
    }

    return;

  } catch (e) {
    return e;
  }

}

/**
  * Flip the map to make it easier to find phrases by theme id
  */
function flipMap(map: ThemesJson['map']): Map<string, string[]> {
  return Object.entries(map).reduce((acc, [key, value]) => {
    if (!acc.has(value)) {
      acc.set(value, []);
    }
    const existing = acc.get(value);
    existing.push(key);
    acc.set(value, existing);
    return acc;
  }, new Map());
}

export interface ThemesStoreInterface {
  addPhrases: (options: {
    group: ThemeGroup,
    node: ThemeTreeItem,
    toThemeId: string,
    phrases: string[]
  }) => void;
  apply: () => Promise<void>;
  applyTransform: (transform: Transform) => void;
  markThemesAsReviewed: () => void;
  checkStatus: () => Promise<void>;
  poll: <T>(url: string,
    onData: (data: T) => void,
    shouldRequeue: (data: T) => boolean,
    after: () => void
  ) => Promise<void>;

  pollStatus: (surveyId: string) => void;
  canRequestStatus: boolean;
  cancelStatusPoll: () => void;
  statusTimeoutId: ReturnType<typeof setTimeout> | undefined;
  statusAbortController: AbortController | undefined;
  titles: { [key: string]: string };

  status: string;
  themeAppliedBy: string;
  themeApplies: ThemeJson[];
  findParentId: (groupId: string, id: string) => string | undefined;
  findNodeById: (id: string) => ThemeTreeItem | undefined;
  getExampleComments: (orgId?: string, surveyId?: string) => Promise<void>;
  getThemeParameters: () => Promise<void>;
  initThemesHierarchy: (requestOptions: any, commentColumns: CommentColumn[]) => Promise<void>;
  resetHierarchy: () => void;
  getPhrasesByThemeId: (themeId: string) => string[];

  setThemeParameter: (key: string, value: number | string) => void;
  applyThemeParameters: () => Promise<boolean>;
  getThemes: (opts: { orgId: string, surveyId: string, hasDraft?: boolean, watch?: boolean }) => Promise<void>;
  saveDraft: (orgId: string, surveyId: string) => Promise<void>;
  receiveThemes: (s: string) => void;
  deleteTheme: (group: ThemeGroup, id: string, parentId?: string) => void;
  countComments: () => void;
  countNewThemes: () => void;
  deletePhrases: (
    group: ThemeGroup,
    node: ThemeTreeItem,
    phrase: string[]
  ) => void;
  mergeTheme: (group: ThemeGroup, id: string, targetId: string) => void;
  moveTheme: (
    group: ThemeGroup,
    id: string,
    targetId: string | undefined
  ) => void;
  addTheme: (group: ThemeGroup,
    title: string,
    parentId?: string,
    phrases?: string[],
    starTheme?: boolean) => ThemeTreeItem | undefined;
  getActiveNode: (group: ThemeGroup) => ThemeTreeItem | undefined;
  updateThemeTitle: (group: ThemeGroup) => void;
  resetFromThemeFile: () => void;
  unmerge: (group: ThemeGroup, id: string) => void;
  redoTransform: () => void;
  undoTransform: () => void;
  unwatch: () => void;
  watch: () => void;
  watchUnsaved: () => void;
  revert: (index: number) => Promise<void>;
  expandGroup: (group: ThemeGroup) => void;
  collapseGroup: (group: ThemeGroup) => void;
  getContents: () => string | undefined;
  toMergeItems: (node: ThemeTreeItem) => LiteTreeItem[];

  getMergedActiveThemes: (groupId: string) => LiteTheme[];
  toggleThemeInfo: (toggle: boolean) => void;

  startTime: number;
  getMappedPhraseInComment: (comment: string, id: string) => MappedPhraseMatch[] | undefined;

  evaluateThemes: (surveyId: string, abortController?: AbortSignal) => Promise<ThemeQualityDetails | undefined>;
  refreshMappedPhrases: (surveyId: string, filter: string, sampleSize: number, abortSignal?: AbortSignal) =>
    Promise<RefreshMappedPhrasesSuggestions | undefined>;
  reorganizeStructure: (surveyId: string, abortSignal?: AbortSignal) => Promise<void>;

  getThemesStatusOnce: (surveyId: string) => Promise<string>;

  handleInlineThemeApplication: () => Promise<void>;
  getStatus: (surveyId: string) => Promise<void>;

  allPhrases: string[];

  sorts: { [key: string]: SortType | null };

  trackCommentCounts: boolean;
  editingGroup?: ThemeGroup;
  editingGroupId?: string;
  restoreEditorState: (state: EditorState) => void;
  getEditorState: () => EditorState;
  hasGroups: boolean;
  exampleCommentsError: boolean;
  exampleCommentsLoading: boolean;
  exampleCommentsLoadedFor: {
    orgId: string;
    surveyId: string;
    columns: string | undefined,
  };
  exampleComments: PlainComment[] ;
  commentCounts: { [key: string]: number };
  commentCountMax: number;
  commentCounting: boolean;

  parametersError: boolean;
  parametersLoading: boolean;
  parameters: ThemeParameters;

  themesHierarchy: ThemesObject | null;
  isLoadingThemesHierarchy: boolean;

  orgId?: string;
  surveyId?: string;
  hasDraft?: boolean;
  hasDraftThemes: boolean;
  recentlyUnmergedIds: string[];

  enqueue: (fn: ThemesStoreInterface['checkStatus']) => void;

  saving: boolean;
  saveError: boolean;
  lastSaved?: string;
  uploading: boolean;
  applying: boolean;
  discovering: boolean;
  reverting: boolean;
  working: boolean;
  loading: boolean;
  errorMessage?: string;

  groups: ThemeGroup[];

  themeFile: ThemesJson | undefined;
  transforms: Transform[];
  undone: Transform[];

  unsaved: Transform[];
  unreverted: Transform[];
  unsavedCount: number;
  hasNewThemes: boolean;

  revertItems: RevertItem[];
  loadErrors: string[];
  validationErrors: string[];

  showThemeInfo: boolean;

  evaluatingThemeQuality: boolean;
  evaluatingThemeQualityError?: string;

  refreshingMappedPhrases: boolean;
  refreshingMappedPhrasesError?: string;

  reorganizingStructure: boolean;
  reorganizingStructureError?: string;
}

function onThemeStatus(this: ThemesStoreInterface, data: ThemeStatusJson): void {
  switch (data.status) {
    case 'applying':
    case 'applying concepts': {
      this.applying = true;
      break;
    }
    case 'discovering': {
      this.discovering = true;
      break;
    }
    default: break;
  }
}

function isPending(data: ThemeStatusJson): boolean {
  return data.status !== 'ready';
}

function sortItems(
  items: ThemeTreeItem[],
  sorts: string[] | undefined,
  defaultIndex: number
) {
  if (!sorts) {
    return items;
  }
  return sortBy(items, n => {
    const index = indexOf(sorts, n.id);
    if (index >= 0) {
      return index;
    } else {
      if (defaultIndex >= 0) {
        return defaultIndex + 0.5;
      } else {
        return items.length;
      }
    }
  });
}

class ThemesStore implements ThemesStoreInterface {
  surveyStore: SurveyStoreInterface;
  notificationStore: NotificationStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;

  startTime: number = Date.now();

  workers: Worker[] = [];

  validationLookups: ThemeLookup = {};
  canRequestStatus = true;

  @observable
  recentlyUnmergedIds: string[] = [];

  @observable
  prevStatus = 'ready';

  @observable
  status = 'ready';

  @observable
  applying = false;

  @observable
  uploading = false;

  @observable
  discovering = false;

  @observable
  saving = false;

  @observable
  saveError = false;

  @observable
  lastSaved: string | undefined = undefined;

  @observable
  loading = false;

  @observable
  reverting = false;

  @observable
  titles: { [key: string]: string } = {};
  @observable
  errorMessage: string | undefined = undefined;

  @observable
  themeAppliedBy = '';

  @observable
  themeApplies: ThemeJson[] = [];

  @observable
  themeFile: ThemesJson | undefined;

  @observable
  themesHierarchy: ThemesObject | null = null;

  @observable
  isLoadingThemesHierarchy = false;

  @observable
  groups = [] as ThemeGroup[];

  @observable
  trackCommentCounts = false;

  @observable
  hasNewThemes: boolean;

  @observable
  showThemeInfo = false;

  @observable
  evaluatingThemeQuality = false;

  @observable
  evaluatingThemeQualityError: string | undefined = undefined;

  @observable
  refreshingMappedPhrases = false;

  @observable
  refreshingMappedPhrasesError: string | undefined = undefined;

  @observable
  reorganizingStructure = false;

  @observable
  reorganizingStructureError: string | undefined = undefined;

  @computed
  get phraseMap() {
    return reduce(
      this.groups,
      (result, group) => {
        forEach(group.nodes, node => {
          result[node.id] = node.phrases;
          forEach(node.children, (subtheme: ThemeTreeItem) => {
            result[subtheme.id] = subtheme.phrases;
          });
        });
        return result;
      },
      {} as { [key: string]: string[] }
    );
  }

  @computed
  get allPhrases() {
    return reduce(
      this.groups,
      (result, group) => {
        forEach(group.nodes, node => {
          result = result.concat(node.phrases);
          forEach(node.children, (subtheme: ThemeTreeItem) => {
            result = result.concat(subtheme.phrases);
          });
        });
        return result;
      },
      [] as string[]
    );
  }

  @observable
  transforms: Transform[] = [];

  @observable
  undone: Transform[] = [];

  @observable
  orgId?: string = undefined;

  @observable
  hasDraft?: boolean = undefined;

  @observable
  surveyId?: string = undefined;

  @observable
  sorts: { [key: string]: SortType | null } = {};

  @observable
  editingGroupId: string | undefined = undefined;

  @computed
  get editingGroup() {
    const { editingGroupId, groups } = this;
    return find(groups, group => group.id === editingGroupId);
  }

  set editingGroup(group: ThemeGroup | undefined) {
    if (group) {
      this.editingGroupId = group.id;
      this.getExampleComments();
    } else {
      this.editingGroupId = undefined;
    }
  }

  @observable
  exampleCommentsLoadedFor = {
    orgId: '',
    surveyId: '',
    columns: '' as string | undefined,
  };

  @observable
  exampleCommentsError = false;

  @observable
  exampleCommentsLoading = false;

  @observable
  exampleComments: PlainComment[]  = [];

  @observable
  commentCounts: { [key: string]: number } = {};

  @observable
  commentCounting = false;

  @computed
  get commentCountMax() {
    const lengths = map(this.commentCounts, len => len);
    return max(lengths) || 0;
  }

  @observable
  parametersError = false;

  @observable
  parametersLoading = false;

  @observable
  parameters = { 'APPLY_THEMES_PARAMS.APPLY_CONFIDENCE': 0.5, 'ROW_FILTERING_DEFINITION': '{}' };

  @observable
  loadErrors: string[] = [];

  @observable
  validationErrors: string[] = [];

  disposers: IReactionDisposer[] = [];

  enqueue = debounce(fn => fn(), 15 * 1000);

  @observable
  statusTimeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

  @observable
  statusAbortController: AbortController | undefined = undefined;

  @computed
  get hasDraftThemes() {
    const lastApply = last(this.themeApplies);
    if (lastApply) {
      return lastApply.type === 'draft';
    } else {
      return false;
    }
  }

  constructor(
    surveyStore: SurveyStoreInterface,
    notificationStore: NotificationStoreInterface,
    themeEditorSessionStore: ThemeEditorSessionStoreInterface,
  ) {
    this.surveyStore = surveyStore;
    this.notificationStore = notificationStore;
    this.themeEditorSessionStore = themeEditorSessionStore;
  }

  @action
  initThemesHierarchy = async (requestOptions: any, commentColumns: CommentColumn[]) => {
    this.isLoadingThemesHierarchy = true;
    const hierarchy = await thematicData.getThemeHierarchy(commentColumns, requestOptions);
    this.isLoadingThemesHierarchy = false;
    this.themesHierarchy = hierarchy;
  };

  @action
  resetHierarchy() {
    this.themesHierarchy = null;
  }

  @action
  getExampleComments = async (orgId?: string, surveyId?: string) => {
    orgId = orgId ? orgId : this.orgId;
    surveyId = surveyId ? surveyId : this.surveyId;

    if (!orgId || !surveyId) {
      return;
    }

    let columnsArray = [0];
    const analysis = await this.surveyStore.getSurvey(surveyId);
    if (analysis) {
      const configuration = JSON.parse(analysis.configuration);
      if (configuration && "comment_columns" in configuration) {
        columnsArray = configuration["comment_columns"];
        if (!Array.isArray(columnsArray)) {
            columnsArray = [columnsArray];
        }
      }
    }

    const columns = columnsArray.join(",");
    // no need to keep pulling example comments constantly
    if (this.exampleCommentsLoadedFor.orgId === orgId &&
      this.exampleCommentsLoadedFor.surveyId === surveyId &&
      this.exampleCommentsLoadedFor.columns === columns) {
      return;
    }

    this.exampleCommentsError = false;
    this.exampleCommentsLoading = true;
    this.exampleComments = [];

    try {
      const filter = '';
      const requestOptions = {
        dataSource: `${toDataSourceUrl(surveyId, '_')}/` ,
      };
      const pagination = { page: 0, pageSize: 1000 };
      const flatten = true;

      const comments: PlainComment[]  = await thematicData.getComments({
        filter,
        commentColumns: null,
        requestOptions,
        pagination,
        flatten
      });

      if (comments) {
        this.exampleComments = uniqBy(comments, comment => comment.comment);

        this.exampleCommentsLoadedFor = { orgId, surveyId, columns };

        this.countComments();
      } else {
        this.exampleCommentsError = true;
      }
    } catch (e) {
      this.exampleCommentsError = true;
    } finally {
      this.exampleCommentsLoading = false;
    }
  };

  @action
  getThemeParameters = async () => {
    const { orgId, surveyId } = this;

    if (!orgId || !surveyId) {
      return;
    }

    const url = getUrl(orgId, surveyId, 'themes/parameters');
    this.parametersError = false;
    this.parametersLoading = true;

    try {
      const { data, ok } = await auth.fetch<{ parameters: ThemeParameters }>(url);
      if (data && ok) {
        this.parameters = data.parameters;
      } else {
        this.parametersError = true;
      }
    } catch (e) {
      this.parametersError = true;
    } finally {
      this.parametersLoading = false;
    }
  };

  @action
  setThemeParameter = (key: string, value: number | string) => {
    this.parameters[key] = value;
  }

  @action
  poll = async <T>(
    surveyId: string,
    onData: (data: T) => void,
    shouldRequeue: (data: T) => boolean,
    after: () => void
  ) => {
    const url = `/survey/${ surveyId }/themes/status`;

    const surveyHasUpdated = surveyId !== this.surveyId;

    if (surveyHasUpdated) {
      return;
    }

    try {
      const { data, ok } = await auth.fetch<T>(url);
      if (data && ok) {

        onData(data);

        if (!surveyHasUpdated && shouldRequeue(data)) {
          this.enqueue(() => this.poll(surveyId, onData, shouldRequeue, after));
        } else {
          after();
        }
      }
    } catch (e) {
      return e;
    }

  };

  @action getStatus = async (surveyId: string) => {
    const url = `/survey/${ surveyId }/themes/status`;
    const response = await auth.fetch<ThemeStatusJson>(url);

    this.canRequestStatus = response.ok;

    if (response.ok && response.data) {
      const nextStatus = response.data.status;

      if (response.data.responsible) {
        this.themeAppliedBy = response.data.responsible;
      }

      if (nextStatus !== this.prevStatus) {
        this.prevStatus = this.status;
        this.status = nextStatus;
        onThemeStatus.call(this, response.data);
      }

    }
  }

  @action
  getThemesStatusOnce = async (surveyId: string) => {
    const url = `/survey/${ surveyId }/themes/status`;
    const response = await auth.fetch<ThemeStatusJson>(url);

    if (response.ok && response.data) {
      return response.data.status;
    }
    return '';
  }

  @action
  pollStatus = (surveyId: string) => {
    this.canRequestStatus = true;
    this.getStatus(surveyId);
    this.statusTimeoutId = setInterval(async () => {
      if (!this.canRequestStatus) {
        this.cancelStatusPoll();
        return;
      }
      this.getStatus(surveyId);
    }, 15 * 1000);

    const disposer = autorun(() => {

      // we are now applying
      if (this.status === 'applying' && this.prevStatus !== 'applying') {

        this.pushApplyingNotification(surveyId, this.themeAppliedBy);
        return;
      } else if (this.status !== 'applying' && this.prevStatus === 'applying') {
        this.notificationStore.dismiss(this.applyingNotificationId(surveyId));
      }
      // we are now ready
      if (this.status === 'ready' && this.prevStatus !== 'ready') {
        const id = `updated-data-available-${ surveyId }`;
        this.notificationStore.push({
          preventClose: true,
          id,
          title: 'Update available',
          message: this.themeAppliedBy ? `by ${ this.themeAppliedBy }` : '',
          link: true,
          icon: 'THEME_EDIT_GREEN',
          actionText: 'View data',
          action: () => {
            this.notificationStore.dismiss(id);
            window.location.reload();
          },
        });
        return;
      }

    });

    this.disposers.push(disposer);

  };

  @action
  cancelStatusPoll = () => {
    if (this.statusTimeoutId) {
      clearTimeout(this.statusTimeoutId);
      this.statusTimeoutId = undefined;
    }
    if (this.statusAbortController) {
      this.statusAbortController.abort();
      delete this.statusAbortController;
    }

    this.notificationStore.dismiss(this.applyingNotificationId(this.surveyId));
    this.unwatch();
  };

  @action
  checkStatus = async () => {
    if (!this.orgId || !this.surveyId) {
      this.enqueue(this.checkStatus);
      return;
    }

    const orgId = this.orgId as string;
    const surveyId = this.surveyId as string;

    await this.poll<ThemeStatusJson>(
      surveyId,
      onThemeStatus.bind(this),
      isPending.bind(this),
      async () => {

        // now get the historical list of applies. This populates the themes that can be reverted to list
        const url = `/survey/${ surveyId }/themes?organization=${ orgId }`;

        const { data: applies, ok } = await auth.fetch<ThemeJson[]>(url);
        if (applies && ok) {
          this.themeApplies = applies;
        }

        if (this.applying || this.discovering) {
          await this.getThemes({
            orgId,
            surveyId,
            hasDraft: this.hasDraftThemes,
            watch: true
          });
        }
        this.applying = false;
        this.discovering = false;

      }
    );

  };
  expandGroup = (group: ThemeGroup) => {
    forEach(group.nodes, n => {
      n.expanded = true;
    });
  };
  collapseGroup = (group: ThemeGroup) => {
    forEach(group.nodes, n => {
      n.expanded = false;
    });
  };
  @computed
  get working() {
    return (
      this.applying ||
      this.discovering ||
      this.loading ||
      this.reverting ||
      this.saving ||
      this.uploading
    );
  }

  @action
  applyThemeParameters = async () => {
    const { orgId, surveyId } = this;
    if (!orgId || !surveyId) {
      this.parametersError = true;
      this.errorMessage = 'Can\'t find survey';
      return false;
    }
    // Get the analysis tool target to include in the completion email

    const analysis = await this.surveyStore.getSurvey(surveyId);

    if (!analysis) {
      this.parametersError = true;
      this.errorMessage = 'Can\'t find the correct analysis';
      return false;
    }
    const surveyTarget = new URL(
      `#${ analysis.url }`,
      window.location.href
    ).toString();

    const url = getUrl(orgId, surveyId, 'themes/parameters/apply');

    const { data, ok, errorData } = await auth.fetch<ThemeJson>(url, {
      body: JSON.stringify({ surveyTarget, parameters: this.parameters }),
      method: 'POST'
    });
    if (data && ok) {
      this.parametersError = false;
      this.applying = true;
    } else {
      this.parametersError = true;
      this.errorMessage = parseError(errorData);
    }
    analytics.track('Themes Editor: Apply parameters', {
      category: 'Themes Editor',
      survey: surveyId
    });

    return ok;
  }

  @action
  apply = async () => {
    const { orgId, surveyId, unsavedCount } = this;
    if (!orgId || !surveyId) {
      return;
    }
    const analysis = await this.surveyStore.getSurvey(surveyId);

    if (!analysis) {
      return;
    }

    const surveyTarget = new URL(
      `#${ analysis.url }`,
      window.location.href
    ).toString();

    if (unsavedCount > 0) {
      await this.saveDraft(orgId, surveyId);
    }

    const url = getUrl(orgId, surveyId, 'themes/draft/apply');

    const { data, ok, errorData } = await auth.fetch<ThemeJson>(url, {
      body: JSON.stringify({ surveyTarget }),
      method: 'POST'
    });
    if (data && ok) {
      this.applying = true;
    } else {
      this.errorMessage = parseError(errorData);
    }

    const survey = this.surveyStore.surveys.find(s => s.surveyId === surveyId);
    const sessionId = this.themeEditorSessionStore.currentSessionId;
    const sessionDuration = sessionId ? this.themeEditorSessionStore.getSessionDuration(sessionId) : null
    const operationCount = this.transforms.length;
    const fileCreatedAt = data?.created ?? '';
    const surveyState = survey?.surveyStatus ?? '';

    const info = {
      operationCount,
      fileCreatedAt,
      surveyState,
      sessionDuration
    };

    analytics.track('Themes Editor: Apply themes', {
      category: 'Themes Editor',
      value: Date.now() - this.startTime,
      survey: surveyId,
      ...info
    });

    this.hasDraft = false; // assume hasDraft is false
    await this.surveyStore.fetchSurveys(); // update the draft status for surveys
    this.hasDraft = this.surveyStore.hasDraft(surveyId);
    await this.getThemes({
      orgId,
      surveyId,
      hasDraft: !!this.hasDraft,
      watch: true
    });
  };
  @action
  revert = async (id: number) => {
    const { hasDraft: hasDraft, orgId, surveyId } = this;
    if (orgId === undefined || surveyId === undefined) {
      return;
    }
    const url = getUrl(orgId, surveyId, 'themes/draft');
    const urlFetch = getUrl(orgId, surveyId, `themes/${ id }/contents`);

    this.reverting = true;
    try {
      this.transforms = [];
      this.undone = [];
      if (hasDraft) {
        await auth.fetch(url, {
          method: 'DELETE'
        });
      }
      if (id !== -1) {
        this.hasDraft = true;
        // Get new contents
        const { data, ok } = await auth.fetch<{ contents: string }>(urlFetch);

        if (ok && data) {
          const { contents } = data;
          this.receiveThemes(contents);
          this.watchUnsaved();
          await this.saveDraft(orgId, surveyId);
        }
      } else {
        this.hasDraft = false;
        await this.getThemes({
          orgId,
          surveyId,
          hasDraft: false,
          watch: true
        });
      }
      this.surveyStore.fetchSurveys();
    } catch (e) {
      return e;
    } finally {
      this.reverting = false;
    }
  };

  @action
  markThemesAsReviewed = () => {

    const transform: MarkAllAsReviewedTransform = {
      type: TransformType.MarkAllAsReviewed,
      groupId: '',
      message: { prefix: `Marked themes as reviewed` },
      themesUnmarkedFromNew: undefined,
      themesUnmarkedFromReview: undefined
    };
    this.applyTransform(transform);
  }

  @action
  markSaved = (toSave: Transform[]) => {
    forEach(toSave, t => (t.persisted = true));
  };
  watch = () => {
    const statusCheck = autorun(() => {
      const { applying, discovering } = this;
      if (applying || discovering) {
        this.enqueue(this.checkStatus);
      }
    });

    this.disposers.push(statusCheck);
  };
  unwatch = () => {
    while (this.disposers.length) {
      const disposer = this.disposers.pop();
      if (disposer) {
        disposer();
      }
    }
  };
  watchUnsaved = () => {
    // save to local storage when there's a change
    const disposer = autorun(() => {
      const { unsaved } = this;

      const unsavedKey = snakeCase(`${ location.hash } unsaved transforms`);
      localStorage[unsavedKey] = JSON.stringify(unsaved);

      this.hasDraft = this.hasDraft || !!unsaved.length;
    });
    this.disposers.push(disposer);
  };

  @computed
  get revertItems() {
    const { themeApplies } = this;

    const take = 4;
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };

    return reduceRight(
      themeApplies,
      (result, themeApply) => {
        const { id, last_applied, last_updated_by: updatedBy } = themeApply;
        if (last_applied && result.length < take) {
          const date = new Date(last_applied);
          const label = date.toLocaleTimeString('en-us', options);

          result.push({ id, label, updatedBy });
        }
        return result;
      },
      [] as RevertItem[]
    );
  }

  @computed
  get unsavedCount() {
    const { unreverted, unsaved } = this;
    return [...unreverted, ...unsaved].length;
  }
  @computed
  get unsaved(): Transform[] {
    const { transforms } = this;
    return filter(transforms, t => !t.persisted);
  }
  @computed
  get unreverted(): Transform[] {
    const { undone } = this;
    return filter(undone, t => !t.persisted);
  }
  @computed
  get hasGroups(): boolean {
    if (this.groups) {
      return !!this.groups.length;
    } else {
      return false;
    }
  }

  getMergedActiveThemes(groupId: string) {
    const group = find(this.groups, { id: groupId });
    if (group) {
      return sortBy(
        flatten(
          map(group.nodes, n => {
            const { id, title } = n;

            const children = map(n.children as ThemeTreeItem[], c => {
              return {
                id: c.id,
                title: String(c.title)
              };
            });

            return {
              children: sortBy(children, 'title'),
              id,
              title: String(title)
            };
          })
        ),
        'title'
      );
    }
    return [];
  }

  flatten = (node: ThemeTreeItem): ThemeTreeItem[] => {
    const { children = [] } = node;
    return [node, ...(children as ThemeTreeItem[])];
  };
  getActiveNode = (group: ThemeGroup): ThemeTreeItem | undefined => {
    const { activeNodeId } = group;
    if (!activeNodeId) {
      return undefined;
    }
    return this.findNodeById(activeNodeId);
  };
  toMergeItems = (node: ThemeTreeItem) => {
    const alreadyMerged = this.collectMerged(node);
    const nodes = this.flattenToLite(node);
    return [...alreadyMerged, ...nodes];
  };
  collectMerged = (node: ThemeTreeItem): LiteTreeItem[] => {
    const merged: LiteTreeItem[] = node.merged;
    forEach(node.children, (n: ThemeTreeItem) => {
      merged.push(...n.merged);
    });
    return merged;
  };
  flattenToLite = (node: ThemeTreeItem): LiteTreeItem[] => {
    return map(this.flatten(node), this.mapToLite);
  };
  mapToLite = (node: ThemeTreeItem): LiteTreeItem => {
    const { freq, id, title } = node;
    return { freq, id, isNew: has(node, 'new'), title: String(title) };
  };
  getUniqueId = (title: string) => {
    const { titles } = this;

    const baseId = snakeCase(title);
    let id = baseId;

    let count = 1;

    while (has(titles, id)) {
      id = `${ baseId }${ count++ }`;
    }

    return id;
  };

  @computed
  get nodes(): ThemeTreeItem[] {
    const nodes = [] as ThemeTreeItem[];

    forEach(this.groups, group => {
      return forEach(group.nodes, node => {
        nodes.push(node);
        const children = node.children as ThemeTreeItem[];
        if (children) {
          nodes.push(...children);
        }
      });
    });

    return nodes;
  }

  findParentId = (groupId: string, id: string): string | undefined => {
    const { validationLookups } = this;
    const parentId = validationLookups[groupId][id];

    if (startsWith(parentId, '__')) {
      return undefined;
    } else {
      return parentId;
    }
  };

  findNodeById = (id: string): ThemeTreeItem | undefined => {
    return find(this.nodes, node => node.id === id);
  };

  getEditorState = () => {
    const { groups } = this;
    const sorts = {} as { [id: string]: string[] };
    const expansions = reduce(
      groups,
      (result, group) => {
        forEach(group.nodes, ({ children, id, expanded }) => {
          result[id] = expanded;
          sorts[''] = sorts[''] || [];
          sorts[''].push(id);
          if (children && children.length) {
            sorts[id] = map(children, 'id');
          }
        });
        return result;
      },
      {}
    );
    const activeNodeIds = map(groups, 'activeNodeId');
    const activeNodeTitles = map(groups, 'proposedNodeTitle');
    return { activeNodeIds, activeNodeTitles, expansions, sorts };
  };
  restoreEditorState = (state: EditorState) => {
    const { groups } = this;
    const { activeNodeIds, activeNodeTitles, expansions, sorts } = state;
    forEach(groups, (group, i) => {
      group.activeNodeId = activeNodeIds[i];
      group.proposedNodeTitle = activeNodeTitles[i];

      const themeSorts = sorts[''];

      // sort themes
      const activeNodeIndex = indexOf(themeSorts, group.activeNodeId);
      group.nodes = sortItems(group.nodes, themeSorts, activeNodeIndex);
      forEach(group.nodes, node => {
        node.expanded = expansions[node.id];

        // sort subthemes
        if (node.children) {
          node.children = sortItems(
            node.children,
            sorts[node.id],
            activeNodeIndex
          );
        }
      });
    });
  };

  validateThemefile = (lite?: boolean) => {
    const validation = validate(this.themeFile, lite);

    // if there are errors try & repair them, then use new values
    if (validation.errors.length) {
      this.themeFile = repair(this.themeFile, validation.lookups);
      // reset to file state after repair!
      this.resetFromThemeFile();
      return validate(this.themeFile, lite);
    } else {
      return validation;
    }
  };
  @action
  applyTransform = (transform: Transform) => {
    const { themeFile } = this;
    if (!themeFile) {
      return;
    }
    this.themeFile = apply(themeFile, transform);
    transform.persisted = false;
    this.transforms = [...this.transforms, transform]; // A new reference that mobx can react to
    this.undone = [];

    const validation = this.validateThemefile(true);
    this.validationErrors = validation.errors;
    this.validationLookups = validation.lookups;

    // after any transformation we should reset the tree structure
    // this is to ensure it matches what would be presented on page reload
    // we also do this on undo/redo
    this.resetFromThemeFile();
  };
  resetFromThemeFile = () => {
    const { themeFile } = this;
    if (!themeFile) {
      return;
    }
    const { groups, titles } = parse(themeFile);
    const expanded = this.getEditorState();
    this.groups = groups;
    this.restoreEditorState(expanded);
    this.titles = titles;
    this.countComments();
    this.countNewThemes();
  };
  @action
  redoTransform = () => {
    const { themeFile } = this;
    if (!themeFile) {
      return;
    }
    const { transforms, undone } = this;
    const transform = undone.pop();
    if (transform) {
      this.themeFile = apply(themeFile, transform);
      transform.persisted = false;
      transforms.push(transform);
      this.resetFromThemeFile();
    }
  };
  @action
  undoTransform = () => {
    const { themeFile } = this;
    if (!themeFile) {
      return;
    }
    const { transforms, undone } = this;
    const transform = transforms.pop();
    if (transform) {
      this.themeFile = undo(themeFile, transform);
      transform.persisted = false;
      undone.push(transform);
      this.resetFromThemeFile();
      const validation = this.validateThemefile(true);
      this.validationErrors = validation.errors;
      this.validationLookups = validation.lookups;
    }
  };

  /**
   * Update node title
   */
  @action
  updateThemeTitle = (group: ThemeGroup) => {
    const { titles, themeFile } = this;
    const node = this.getActiveNode(group);
    const { proposedNodeTitle: title } = group;
    if (!node || !themeFile) {
      return;
    }

    const fromTitle = titles[node.id];
    // keep the representation consistent
    titles[node.id] = title;

    // update themes file
    const transform: ChangeThemeTitleTransform = {
      fromTitle,
      groupId: group.id,
      message: { prefix: `Changed theme title to "${ title }"` },
      themeId: node.id,
      toTitle: title,
      type: TransformType.ChangeThemeTitle
    };
    this.applyTransform(transform);
  };

  @action
  addPhrases = (options: {
    // Given themeId is merged to another theme 'X', and resolve is true, phrases will
    // be added to theme 'X'
    resolve?: boolean,
    group: ThemeGroup,
    node: ThemeTreeItem,
    // Phrases may be added to a theme *merged* to the node, so
    // node.id is not always the same as toThemeId
    toThemeId: string,
    phrases: string[]
  }) => {
    const {
      resolve = false,
      group,
      node,
      phrases,
      toThemeId,
    } = options;
    const { themeFile } = this;
    if (!themeFile) {
      return;
    }

    // find existing node & remove representation
    let fromThemeId: string | undefined;
    phrases.forEach(phrase => {
      const existingNodeId = themeFile.map[phrase];

      if (existingNodeId) {
        // this will get confused if moving from multiple nodes, don't do that
        fromThemeId = fromThemeId || existingNodeId;
        const existingNode = this.findNodeById(existingNodeId);
        if (existingNode) {
          existingNode.phrases = without(existingNode.phrases, phrase);
        }
      }
    });

    // add new representation
    node.phrases.push(...phrases);

    const tokens = [] as string[];
    if (fromThemeId) {
      tokens.push('Moved');
    } else {
      tokens.push('Added');
    }
    if (phrases.length === 1) {
      tokens.push(`phrase "${ phrases[0] }"`);
    } else {
      tokens.push(`${ phrases.length } phrases`);
    }
    tokens.push(`to`);

    const themeTitle = this.titles[toThemeId] || node.title;

    // update theme file
    const transform: AddPhrasesTransform = {
      fromThemeId,
      groupId: group.id,
      message: { prefix: tokens.join(' '), theme: themeTitle },
      resolve,
      toThemeId,
      phrases,
      type: TransformType.AddPhrases
    };
    this.applyTransform(transform);
  };

  @action
  addTheme = (group: ThemeGroup, title: string, parentId?: string, phrases?: string[], starTheme?: boolean) => {
    const { themeFile } = this;
    if (!themeFile || !themeFile[group.id]) {
      return undefined;
    }

    const id = this.getUniqueId(title);

    // either use the provided phrases, or default to using the title as a phrase
    const mappedPhrases = phrases ? phrases : [title];

    const isNew = !!starTheme;

    // representation work
    this.titles[id] = title; // save into title map
    const newNode = {
      comments: 0,
      freq: 0,
      id,
      merged: [] as LiteTreeItem[],
      isNew,
      phrases: mappedPhrases,
      toReview: false,
      title
    } as ThemeTreeItem;

    if (parentId === undefined) {
      newNode.children = [];
      group.nodes.unshift(newNode);
    } else {
      const parent = this.findNodeById(parentId);
      if (parent && parent.children) {
        parent.expanded = true;
        parent.children.unshift(newNode);
      }
    }
    // once we've added we can't be sorted no more
    this.sorts[group.id] = null;

    group.activeNodeId = id;
    group.proposedNodeTitle = title;

    // data
    const transform: AddThemeTransform = {
      groupId: group.id,
      message: {
        prefix: parentId ? `Added subtheme` : `Added theme`,
        theme: title
      },
      type: TransformType.AddTheme,
      themeId: id,
      title,
      themeIsNew: isNew,
      newMappedPhrases: mappedPhrases
    };

    if (parentId) {
      transform.parentThemeId = parentId;
    }

    this.applyTransform(transform);

    return newNode;
  };

  @action
  deleteTheme = (group: ThemeGroup, id: string) => {
    const { themeFile } = this;
    const node = this.findNodeById(id);
    if (!node || !themeFile || !themeFile[group.id]) {
      return;
    }
    // update data (find all ids for deleted nodes)
    const fileGroup = themeFile[group.id];

    const themeIds = collectIds(fileGroup, [id]);
    const states = map(themeIds, themeId => {
      let state = pick(fileGroup[themeId], ['merged', 'sub_themes']) as any;
      state.parentId = this.findParentId(group.id, id);
      return state;
    });
    const previousState = zipObject(themeIds, states);

    const transform: DeleteThemesTransform = {
      groupId: group.id,
      message: {
        prefix: node.children ? `Deleted theme` : `Deleted subtheme`,
        theme: node.title
      },
      previousState,
      themeIds,
      type: TransformType.DeleteThemes
    };
    this.applyTransform(transform);
  };

  @action
  deletePhrases = (group: ThemeGroup, node: ThemeTreeItem, phrases: string[]) => {

    const { themeFile } = this;
    if (!themeFile) {
      return;
    }
    // representation
    node.phrases = without(node.phrases, ...phrases);

    const formattedPhrasesForMessage = phrases.map(phrase => `"${ phrase }"`);

    // theme file
    const transform: DeletePhrasesTransform = {
      groupId: group.id,
      message: { prefix: `Deleted phrase${ phrases.length > 1 ? 's' : '' } ${ formattedPhrasesForMessage }` },
      phrases,
      themeId: node.id,
      type: TransformType.DeletePhrases
    };

    this.applyTransform(transform);
  };

  @action
  mergeTheme = (group: ThemeGroup, id: string, targetId: string) => {
    const { themeFile } = this;
    if (!themeFile || !themeFile[group.id]) {
      return;
    }

    // data
    const fileGroup = themeFile[group.id];
    const ids = collectIds(fileGroup, [id]);
    const vals = map(
      ids,
      themeId => fileGroup[themeId] && fileGroup[themeId].merged
    );
    const previousMerges = zipObject(ids, vals);

    const theme = fileGroup[id];
    let previousParent;
    if (theme.sub_themes) {
      previousParent = {
        id,
        subthemes: theme.sub_themes
      };
    } else {
      const parentId = this.findParentId(group.id, id);
      const parentTheme = fileGroup[parentId];
      previousParent = {
        id: parentId,
        subthemes: parentTheme.sub_themes
      };
    }
    const mergedNodeTitle = this.titles[id];
    group.activeNodeId = targetId;
    const transform: MergeThemeTransform = {
      groupId: group.id,
      mergeId: id,
      message: { prefix: 'Merged', theme: mergedNodeTitle },
      previousMerges,
      previousParent,
      targetId: targetId,
      type: TransformType.MergeTheme
    };
    this.applyTransform(transform);
  };

  @action
  moveTheme = (group: ThemeGroup, id: string, targetId: string | undefined) => {
    const { themeFile } = this;
    const moved = this.findNodeById(id);
    if (!themeFile || !themeFile[group.id] || !moved) {
      return;
    }
    const fileGroup = themeFile[group.id];

    let parentOrChildren;
    const theme = fileGroup[id];
    const parentId = this.findParentId(group.id, id);
    if (theme.sub_themes) {
      parentOrChildren = [...theme.sub_themes];
    } else {
      parentOrChildren = parentId;
    }

    this.sorts[group.id] = null;

    // we only apply a transform if it's actually a data change
    if (parentId !== targetId) {
      const transform: MoveThemeTransform = {
        groupId: group.id,
        message: { prefix: `Moved`, theme: moved.title },
        moveId: id,
        parentOrChildren,
        targetId: targetId,
        type: TransformType.MoveTheme
      };
      this.applyTransform(transform);
    }
  };

  @action
  reset = () => {
    // zero out state while waiting for stuff to arrive
    this.startTime = Date.now();
    this.themeFile = undefined;
    this.groups = [];
    this.titles = {};
    this.transforms = [];
    this.undone = [];
    this.commentCounts = {};
    this.hasNewThemes = false;
    this.status = 'ready';
    this.prevStatus = 'ready';
    this.recentlyUnmergedIds = [];
    const { workers } = this;
    while (workers.length) {
      const worker = workers.shift();
      if (worker) {
        worker.terminate();
      }
    }
  };
  @action
  getThemes = async (opts: {
    orgId: string,
    surveyId: string,
    hasDraft: boolean,
    watch: boolean
  }) => {
    const { orgId, surveyId, hasDraft, watch } = opts;
    this.errorMessage = undefined;
    this.orgId = orgId;
    this.surveyId = surveyId;
    this.hasDraft = hasDraft;
    this.loading = true;
    this.reset();
    const params = stringify({ organization: orgId });
    const path = hasDraft ? 'draft' : 'current';
    const url = `/survey/${ surveyId }/themes/${ path }/contents?${ params }`;

    try {
      const result = await auth.fetch<ThemeJson>(url);
      const { ok, data: theme, errorData } = result;

      if (theme && ok) {
        const { contents } = theme;

        this.receiveThemes(contents, true);
        if (watch) {
          this.watchUnsaved();
        }
      } else {
        this.errorMessage = parseError(errorData);
      }
    } catch (e) {
      this.errorMessage = e.message;
    } finally {
      this.loading = false;
    }
  };

  @action
  receiveThemes = (s: string, applyUnsaved = false) => {
    // clear validation errors, loadErrors holds these ones
    this.validationErrors = [];
    try {
      const themeFile = JSON.parse(s);

      this.themeFile = themeFile;
      this.trackCommentCounts = Object.keys(themeFile.map).length < 20000;

      const validation = this.validateThemefile();
      this.loadErrors = validation.errors;
      this.validationLookups = validation.lookups;
    } catch (e) {
      this.loadErrors = ['Invalid JSON file.'];
      return;
    }

    if (applyUnsaved) {
      let transforms: Transform[] = [];
      try {
        const unsavedKey = snakeCase(`${ location.hash } unsaved transforms`);
        transforms = JSON.parse(localStorage[unsavedKey]);
      } catch (e) {
        // do nothing, accept local storage might not be there
      }
      if (isArray(transforms)) {
        forEach(transforms, transform => {
          const { persisted } = transform;
          if (persisted) {
            this.transforms.push(transform);
          } else {
            // this also adds it to the array
            this.applyTransform(transform);
          }
        });
      }
    }

    if (this.themeFile) {
      const { groups, titles } = parse(this.themeFile);
      this.groups = groups;
      this.editingGroupId = get(groups, '0.id');
      this.titles = titles;
      this.countComments();
      this.countNewThemes();
    }

  };

  getContents() {
    const { themeFile } = this;
    if (themeFile) {
      return JSON.stringify(themeFile, null, 2);
    } else {
      return undefined;
    }
  }

  getPhrasesByThemeId = (themeId: string): string[] => {
    const { themeFile } = this;
    const map = themeFile?.map ?? {};
    const flippedMap = flipMap(map);

    return flippedMap.get(themeId) ?? [];
  }

  @action
  handleInlineThemeApplication = async () => {

    const token = auth.getToken();
    const surveyId = this.surveyId;
    const orgId = this.orgId;

    if (!surveyId || !orgId) {
      return;
    }

    try {

      this.uploading = true;

      const [updateThemeResult] = await Promise.all([
        updateThemes(token, surveyId, orgId, this.transforms),
        updateDraft(surveyId, orgId, this.transforms)
      ]);

      this.applying = updateThemeResult.ok;

    } catch (e) {
      return e;
    } finally {
      this.uploading = false;
    }

    // push a notification
    this.pushApplyingNotification(surveyId, '');
  }

  @action
  saveDraft = async (orgId: string, surveyId: string) => {
    const contents = this.getContents();

    const url = getUrl(orgId, surveyId, 'themes/draft');

    const body = JSON.stringify({ contents });
    try {
      this.saving = true;
      this.saveError = false;
      const toSave = [...this.unsaved, ...this.undone];
      const { data, ok } = await auth.fetch<{ data: ThemeJson }>(url, {
        body,
        method: 'PUT'
      });
      if (!ok || !data) {
        this.saveError = true;
        return;
      }
      this.markSaved(toSave);

      if (this.validationErrors.length && window.rg4js) {
        const errs = this.validationErrors.join('\n');
        // create a history of the last 5 transforms to help in debugging
        // does not include transform specific information
        const history = map(this.transforms.slice(this.transforms.length - 5), transform => {
          return `${ transform.type }: ${ transform.message.prefix } "${ transform.message.theme }"`;
        }).reverse().join('\n');
        const message = `Theme Editor created an invalid file:\n ${ errs }\n\n History (most recent first):\n ${ history }`;

        window.rg4js('send', new Error(message));
      }

      this.hasDraft = true;
      const time = moment().format('HH:mm');
      this.lastSaved = `All changes saved at ${ time }`;
    } catch (e) {
      this.saveError = true;
    } finally {
      this.saving = false;
    }
  };

  /**
   * Update data and representation
   */
  @action
  unmerge = (group: ThemeGroup, id: string) => {
    const { themeFile } = this;

    const node = this.getActiveNode(group);
    if (!themeFile || !themeFile[group.id] || !node) {
      return;
    }

    // data
    const transform: UnmergeThemeTransform = {
      groupId: group.id,
      message: { prefix: `Unmerged`, theme: themeFile.titles[id] },
      sourceId: node.id,
      mergeId: id,
      type: TransformType.UnmergeTheme
    };

    // Given the theme being unmerged is a "source" theme; the one that others are
    // merged to - we'll need a fallback source theme to replace it.
    if (node.id === id) {

      // The preferred fallback is the merged theme with the shortest title
      const sortedEntries = Object
        .entries(themeFile.themes1)
        .filter(([, theme]) => theme.merged === node.id)
        .sort(([idA], [idB]) => {
          const titleA = themeFile.titles[idA];
          const titleB = themeFile.titles[idB];
          if (titleA.length === titleB.length) {
            return titleA.localeCompare(titleB);
          }
          return titleA.length - titleB.length ? -1 : 1;
        });

      const [[id]] = sortedEntries;

      transform.fallbackId = id;
    }

    this.applyTransform(transform);

    if (transform.fallbackId && this.editingGroup) {
      const node = this.editingGroup.nodes.find(n => n.id === transform.fallbackId);
      if (node) {
        node.expanded = true;
      }
    }

    this.recentlyUnmergedIds = [...this.recentlyUnmergedIds, id];
  };

  countNewThemes = () => {
    // This function counts the number of themes that have the 'new' or 'review' flags set
    let numberNewNodes = 0;
    forEach(this.groups, group => {
      numberNewNodes += reduce(group.nodes, (numNew, node) => {
        if (node.isNew || node.toReview) {
          numNew += 1;
        }
        numNew += reduce(node.children, (numNewSub, subNode) => {
          if (subNode.isNew || subNode.toReview) {
            numNewSub += 1;
          }
          return numNewSub;
        }, 0);
        return numNew;
      }, 0);
    });
    this.hasNewThemes = numberNewNodes > 0;
  }

  countComments = () => {
    const { exampleComments: comments, phraseMap, trackCommentCounts, groups } = this;
    if (!trackCommentCounts) {
      return;
    }

    const nodePhraseMap = cloneDeep(phraseMap);
    if (comments.length && !isEmpty(phraseMap)) {
      this.commentCounting = true;
      const worker = new Worker(workerPath.default);
      worker.onmessage = (ev: MessageEvent) => {
        const result = ev.data as { [key: string]: string[] };

        if (result._finished) {
          this.commentCounting = false;
          worker.terminate();
        } else {
          worker.postMessage({ requestBatch: 10000 });
        }

        const counts = reduce(
          result,
          (memo, strings, key) => {
            if (!/^_/.test(key)) {
              memo[key] = strings.length;
              forEach(groups, group => {
                const parentId = this.findParentId(group.id, key);

                // only update the parent if is included in result
                if (parentId && result[parentId]) {
                  memo[parentId] = (memo[parentId] || 0) + strings.length;
                }
              });
            }
            return memo;
          },
          {} as { [key: string]: number }
        );
        forEach(counts, (val, key) => (this.commentCounts[key] = val));

      };
      worker.postMessage({
        comments: getAllCommentsText(comments),
        nodePhraseMap
      });
      worker.postMessage({ requestBatch: 10000 });
      this.workers.push(worker);
    }
  };

  @action
  toggleThemeInfo = (toggle: boolean) => {
    this.showThemeInfo = toggle;
  }

  @action
  getMappedPhraseInComment = (comment: string, id: string) => {
    const commentText = comment.toLowerCase();
    const node = this.findNodeById(id);
    if (!node) {
      return;
    }
    const results = reduce(node.phrases, (result, phrase) => {
      const loc = commentText.indexOf(phrase);
      if (loc >= 0) {
        result.push({
          id,
          phrase,
          start: loc,
          end: loc + phrase.length
        });
      }
      return result;
    }, [] as MappedPhraseMatch[]);

    return results;
  }

  evaluateThemes = async (surveyId: string, abortSignal?: AbortSignal) => {

    const url = `/survey/${ surveyId }/themes/current/qualityScore`;
    this.evaluatingThemeQuality = true;
    this.evaluatingThemeQualityError = undefined;

    try {
      const { data, ok, errorData } = await auth.fetch<ThemeQualityDetails>(url, {
        method: 'GET',
        signal: abortSignal
      });

      if (!data || !ok) {
        this.evaluatingThemeQualityError = parseError(errorData);

      }
      return data;

    } catch (e) {
      this.evaluatingThemeQualityError = e.message;
    } finally {
      this.evaluatingThemeQuality = false;
    }
    return undefined;
  }

  refreshMappedPhrases = async (surveyId: string, filterRql: string, sampleSize: number, abortSignal?: AbortSignal) => {
    const url = `/survey/${ surveyId }/themes/helpers/refresh_phrases?filter=${ filterRql }&limit=${ sampleSize }`;
    this.refreshingMappedPhrases = true;
    this.refreshingMappedPhrasesError = undefined;

    try {
      const { data, ok, errorData } = await auth.fetch<RefreshMappedPhrasesSuggestions>(url, {
        method: 'GET',
        signal: abortSignal
      });

      if (!data || !ok) {
        this.refreshingMappedPhrasesError = parseError(errorData);

      }
      return data;

    } catch (e) {
      this.refreshingMappedPhrasesError = e.message;
    } finally {
      this.refreshingMappedPhrases = false;
    }
    return undefined;

  }

  reorganizeStructure = async (surveyId: string, abortSignal?: AbortSignal) => {
    const { editingGroupId: themesGroupName } = this;

    if (!themesGroupName) {
      return;
    }

    const url = `/survey/${ surveyId }/themes/recreate`;
    this.reorganizingStructure = true;
    this.reorganizingStructureError = undefined;
    try {
      const { data, ok, errorData } = await auth.fetch(url, {
        body: JSON.stringify({
          themesGroupName
        }),
        method: 'POST',
        signal: abortSignal
      });

      if (!data || !ok) {
        this.reorganizingStructureError = parseError(errorData);
        return;
      }

    } catch (e) {
      this.reorganizingStructureError = e.message;
    } finally {
      await this.checkStatus();
      this.reorganizingStructure = false;
    }
  };

  applyingNotificationId = (surveyId) => {
    return `themes-refresh-in-progress-${ surveyId }`;
  }

  pushApplyingNotification = (surveyId, themeAppliedBy) => {
    this.notificationStore.push({
      id: this.applyingNotificationId(surveyId),
      preventClose: true,
      title: 'Themes refresh in progress',
      message: themeAppliedBy ? `by ${ themeAppliedBy }` : '',
      icon: 'THEME_EDIT_BLUE',
    });
  }

}

export default ThemesStore;
