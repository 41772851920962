import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowAltDown,
  faArrowAltUp,
  faArrowCircleRight,
  faArrows,
  faCalendar,
  faCamera,
  faCaretCircleRight,
  faCaretCircleUp,
  faChartBar,
  faChartLine,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faCloudDownloadAlt,
  faCog,
  faComments,
  faCut,
  faEnvelope,
  faExchangeAlt,
  faExclamationTriangle,
  faFileCode,
  faFilePowerpoint,
  faFlag,
  faFrown,
  faHome,
  faLink,
  faMeh,
  faProjectDiagram,
  faQuestionCircle,
  faSmile,
  faSpinner,
  faStar,
  faTilde,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { Confirm, Segment } from 'semantic-ui-react';
import { SubscriptionStoreInterface } from 'stores/SubscriptionStore';
import { ActiveDashboardUIStoreInterface } from 'stores/ui/ActiveDashboardUIStore';
import { UserStoreInterface } from 'stores/UserStore';
import Vue from 'vue';
import Router from 'vue-router';
import DashboardVue from 'vue/Dashboard.vue';
import { VueInReact } from 'vuera';
import SubscriptionModal from '../Subscription/SubscriptionModal';
import customHistory from 'routing/history';
import { Location } from 'history';

library.add(
  faArrows,
  faChartBar,
  faCalendar,
  faCaretCircleUp,
  faCaretCircleRight,
  faChartLine,
  faEnvelope,
  faFilePowerpoint,
  faFileCode,
  faTimes,
  faCamera,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faCloudDownloadAlt,
  faCog,
  faCut,
  faExchangeAlt,
  faExclamationTriangle,
  faFlag,
  faFrown,
  faHome,
  faLink,
  faMeh,
  faProjectDiagram,
  faQuestionCircle,
  faSmile,
  faSpinner,
  faTilde,
  faArrowAltUp,
  faArrowAltDown,
  faStar,
  faComments,
  faArrowCircleRight,
);

let DashboardComponent = VueInReact();
if (Vue) {
  Vue.component('font-awesome-icon', FontAwesomeIcon);

  Vue.use(Element, { locale });
  Vue.use(Router);

  const router = new Router();
  DashboardComponent = VueInReact({
    router,
    ...DashboardVue,
  });
}

interface DashboardProps {
  orgId: string;
  dashboardId: string;
  hasJustBeenCreated: boolean;
  userStore?: UserStoreInterface;
  subscriptionStore?: SubscriptionStoreInterface;
  activeDashboardUIStore?: ActiveDashboardUIStoreInterface;
}

interface DashboardState {
  blinking: boolean;
  modalVisible: boolean;
  toLocation: any;
  confirmedNavigation: boolean;
}

@inject('subscriptionStore', 'userStore', 'activeDashboardUIStore')
@observer
class Dashboard extends React.Component<DashboardProps, DashboardState> {
  state = {
    blinking: false,
    modalVisible: false,
    toLocation: null,
    confirmedNavigation: false,
  } as DashboardState;

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { blinking: true, loading: false };
  }
  componentDidMount() {
    const { activeDashboardUIStore: store } = this.props;

    // Intialize store with current dashboard
    store!.initializeActiveDashboard(this.props.dashboardId);

    // Turn edit mode on if dashboard has just been created
    if (this.props.hasJustBeenCreated) {
      store!.startEditingDashboard();
    }
  }
  componentDidUpdate(prevProps: DashboardProps) {
    const { activeDashboardUIStore: store } = this.props;
    if (this.props.dashboardId !== prevProps.dashboardId) {
      this.blinkDashboard();
      store!.initializeActiveDashboard(this.props.dashboardId);
    }
    // we need this here in order to show warning when page is being closed/reloaded while in edit mode
    if (store!.requiresSaving) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }
  componentWillUnmount() {
    const { activeDashboardUIStore: store } = this.props;
    store!.reset();
  }
  componentDidCatch() {
    this.blinkDashboard();
  }
  blinkDashboard = () => {
    this.setState({ blinking: true });
    setTimeout(() => {
      this.setState({ blinking: false });
    }, 10);
  };
  hideCreateSubscription = () => {
    const { subscriptionStore: store } = this.props;
    store!.subscriptionRequest = undefined;
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  handleBlockedNavigation = (nextLocation: Location) => {
    // Adding filters will update the querystring - we are not interested in that.
    const willChangePath = customHistory.location.pathname !== nextLocation.pathname;

    const { confirmedNavigation } = this.state;
    if (!confirmedNavigation && willChangePath) {
      this.showModal();
      this.setState({ toLocation: nextLocation });
      return false;
    }
    return true;
  };

  handleConfirmNavigationClick = () => {
    this.closeModal();
    const { toLocation } = this.state;
    if (toLocation) {
      this.setState({ confirmedNavigation: true }, () => {
        this.props.activeDashboardUIStore!.stopEditingDashboard();
        window.location.href = `/#${toLocation.pathname}`;
      });
    }
  };

  render(): JSX.Element | null {
    const { subscriptionRequest } = this.props.subscriptionStore!;
    const { requiresSaving, isEditing } = this.props.activeDashboardUIStore!;
    const { blinking, modalVisible } = this.state;
    const dashboardId = String(this.props.dashboardId);
    if (blinking) {
      return <div />;
    }
    return (
      <>
        <Prompt when={requiresSaving} message={this.handleBlockedNavigation} />
        <Confirm
          onConfirm={this.handleConfirmNavigationClick}
          onCancel={this.closeModal}
          open={modalVisible}
          content="Are you sure you want to leave this page? Any unsaved changes will be discarded."
          confirmButton="Discard changes"
        />
        <Segment className={`fullscreen dashboard-segment ${isEditing ? 'edit-mode' : ''}`}>
          <DashboardComponent/>
          {subscriptionRequest && subscriptionRequest.context && (
            <SubscriptionModal
              close={this.hideCreateSubscription}
              orgId={this.props.orgId}
              dashboardId={dashboardId}
              request={subscriptionRequest}
            />
          )}
        </Segment>
      </>
    );
  }
}

export default Dashboard;
