import { Button } from 'components/Shared/Button';
import { Dropdown } from 'components/Shared/Dropdown';
import { Modal, ModalActions, ModalContent, ModalHeader } from 'components/Shared/Modal';
import * as React from 'react';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { getSurveyStore } from 'stores/RootStore';

interface ImportThemesProps {
  onClose: () => void;
}

interface DatasetOption {
  key: string;
  text: string;
  value: string;
}

const ImportThemes = ({ onClose }: ImportThemesProps) => {
  const surveyStore = getSurveyStore();
  const [selectedSurvey, setSelectedSurvey] = React.useState<LiteSurvey | null>(null);

  const getSurveys = () => {
    const surveys = surveyStore.getSurveysThatCanAction('manage:themes');
    if (!surveys || surveys.length <= 0) {
      return [];
    }
    return surveys;
  }

  const getOptions = (): DatasetOption[] => {
    const surveys = getSurveys();
    return surveys.map((survey: LiteSurvey) => ({
      key: survey.surveyId,
      text: survey.title,
      value: survey.surveyId,
    }));
  }

  const handleOptionChange = (_e: any, { value }: { value: string }) => {
    const selectedOption = getOptions().find(option => option.value === value);
    if (selectedOption) {
      const selectedSurvey = getSurveys().find(survey => survey.surveyId === selectedOption.key)
      setSelectedSurvey(selectedSurvey || null);
    }
  };

  const apply = () => {
    // Apply
    onClose();
  }

  return (
    <Modal
      onClose={onClose}
      open={true}
    >
      <ModalHeader as="h2">Import existing themes</ModalHeader>
      <ModalContent
        showOverflow={true}
      >
        <h4>Choose source</h4>
        <p>Select a source dataset to transfer its themes to the current dataset. This will replace the existing theme structure but retain all current comments.</p>
        <Dropdown
          placeholder='Search source'
          fluid={true}
          search={true}
          selection={true}
          options={getOptions()}
          icon='search'
          onChange={handleOptionChange}
        />
      </ModalContent>
      <ModalActions>
        <Button
          variant="secondary"
          size="medium"
          subvariant="white"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={apply}
          variant="primary"
          size="medium"
          disabled={!selectedSurvey}
        >
          Apply
        </Button>
      </ModalActions>
    </Modal>
  );
};

export { ImportThemes };
