import FileSaver from 'file-saver';
import { isEmpty } from 'lodash';
import { getFilterStore, getInitConfigStore } from 'stores/RootStore';
import thematicData from 'vue/libs/thematicData';

type CsvField = string | number | null;

function arraysToCSV(rows: CsvField[][]) {
  let processRow = function (row: CsvField[]) {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      const rawValue = row[j];
      const innerValue = rawValue === null ? '' : rawValue.toString();

      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = '"' + result + '"';
      }
      if (j > 0) {
        finalVal += ',';
      }
      finalVal += result;
    }
    return finalVal + '\n';
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }
  return csvFile;
}

function createFilename(title: string) {
  const today = new Date();
  const filename =
    (window as any).title +
    ' in ' +
    title +
    ' at ' +
    today.getFullYear() +
    '-' +
    (today.getMonth() + 1) +
    '-' +
    today.getDate() +
    '.csv';
  return filename;
}

function isDefaultColumn(column: { column: string, name: string }): boolean {
  return ['comment', 'sentiment', 'theme', 'categories'].includes(column.column);
}

// columns that are not in the row.data
function isNonDataColumn(column: { column: string, name: string }): boolean {
  return ['comment', 'sentiment', 'theme', 'categories', 'tags'].includes(column.column);
}

/**
  * columns specifies which columns should be present in the CSV
  */
async function getResultTable(filterString: string, columns?: { column: string, name: string }[]):Promise<string[][] | undefined> {
  const initConfigStore = getInitConfigStore();
  const commentColumns = initConfigStore.commentColumns;

  if (!filterString) {
    filterString = getFilterStore().queryStrings.baseline || '';
  }

  // We don't need to include the default columns in the request
  const additionalFields = columns
    ? { metadataColumns: columns.filter(column => !isDefaultColumn(column)) }
    : {}

  const requestOptions = {
    ...initConfigStore.requestOptions,
    ...additionalFields
  };

  const results = await thematicData.getResults(
    filterString,
    commentColumns,
    requestOptions,
  );
  if (isEmpty(results)) {
    return;
  }

  const headings = columns
    ? [...columns.filter(isNonDataColumn).map(column => column.name), ...results.fields]
    // add any extra data columns that have been provided (Should be the filters)
    : ['Comment', 'Sentiment', 'Themes', 'Categories', ...results.fields];

  const rows = results.results.map(row => {

    const { comment, data, sentiment } = row;
    const themes = row.themes.map((theme:string) => `'${theme}'`).join(' , ');
    const categories = row.categories.join(' , ');
    const tags = row.tags?.join(' , ') ?? '';

    // Given the columns are specified, we include/exclude accordingly
    if (columns) {
      return [
        ...columns.reduce((acc, column) => {
          switch (column.column) {
            case 'comment': return [...acc, comment];
            case 'sentiment': return [...acc, sentiment];
            case 'theme': return [...acc, themes];
            case 'categories': return [...acc, categories];
            case 'tags': return [...acc, tags];
            default: return acc;
          }
        }, []),
        ...row.data
      ]
    }

    // If no columns are specified, we include all the columns
    return [
      comment,
      sentiment,
      themes,
      categories,
      ...data
    ];

  });

  return [
    headings,
    ...rows
  ];

}

function downloadArray(arr: string[][], filename: string) {
  if (!filename) {
    const title = getFilterStore().queryTitles.baseline || '';
    filename = createFilename(title);
  }

  const data = arraysToCSV(arr);
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, filename);
}

export default { downloadArray, getResultTable, createFilename };
