<template>
  <div class="show-comments">
    <el-dialog
      :visible="dialogVisible"
      width="40%"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div
        v-if="commentsUpdating"
        v-loading="commentsUpdating"
        element-loading-text="Loading..."
        class="show-comments-loader"
      />
      <div
        v-else
        class="show-comments-body"
      >
        <div
          class="icon__tag-add"
        >
          <tag-add class="icon" />
        </div>
        <div class="show-comments-info">
          <div
            class="title"
          >
            Add <span class="theme-name">{{ title }}</span> to <span :class="{'updating-coverage': updatingCoverage}">{{ affectedCommentsPercentage >= 1 ? "approx." : "less than" }}
              {{ affectedCommentsPercentage > 1 ? affectedCommentsPercentage : '1' }}%</span> of comments?
            <div class="description">
              This will affect all comments containing "<span class="selected-phrase">{{ currentSelectedPhrases[0] }}</span>"{{ suggestedPhrases.length > 0 ? '' : '.' }}
              <span v-if="suggestedPhrases.length > 0">
                and
                <span
                  class="suggested-phrase-count"
                  @click="toggleSuggestedPhrases()"
                >{{ suggestedPhrases.length }} similar phrases</span>
              </span>
              <span
                v-else
                class="add-phrases"
                @click="toggleSuggestedPhrases()"
              >Add phrases</span>.
            </div>
          </div>
          <div
            v-if="showSuggestedPhrases"
            class="content"
          >
            <show-suggested-phrases
              :suggested-phrases="suggestedPhrases"
              @onPhrasesUpdate="onPhrasesUpdate"
            />
            <span
              class="hide-phrases"
              @click="toggleSuggestedPhrases()"
            >
              Hide phrases
            </span>
          </div>
          <div
            v-else
            class="content"
          >
            <example-comments
              v-if="!!exampleComments.length"
              :comments="exampleComments.slice(0, 3)"
              :phrases="currentSelectedPhrases"
              :processing="processing"
              :processerror="false"
              :istruncated="true"
              :isquickedit="true"
            />
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="cancel-button"
          @click="onCancel"
        >Cancel</el-button>
        <span>
          <el-button
            v-if=" !isPhrasePreSelected"
            class="back-button"
            @click="onBack"
          >
            Back
          </el-button>
          <el-button
            type="primary"
            :disabled="commentsUpdating"
            @click="onConfirm"
          >
            Add theme
          </el-button>
        </span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ExampleComments from 'components/ThemeEditor/ExampleComments.tsx';
import {
  getThemesStore,
  getOrganizationStore,
  getQuickEditUIStore,
  getAnalysisToolsUIStore,
  getThemeDiscoveryStore
} from 'stores/RootStore';
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import ShowSuggestedPhrases from './ShowSuggestedPhrases.vue';
import { getCoverageAndExamplesForPhrases } from 'vue/libs/quick-edits-review-changes';
import TagAdd from 'images/vue-icons/tag-add-right.svg';

export default {
  name: 'AddThemeExampleComments',
  components: {
    'example-comments': ReactInVue(ExampleComments),
    ShowSuggestedPhrases,
    TagAdd
  },
  commentWorker: Worker,
  props: {
    selectedPhrases: {default: () => [], type: Array},
    selectedTheme: {default: () => ({}), type: Object},
    isPhrasePreSelected: {default: false, type: Boolean},
    commentId: { default: '', type: [String, Number] },
    selectedBlock: {default: () => ({}), type: Object},
  },
  data() {
    return {
      themesStore: getThemesStore(),
      organizationStore: getOrganizationStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      quickEditUIStore: getQuickEditUIStore(),
      themesDiscoveryStore: getThemeDiscoveryStore(),

      themeTitle: null,
      dialogVisible: !!this.selectedPhrases.length,
      currentSelectedPhrases: this.selectedPhrases,
      exampleComments: [],
      processing: false,
      affectedCommentsPercentage: 0,
      commentsUpdating: false,
      suggestedPhrases: [],
      showSuggestedPhrases: false,
      updatingCoverage: false
    };
  },
  computed: {
    title() {
      if (this.selectedTheme.subTitle) {
        return `${this.selectedTheme.baseTitle}: ${this.selectedTheme.subTitle}`;
      } else {
        return this.selectedTheme.baseTitle;
      }
    },
  },
  mounted() {
    // call async so it doesn't block
    this.initalizeExamples();
  },
  methods: {
    async initalizeExamples() {
      this.commentsUpdating = true;
      await this.fetchSuggestions();
      await this.updateCommentsAndCoverage();
      this.commentsUpdating = false;
    },
    async fetchSuggestions() {
      const orgId = this.organizationStore.orgId;
      const surveyId = this.analysisToolsUIStore.currentSurveyId;
      const themeTitle = this.selectedPhrases[0];

      const params = {
        orgId,
        surveyId,
        themeTitle,
        existingMappedPhrases: this.selectedPhrases,
        manualCreation: true
      };
      const [tokenizedThemeTitle, ...suggestions] = await this.themesDiscoveryStore.suggestMappedPhrases( params );

      this.themeTitle = {
        tokenized: tokenizedThemeTitle,
        original: themeTitle
      };

      // we will only add 5 similar phrases
      this.onPhrasesUpdate( suggestions.slice(0, 5) );

    },
    onConfirm() {
      this.dialogVisible = false;
      const { editingGroup: group } = this.themesStore;
      let newNode;
      if (!this.selectedTheme.id) {
        // if no id is present, means it is a new theme
        newNode = this.themesStore.addTheme(group, this.selectedTheme.label, this.selectedTheme.base, this.currentSelectedPhrases);
      }
      this.addMappedPhrases(group, newNode);
      analytics.track('Analysis: Add Theme Completed');
      this.quickEditUIStore.haveEditedComment(this.commentId, {themesAdded: [this.selectedTheme]});
      this.quickEditUIStore.saveEditedComments(this.analysisToolsUIStore.currentSurveyId);
      this.$emit('onAction');
    },
    getAddablePhrases() {

      if (!this.themeTitle) {
        return this.currentSelectedPhrases;
      }

      // To avoid tokenization issues we do not save the original theme title; instead we save a tokenized version of it.
      // See PORTAL-4516.
      const { original, tokenized } = this.themeTitle;

      return this.currentSelectedPhrases.map(phrase => phrase === original ? tokenized : phrase);

    },
    addMappedPhrases(group, newNode) {
      let node = newNode;
      if (!newNode) {
        const updatedGroupWithActiveNodeId = {
          ...group,
          activeNodeId: this.selectedTheme.sub ? this.selectedTheme.sub : this.selectedTheme.base
        };
        const activeNode = this.themesStore.getActiveNode(updatedGroupWithActiveNodeId);
        if (!activeNode) {
          return;
        }
        node = activeNode;
      }

      const addablePhrases = this.getAddablePhrases();

      this.themesStore.addPhrases({
        group,
        node,
        toThemeId: node.id,
        phrases: addablePhrases
      });
    },
    onCancel() {
      this.dialogVisible = false;
      this.$emit('onAction');
    },
    onBack() {
      this.$emit('onBack');
    },
    toggleSuggestedPhrases() {
      this.showSuggestedPhrases = !this.showSuggestedPhrases;
    },
    async updateCommentsAndCoverage() {
      if (!this.currentSelectedPhrases) {
        return;
      }
      const orgId = this.organizationStore.orgId;
      const surveyId = this.analysisToolsUIStore.currentSurveyId;
      this.updatingCoverage = true;
      const results = await getCoverageAndExamplesForPhrases(orgId, surveyId, this.currentSelectedPhrases);
      this.affectedCommentsPercentage = results.coverage;
      this.exampleComments = results.exampleComments;
      this.updatingCoverage = false;
    },
    onPhrasesUpdate(phrases) {
      this.suggestedPhrases = [...phrases];
      this.currentSelectedPhrases = [...this.selectedPhrases, ...phrases];
      this.updateCommentsAndCoverage();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.show-comments ::v-deep .el-dialog__header {
  padding: 0;
}
.show-comments {
  .show-comments-loader {
    padding: 20px 20px 40px 20px;
  }
  .show-comments-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .icon {
      &__tag-add {
        svg {
          background-color: $--primary-500;
          border-radius: 50%;
          padding: 0.5rem;
          display: block;
          width: 3rem;
          height: 3rem;
        }
      }
    }
    .show-comments-info {
      margin-left: 1rem;
      flex-grow: 1;
      .title {
        font-size: 16px;
        color: $--color-text-primary;
        padding-bottom: 15px;
        line-height: 22px;
        font-weight: 600;
        .theme-name {
          align-items: center;
          border: none;
          color: $--neutral-700;
          line-height: 1;
          border-radius: 10em;
          padding: 4px 8px;
          background: $--neutral-100;
          height: 22px;
        }
        .updating-coverage {
          color: $--neutral-400;
        }
        .description {
          font-size: 14px;
          color: $--neutral-900;
          font-weight: 400;
          padding-bottom: 10px;
          padding-top: 2px;
          .selected-phrase {
            font-weight: bold;
          }
          .suggested-phrase-count, .add-phrases {
            color: $--primary-400;
            cursor: pointer;
            &:hover {
              color: $--primary-500;
            }
          }
        }
      }
    }
    .content {
      font-size: 14px;
      border-radius: 4px;
      line-height: 1.6em;
      div {
        text-align: left;
        ::v-deep .ui.list {
          .list-action {
            background: $--neutral-100;
            padding: 12px;
            border-bottom: none;
            mark {
              background-color: transparent;
              font-weight: bold;
              color: $--neutral-900;
            }
          }
        }
      }
      .hide-phrases {
        color: $--primary-400;
        cursor: pointer;
        &:hover {
          color: $--primary-500;
        }
      }
    }
  }
  .dialog-footer {
    .untag-comments {
      background-color: $--red-600;
      border-color: $--red-600;
    }
    .untag-comments.is-disabled {
      background-color: $--red-300;
      border-color: $--red-300;
    }
  }
}
</style>
