import { DiscoveredTheme, DiscoveredThemesJson } from 'api/interfaces';
import auth from 'Auth/Auth';
import { SelectedDropdownOption } from 'components/ThemeDiscovery/ThemeDiscoveryItemDropdown';
import { action, computed, observable } from 'mobx';
import { stringify } from 'query-string';
import { AnalysisToolsStoreInterface } from './AnalysisToolsStore';
import { ThemesStoreInterface } from './ThemesStore';

export interface SuggestParams {
  orgId: string;
  surveyId: string;
  themeTitle: string;
  manualCreation?: boolean;
  existingMappedPhrases: string[];
}

export interface GenerateParams {
  filter: string;
  orgId: string;
  surveyId: string;
  focusTheme?: string;
  sentencesOnly: boolean;
  excludeSingleWordThemes: boolean;
}

export interface SelectedTheme {
  theme: string;
  subtheme: string | null;
}

interface ProposedDiscoveredTheme extends DiscoveredTheme {
  proposedTitle: string;
  selected: boolean;
  proposedTheme?: SelectedTheme;
  mergeInto?: SelectedTheme;
}

export interface ThemeDiscoveryStoreInterface {
  canTime?: boolean;
  checkingCanTime: boolean;
  suggestMappedPhrases: (params: SuggestParams) => Promise<string[]>;
  generate: (params: GenerateParams) => Promise<void>;
  filter: string;
  requestGenerateErrored?: string;
  requestingGenerate: boolean;
  generatedThemes?: DiscoveredThemesJson;

  resolvingThemes?: ProposedDiscoveredTheme[];
  updateProposedTitle: (proposedTitle: string, index: number) => void;
  handleThemeSelection: (index: number, checked: boolean | undefined) => void;
  handleProposeTheme: (index: number, toTheme: SelectedTheme) => void;
  applyProposedThemes: (
    groups: ThemesStoreInterface['groups'],
    addTheme: ThemesStoreInterface['addTheme'],
    mergeTheme: ThemesStoreInterface['mergeTheme']
  ) => void;
  handleFullSelection: (checked: boolean | undefined) => void;
  removeThemePhrase: (themeIndex: number, phraseIndex: number) => void;
  handleMergeTheme: (index: number, toTheme: SelectedTheme) => void;
  updateSelectedDropdownAction: (selectedOption: SelectedDropdownOption | undefined) => void;

  requestSuggestionsErrored: boolean;
  requestingSuggestions: boolean;

  propose: boolean;
  selectedTheme?: SelectedTheme;
  selectedFilterRql: string;
  isValid: boolean;
  selectedDropdownAction: SelectedDropdownOption | undefined;
}

class ThemeDiscoveryStore implements ThemeDiscoveryStoreInterface {
  analysisToolsStore: AnalysisToolsStoreInterface;

  @observable
  requestGenerateErrored = undefined;
  @observable
  requestingGenerate = false;
  @observable
  generatedThemes = undefined as DiscoveredThemesJson | undefined;

  @observable
  resolvingThemes = undefined as ProposedDiscoveredTheme[] | undefined;

  @observable
  requestSuggestionsErrored = false;
  @observable
  requestingSuggestions = false;

  @observable
  propose = false;
  @observable
  selectedTheme: SelectedTheme | undefined = undefined;
  @observable
  selectedFilterRql = '';
  @observable
  selectedDropdownAction = undefined as SelectedDropdownOption | undefined;

  constructor(analysisToolsStore: AnalysisToolsStoreInterface) {
    this.analysisToolsStore = analysisToolsStore;
  }

  @computed
  get canTime() {
    const { analysisToolsConfig } = this.analysisToolsStore;
    const { checked, errored } = analysisToolsConfig;

    return !!(checked && !errored);
  }
  @computed
  get checkingCanTime() {
    const { analysisToolsConfig } = this.analysisToolsStore;
    const { fetching } = analysisToolsConfig;

    return fetching;
  }
  @computed
  get isValid() {
    const { selectedTheme, selectedFilterRql } = this;

    return !!selectedTheme || !!selectedFilterRql;
  }

  @action
  updateSelectedDropdownAction = (selectedOption: SelectedDropdownOption | undefined) => {
    this.selectedDropdownAction = selectedOption;
  }

  @action
  suggestMappedPhrases = async (params: SuggestParams) => {
    /*
    Requests mapped phrase suggestions based on a theme and survey
    */
    const { orgId, surveyId } = params;

    this.requestSuggestionsErrored = false;
    this.requestingSuggestions = true;

    const query = stringify({ organization: orgId });
    try {
      const url = `/survey/${ surveyId }/themes/helpers/mapped_phrases?${ query }`;
      const { data, ok } = await auth.fetch<string[]>(url,
        {
          body: JSON.stringify(params),
          method: 'POST'
        });
      if (data && ok) {
        return data;
      } else {
        throw new Error('Theme apply failed');
      }
    } catch (e) {
      this.requestSuggestionsErrored = true;
    } finally {
      this.requestingSuggestions = false;
    }
    return [] as string[];
  };

  @action
  generate = async (params: GenerateParams) => {
    const { filter, surveyId, focusTheme, sentencesOnly, excludeSingleWordThemes } = params;
    this.requestGenerateErrored = undefined;
    this.requestingGenerate = true;
    this.generatedThemes = undefined;

    const query = stringify({ filter, focusTheme, sentencesOnly, excludeSingleWordThemes });

    try {
      const url = `/survey/${ surveyId }/themes/helpers/discover_themes?${ query }`;
      const { data, ok, errorData } = await auth.fetch<DiscoveredThemesJson>(url);
      if (data && ok) {
        this.generatedThemes = data;

        // We need to copy the themes for users to resolve
        this.resolvingThemes = this.createResolvingThemes();
      } else if (errorData) {
        throw new Error(errorData.message);
      }
    } catch (e) {
      this.requestGenerateErrored = e.message;
    } finally {
      this.requestingGenerate = false;
    }
  };

  createResolvingThemes = () => {
    return this.generatedThemes?.themes.map(theme => {
      const selected = this.selectedTheme && this.selectedTheme.theme !== 'other' ? this.selectedTheme : {
        theme: theme.nearest ? theme.nearest.base : '',
        subtheme: theme.nearest && theme.nearest.sub ? theme.nearest.sub : null
      };

      // we will only send suggestedAction when there is no selected theme
      // because if there is a theme selected we only show that theme or it's parent theme as a suggestion
      const suggestedAction = this.selectedTheme && this.selectedTheme.theme !== 'other'
        ? undefined
        : theme['suggested_action'];

      return {
        title: theme.title,
        map: theme.map,
        nearest: theme.nearest,
        suggestedAction: suggestedAction,

        proposedTitle: theme.title,
        proposedTheme: selected,
        mergeInto: undefined,
        selected: false
      };
    });
  };

  @action
  updateProposedTitle = (proposedTitle: string, index: number) => {
    if (!this.resolvingThemes) {
      return;
    }

    this.resolvingThemes[index].proposedTitle = proposedTitle;
  };

  @action
  handleThemeSelection = (index: number, checked: boolean | undefined) => {
    if (!this.resolvingThemes) {
      return;
    }

    this.resolvingThemes[index].selected = !!checked;
  }

  @action
  handleProposeTheme = (index: number, toTheme: SelectedTheme) => {
    if (!this.resolvingThemes) {
      return;
    }

    this.resolvingThemes[index].proposedTheme = toTheme;
  };

  @action
  handleMergeTheme = (index: number, toTheme: SelectedTheme) => {
    if (!this.resolvingThemes) {
      return;
    }

    this.resolvingThemes[index].mergeInto = toTheme;
  }

  @action
  removeThemePhrase = (themeIndex: number, phraseIndex: number) => {
    if (!this.resolvingThemes) {
      return;
    }

    this.resolvingThemes[themeIndex].map = this.resolvingThemes[themeIndex].map.filter(
      (val, index) => index !== phraseIndex
    );
  }

  @action
  applyProposedThemes = async (
    groups: ThemesStoreInterface['groups'],
    addTheme: ThemesStoreInterface['addTheme'],
    mergeTheme: ThemesStoreInterface['mergeTheme']
  ) => {
    const group = groups.length > 0 ? groups[0] : undefined;

    if (!group || !this.resolvingThemes) {
      return;
    }

    this.resolvingThemes.forEach(value => {
      value.title = value.proposedTitle;

      const isMerge = !!value.mergeInto;
      if (isMerge) {
        const toTargetedTheme = value.mergeInto;
        if (value.selected && !!toTargetedTheme) {
          // need to create theme first in order to merge
          const themeTarget = undefined;
          const newNode = addTheme(group, value.title, themeTarget, value.map, true);

          if (newNode) {
            const targetId = toTargetedTheme.subtheme ? toTargetedTheme.subtheme : toTargetedTheme.theme;
            mergeTheme(group, newNode.id, targetId);
          }
        }
      } else {
        const toTheme = value.proposedTheme;
        if (value.selected) {
          const themeTarget = !toTheme || toTheme.theme === '-create-new-' ? undefined : toTheme.theme;
          addTheme(group, value.title, themeTarget, value.map, true);
        }
      }
    });
  }

  @action
  handleFullSelection = (checked: boolean | undefined) => {
    if (!this.resolvingThemes) {
      return;
    }

    this.resolvingThemes.forEach(theme => {
      theme.selected = !!checked;
    });
  }

  @computed
  get filter() {
    const { selectedFilterRql } = this;

    let rql = '';

    if (selectedFilterRql) {
      // handle both types of filter
      if (rql) {
        rql += `;${ selectedFilterRql }`;
      } else {
        // or it is just the selected filter rql
        rql = selectedFilterRql;
      }
    }
    return rql;
  }
}

export default ThemeDiscoveryStore;
