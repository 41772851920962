import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataDestinationIntegration } from 'api/interfaces';
import { Button } from 'components/Shared/Button';
import { Empty } from 'components/Shared/Empty';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Loader, Segment } from 'semantic-ui-react';

import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { DataDestinationStoreInterface } from 'stores/DataDestinationStore';
import { IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import ConfigureExportDestination, { NEW_CONNECTION_ID } from './ConfigureExportDestination';
import './configure-export.scss';

interface ConfigureExportStoreProps {
  dataDestinationStore?: DataDestinationStoreInterface;
  surveyStore?: SurveyStoreInterface;
  integrationsStore?: IntegrationsStoreInterface;
}

interface ConfigureExportProps extends
  ConfigureExportStoreProps {
  surveyId: string;
  orgId: string;
}


interface ConfigureExportState {
  survey?: LiteSurvey;
  dataDestinations: DataDestinationIntegration[];
  showNewConnection: boolean;
}

@inject('dataDestinationStore', 'surveyStore', 'organizationStore')
@observer
class ConfigureExport extends React.Component<ConfigureExportProps, ConfigureExportState> {
  state: ConfigureExportState = {
    survey: undefined,
    dataDestinations: [] as DataDestinationIntegration[],
    showNewConnection: false
  };

  componentDidMount() {
    this.getSurveyDetails();
  }

  componentDidUpdate(prevProps: ConfigureExportProps) {
    const { surveyId } = this.props;
    if (prevProps.surveyId !== surveyId) {
      this.getSurveyDetails();
    }
  }

  getSurveyDetails = async () => {
    const { surveyId, surveyStore, dataDestinationStore } = this.props;
    if (surveyId) {

      // requesting getAvailableDataDestinations is required as child components use this information 
      // and expect it to be available
      const [survey, dataDestinations, _] = await Promise.all([
        surveyStore!.getSurvey(surveyId),
        dataDestinationStore!.getDataDestinations(surveyId),
        dataDestinationStore!.getAvailableDataDestinations(),
      ]);
      this.setState({ survey, dataDestinations });
    }
  }

  createNewDestination = () => {
    this.setState({ showNewConnection: true });
  }


  createDestination = async (destination: DataDestinationIntegration) => {
    const { surveyId, dataDestinationStore } = this.props;

    await dataDestinationStore!.createDataDestination(surveyId, destination);
    const dataDestinations = await dataDestinationStore!.getDataDestinations(surveyId);
    this.setState({ dataDestinations, showNewConnection: false });

  }

  deleteDestination = async (destinationId: string) => {
    const { surveyId, dataDestinationStore } = this.props;
    if (destinationId === NEW_CONNECTION_ID) {
      this.setState({ showNewConnection: false });
    }
    else {
      await dataDestinationStore!.deleteDataDestination(surveyId, destinationId);
      const dataDestinations = await dataDestinationStore!.getDataDestinations(surveyId);
      this.setState({ dataDestinations });
    }
  }

  renderDestinations = () => {
    const { surveyId, orgId, dataDestinationStore, surveyStore } = this.props;
    const { dataDestinations, showNewConnection } = this.state;
    if (surveyStore!.fetchingSurveys || dataDestinationStore!.listSurveysDataDestinationsLoading) {
      return <div className="configure-export__loading">
        <Loader size="large" content="Loading destinations..." active={true} />
      </div>;
    } else if (!showNewConnection && dataDestinations.length === 0) {
      return <Empty
        title="Start exporting your data"
        icon="open-box"
        description="You can automate data delivery to destinations such as sFTP."
        orientation='horizontal' />;
    }

    const destinationsElements = dataDestinations.map(destination => {
      return (
        <ConfigureExportDestination
          key={destination.id}
          surveyId={surveyId}
          orgId={orgId}
          destinationId={destination.id}
          deleteDestination={this.deleteDestination}
          createDestination={this.createDestination}
        />
      );
    });

    if (showNewConnection) {
      destinationsElements.unshift(
        <ConfigureExportDestination
          key="new-connection"
          surveyId={surveyId}
          orgId={orgId}
          destinationId="new-connection"
          deleteDestination={this.deleteDestination}
          createDestination={this.createDestination}
        />
      );
    }

    return destinationsElements;
  }

  render() {
    const destinationsElements = this.renderDestinations();

    return (
      <Segment className="white configure-export">
        <h3 className="configure-export__title">Dataset export</h3>
        <div>
          <Button
            variant="primary"
            onClick={() => {
              this.createNewDestination()
            }}
            size="medium">
            <FontAwesomeIcon icon="plus" />
            &nbsp;Add destination
          </Button>
        </div>
        <div className="configure-export__destinations">
          {destinationsElements}
        </div>
      </Segment>
    );
  }
}

export default ConfigureExport;