import * as React from 'react';
import { RemoveThemeParams, ThemeCodes } from 'stores/ui/AnalysisToolsUIStore';
import { PhraseSegmentTheme, Thread } from 'types/custom';
import { useSimilarSentencesContext } from './similar-sentences.context';
import toThreadParts from 'vue/libs/to-thread-parts';
import getCommentHighlightLocations from 'lib/get-comment-highlight-locations';
import segmentsToOrderedThemes from 'lib/segments-to-ordered-themes';
import { VueInReact } from 'vuera';
import VerticalCommentThreadPart from 'vue/components/Comments/VerticalCommentThreadPart.vue';
import CommentThemesList from 'vue/components/Comments/CommentThemesList.vue';
import RemoveTheme from 'vue/components/Themes/RemoveTheme.vue';
import { Button } from 'components/Shared/Button';
import { useAddThemeToSelection } from './useAddThemeToSelection';
import classNames from 'classnames';
import { SELECTION_STATUS } from '../AddTheme/types';
import toCommentThemeItem from 'vue/libs/to-comment-theme-item';
import {
  createThemeTitle,
  doesThemeAlreadyExist,
  isNewlyAddedTheme,
  isThemeRemoved,
} from 'vue/libs/edited-comment-helpers';
import { getRemoveThemeParams } from './getRemoveThemeParams';
import './vertical-comment-thread.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddTheme from 'vue/components/Themes/AddTheme.vue';
import CommentCategories from 'vue/components/Categories/CommentCategories.vue';
import CommentTags from 'vue/components/Tags/CommentTags.vue';
import CommentMetadataList from 'vue/components/Comments/CommentMetadataList.vue';
import segmentsToCategories from 'lib/segments-to-categories';
import { Block, ContentBlock } from 'lib/segments-to-blocks';
import { isEqual } from 'lodash';
import { AddThemeToSelection } from 'components/AddTheme/AddThemeToSelection';

const AddThemeVue = VueInReact(AddTheme);
const CommentCategoriesVue = VueInReact(CommentCategories);
const CommentMetadataListVue = VueInReact(CommentMetadataList);
const CommentTagsVue = VueInReact(CommentTags);
const CommentThemesListVue = VueInReact(CommentThemesList);
const RemoveThemeVue = VueInReact(RemoveTheme);
const VerticalCommentThreadPartVue = VueInReact(VerticalCommentThreadPart);

type HoveredLocation = [[start: number, end: number], number, number];

interface Props {
  alwaysExpanded: boolean;
  thread: Thread;
  highlightType: string;
  hoverOnThemes: boolean;
  isDisabled: boolean;
  isNarrow: boolean;
  selectedThemeCodes?: ThemeCodes;
}

const VerticalCommentThread = ({
  alwaysExpanded,
  thread,
  highlightType,
  hoverOnThemes,
  isDisabled,
  isNarrow,
  selectedThemeCodes,
}: Props) => {
  const [collapsedIndexes, setCollapsedIndexes] = React.useState<number[]>([]);
  const [hoveredLocation, setHoveredLocation] = React.useState<HoveredLocation | undefined>(undefined);
  const [hoveredThemeCodes, setHoveredThemeCodes] = React.useState<ThemeCodes | undefined>(undefined);
  const [expanded, setExpanded] = React.useState<boolean>(!isNarrow || alwaysExpanded);
  const [removeThemeParams, setRemoveThemeParams] = React.useState<RemoveThemeParams | null>(null);
  const [tags, setTags] = React.useState<string[]>(thread.tags || []);

  const conversationRef = React.useRef<HTMLDivElement>(null);
  const { orgId, surveyId } = useSimilarSentencesContext();

  const {
    clearSelection,
    onEnterBlock,
    onSelectionChange,
    onTextSelectionEnd,
    selectedPhrase,
    selectedPhrasePosition,
    selectedStatus
  } = useAddThemeToSelection();

  React.useEffect(() => {
    if (selectedStatus !== SELECTION_STATUS.None) {
      analytics.track('Analysis: Select Comment', { 'Type': 'Conversation' });
    }
  }, [selectedStatus]);

  const {
    areSupportMessagesVisible,
    canManageThemes,
    editedComments,
    hasSentiment,
    isApplyingThemes,
    threadDisplayConfig,
  } = useSimilarSentencesContext();

  const conversations = threadDisplayConfig && toThreadParts(threadDisplayConfig, thread.comment);
  const segments = thread.comment.flatMap((c) => c.segments);

  const highlightedLocations = React.useMemo(() => {
    const isHoveringOverAnyLocation = !!hoveredLocation;

    return conversations?.map((conversation, index) => {
      return conversation.pairs.map((pairs, pairsIndex) => {
        const isHoveringOverThisLocation =
          isHoveringOverAnyLocation && hoveredLocation[1] === index && hoveredLocation[2] === pairsIndex;

        if (!isHoveringOverThisLocation && isHoveringOverAnyLocation) {
          return [];
        }

        const segments = pairs.reduce((result, pair) => [...result, ...pair.threadedComment.segments], []);

        const hoveredLoc = hoveredLocation ? hoveredLocation[0] : undefined;

        return getCommentHighlightLocations(segments, selectedThemeCodes, hoveredLoc, hoveredThemeCodes);
      });
    });
  }, [conversations, hoveredLocation, selectedThemeCodes, hoveredThemeCodes]);

  const orderedThemes = React.useMemo(() => {
    if (!selectedThemeCodes) {
      return [];
    }

    return segmentsToOrderedThemes(segments, selectedThemeCodes);
  }, [segments, selectedThemeCodes]);

  const onCollapseParts = (index) => {
    if (!collapsedIndexes.includes(index)) {
      setCollapsedIndexes([...collapsedIndexes, index]);
    }
  };

  const getThemeLocations = (theme: PhraseSegmentTheme) => {
    return segments.reduce((result, segment) => {
      const index = segment.themes.findIndex((t) => {
        return t.base === theme.base && t.sub === theme.sub;
      });

      const segmentHasTheme = index > -1;

      return segmentHasTheme ? [...result, segment.location] : result;
    }, []);
  };

  const isOrderedThemeRemoved = (theme: PhraseSegmentTheme) => {
    return isThemeRemoved(thread.id, theme, getThemeLocations(theme));
  };

  const getCommentThemeItems = () => {
    return orderedThemes.map((theme) =>
      toCommentThemeItem({
        theme,
        segments: segments,
        isNew: isNewlyAddedTheme(thread.id, theme),
        isRemoved: isOrderedThemeRemoved(theme),
        hoveredLocation: hoveredLocation?.[0],
        hoveredThemeCodes: hoveredThemeCodes,
        selectedThemeCodes: selectedThemeCodes,
      }),
    );
  };

  const handleRemoveTheme = (theme: PhraseSegmentTheme, block?: Block) => {
    if (isApplyingThemes) {
      return;
    }

    const removeThemeParams = getRemoveThemeParams({ block, theme, thread });

    setRemoveThemeParams(removeThemeParams as any);

    analytics.track('Analysis: Remove Theme Started', { Location: 'Metadata' });
  };

  const handleRemoveThemeClose = () => {
    setRemoveThemeParams(null);
  };

  const getThemesAdded = () => {
    if (!editedComments || !(thread.id in editedComments)) {
      return [];
    }

    return editedComments[thread.id].themesAdded;
  };

  const updateTags = (newTags: string[]) => {
    setTags(tags.splice(0, tags.length, ...newTags));
  };

  const handleHoverBlock = (block: ContentBlock, conversationIndex: number, pairIndex: number) => {
    if (!block.hasThemes) {
      return;
    }

    setHoveredLocation([block.location, conversationIndex, pairIndex]);
  };

  const persistHoveredBlock = (block: ContentBlock, conversationIndex: number, pairIndex: number) => {
    const newHoveredLocation: HoveredLocation = [block.location, conversationIndex, pairIndex];

    if (!isEqual(newHoveredLocation, hoveredLocation)) {
      setHoveredLocation(newHoveredLocation);
    }
  };

  const expandPart = (conversationIndex: number) => {
    setCollapsedIndexes(collapsedIndexes.filter((index) => index !== conversationIndex));
  };

  const commentCategories = segmentsToCategories(segments);

  React.useEffect(() => {
    const conversationElement = conversationRef.current;

    conversationElement?.addEventListener('selectionchange', onSelectionChange);

    return () => {
      conversationElement?.removeEventListener('selectionchange', onSelectionChange);
    };
  }, [onSelectionChange]);

  return (
    <div
      className={classNames('react-vertical-thread', {
        'is-disabled': isDisabled,
        'is-expanded': expanded,
        'is-narrow': isNarrow,
      })}
    >
      <div className="react-vertical-thread__body" ref={conversationRef}>
        <div onMouseUp={onTextSelectionEnd}>
          {conversations?.map((conversation, conversationIndex) => {
            if (
              conversation.collapsible &&
              collapsedIndexes.includes(conversationIndex) &&
              !areSupportMessagesVisible
            ) {
              return (
                <button
                  onClick={() => expandPart(conversationIndex)}
                  className="react-vertical-thread__hidden-message"
                  key={conversationIndex}
                >
                  {conversation.pairs.length} message {conversation.pairs.length > 1 ? 's' : ''} hidden
                </button>
              );
            } else {
              return (
                <span key={conversationIndex}>
                  {conversation.pairs.map((pair, pairIndex) => (
                    <span key={`${conversationIndex}-${pairIndex}`}>
                      <VerticalCommentThreadPartVue
                        areThemesInApplyingState={isApplyingThemes}
                        comment={conversation}
                        commentColumnId={thread.column}
                        highlightedLocations={highlightedLocations?.[conversationIndex][pairIndex]}
                        highlightType={highlightType}
                        hoverOnThemes={hoverOnThemes}
                        indexes={[conversationIndex, pairIndex]}
                        isCollapsed={true}
                        isDisabled={isDisabled}
                        isNarrow={isNarrow}
                        intersectionObserver={null}
                        selectedPhrase={selectedPhrase}
                        selectedThemeCodes={selectedThemeCodes}
                        seen={true}
                        threadParts={pair}
                        on={{
                          'collapseParts': () => onCollapseParts(conversationIndex),
                          'hoverBlock': (block) => handleHoverBlock(block, conversationIndex, pairIndex),
                          'leaveBlock': () => setHoveredLocation(undefined),
                          'onEnterBlock': (block) => onEnterBlock(block),
                          'onRemoveThemeFromPart': (theme, block) => handleRemoveTheme(theme, block),
                          'persistHoveredBlock': (block) => persistHoveredBlock(block, conversationIndex, pairIndex),
                        }}
                      />
                    </span>
                  ))}
                </span>
              );
            }
          })}
        </div>
        {canManageThemes && (
          <AddThemeToSelection
            clearSelection={clearSelection}
            commentId={thread.id}
            isApplyingThemes={isApplyingThemes}
            orgId={orgId}
            preSelectedPhrase={selectedPhrase}
            selectedPhrasePosition={selectedPhrasePosition}
            selectionStatus={selectedStatus}
            surveyId={surveyId}
          />
        )}
      </div>
      {expanded && (
        <div className="react-vertical-thread__themes">
          <div className="react-vertical-thread__theme-section react-theme-list">
            <h3 className="react-vertical-thread__section-heading">Themes</h3>
            <CommentThemesListVue
              areThemesInApplyingState={!!isApplyingThemes}
              canManageThemes={canManageThemes}
              hasRemoveButton={!isDisabled}
              hasSentiment={true}
              isSentimentEnabled={hasSentiment}
              items={getCommentThemeItems()}
              on={{
                'mouseLeaveTheme': () => setHoveredThemeCodes(undefined),
                'removeTheme': (theme) => handleRemoveTheme(theme),
              }}
            />
            {getThemesAdded()?.map((theme, index) => (
              <React.Fragment key={`${index}-${JSON.stringify(theme)}`}>
                {!doesThemeAlreadyExist(thread.id, theme, orderedThemes) && (
                  <div className="react-themes-added">
                    <FontAwesomeIcon icon="sparkles" />
                    <span className="react-added-theme-title">{createThemeTitle(theme)}</span>
                  </div>
                )}
              </React.Fragment>
            ))}
            {canManageThemes && !isDisabled && (
              <div className="">
                <AddThemeVue
                  areThemesInApplyingState={isApplyingThemes}
                  commentId={thread.id}
                  conversations={conversations}
                />
              </div>
            )}
          </div>
          {commentCategories && (
            <div className="react-vertical-thread__theme-section">
              <h3 className="react-vertical-thread__section-heading">Categories</h3>
              <CommentCategoriesVue
                commentId={thread.id}
                commentColumnId={thread.column}
                categories={commentCategories}
              />
            </div>
          )}
          {!isDisabled && (
            <div className="react-vertical-thread__theme-section react-tags-list">
              <h3 className="react-vertical-thread__section-heading">Labels</h3>
              <CommentTagsVue
                commentColumnId={thread.column}
                commentId={thread.id}
                commentTags={tags}
                location="explore"
                on={{
                  'updateTags': (newTags) => updateTags(newTags),
                }}
              />
            </div>
          )}
          <div className="react-vertical-thread__theme-section react-vertical-thread__metadata">
            <CommentMetadataListVue info={thread.data} />
          </div>
        </div>
      )}
      {isNarrow && !alwaysExpanded && (
        <div className="react-vertical-thread__meta-data-button">
          <Button onClick={() => setExpanded(!expanded)} inline={true} size="small" variant="tertiary">
            {expanded ? 'Hide details' : 'Show details'}
          </Button>
        </div>
      )}
      {removeThemeParams && <RemoveThemeVue onClose={handleRemoveThemeClose} removeThemeParams={removeThemeParams} />}
    </div>
  );
};

export { VerticalCommentThread };
