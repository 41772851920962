import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataIntegrationStatus, IntegrationType } from 'api/enums';
import { ConnectedIntegration } from 'api/interfaces';
import IntegrationLogo from 'components/Integrations/IntegrationLogo';
import { Empty } from 'components/Shared/Empty';
import { supportedDataSources } from 'components/Upload/constants';
import { compact } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { Button, Dropdown, Form, Label, Message, Segment, SemanticCOLORS } from 'semantic-ui-react';

import DataAlerting from 'components/Upload/DataAlerting';
import { LiteSurvey } from 'stores/AnalysisToolsStore';
import { IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import './configure-import.scss';
import ConfigureImportDataSourceHistory from './ConfigureImportDataSourceHistory';
import ConfigureImportUploadHistory from './ConfigureImportUploadHistory';

interface ConfigureImportStoreProps {
  setupStore?: SetupStoreInterface;
  surveyStore?: SurveyStoreInterface;
  integrationsStore?: IntegrationsStoreInterface;
}

interface ConfigureImportProps extends
  ConfigureImportStoreProps {
  surveyId: string;
  orgId: string;
}

interface ConfigureImportState {
  survey?: LiteSurvey;
  integrationId?: string;
  integrations: ConnectedIntegration[];
  showingChangeConnection: boolean;
}

@inject('setupStore', 'surveyStore', 'organizationStore')
@observer
class ConfigureImport extends React.Component<ConfigureImportProps, ConfigureImportState> {
  state = {
    survey: undefined,
    integrations: [] as ConnectedIntegration[],
    showingChangeConnection: false
  } as ConfigureImportState;

  componentDidMount() {
    this.getSurveyDetails();
  }

  componentDidUpdate(prevProps: ConfigureImportProps) {
    const { surveyId } = this.props;
    if (prevProps.surveyId !== surveyId) {
      this.getSurveyDetails();
    }
  }

  getSurveyDetails = async () => {
    const { surveyId } = this.props;
    if (surveyId) {
      const { surveyId } = this.props;

      const survey = await this.props.surveyStore!.getSurvey(surveyId);
      this.setState({ survey });

      const { integrationsStore } = this.props;
      if (integrationsStore && survey && survey.dataSourceIntegration) {
        const integrationId = survey.dataSourceIntegration.integrationId;
        const integrationType = survey.dataSourceIntegration.type as IntegrationType;
        const integrations = integrationsStore.getConnectedIntegrationsOfType(integrationType);
        this.setState({ integrationId, integrations });
      }
    }
  }

  updateSource = async () => {
    const { setupStore } = this.props;
    const { integrationId, survey } = this.state;

    const integationIdHasChanged = survey && survey.dataSourceIntegration?.integrationId !== integrationId;
    if (integationIdHasChanged) {
      const dataSourceOptions = { integrationId };
      await setupStore!.updateDataSource(survey.surveyId, dataSourceOptions);
      this.getSurveyDetails();
    }
  }

  pullNow = () => {
    const { surveyId, setupStore } = this.props;

    setupStore?.pullSurveyDataSource(surveyId);
  }

  togglePulling = async () => {
    const { surveyId, setupStore } = this.props;
    const { survey } = this.state;

    const dataSource = survey ? survey.dataSourceIntegration : null;
    if (!dataSource) {
      return;
    }

    let status = DataIntegrationStatus.ENABLED;
    if (dataSource.status === DataIntegrationStatus.ENABLED) {
      status = DataIntegrationStatus.STOPPED;
    }

    setupStore!.setDataSourceStatus(surveyId, status);
  }

  render() {
    const { setupStore, surveyStore, integrationsStore, surveyId } = this.props;
    const {
      survey,
      integrations,
      showingChangeConnection,
      integrationId
    } = this.state;
    const errorsToShow = compact([surveyStore!.updateSurveyError,
    setupStore!.updateDataSourceError,
    setupStore!.runPullDataSourceError,
    ]);

    let integration;
    let label = 'Unknown';
    let labelColor = 'grey';

    const dataSource = survey ? survey.dataSourceIntegration : null;
    if (dataSource) {
      // get details where there is a connected integration
      if (dataSource.integrationId && integrations && integrationsStore) {
        integration = integrationsStore.getIntegrationById(dataSource.integrationId);
      }
      // get details for all data sources
      if (dataSource.status === DataIntegrationStatus.STOPPED) {
        label = 'Paused';
        labelColor = 'grey';
      } else if (dataSource.lastFailureDate &&
        (!dataSource.lastSuccessDate || dataSource.lastFailureDate > dataSource.lastSuccessDate)) {
        label = 'Errored';
        labelColor = 'red';
      } else if (dataSource.lastSuccessDate) {
        label = 'Active';
        labelColor = 'green';
      }
    }


    return (
      <Segment className="white configure-import">
        <h3 className="configure-import__title">Dataset import</h3>
        <Form>
          {dataSource && (
            <Form.Field>
              <div
                className='import-section-title'>Data Source</div>
              <div className="integration-manage">

                <div className="integration-details">
                  <IntegrationLogo integrationType={dataSource.type} />
                  <div className="integration-connection">
                    <div className="integration-name">
                      {supportedDataSources[dataSource.type]!.name}
                    </div>
                    <div className="integration-connection-details">
                      {dataSource.lastSuccessDate ? (
                        <span>Refreshed: {moment(dataSource.lastSuccessDate).format('MMMM Do, YYYY')}</span>
                      ) : (
                        <span>No successful connections</span>
                      )}
                    </div>
                    <div className="integration-connection-controls">
                      <Button
                        size="small"
                        loading={setupStore?.pullingDataSource}
                        onClick={this.pullNow}
                      >
                        <span>
                          <FontAwesomeIcon icon="sync-alt" />
                          &nbsp;Refresh
                        </span>
                      </Button>
                      <Button
                        size="small"
                        loading={setupStore?.updatingDataSource}
                        onClick={this.togglePulling}
                      >
                        {dataSource.status === DataIntegrationStatus.ENABLED ? (
                          <span>
                            <FontAwesomeIcon icon="pause" />
                            &nbsp;Pause
                          </span>
                        ) : (
                          <span>
                            <FontAwesomeIcon icon="play" />
                            &nbsp;Resume
                          </span>
                        )}
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Label color={labelColor as SemanticCOLORS} size="large">{label}</Label>
                  </div>
                </div>
                {supportedDataSources[dataSource.type]!.requiresIntegration && (
                  <div className="integration-change">
                    {showingChangeConnection ? (
                      <div>
                        Integration set up by
                        <Dropdown
                          fluid={true}
                          selection={true}
                          placeholder="Select Integration"
                          options={integrations.map(i => {
                            return {
                              value: i.id,
                              key: i.id,
                              text: `${ i.authorizedBy } on ${ i.authorizedAt }`
                            };
                          })}
                          value={integrationId}
                          onChange={(e, { value }) => { this.setState({ integrationId: value as string }); }}
                        />
                      </div>
                    ) : integration ? (
                      <div>
                        Integration set up by&nbsp;
                        {integration.authorizedBy}
                        &nbsp;on&nbsp;
                        {moment(integration.authorizedAt).format('MMMM Do, YYYY')}
                      </div>
                    ) : (
                      <div>Integration missing!</div>
                    )}

                    {(integrations.length > 1 || (integrations.length > 0 && !integration)) && (
                      <div className="integration-change-action">
                        {showingChangeConnection ? (
                          <Button
                            color="blue"
                            size="small"
                            disabled={survey?.dataSourceIntegration?.integrationId === integrationId}
                            loading={setupStore?.updatingDataSource}
                            onClick={this.updateSource}
                          >
                            <FontAwesomeIcon icon="edit" />
                            &nbsp;Update
                          </Button>) : (
                          <Button
                            size="small"
                            onClick={() => { this.setState({ showingChangeConnection: true }); }}
                          >
                            <FontAwesomeIcon icon="edit" />
                            &nbsp;Change source
                          </Button>
                        )}
                      </div>
                    )}

                  </div>
                )}
              </div>
            </Form.Field>
          )}
          {!dataSource && (
            <Empty
              title="No data source connected"
              icon="open-box"
              description="You don't have a data source connected for this dataset"
              orientation='horizontal' />
          )}
          <Form.Field>
            <div
              className='import-section-title'>Alerting</div>
            <DataAlerting
              surveyId={surveyId}
            />
          </Form.Field>
          {errorsToShow.map(error => (
            <Message
              key={error}
              className="error"
              negative={true}
              header={error}
            />
          ))}
          {survey && (
            <>
              {dataSource && (
                <ConfigureImportDataSourceHistory survey={survey} />)}
              {!dataSource && (
                <ConfigureImportUploadHistory survey={survey} />
              )}</>
          )}
        </Form>
      </Segment>
    );
  }
}

export default ConfigureImport;