import * as React from 'react';
import {
  Dropdown as SemanticDropdown,
  DropdownProps as SemanticDropdownProps,
} from 'semantic-ui-react';
import './dropdown.scss';

type DropdownProps = SemanticDropdownProps;

export const Dropdown = (props: DropdownProps) => {

  return (
    <SemanticDropdown
      className="thematic-dropdown"
      {...props}
    />
  );
};