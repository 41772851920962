






















































































import {
  getAnalysisConfigStore,
  getAnalysisToolsUIStore,
  getFilterStore,
  getNotificationStore,
  getThemesStore,
  getUrlParametersStore,
  getInitConfigStore,
  getExploreToolUIStore
} from 'stores/RootStore';
import 'vue/assets/css/options-popover.scss';
import VerticalComments from 'vue/components/Comments/VerticalComments.vue';
import ThematicFilters from 'vue/components/Filters/ThematicFilters.vue';
import ManageTags from 'vue/components/Tags/ManageTags.vue';
import AnalysisToolsMenu from 'vue/tools/AnalysisToolsMenu.vue';
import AnalysisToolSwitcher from 'vue/tools/AnalysisToolSwitcher.vue';
import dataMixin from 'vue/tools/Utils/dataMixin';
import {applyThemeChanges} from 'vue/libs/quick-edits';
import RemoveTheme from 'vue/components/Themes/RemoveTheme.vue';

export default {
  name: 'Analysis',
  components: {
    AnalysisToolsMenu,
    AnalysisToolSwitcher,
    ThematicFilters,
    VerticalComments,
    ManageTags,
    RemoveTheme
  },
  mixins: [dataMixin],
  props: {
    configError: { default: undefined, type: Error },
    isSticky: { default: true, type: Boolean},
    orgId: { default: undefined, type: String },
    surveyId: { default: undefined, type: String },
  },
  data() {
    return {
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      notificationStore: getNotificationStore(),
      urlParametersStore: getUrlParametersStore(),
      filterStore: getFilterStore(),
      analysisConfigStore: getAnalysisConfigStore(),
      themesStore: getThemesStore(),
      initConfigStore: getInitConfigStore(),
      exploreToolUIStore: getExploreToolUIStore(),
      loadingMessage: '',
      error: undefined,
    };
  },
  computed: {
    canManageThemes() {
      return this.analysisConfigStore.canManageThemes;
    },
    themesError() {
      return this.themesStore.errorMessage;
    },
    title() {
      window.title = this.initConfigStore.title;
      return this.initConfigStore.title;
    },
    selectedTheme() {
      return this.analysisToolsUIStore.themeFilterSet.theme;
    },
    selectedSubtheme() {
      return this.analysisToolsUIStore.themeFilterSet.subtheme;
    },
    commentsSelection() {
      return this.analysisToolsUIStore.commentsSelection;
    },
    transformCount() {
      return this.themesStore.transforms.length;
    },
  },
  watch: {
    transformCount: {
      handler(transformCount) {
        if (!this.canManageThemes) {
          return;
        }

        if (transformCount > 0) {
          this.notificationStore.push({
            id: `themes-edited-${this.analysisToolsUIStore.currentSurveyId}`,
            preventClose: true,
            link: true,
            title: 'Apply edited themes',
            message: 'It can take 5 min',
            icon: 'THEME_EDIT_YELLOW',
            actionText: 'Apply',
            action: () => this.uploadThemeFile(),
          });
          window.onbeforeunload = () => true;
        } else {
          window.onbeforeunload = () => null;
        }
      },
      immediate: true,
    },
    configError: {
      handler(configError) {
        this.error = configError;
      },
      immediate: true,
    },
    themesError: {
      handler(themesError) {
        this.error = themesError;
      },
      immediate: false,
    }
  },
  destroyed() {
    this.analysisToolsUIStore.resetAnalysis();
    this.initConfigStore.resetConfig();
    this.exploreToolUIStore.resetExplore();
    this.filterStore.resetFilters();
    this.urlParametersStore.reset();
  },
  methods: {
    async uploadThemeFile() {
      applyThemeChanges();
    },
    onError(error) {
      this.error = error;
    },
  }
};
