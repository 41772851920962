import { utcToDateString } from "lib/date";
import queryBuilder from "vue/libs/queryBuilder";

type DateSelection = {
  selected: {
    name: string;
    rql: string
    startDate: Date | null,
    endDate: Date | null,
  },
  previous: {
    name: string;
    rql: string
    startDate: Date | null,
    endDate: Date | null,
  },
  validRange: {
    start: string,
    end: string,
  },
  column?: number;
  id?: string;
}

type ContextOptions = {
  baseFilter: string | null;
  compareFilter?: string;
  compareFilterName?: string;
  dateFilter: null | DateSelection;
  filterNames: Record<string, string>;
  filterString: string;
  viewDashboardUrl: string;
}

type DashboardContext = {
  baseFilter?: string;
  compareFilter?: string;
  compareFilterName?: string;
  currentFilters?: string[];
  currentCompareFilters?: string;
  filter?: string;
  filterName?: string;
  previousPeriod?: string;
  selectedPeriod?: string;
  viewDashboardUrl: string;
};

export function getContext(options: ContextOptions): DashboardContext | null {
  const { dateFilter, filterString, filterNames, baseFilter, compareFilter, compareFilterName } = options;

  // if the date filter isn't ready yet, stop
  if (!dateFilter) {
    return null;
  }

  const context: DashboardContext = {
    viewDashboardUrl: options.viewDashboardUrl,
  };
  if (filterString) {
    context.filter = filterString;

    const currentFilters: string[] = Object
      .entries(filterNames)
      .filter(([, value]) => value)
      .map(([name, value]) => `${name}: ${value}`);

    context.currentFilters = currentFilters;
    context.filterName = currentFilters.join(', ');
  }

  if (compareFilter) {
    // compareFilter needs to have the filterString applied too
    context.compareFilter = queryBuilder.appendToFilter(filterString, compareFilter);
    context.currentCompareFilters = compareFilterName;

    // and compareFilterName should have the overall filter name included too
    context.compareFilterName = context.filterName ? `${context.filterName}, ${compareFilterName}` : compareFilterName;
  }

  if (baseFilter) {
    context.baseFilter = baseFilter;
  }

  const startDateString = dateFilter.selected?.startDate;

  if (startDateString) {
    const startDate = utcToDateString(startDateString);
    const endDate = utcToDateString(dateFilter.selected?.endDate);
    context.selectedPeriod = `${startDate}:${endDate}`;

    // there are situations where we want to override the previous period
    const previousStartDateString = dateFilter.previous?.startDate;
    if (previousStartDateString) {
      const previousStartDate = utcToDateString(previousStartDateString);
      const previousEndDate = utcToDateString(dateFilter.previous?.endDate);
      context.previousPeriod = `${previousStartDate}:${previousEndDate}`;
    }
  }
  return context;
}
