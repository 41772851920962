import * as React from 'react';
import { useAsyncFunction } from 'lib/useAsyncFunction';
import { getThemeDiscoveryStore } from 'stores/RootStore';
import { getCoverageAndExamplesForPhrases } from 'vue/libs/quick-edits-review-changes';
import { SuggestParams } from 'stores/ThemeDiscoveryStore';

export const getUpdatedCommentsAndCoverage = async (orgId: string, surveyId: string, selectedPhrases: string[]) => {
  return getCoverageAndExamplesForPhrases(orgId, surveyId, selectedPhrases);
};

interface UseFetchCommentsAndCoverageProps {
  orgId: string;
  selectedPhrases: string[];
  surveyId: string;
}

const useFetchCommentsAndCoverage = ({ orgId, selectedPhrases, surveyId }: UseFetchCommentsAndCoverageProps) => {
  const memoizedSelectedPhrases = React.useMemo(() => selectedPhrases, [selectedPhrases]);

  const {
    fetch: fetchCommentsAndCoverage,
    data: commentsAndCoverage,
    error: commentsAndCoverageError,
    loading: loadingCommentsAndCoverage,
  } = useAsyncFunction(getUpdatedCommentsAndCoverage);

  React.useEffect(() => {
    fetchCommentsAndCoverage(orgId, surveyId, memoizedSelectedPhrases);
  }, [fetchCommentsAndCoverage, orgId, memoizedSelectedPhrases, surveyId]);

  return {
    commentsAndCoverage,
    commentsAndCoverageError,
    loadingCommentsAndCoverage,
  }
};

const getSuggestMappedPhrases = async (suggestParams: SuggestParams) => {
  const themesDiscoveryStore = getThemeDiscoveryStore();

  return await themesDiscoveryStore.suggestMappedPhrases(suggestParams);
};

const useFetchSuggestions = ({ orgId, existingMappedPhrases, surveyId, themeTitle }: SuggestParams) => {
  const {
    fetch: fetchSuggestions,
    data: suggestions,
    error: suggestionsError,
    loading: suggestionsLoading,
  } = useAsyncFunction(getSuggestMappedPhrases);

  React.useEffect(() => {
    fetchSuggestions({
      orgId,
      surveyId,
      themeTitle,
      existingMappedPhrases,
      manualCreation: true
    });
  }, [fetchSuggestions, orgId, existingMappedPhrases, surveyId, themeTitle]);

  return {
    suggestions,
    suggestionsError,
    suggestionsLoading,
  }
};

export { useFetchCommentsAndCoverage, useFetchSuggestions };
