import { UserToken } from 'api/interfaces';
import { inject } from 'mobx-react';
import * as React from 'react';
import { Button, Segment, Table } from 'semantic-ui-react';
import { UserStoreInterface } from 'stores/UserStore';

interface UserRefreshTokensProps {
  userStore?: UserStoreInterface;
}

interface UserRefreshTokensState {
  tokens: UserToken[];
  revokingToken: boolean;
}

@inject('userStore')
export default class UserRefreshTokens extends React.Component<
  UserRefreshTokensProps, UserRefreshTokensState
> {
  state = {
    tokens: [] as UserToken[],
    revokingToken: false
  };

  componentDidMount() {
    this.retrieveTokens();
  }

  retrieveTokens = async () => {
    const { userStore } = this.props;
    const tokens = await userStore!.fetchTokens();
    this.setState({ tokens });
  }

  revokeToken = async (token: UserToken) => {
    this.setState({ revokingToken: true });
    const { userStore } = this.props;
    const tokens = await userStore!.revokeToken(token);
    if (tokens) {
      this.setState({ tokens });
    }
    this.setState({ revokingToken: false });
  }

  render() {
    const { tokens, revokingToken } = this.state;
    if (!tokens) {
      return null;
    }

    return (
      <Segment>
        <Table striped={true} basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">Existing Refresh Tokens</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tokens.map(token => {
              return (
                <Table.Row key={token.id} verticalAlign="middle">
                  <Table.Cell className="name">{token.device_name}</Table.Cell>
                  <Table.Cell textAlign='right'>
                    <Button
                      loading={revokingToken}
                      onClick={() => { this.revokeToken(token); }}
                      size="small">
                      Revoke
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
            {tokens.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan="4">
                  No refresh tokens found.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}
