import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { SelectedTheme } from './types';
import { Modal, ModalActions, ModalContent, ModalHeader } from 'components/Shared/Modal';
import { getUpdatedCommentsAndCoverage, useFetchSuggestions } from 'components/AddTheme/hooks';
import { Loader } from 'semantic-ui-react';
import ExampleComments from 'components/ThemeEditor/ExampleComments';
import { Button } from 'components/Shared/Button';
import { useAsyncFunction } from 'lib/useAsyncFunction';
import { SuggestedPhrases } from './SuggestedPhrases';
import './add-theme-example-comments-modal.scss';
import classNames from 'classnames';

interface SuggestedPhraseToggleProps {
  onToggleSuggestedPhrases: () => void;
  suggestedPhraseCount: number;
}

const SuggestedPhraseToggle = ({ onToggleSuggestedPhrases, suggestedPhraseCount }: SuggestedPhraseToggleProps) => {
  return (
    <button className="add-theme-example-comments-modal__suggest-phrase-toggle" onClick={onToggleSuggestedPhrases} type="button">
      {suggestedPhraseCount > 0 ? `${suggestedPhraseCount} similar phrases` : 'Add phrases'}
    </button>
  );
}

interface Props {
  isPhrasePreSelected: boolean;
  onClose: () => void;
  onConfirm: (phrases: string[]) => void;
  orgId: string;
  selectedPhrases: string[];
  selectedTheme: SelectedTheme;
  surveyId: string;
}

const AddThemeExampleCommentsModal = observer(({
  isPhrasePreSelected,
  onClose,
  onConfirm,
  orgId,
  selectedPhrases,
  selectedTheme,
  surveyId
}: Props) => {
  const [currentSelectedPhrases, setCurrentSelectedPhrases] = React.useState<string[]>(selectedPhrases);
  const [originalThemeTitle, setOriginalThemeTitle] = React.useState<string>('')
  const [showSuggestedPhrases, setShowSuggestedPhrases] = React.useState<boolean>(false);
  const [suggestedPhrases, setSuggestedPhrases] = React.useState<string[]>([]);
  const [tokenizedThemeTitle, setTokenizedThemeTitle] = React.useState<string>('');

  const { suggestions, suggestionsLoading } = useFetchSuggestions({
    orgId,
    surveyId,
    existingMappedPhrases: selectedPhrases,
    themeTitle: selectedPhrases[0],
    manualCreation: true
  });

  const memoizedCurrentSelectedPhrases = React.useMemo(() => currentSelectedPhrases, [currentSelectedPhrases]);

  const {
    fetch: fetchCommentsAndCoverage,
    data: commentsAndCoverage,
    error: commentsAndCoverageError,
    loading: loadingCommentsAndCoverage,
  } = useAsyncFunction(getUpdatedCommentsAndCoverage);

  React.useEffect(() => {
    if (!suggestions || suggestionsLoading) {
      return;
    }

    fetchCommentsAndCoverage(orgId, surveyId, memoizedCurrentSelectedPhrases);
  }, [
    fetchCommentsAndCoverage,
    orgId,
    memoizedCurrentSelectedPhrases,
    suggestions,
    suggestionsLoading,
    surveyId,
  ]);

  const onPhrasesUpdate = React.useCallback((phrases: string[]) => {
    setSuggestedPhrases(phrases);
    setCurrentSelectedPhrases((prev) => [...prev, ...phrases]);
  }, [])

  React.useEffect(() => {
    if (!suggestions || suggestions?.length === 0) {
      return;
    }

    const [tokenizedThemeTitle, ...remainingSuggestions] = suggestions;

    setOriginalThemeTitle(selectedPhrases[0]);
    setTokenizedThemeTitle(tokenizedThemeTitle);
    onPhrasesUpdate(remainingSuggestions);
  }, [onPhrasesUpdate, selectedPhrases, suggestions]);

  const getSelectedThemeTitle = () => {
    if (!selectedTheme) {
      return '';
    }

    if ('sub' in selectedTheme) {
      return `${selectedTheme.baseTitle}: ${selectedTheme.subTitle}`;
    }

    return selectedTheme.baseTitle;
  };

  const getHeaderText = () => {
    if (!commentsAndCoverage) {
      return '';
    }

    const { coverage } = commentsAndCoverage;

    const prefix = coverage >= 1 ? 'approx' : 'less than';
    const amount = coverage > 1 ? coverage : '1';

    return `${prefix} ${amount}% of comments`;
  };

  const toggleSuggestedPhrases = () => {
    setShowSuggestedPhrases((prev) => !prev);
  };

  const getAddablePhrases = () => {
    if (!originalThemeTitle || !tokenizedThemeTitle) {
      return currentSelectedPhrases;
    }

    return currentSelectedPhrases.map(phrase => phrase === originalThemeTitle ? tokenizedThemeTitle : phrase);
  }

  const handleConfirm = () => {
    const phrases = getAddablePhrases();

    onConfirm(phrases);
  };

  const title = getSelectedThemeTitle();

  const loading = suggestionsLoading || loadingCommentsAndCoverage;

  return (
    <Modal onClose={onClose} isSizeMedium={true} open={true}>
      {(!commentsAndCoverage && loading) && (
        <div className="show-comments-modal__loader">
          <Loader active={true} inline="centered" size="large" />
        </div>
      )}
      {(commentsAndCoverage && !suggestionsLoading) && (
        <>
          <ModalHeader as="h2">
            Add <span>{title}</span> to <span className={classNames({ 'show-comments-modal__header--loading': loadingCommentsAndCoverage })}>{getHeaderText()}</span>
          </ModalHeader>
          <ModalContent>
            <p>This will affect all comments containing <span className="show-comments-modal__selected-phrase">"{currentSelectedPhrases[0]}"</span>
              <span>{suggestedPhrases.length > 0 ? ' and ' : '.'}</span>
              <SuggestedPhraseToggle
                suggestedPhraseCount={suggestedPhrases.length}
                onToggleSuggestedPhrases={toggleSuggestedPhrases}
              />
            </p>
            {showSuggestedPhrases && (
              <SuggestedPhrases onPhrasesUpdate={onPhrasesUpdate} suggestedPhrases={suggestedPhrases} />
            )}
            {!showSuggestedPhrases && (
              <ExampleComments
                comments={commentsAndCoverage.exampleComments.slice(0, 3)}
                phrases={selectedPhrases}
                processing={loadingCommentsAndCoverage}
                processerror={!!commentsAndCoverageError}
                istruncated={true}
              />
            )}
          </ModalContent>
        </>
      )}
      <ModalActions>
        <Button variant="tertiary" onClick={onClose}>Cancel</Button>
        {!isPhrasePreSelected && <Button variant="tertiary" onClick={onClose}>Back</Button>}
        <Button onClick={handleConfirm}>Add theme</Button>
      </ModalActions>
    </Modal>
  )
});

export { AddThemeExampleCommentsModal };
