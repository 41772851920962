import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { canNavigate, getDestination, goToAnalysis } from 'components/Dashboard/Utils/navigation-helpers';
import { LineGraph } from 'components/Dashboard/Widgets/LineGraph';
import { WidgetWrapper } from 'components/Dashboard/Widgets/WidgetWrapper';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import * as React from 'react';


interface Props {
  orgId: string;
  error?: string;
  warning?: string;
  periodResolution?: string;
  labels?: string[];
  loading?: boolean;
  scoreName?: string;
  isWeighted?: boolean;
  source?: string;
  title?: string;
  values?: number[];
  panelOrder: number;
  widgetOrder: number;
}

const ScoreOvertimeComponent = withCamelCaseProps((props: Props) => {
  const {
    orgId,
    error,
    warning,
    periodResolution,
    labels = [],
    loading,
    scoreName = '',
    isWeighted = false,
    source,
    title,
    values = [],
    panelOrder,
    widgetOrder
  } = props;

  const canNavigateToAnalysis = canNavigate({ source, isWeighted });
  const empty = !values || values.length === 0;
  const numPeriods = labels.length;

  const getPeriodName = () => {
    switch (periodResolution) {
      case 'rolling90':
        return 'periods';
      case 'quarterly':
        return 'quarters';
      case 'monthly':
        return 'months';
      case 'weekly':
        return 'weeks';
      case 'daily':
        return 'days';
      default:
        return undefined;
    }
  };

  const getSubtitle = () => {
    let subtitle = `Change in ${scoreName}`;
    const periodName = getPeriodName();
    if (periodName) {
      subtitle += ` over last ${numPeriods} ${periodName}`;
    }
    return subtitle;
  };

  const handleNavigate = () => {
    if (!canNavigateToAnalysis || !source) {
      return;
    }

    const destination = getDestination({
      orgId,
      source,
      tool: 'over-time'
    });
    goToAnalysis(destination);
  };

  const subtitle = getSubtitle();

  const subtitleElements = () => (
    <div
      className={`widget-subtitle${canNavigateToAnalysis ? ' widget-subtitle-clickable' : ''}`}
      onClick={handleNavigate}
    >
      <div className="widget-subtitle-text">
        {subtitle}
        {canNavigateToAnalysis && (
          <FontAwesomeIcon className="link-icon" icon="chart-bar" />
        )}
      </div>
    </div>
  );

  return (
    <WidgetWrapper
      aria-label={subtitle}
      title={title}
      loading={loading}
      error={error}
      warning={warning}
      panelOrder={panelOrder}
      widgetOrder={widgetOrder}
      subtitle={subtitleElements()}
    >
      <div className={`score-graph-container widget-body ${empty ? ' empty' : ''}`}>
        {!empty ? (
          <LineGraph
            values={values}
            labels={labels}
          />
        ) : (
          <div>No data found</div>
        )}
      </div>
    </WidgetWrapper>
  );
});

export { ScoreOvertimeComponent };
