import { DashboardWidgetType, SelectionWidgetType } from 'api/enums';
import {
  DashboardConfig, DashboardFetchResult, DashboardSource, DashboardWidgetConfig, DataSources, Panel, SelectionWidgetConfig,
  Widget
} from 'api/interfaces';
import Auth from 'Auth/Auth';
import { getConfigUrl } from 'components/Dashboard/Utils/dashboard-helper';
import { Fetchable } from 'lib/fetch';
import { cloneDeep, compact, filter, flatten, get, isEmpty, maxBy, omit, set, uniq } from 'lodash';
import { action, computed, observable } from 'mobx';
import { DashboardJson, DashboardStoreInterface } from 'stores/DashboardStore';
import { getAnalysisId, getSourceUrl } from '../utils/analysis-helper';

interface ActiveDashboardWidget extends Widget {
  order: number;
}
export interface ActiveDashboardPanel extends Omit<Panel, 'widgets'> {
  order: number;
  widgets?: ActiveDashboardWidget[];
}
interface ActiveDashboardConfig extends Omit<DashboardConfig, 'panels'> {
  panels?: ActiveDashboardPanel[];
}

const COMPARISON_WIDGETS = [
  DashboardWidgetType.IMPACT_SIMPLE,
  DashboardWidgetType.SCORE,
  DashboardWidgetType.SCORE_DETAIL,
  DashboardWidgetType.SCORE_OVERTIME,
  DashboardWidgetType.THEMES1,
  DashboardWidgetType.THEMES_SIMPLE
];

export interface ActiveDashboardUIStoreInterface {
  dashboardConfig?: ActiveDashboardConfig;
  currentDashboardId: string;
  configLocation: string;
  fetchingDashboard: Promise<Fetchable<DashboardFetchResult>> | null;
  fetchDashboardError: string;
  fetchDashboardConfig: () => Promise<void>;

  dragHandleStyle: { cursor: string };
  dragHandleClass: string;

  // state of the UI
  isEditing: boolean;
  requiresSaving: boolean;

  // properties of current configuration
  version?: string;
  name: string;

  updatedMenuName: string;

  selectionWidget?: SelectionWidgetConfig;
  selectionWidgetType?: SelectionWidgetType;
  sources?: DataSources;

  // for tracking the state of saving
  savingDashboard: boolean;
  savingDashboardError: string;

  // for retrieving state
  getSourceUrl: (sourceName: string) => string | undefined;

  getCanAction: (actionRequired: string) => boolean;

  // actions for controlling the active survey
  initializeActiveDashboard: (dashboardId: string) => Promise<void>;
  reset: () => void;

  // actions for editing
  startEditingDashboard: () => void;
  stopEditingDashboard: () => void;
  getValue: (target: string) => string | object | number;
  setValue: (target: string, value: string | object | number) => void;
  save: () => void;
  deleteWidget: (panelOrder: number, widgetOrder: number) => void;
  deleteSelectionWidget: () => void;
  cancelEditingDashboard: () => void;

  setDashboardMenuName: (name: string) => void;
  getPanelWidgetPath: (panelOrder: number, widgetOrder?: number) => string | undefined;
  getSourceByWidgetPath: (widgetPath: string) => DashboardSource | undefined;
  getWidgetSourceKey: (widget: Widget) => string | undefined;
}

class ActiveDashboardUIStore implements ActiveDashboardUIStoreInterface {
  dashboardStore: DashboardStoreInterface;

  dashboardConfig?: ActiveDashboardConfig = undefined;
  originalDashboardConfig?: ActiveDashboardConfig = undefined;
  availableActions = [] as string[];

  @observable
  currentDashboardId = '';

  @observable
  configLocation = '';

  @observable
  fetchingDashboard = null as Promise<Fetchable<DashboardFetchResult>> | null;

  @observable
  fetchDashboardError = '';

  @observable
  isEditing = false;

  @observable
  requiresSaving = false;

  @observable
  updatedMenuName = '';

  @observable
  version = undefined as string | undefined;

  @observable
  savingDashboard = false;
  @observable
  savingDashboardError = '';

  // selector to indicate draggable part on the widget
  @observable
  dragHandleClass = 'drag-handle';

  @observable
  dragHandleStyle = { cursor: 'grab' };

  @computed
  get name() {
    const details = this.dashboardStore.getDashboardById(this.currentDashboardId);
    if (details) {
      return details.name;
    }
    return '';
  }

  @computed
  get selectionWidgetExists() {
    if (this.dashboardConfig) {
      return !isEmpty(get(this.dashboardConfig, 'selectionWidget.config'));
    }
    return false;
  }

  @computed
  get selectionWidget() {
    if (this.dashboardConfig) {
      return get(this.dashboardConfig, 'selectionWidget.config');
    }
    return undefined;
  }

  @computed
  get selectionWidgetType() {
    if (this.dashboardConfig) {
      return get(this.dashboardConfig, 'selectionWidget.config.type');
    }
    return undefined;
  }

  @computed
  get sources() {
    return this.dashboardConfig?.sources || {};
  }

  /*
    Get analysisId for each dashboard source and attach it to them
    The analysisId is meant to be a unique idenitifier across sources
    This is used to populate selected sources in source configuration
  */
  @computed
  get sourcesWithAnalysisId() {
    if (!this.isEditing) {
      return {};
    }
    let sourcesWithAnalysisId = {};
    for (const sourceKey in this.sources) {
      if (this.sources[sourceKey]) {
        const source = this.sources[sourceKey];
        const analysisId = getAnalysisId(source);

        sourcesWithAnalysisId[sourceKey] = { ...source, analysisId };
      }
    }
    return sourcesWithAnalysisId;
  }

  /*
    Gets the list of source keys that are in use by widgets in the dashboard
  */
  @computed
  get sourceKeysInUse() {
    if (!this.isEditing || !this.dashboardConfig) {
      return [];
    }

    const { source: dashboardSource, selectionWidget, panels } = this.dashboardConfig;

    // Get source for each widget for each panel and flatten the array
    const widgetSources = flatten((panels || [])
      .map(panel => (panel.widgets || [])
        .map(widget => widget.source)));

    const sources = uniq(compact([dashboardSource, selectionWidget?.source, ...widgetSources]));

    return sources;
  }

  /*
    Gets the list of sources (with analysis id) that are in use by widgets in the dashboard
    This is used to disable unselecting a source in dashboard source configuration that is in use
  */
  @computed
  get sourcesInUse() {

    let sourcesInUse = {};

    this.sourceKeysInUse.forEach(sourceKey => {
      const source = this.sourcesWithAnalysisId[sourceKey];
      sourcesInUse[sourceKey] = source;
    });

    return sourcesInUse;
  }

  constructor(dashboardStore: DashboardStoreInterface) {
    this.dashboardStore = dashboardStore;
  }

  @action
  initializeActiveDashboard = async (dashboardId: string) => {
    this.reset();
    const dashboard = this.dashboardStore.dashboards.find(dashboard => dashboard.id === dashboardId);

    if (dashboard) {
      this.currentDashboardId = dashboardId;
      this.fetchDashboardConfig();
    }
  }

  @action
  reset = () => {
    this.currentDashboardId = '';
    this.configLocation = '';
    this.originalDashboardConfig = undefined;
    this.updatedMenuName = '';

    this.requiresSaving = false;
    this.isEditing = false;
  }

  @action
  startEditingDashboard = () => {
    this.isEditing = true;
  }

  @action
  stopEditingDashboard = () => {
    this.isEditing = false;
    this.requiresSaving = false;
  }

  @action
  cancelEditingDashboard = () => {
    this.isEditing = false;
    this.requiresSaving = false;
    this.dashboardConfig = cloneDeep(this.originalDashboardConfig);
  }

  @action
  save = async () => {
    if (!this.currentDashboardId) {
      return;
    }
    const url = `/dashboard/${ this.currentDashboardId }`;
    this.savingDashboardError = '';
    this.savingDashboard = true;

    const configuration = this.mapDashboardConfigForAPI(this.dashboardConfig);
    const hasNameChanged = !!this.updatedMenuName && this.name !== this.updatedMenuName;

    try {
      const { data, ok, errorData } = await Auth.fetch<DashboardJson>(
        url,
        {
          body: JSON.stringify({
            version: this.version,
            configuration, name: this.name,
            ...(hasNameChanged ? { name: this.updatedMenuName } : {})
          }),
          method: 'PUT'
        }
      );
      if (!ok && errorData) {
        throw new Error(`Failed to save dashboard: ${ errorData.message }`);
      } else if (data) {
        if (hasNameChanged) {
          this.updatedMenuName = '';
          // Refresh dashboards to see updated menu name
          await this.dashboardStore.refreshDashboards();
        }
      }
    } catch (e) {
      this.savingDashboardError = `Failed to save dashboard: ${ e.message }`;
    } finally {
      this.savingDashboard = false;
    }
  }

  @action
  setDashboardMenuName = async (name: string) => {
    this.updatedMenuName = name;
  }

  getValue = (target: string) => {
    return get(this.dashboardConfig, target);
  }

  @action
  setValue = (target: string, value: string | object | number) => {
    if (this.dashboardConfig) {
      // we set on a new object so that observers of configuration will be notified
      const newConfig = cloneDeep(this.dashboardConfig);
      set(newConfig, target, value);
      this.dashboardConfig = newConfig;
      this.requiresSaving = true;
    }
  }

  /*
    Update all applicable widgets to have compareToOverall set to true
    - To be used when adding a new selection widget
  */
  @action
  updateWidgetsForSelectionFilter = () => {
    if (!this.dashboardConfig || isEmpty(this.dashboardConfig.panels)) {
      return;
    }

    const panels = this.dashboardConfig.panels?.map(panel => {
      if (!panel.widgets) {
        return panel;
      }
      const updatedWidgets = panel.widgets.map(widget => {
        if (widget.config && COMPARISON_WIDGETS.includes(widget.config.type)) {
          return { ...widget, config: { ...widget.config, compareToOverall: true } };
        }
        return widget;
      });
      return { ...panel, widgets: updatedWidgets };
    });

    this.dashboardConfig = { ...this.dashboardConfig, panels };
  }

  @action
  deleteFilter = (filterId: string) => {
    if (!this.dashboardConfig) {
      return;
    }
    this.dashboardConfig.filters = this.dashboardConfig.filters?.filter(f => f.id !== filterId);
    this.requiresSaving = true;
  }

  @action
  deleteSelectionWidget = () => {
    if (this.dashboardConfig) {
      this.dashboardConfig.selectionWidget = undefined;
      this.requiresSaving = true;
    }
  }

  @action
  deleteWidget = (panelOrder: number, widgetOrder: number) => {
    const panel = this.getPanelByOrder(panelOrder);
    if (panel) {
      panel.widgets =
        filter((panel.widgets || []), (widget) => widget.order !== widgetOrder);
      this.requiresSaving = true;
    }
  }

  /*
    Add a new widget to the given panel with appropriate order
  */
  @action
  addWidget = (panelOrder: number, configuration: DashboardWidgetConfig, source: string) => {
    const panel = this.getPanelByOrder(panelOrder);
    if (panel) {
      // We show comparison widget if dashboard has a selection widget
      const showComparisonWidget = this.selectionWidgetExists && COMPARISON_WIDGETS.includes(configuration.type);
      const config = { ...(showComparisonWidget ? { ...configuration, compareToOverall: true } : configuration) };
      let order = maxBy(panel.widgets, 'order')?.order || 0;
      let widget: ActiveDashboardWidget = {
        order: ++order,
        config,
        source
      };
      panel.widgets = [...panel.widgets || [], widget];
      this.requiresSaving = true;
    }
  }

  getSource = (sourceName?: string): DashboardSource | undefined => {
    if (!this.dashboardConfig) {
      return undefined;
    }

    // If no is source name is provided, take the dashboard level default source
    const name = sourceName ? sourceName : this.dashboardConfig.source;
    if (name) {
      return this.dashboardConfig?.sources?.[name];
    }
    return undefined;
  }

  getSourceUrl = (sourceName?: string): string | undefined => {
    const source: DashboardSource | undefined = this.getSource(sourceName);
    if (!source || typeof source === 'string') {
      return source;
    }
    return getSourceUrl(source);
  }

  getCanAction = (actionRequired: string) => {
    if (this.availableActions) {
      return this.availableActions.includes(actionRequired);
    }
    return false;
  }

  fetchDashboardConfig = async () => {
    if (!this.currentDashboardId) {
      return;
    }
    const url = `/dashboard/${ this.currentDashboardId }/config`;

    this.fetchingDashboard = Auth.fetch<DashboardFetchResult>(url, { isRaw: true });

    try {
      const { data: dashboardResult, ok } = await this.fetchingDashboard;
      if (dashboardResult && ok) {
        this.version = dashboardResult.version;
        this.availableActions = dashboardResult.scopes;
        this.dashboardConfig = this.mapDashboardConfigForDisplay(dashboardResult.config);
        this.originalDashboardConfig = cloneDeep(this.dashboardConfig);
        this.configLocation = getConfigUrl(this.currentDashboardId);
      } else {
        throw new Error('Failed to fetch dashboard configuration');
      }
    } catch (e) {
      this.fetchDashboardError = e.message;
    } finally {
      this.fetchingDashboard = null;
    }
  }

  /*
    Adds order property to each widget in a panel and each panel in a dashboard
    Order property makes it easier to locate the widget to save/re-order/delete
    Order starts with 1 for simplicity with null checks
  */
  mapDashboardConfigForDisplay = (dashboardConfig: DashboardConfig = {}) => {
    const { panels } = dashboardConfig;
    let mappedPanels: ActiveDashboardPanel[] = [];
    if (panels) {
      let order = 0;
      mappedPanels = panels.map(panel => {
        let mappedPanel = { ...panel };
        const { widgets } = mappedPanel;
        if (widgets) {
          let widgetOrder = 0;
          let mappedWidgets = widgets.map(widget => {
            return { ...widget, order: widgetOrder++ };
          });
          mappedPanel.widgets = mappedWidgets;
        }
        return { ...mappedPanel, order: order++ } as ActiveDashboardPanel;
      });
    }
    return { ...dashboardConfig, panels: mappedPanels };
  }

  /*
    Removes order property that was added from each widget in a panel and each panel in a dashboard
  */
  mapDashboardConfigForAPI = (dashboardConfig: ActiveDashboardConfig = {}) => {
    const configuration: DashboardConfig = omit(dashboardConfig, 'panels');
    let { panels } = { ...dashboardConfig };
    if (panels) {
      configuration.panels = panels.map(panel => {
        let mappedPanel: Panel = { ...omit(panel, 'order', 'widgets'), widgets: [] };
        if (panel.widgets) {
          mappedPanel.widgets = panel.widgets.map(widget => omit(widget, 'order'));
        }
        return mappedPanel;
      });
    }
    return configuration;
  }

  /*
    Get the path of the widget or panel given their corresponding order
  */
  @action
  getPanelWidgetPath(panelOrder: number, widgetOrder?: number) {
    const panels = get(this.dashboardConfig, 'panels');
    if (panels) {
      const panelIndex = panels.findIndex(panel => panel.order === panelOrder);
      if (panelIndex > -1) {
        const panel = panels[panelIndex];
        if (panel.widgets && widgetOrder !== undefined) {
          const widgetIndex = panel.widgets.findIndex(widget => widget.order === widgetOrder);
          if (widgetIndex > -1) {
            return `panels[${ panelIndex }].widgets[${ widgetIndex }]`;
          }
        } else {
          return `panels[${ panelIndex }]`;
        }
      }
    }
    return undefined;
  }

  getPanelByOrder = (panelOrder: number) => {
    if (this.dashboardConfig?.panels) {
      const panels = this.dashboardConfig?.panels;
      const panelIndex = panels.findIndex(panel => panel.order === panelOrder);
      if (panelIndex > -1) {
        return panels[panelIndex];
      }
    }
    return undefined;
  }

  /*
    Get source key for widget
    - Check if widget has source specified
    - If not, check if there is a 'default' source
    - Otherwise, use the default dashboard source
  */
  @action
  getWidgetSourceKey = (widget: Widget) => {
    const widgetSource = get(widget, 'source');
    if (widgetSource) {
      return widgetSource;
    } else {
      const sources = this.dashboardConfig || {};
      if (sources && sources['default']) {
        return 'default';
      }
      return this.dashboardConfig?.source;
    }
  }

  /*
    Get source using widget path
  */
  @action
  getSourceByWidgetPath = (widgetPath: string) => {
    const widget = this.getValue(widgetPath);
    const sourceKey = this.getWidgetSourceKey(widget);
    if (!sourceKey) {
      return undefined;
    }

    const source = this.sources[sourceKey];
    return source;
  }
}

export default ActiveDashboardUIStore;
