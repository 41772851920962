import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import * as React from 'react';
import { Button, List } from 'semantic-ui-react';

export interface MappedPhraseProps {
  onClick: () => void;
  onDelete: (phrase: string) => void;
  phrase: string;
  isSelected: boolean;
}

export default (props: MappedPhraseProps) => {
  const { onClick, onDelete, phrase, isSelected, } = props;
  const cls = classnames('list-action clickable', { selected: isSelected });
  return (
    <List.Item key={phrase} className={cls} onClick={onClick}>
      <List.Content>
        <div className="ui checkbox fluid">
          <input
            className="hidden"
            readOnly={true}
            type="checkbox"
            checked={isSelected}
          />
          <label>{phrase}</label>
        </div>
      </List.Content>
      <Button
        className="action"
        size="small"
        onClick={() => onDelete(phrase)}
      >
        <FontAwesomeIcon icon="trash" className="icon" />
      </Button>
    </List.Item>
  );
};
