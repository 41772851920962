<template>
  <widget-wrapper
    aria-label="Changes in theme impact"
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        <span class="key-text">
          Changes in theme impact
          <font-awesome-icon
            v-if="canNavigate"
            class="link-icon"
            icon="chart-bar"
          />
        </span>
      </div>
      <div class="widget-display-categories">
        <div class="category">
          Impact
        </div>
        <div class="category">
          Volume
        </div>
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <div class="legend">
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.primary500"
          />
        </svg>
        <span class="key-text">{{ baselineName }}</span>
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.orange500"
          />
        </svg>
        <span class="key-text">{{ comparisonName }}</span>
      </div>
      <div
        v-for="(theme, index) in themes"
        :key="index + theme.name"
        class="theme-row"
      >
        <div
          class="theme-name"
          :class="{ 'clickable-theme': canClickTheme }"
          @click="doNavigate({code: theme.code, title: theme.name}, null)"
        >
          <span>{{ theme.name }}</span>
        </div>
        <bar-comparison
          :value="theme.impactBaseline"
          :comparison-value="theme.impactComparison"
          :max-positive="maxPositive"
          :max-negative="maxNegative"
          :display-percentage="theme.volumeBaseline"
          :comparison-display-percentage="theme.volumeComparison"
        />
      </div>
      <div
        v-if="empty"
        class="empty-change-list"
      >
        No changes found
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      :comparison-themes="comparisonThemes"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import { flatten, get, map, max, min, slice } from 'lodash';

import { BarComparison } from 'components/Dashboard/Widgets/BarComparison';
import { CommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import { SummaryComponent } from 'components/Dashboard/Widgets/Components/SummaryComponent.tsx';

import { canClickTheme } from 'components/Dashboard/Utils/dashboard-helper';
import {
  canNavigate,
  goToAnalysis,
  getDestination
} from 'components/Dashboard/Utils/navigation-helpers';

import WidgetWrapper from './WidgetWrapper';
import colors from 'vue/styles/element-variables.scss';

export default {
  name: 'IMPACT_CHANGES',
  components: {
    BarComparison: ReactInVue(BarComparison),
    WidgetWrapper,
    SummaryComponent: ReactInVue(SummaryComponent),
    CommentComponent: ReactInVue(CommentComponent),
  },
  props: {
    orgId: { type: String },
    isWeighted: { type: Boolean, default: false },
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      selectedTheme: null,
      isCommentsEnabled: false,
      isSummaryEnabled: false,
      colors: colors,
    };
  },
  computed: {
    canClickTheme() {
      return canClickTheme({
        source: this.source,
        isWeighted: this.isWeighted,
        isCommentsEnabled: this.isCommentsEnabled,
        isSummaryEnabled: this.isSummaryEnabled,
      });
    },
    canNavigate() {
      return canNavigate({ source: this.source, isWeighted: this.isWeighted });
    },
    baselineName() {
      return get(this, 'data.currentPeriod') || get(this, 'data.baseline.name', 'This Period');
    },
    empty() {
      return this.themes.length === 0;
    },
    impacts() {
      const impacts = map(this.themes, (theme) => {
        return [theme.impactBaseline, theme.impactComparison];
      });
      return flatten(impacts);
    },
    maxNegative() {
      const { impacts } = this;
      return Math.min(0, min(impacts));
    },
    maxPositive() {
      const { impacts } = this;
      return Math.max(0, max(impacts));
    },
    themes() {
      const themes = get(this, 'data.themes', []);
      return slice(themes, 0, this.limit);
    },
    comparisonName() {
      return get(this, 'data.previousPeriod') || get(this, 'data.comparison.name', 'Previous Period');
    },
    count() {
      return get(this, 'data.baseline.count');
    },
    previousCount() {
      return get(this, 'data.baseline.count');
    },
    comparisonThemes() {
      return get(this, 'data.comparison');
    },
  },
  watch: {
    config: {
      handler (config) {
        this.isCommentsEnabled = config.showComments;
        this.isSummaryEnabled = config.showSummary;
      },
      immediate: true
    },
  },
  methods: {
    doNavigate(theme, subtheme, options, isFinalNavigate) {
      const {
        source,
        isCommentsEnabled,
        isSummaryEnabled,
        isWeighted,
        orgId,
      } = this;

      // if the navigation is to a specific theme, and there is a comments component, don't navigate yet
      if ((isCommentsEnabled || isSummaryEnabled) && !isFinalNavigate && theme) {
        this.selectedTheme = { theme, subtheme, options };
        analytics.track('Report: open comments', { category: 'Report' });
        return;
      }
      if (!canNavigate({ source, isWeighted })) {
        return;
      }

      const destination = getDestination({
        options,
        orgId,
        source,
        subtheme,
        theme,
        subtool: 'impact',
        tool: 'explore'
      });
      goToAnalysis(destination);
    },
  },
};
</script>


<style lang="scss" scoped>
.theme-row {
  display: grid;
  grid:
    'name-area bar-area' 40px
    / 150px auto;
  grid-gap: 0 10px;
  padding: 0 10px;
}
</style>
