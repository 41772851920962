import * as React from 'react';
import { Button, List } from 'semantic-ui-react';
import './merged-theme.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'components/Shared/Tooltip';

import FlagKeys from 'Auth/flag-keys';
import { FeatureFlagManager } from 'lib/feature-flag';
import classNames from 'classnames';

export interface MergedThemeProps {
  onUnmerge: () => void;
  onSelect: () => void;
  isNew: boolean;
  isActive: boolean;
  title: string;
}

const MergedTheme = (props: MergedThemeProps) => {
  const {
    isNew,
    isActive,
    title,
    onSelect,
    onUnmerge,
  } = props;

  const canSeeNewMergedThemes:boolean = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_NEW_MERGED_THEMES) !== false;

  const selectionTooltipMessage = isActive
    ? 'Click to clear highlighted theme'
    : 'Click to highlight theme and related content below'

  if (canSeeNewMergedThemes) {
    return (
      <List.Item>
        <div
          className={classNames('merged-theme', {
            'merged-theme--active': isActive,
          })}
          aria-label={`merged theme: ${title}`}
          aria-checked={isActive}
        >
          <Tooltip
            position='bottom left'
            content={selectionTooltipMessage}
            inverted
            trigger={
              <button
                onClick={onSelect}
                className="merged-theme__button merged-theme__filter-button"
                aria-label={`filter: ${title}`}
              >
                {isNew && <FontAwesomeIcon icon="star" className="icon" />}
                {title}
              </button>
            }
          />
          <Tooltip
            position='bottom right'
            content="Unmerge to show as subtheme"
            inverted
            trigger={
              <button
                onClick={onUnmerge}
                className="merged-theme__button merged-theme__undo-button"
                aria-label={`unmerge: ${title}`}
              >
                <FontAwesomeIcon icon="undo" className="icon" />
              </button>
            }
          />
        </div>
      </List.Item>
    );
  }

  return (
    <List.Item className="list-action">
      <List.Content content={title} />
      {isNew && <FontAwesomeIcon icon="star" className="icon" />}
      <Button className="action" size="small" onClick={onUnmerge}>
        <FontAwesomeIcon icon="undo" className="icon" />
      </Button>
    </List.Item>
  );
};

export { MergedTheme };
