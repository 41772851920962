import { ReportWidget } from 'api/interfaces';
import { action, observable } from 'mobx';
import thematicData from 'vue/libs/thematicData';

interface Observables {
  report?: object;
}

interface Actions {
  getWidget: (params: {
    config: object;
    context: object;
    source: string;
    dashboardId: string;
  }) => Promise<ReportWidget>;
}

export interface ReportUIStoreInterface extends Observables, Actions {}

class ReportUIStore implements ReportUIStoreInterface {
  @observable
  report?: object;

  @action
  getWidget({
    config,
    context,
    source,
    dashboardId,
  }: {
    config: object;
    context: object;
    source: string;
    dashboardId: string;
  }) {
    const url = `${source}reportWidget?forReport=${dashboardId}`;

    return thematicData.getWidget(url, config, context);
  }
}

export default ReportUIStore;
