import { DashboardWidgetType } from 'api/enums';
import { Context, DashboardWidgetConfig, ReportWidget } from 'api/interfaces';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getReportUIStore } from 'stores/RootStore';

interface UseWidgetDataProps {
  config?: DashboardWidgetConfig;
  context?: Context;
  source?: string;
  dashboardId: string;
  ignoreCompare?: boolean;
}

export const useWidgetData = ({ config, context, source, dashboardId, ignoreCompare = false }: UseWidgetDataProps) => {
  const [error, setError] = useState<string>();
  const [warning, setWarning] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [widgetData, setWidgetData] = useState<ReportWidget | null>(null);
  const lastPromiseRef = useRef<Promise<ReportWidget>>();

  const reportUIStore = getReportUIStore();

  const filteredContext = ignoreCompare
    ? Object.fromEntries(
        Object.entries(context ?? {}).filter(
          ([key]) => !['compareFilter', 'compareFilterName', 'currentCompareFilters'].includes(key)
        )
      )
    : context;

    const fetchData = useCallback(async () => {
      setLoading(true);

      if (config && filteredContext) {
        if (!source) {
          setError('The source for this widget was not found.');
          setLoading(false);
          return;
        }

        const finalConfig = config.type === DashboardWidgetType.METADATA
          ? { ...config, compareToOverall: true }
          : config;

        setWidgetData(null);
        const promise = reportUIStore.getWidget({
          context: filteredContext,
          config: finalConfig,
          source,
          dashboardId
        });
        lastPromiseRef.current = promise;

        try {
          setError(undefined);
          setWarning(undefined);
          const data = await promise;
          if (promise === lastPromiseRef.current) {
            setWidgetData(data);
            setWarning(data.error);
          }
        } catch (e) {
          setError(e?.response?.data?.message ?? e.message ?? 'Could not fetch data.');
        } finally {
          if (promise === lastPromiseRef.current) {
            setLoading(false);
          }
        }
      }
    }, [config, filteredContext, source, dashboardId, reportUIStore]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

  return { error, warning, loading, widgetData };
};
