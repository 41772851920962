import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Ref } from 'semantic-ui-react';
import { Button } from './Button';
import './search-input.scss';

const ClearSearch = ({ onClick }: { onClick: () => void }) => {
  return (
    <span className="search-input__clear">
      <Button icon={<FontAwesomeIcon icon="times" />} onClick={onClick} subvariant="neutral" variant="tertiary" size="small" />
    </span>
  );
};

const SearchIcon = () => {
  return <FontAwesomeIcon className="search-input__icon" icon="search" />;
};

interface Props {
  onChange: (value: string) => void;
  resultCount?: number;
}

const SearchInput = ({ onChange, resultCount }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [search, setSearch] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearch(value);
    onChange(value);
  };

  const handleClear = () => {
    setSearch('');
    onChange('');

    const firstChild = inputRef?.current?.firstChild;

    if (firstChild) {
      (firstChild as HTMLInputElement).focus();
    }
  };

  const showResultCount = resultCount !== undefined && search.length > 0;

  return (
    <div className="search-input">
      {/* Note: https://react.semantic-ui.com/migration-guide/#removal-of-ref-component */}
      <Ref innerRef={inputRef}>
        <Input
          fluid={true}
          icon={search ? <ClearSearch onClick={handleClear} /> : <SearchIcon />}
          onChange={handleChange}
          placeholder="Search"
          type="search"
          value={search}
        />
      </Ref>
      {showResultCount && <span className="search-input__result-count">{resultCount}</span>}
    </div>
  );
};

export { SearchInput };
