import * as React from 'react';
import { ContextMetadataTooltip } from './ContextMetadataTooltip';
import { TranslatedComparison } from '../hooks/useComparisonFilters';
import { AnswersDataSet } from 'api/interfaces';
import fuzzNum from 'vue/libs/fuzz-num';
import './context-comparison.scss';
import { FilterList } from 'components/Filters/FilterList';
import classNames from 'classnames';

interface Props {
  translatedComparisons: TranslatedComparison[];
  dataSets: AnswersDataSet[];
  showFilters: boolean;
}

export const ContextComparison = ({
  translatedComparisons,
  dataSets,
  showFilters
}: Props): JSX.Element => {
  return (
    <div
      className={classNames('context-comparison-group', {
        'context-comparison-group--bordered': showFilters
      })}
    >
      {translatedComparisons.map(({ filters, metaData, name }, index) => {
        return (
          <div className="context-comparison" key={`group-${index}`}>
            <div className="context-comparison-title">
              <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="8" height="8" rx="2" fill={index === 0 ? '#71B0FF' : '#005494'} />
              </svg>
              <span>{name}</span>
            </div>
            <div className="context-comparison-metadata">
              {metaData && (
                <div className="context-comparison-metadata__item">
                  <span className="context-comparison-metadata__label">
                    {fuzzNum(metaData.count)} of {fuzzNum(metaData.totalCount)} comments
                  </span>
                  <ContextMetadataTooltip
                    totalCommentsCount={metaData.totalCount}
                    dataSets={dataSets}
                    filters={filters}
                  />
                </div>
              )}
            </div>
            {showFilters &&
              <FilterList filters={filters} />
            }
          </div>
        );
      })}
    </div>
  );
}
