import { IntegrationType } from 'api/enums';
import * as React from 'react';
import { Form, FormInputProps, Input, Radio } from 'semantic-ui-react';
import './data-source-sftp.scss';
import DataSourceWithIntegrationForm from './DataSourceWithIntegrationForm';

export interface DataSourceSFTPCreateFormProps {
  existingConfiguration?: SFTPConfig;
  setValidity?: (isValid: boolean) => void;
  onChange(configuration: object, integrationId: string): void;
}

export enum TreatmentAfterProcessing {
  DELETE = 'DELETE',
  MOVE = 'MOVE'
}

interface SFTPConfig {
  pathPattern: string;
  treatmentAfterProcessing: TreatmentAfterProcessing;
  moveDestination?: string;
}

interface DataSourceSFTPCreateFormState {
  config: SFTPConfig;
}

class DataSourceSFTPCreateForm extends React.Component<
  DataSourceSFTPCreateFormProps,
  DataSourceSFTPCreateFormState
> {

  state = {
    config: {
      pathPattern: '',
      treatmentAfterProcessing: TreatmentAfterProcessing.DELETE,
      moveDestination: undefined as string | undefined
    }
  };

  componentDidMount() {
    const { existingConfiguration } = this.props;

    if (existingConfiguration) {
      this.setState({
        config: existingConfiguration
      });
      this.checkValidity(existingConfiguration);
    }
  }

  checkValidity = (config: SFTPConfig) => {
    if (this.props.setValidity) {
      let validity = config.pathPattern.length > 0;
      if (config.treatmentAfterProcessing === TreatmentAfterProcessing.MOVE) {
        validity = validity && !!config.moveDestination && config.moveDestination.length > 0;
      }
      this.props.setValidity(validity);
    }
  }

  changeSelectedPathPattern = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    // update the config
    const { config } = this.state;
    config.pathPattern = value;
    this.setState({ config });
    this.checkValidity(config);
  };

  handleAfterUploadTreatmentChange = (treatmentProcessing: TreatmentAfterProcessing) => {
    // update the config
    const { config } = this.state;
    config.treatmentAfterProcessing = treatmentProcessing;
    config.moveDestination = undefined;
    this.setState({ config });
    this.checkValidity(config);
  }

  changeSelectedDestinationPath = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { value }: FormInputProps
  ) => {
    // update the config
    const { config } = this.state;
    config.moveDestination = value;
    this.setState({ config });
    this.checkValidity(config);
  };

  renderForm(): JSX.Element | null {
    const { config } = this.state;
    return (<>
      <Form.Field className="sftp-data-source-row">
        <label>sFTP Path Pattern</label>
        <Input
          data-testid="sftp-path-pattern"
          placeholder="Path Pattern"
          value={config.pathPattern}
          onChange={this.changeSelectedPathPattern}
        />
        <div className="helper-text">This should look like <span className="code-text">uploaded/data_*.csv</span></div>
      </Form.Field>

      <Form.Field className="sftp-data-source-row">
        <div>
          <label>After processing</label>
          <div>
            <Radio
              data-testid="sftp-treatment-delete"
              label="Delete file"
              name="radioGroup"
              value="DELETE"
              checked={config.treatmentAfterProcessing === TreatmentAfterProcessing.DELETE}
              onChange={() => this.handleAfterUploadTreatmentChange(TreatmentAfterProcessing.DELETE)}
            />
          </div>
          <div>
            <Radio
              data-testid="sftp-treatment-move"
              label="Move file to another location"
              name="radioGroup"
              value="MOVE"
              checked={config.treatmentAfterProcessing === TreatmentAfterProcessing.MOVE}
              onChange={() => this.handleAfterUploadTreatmentChange(TreatmentAfterProcessing.MOVE)}
            />
          </div>
        </div>
      </Form.Field>

      {config.treatmentAfterProcessing === TreatmentAfterProcessing.MOVE && (
        <Form.Field className="sftp-data-source-row">
          <div>
            <label>Destination path</label>
            <Input
              data-testid="sftp-destination-path"
              placeholder="Destination path"
              value={config.moveDestination || ''}
              onChange={this.changeSelectedDestinationPath}
            />
            <div className="helper-text">This should look like <span className="code-text">processed/</span></div>
          </div>
        </Form.Field>)}
    </>
    );
  }

  render(): JSX.Element | null {
    const { onChange } = this.props;
    const { config } = this.state;

    return (
      <div>
        <DataSourceWithIntegrationForm
          renderFormComponent={this}
          onChange={onChange}
          config={config}
          configKey={JSON.stringify(config)}
          enumerationSelection={config}
          integrationType={IntegrationType.SFTP}
        />
      </div>
    );
  }
}

export default DataSourceSFTPCreateForm;