import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filterPopUpId } from 'components/Filters/types';
import { Modal, ModalActions, ModalContent, ModalHeader } from 'components/Shared/Modal';
import SingleFilter from 'components/Filters/SingleFilter';
import { forEach, map } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Checkbox, Form, Message, Radio, Table } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import './refresh-mapped-phrases.scss';
import { initializeFilters } from 'components/Filters/filters.service';
import { AnalysisViewSource } from 'api/interfaces';
import { FilterStoreInterface } from 'stores/FilterStore';
import { compose } from 'lib/composeHOCs';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';

export interface RefreshMappedPhrasesProps {
  themesStore: ThemesStoreInterface;
  filterStore: FilterStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;

  open: boolean;
  surveyId: string;

  cancel: () => void;
}
export interface RefreshMappedPhrasesState {
  results?: RefreshMappedPhrasesSuggestionsWithCheckbox;
  selectedFilterRql: string;
  largeSample: boolean;
}

interface RefreshMappedPhrasesSuggestionsWithCheckbox {
  [themeCode: string]: {
    phrase: string;
    confidence: number;
    selected?: boolean;
  }[];
}

const withHocs = compose(
  inject('themesStore', 'themeEditorSessionStore', 'filterStore'),
  observer,
);

export default withHocs(class RefreshMappedPhrases extends React.Component<
  RefreshMappedPhrasesProps,
  RefreshMappedPhrasesState
> {
  abortController = new AbortController();
  state = {
    results: undefined,
    selectedFilterRql: '',
    largeSample: false
  } as RefreshMappedPhrasesState;

  componentDidMount(): void {
    this.initializeFilters();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  initializeFilters() {
    const surveyId = this.props.surveyId;
    const source: AnalysisViewSource = {
      survey: surveyId,
      visualization: '_'
    };

    initializeFilters(
      source,
      [],
    );
  }

  evaluteSuggestions = async () => {
    const { themesStore, surveyId } = this.props;
    const { selectedFilterRql, largeSample } = this.state;

    const sampleSize = largeSample ? 50000 : 1000;

    const results = await themesStore!.refreshMappedPhrases(
      surveyId,
      selectedFilterRql,
      sampleSize,
      this.abortController.signal
    );

    if (results) {
      this.setState({ results });
    }
  }

  close = () => {
    this.props.cancel();
  };

  applyAndClose = () => {
    const {
      themesStore,
      themeEditorSessionStore
    } = this.props;
    const { results } = this.state;
    if (results) {
      const { editingGroup: group } = themesStore;

      // cycle through each group and create a addPhrases transform to add the mapped phrases to each
      forEach(results, (phrases, themeCode) => {
        const selectedPhrases = phrases.reduce((result, phrase) => {
          if (phrase.selected) {
            result.push(phrase.phrase);
          }
          return result;
        }, [] as string[]);

        if (selectedPhrases.length > 0) {
          const node = themesStore.findNodeById(themeCode);
          if (group && node) {
            themesStore.addPhrases({
              group,
              node,
              toThemeId: node.id,
              phrases: selectedPhrases,
            });
            themeEditorSessionStore.addEvent({
              type: 'Addition',
              subType: 'AddMappedPhrase',
              timestamp: Date.now()
            });
          }
        }

      });
    }
    this.close();
  }

  onSelectAllPhraseChange = (themeCode: string) => {
    const { results } = this.state;
    if (results) {
      const phrases = results[themeCode];
      const allSelected = phrases.reduce((result, phrase) => { return result && phrase.selected; }, true);
      results[themeCode].forEach(phrase => {
        phrase.selected = !allSelected;
      });
      this.setState({ results });
    }
  }

  onSelectPhraseChange = (themeCode: string, phraseIndex: number) => {
    const { results } = this.state;
    if (results) {
      results[themeCode][phraseIndex].selected = !results[themeCode][phraseIndex].selected;
      this.setState({ results });
    }
  }

  onFilterClose = () => {
    const rql = this.props.filterStore?.selections.baseline.query ?? '';
    this.setState({ selectedFilterRql: rql });
  };

  handleLargeSampleChange = (largeSample: boolean) => {
    this.setState({ largeSample });
  }

  render() {
    const { open, themesStore, surveyId } = this.props;
    const { results, largeSample } = this.state;
    const {
      refreshingMappedPhrases,
      refreshingMappedPhrasesError,
    } = themesStore!;

    return (
      <div>
        <Modal
          open={open}
          size="large"
          onClose={() => this.close()}
        >
          <ModalHeader>Mapped Phrase Suggestions</ModalHeader>
          <ModalContent>
            <div className="refresh-mapped-phrases-content">
              <div className="search-options">
                <Form>
                  <Form.Group>
                    <Form.Field>
                      <span className="refresh-mapped-phrases-content__filter">
                        <SingleFilter
                          testId="refresh-mapped-phrases-filter"
                          filterPopUpId={filterPopUpId.RefreshMappedPhrases}
                          surveyId={surveyId}
                          onChange={() => { /* no op */ }}
                          onClose={() => this.onFilterClose()}
                          defaultDateToAll={true} />
                      </span>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <div>
                        <label>Sample Size:</label>
                        <Radio
                          label="Normal"
                          name="radioGroup"
                          value="Normal"
                          checked={!largeSample}
                          onChange={() => this.handleLargeSampleChange(false)}
                        />
                        <Radio
                          label="Large Sample"
                          name="radioGroup"
                          value="Large Sample"
                          checked={largeSample}
                          onChange={() => this.handleLargeSampleChange(true)}
                        />
                      </div>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field>
                      <Button
                        color="blue"
                        size="small"
                        onClick={this.evaluteSuggestions}
                      >
                        Create Suggestions
                      </Button>
                    </Form.Field>
                  </Form.Group>
                </Form>
              </div>
              {refreshingMappedPhrasesError &&
                <Message negative={true}>
                  <Message.Content>
                    <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
                    Error refreshing mapped phrases, please try again.
                  </Message.Content>
                </Message>
              }
              {!refreshingMappedPhrasesError && !refreshingMappedPhrases && results &&
                <>
                  <div>
                    {map(results, (phrases, theme) => {
                      const themeNode = themesStore!.findNodeById(theme);
                      if (!themeNode) {
                        return null;
                      }
                      return (<div className="theme-recommendation" key={theme}>
                        <h2>{themeNode.title}</h2>
                        <Table>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className="theme-recommendation__select">
                                <Checkbox
                                  label="Select All"
                                  checked={phrases.reduce((result, phrase) => {
                                    return result && phrase.selected;
                                  }, true)
                                  }
                                  onChange={() => this.onSelectAllPhraseChange(theme)}
                                />
                              </Table.HeaderCell>
                              <Table.HeaderCell className="theme-recommendation__phrase">
                                Phrase
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                Confidence
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {phrases.map((phrase, phraseIndex) => {
                              return (
                                <Table.Row key={phraseIndex}>
                                  <Table.Cell className="theme-recommendation__select">

                                    <Checkbox
                                      checked={phrase.selected}
                                      onChange={() => this.onSelectPhraseChange(theme, phraseIndex)}
                                    />
                                  </Table.Cell>
                                  <Table.Cell className="theme-recommendation__phrase">
                                    {phrase.phrase}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {phrase.confidence}
                                  </Table.Cell>
                                </Table.Row>);
                            })}
                          </Table.Body>
                        </Table>
                      </div>);
                    })}
                  </div>
                </>
              }
              {refreshingMappedPhrases &&
                <FontAwesomeIcon
                  className="icon"
                  icon="spinner"
                  spin={true}
                  fixedWidth={true}
                />
              }
            </div>
          </ModalContent>
          <ModalActions>
            <Button
              onClick={this.close}
            >
              Cancel
            </Button>
            <Button
              color="blue"
              onClick={this.applyAndClose}
            >
              Add Selected Phrases
            </Button>
          </ModalActions>
        </Modal>
      </div>
    );
  }
});
