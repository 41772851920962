import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, map } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, DropdownProps, Form, Modal } from 'semantic-ui-react';
import { Integration, IntegrationConnectionType, IntegrationsStoreInterface } from 'stores/IntegrationsStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { getIntegrationUrlWithState } from './integration-helper';
import IntegrationFieldsForm from './IntegrationFieldsForm';
import IntegrationLogo from './IntegrationLogo';

interface AddIntegrationProps {
  integrationsStore?: IntegrationsStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

interface AddIntegrationState {
  showFieldsModal: boolean;
  showAddIntegration: boolean;
  working: boolean;
  integration?: Integration;
}

@inject('integrationsStore', 'organizationStore')
@observer
export default class AddIntegration extends React.Component<
  AddIntegrationProps,
  AddIntegrationState
> {
  acceptIntegrationsForm = null as unknown as () => Promise<boolean> | null;
  state = {
    showFieldsModal: false,
    showAddIntegration: false,
    working: false
  } as AddIntegrationState;
  connect = () => {
    const {
      organizationStore
    } = this.props;

    const {
      integration
    } = this.state;

    if (!integration) {
      return;
    }

    const {
      integrationUrl: url,
      connectionType
    } = integration;

    if (connectionType === IntegrationConnectionType.OAUTH && url) {
      const { orgId } = organizationStore!;
      const integrationUrl = getIntegrationUrlWithState({ orgId, integrationUrl: url });
      if (integrationUrl) {
        window.location.href = integrationUrl;
      }
    } else if (connectionType === IntegrationConnectionType.FIELDS ||
      connectionType === IntegrationConnectionType.OAUTH_WITH_FIELDS
    ) {
      this.setState({ showFieldsModal: true });
    }
  };

  onAccept = async () => {
    if (this.acceptIntegrationsForm) {
      this.setState({ working: true });
      const success = await this.acceptIntegrationsForm();
      this.setState({ working: false });
      if (success) {
        this.closeModal();
      }
    }
  };

  closeModal = () => {
    this.setState({ showFieldsModal: false });
  };

  handleTypeChange = ({ value }: DropdownProps) => {
    const { integrationsStore: store } = this.props;
    const integration = find(store!.integrations, { type: value });
    if (integration) {
      this.setState({ integration: integration as Integration });
    }
  }

  renderIntegrationForConnection() {
    const { organizationStore } = this.props;
    const { showFieldsModal, working, integration } = this.state;
    const { orgId } = organizationStore!;

    if (!integration) {
      return;
    }

    return (
      <div className="integration">
        <IntegrationLogo integrationType={integration.type} />
        <div className="detail">
          <h3>
            {integration.name}
          </h3>

          <Button as="a" className="plain" size="small" onClick={this.connect}>
            Connect
          </Button>
        </div>

        <Modal
          dimmer="blurring"
          open={showFieldsModal}
          onClose={this.closeModal}
          size="tiny"
          className="add-integration-modal"
        >
          <Modal.Content>
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-title">
                  {integration.name}
                </div>
                <Form>
                  <IntegrationFieldsForm
                    {...integration}
                    orgId={orgId}
                    setAcceptForm={acceptForm => this.acceptIntegrationsForm = acceptForm}
                  />
                </Form>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              disabled={working}
              loading={working}
              color="blue"
              onClick={this.onAccept}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }

  render() {
    const { integrationsStore: store } = this.props;
    const { integration, showAddIntegration } = this.state;

    const connectionForm = this.renderIntegrationForConnection();

    const INTEGRATION_OPTIONS = map(store?.integrations, v => {
      return {
        text: v.name,
        value: v.type,
        key: v.name
      };
    });

    return (
      <li >
        <Button
          color="blue"
          onClick={() => this.setState({ showAddIntegration: true })}>
          + Add new integration
        </Button>
        {showAddIntegration && (<div className="integration-list-item add-integration">
          <Dropdown
            className="integration-type-selector"
            search={true}
            options={INTEGRATION_OPTIONS}
            placeholder="Choose integration"
            value={integration ? integration.type : undefined}
            selection={true}
            onChange={(_e, data) => this.handleTypeChange(data)}
          />
          {connectionForm}

          <FontAwesomeIcon
            className="close-icon"
            onClick={() => this.setState({ showAddIntegration: false })}
            icon="times"
            fixedWidth={true} />
        </div>)}
      </li>
    );
  }
}
